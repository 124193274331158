export class Ubicacion {
    calle: String;
    colonia: String;
    municipio: String;
    codigo_postal: String;

    constructor(
      calle?: string,
      colonia?: string,
      municipio?: string,
      codigo_postal?: string

    ) {}
}
