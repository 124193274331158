import { ComponentRef, ComponentFactoryResolver, ChangeDetectorRef, ViewContainerRef, ViewChild, Component, OnInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
 import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import * as N from '../../graphql/mutations';
import * as A from '../../graphql/queries';
import { SelectItem } from 'primeng/api';
declare var M: any;
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiciosService } from '../../core/services/servicios.service';
import { CreadorComponentesPDFService } from '../../services/pdfmake/creacionComponentes.service';
import { CreadorComprobanteService } from '../../services/pdfmake/creacionComprobante.service';
import { IMAGE } from "../../core/key/imglogo";
import { environment } from '../../../environments/environment';
import { AccountService } from '../../auth/autentication.service';
import { Payments } from '../../payments/payments';
import { SessionStorageService } from '../../core/services/sessionstorage.service';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
type AOA = any[][];
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-detallesregistro',
  templateUrl: './detallesregistro.component.html',
  styleUrls: ['./detallesregistro.component.css']
})
export class DetallesRegistroComponent implements OnInit {
  public paymentsModel: Payments;

  mostrarframe: boolean = false;
  todosvalidados: boolean = false;
  mostrarformpago: boolean = false;

  pdfSrc: any;
  id_registro: any;
  registro_reto: any;
  documentacionTramite: any[];
  documentacionTramiteOpciones: any[];
  documentacionSeleccionada: any;
  file: any;
  fileseleccionado: any;
  formatolineacaptura: any;
  documentoLineaCaptura: any;
  meses: any;
  user: any;
  motivocancelacion: any;
  administrador: boolean = false;
  supervisor: boolean = false;
  modulo: boolean = false;
  statuses: any[];

  constructor(
    private cdref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private apollo?: Apollo,
    private serviciosService?: ServiciosService,
    public creadorComponentesPDFService?: CreadorComponentesPDFService,
    public creadorComprobanteService?: CreadorComprobanteService,
    private sessionStorageService?: SessionStorageService,


  ) {
  }

  ngOnInit() {
    this.paymentsModel = new Payments('3IFAAE001');
    this.traerEstados();

    this.meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    $(document).ready(function() {
      $('.modal').modal({ dismissible: false });
    });
    let id_registro = this.activatedRoute.snapshot.paramMap.get("id_registro");
    this.id_registro = id_registro;
    this.traerRegistro()
    this.user = JSON.parse(this.sessionStorageService.get('usuario'));
    console.log(this.user.rol.nombre);

    if(this.user.rol.nombre.toLowerCase() == 'modulo'){
      this.modulo = true;
    }
    if(this.user.rol.nombre.toLowerCase() == 'supervisor'){
      this.supervisor = true;
    }
    if(this.user.rol.nombre.toLowerCase() == 'administrador' || this.user.rol.nombre.toLowerCase() == 'superusuario'){
      this.administrador = true;
     }
  }


  traerEstados(){
    this.apollo.use('registroReto')
     .watchQuery({
       query: A.GET_CATALOGO_STATUS
     })
     .valueChanges.subscribe(result => {
       this.asignarEstados(result);

     }, (error) => {
     });
  }

  asignarEstados(data){
    this.statuses = [];
     for(var i = 0; i < data.data.catalogos_estatus.length;i++){
      this.statuses.push({id: data.data.catalogos_estatus[i].id, value:data.data.catalogos_estatus[i].id ,label: data.data.catalogos_estatus[i].nombre, name: data.data.catalogos_estatus[i].nombre});
    }

  }

  traerStatusHistorial(etapa){
    let nombreEtapa = '';
    if(etapa!=undefined){
      let estado = this.statuses.find(obj => obj.id === etapa.estatus);
      if(estado!=undefined){
        nombreEtapa = estado.label;
      }
    }

    return nombreEtapa;

  }

  processFile(imageInput: any) {

    const file: File = imageInput.files[0];
    this.file = file;
  }

  traerCatalogoFormatos(){
    this.apollo.use('registroReto')
      .watchQuery({
        query: A.GET_CATALOGO_FORMATOS ,
        fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarCatalogos(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  asignarCatalogos(data){

    this.documentacionTramite = [];

    for(var i = 0; i < data.catalogos_formatos.length;i++){
      if(data.catalogos_formatos[i].nombre.trim() != 'LINEA DE CAPTURA'){
        this.documentacionTramite.push(data.catalogos_formatos[i]);
      }else{
        if(this.registro_reto.estatus.nombre.trim() == 'EN ESPERA DE PAGO'){
          this.documentacionTramite.push(data.catalogos_formatos[i]);
        }
        this.formatolineacaptura = data.catalogos_formatos[i];
      }



    }

    this.traerFormatosenRevista();

  }


  traerFormatosenRevista(){
    this.apollo.use('registroReto')
      .watchQuery({
        query: A.GET_FORMATOS_REGISTRO,
        variables: {
          id_registro_reto: this.id_registro,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarFormatosEncontrado(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }


  asignarFormatosEncontrado(data){
    let arrayaux = [];
    this.todosvalidados= true;
    for(var i = 0; i < data.formatosByRegistro.length;i++){
      let resultado = this.documentacionTramite.find(documento => parseInt(documento.id)  == data.formatosByRegistro[i].id_catalogo_formato);
       if(resultado!=null){
        resultado['documento'] = data.formatosByRegistro[i];
      }

      if(this.formatolineacaptura.id != data.formatosByRegistro[i].id_catalogo_formato){
        if(data.formatosByRegistro[i].validado==false){
          this.todosvalidados = false;
        }

      }else{
        this.documentoLineaCaptura = data.formatosByRegistro[i];
      }
     }
     if(this.registro_reto.estatus.nombre.trim()=='NUEVO' || this.registro_reto.estatus.nombre.trim()=='CORRECCIÓN'){
       this.todosvalidados = false;
     }

     this.documentacionTramiteOpciones=[];
     for(var i = 0; i < this.documentacionTramite.length;i++){
       if(this.documentacionTramite[i].documento!=undefined){
         if(this.registro_reto.estatus.nombre.trim()=='CORRECCIÓN'){
           this.documentacionTramiteOpciones.push(this.documentacionTramite[i]);
         }

         /*
         if(!this.documentacionTramite[i].documento.validado){
           this.documentacionTramiteOpciones.push(this.documentacionTramite[i]);
         }
         */
       }else{
         this.documentacionTramiteOpciones.push(this.documentacionTramite[i]);
       }
     }


     //console.log(data.formatosByRegistro.every((currentValue) => (currentValue.validado || currentValue.id != this.formatolineacaptura.id)));
     //this.todosvalidados = data.formatosByRegistro.every((currentValue) => currentValue.validado);


  }



  traerRegistro(){
    this.apollo.use('registroReto')
      .watchQuery({
        query: A.GET_REGISTRO_RETO,
        variables: {
          id: this.id_registro,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarRegistro(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  asignarRegistro(data){
    if(this.registro_reto==undefined){
      this.registro_reto = data.registro_reto;
      this.traerConcesion();
      this.traerConcesionario();
      if(this.registro_reto.id_persona!=null){
        this.traerPersona();
      }
      this.traerModalidad();
      this.traerModulo();
      this.traerCatalogoEstatus();
      this.traerEncargado();
      this.traerLicencia();
      this.traerHistorial();
    }


  }

  traerHistorial(){
    this.apollo.use('registroReto').watchQuery({
      query: A.GET_HISTORIAL,
      variables: {
        id: this.registro_reto.id,
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarHistorial(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarHistorial(data){
       this.registro_reto['historial'] = data.data.historialCambioFases.reverse();




    /*
    for(var i = 0; i < data.data.historialCambioFases.length;i++){
      //saguz
      let indice = i;
      this.apollo.use('registroReto')
        .watchQuery({
          query: A.GET_CATALOGO_FORMATO,
          variables: {
            id: data.data.historialCambioFases[indice].estatus,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.agregarCatalogoEstatusHistorial(result.data,indice)
        }, (error) => {
          M.toast({ html: error })
        });
    }
    */

  }

  agregarCatalogoEstatusHistorial(data,indice){
    //this.registro_reto['historial'][indice].hola = data.catalogo_estatus;
    //console.log(this.registro_reto['historial'][indice]);
    //this.registro_reto['historial'][indice]['estatus'] = data.catalogo_estatus;

  }

  funcioncrearfechaString(stringdate: any){
    if(stringdate == ''){
      return '';
    }
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    //let fecha = new Date(stringdate);
    var arrayDeCadenas = stringdate.split('T');
    var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
  //let fecha = new Date(stringdate);
    let fecha = new Date(parseInt(arrayDeCadenas2[0]),parseInt(arrayDeCadenas2[1])-1,parseInt(arrayDeCadenas2[2]));



    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  arrayDeCadenas[0] ;
  }

  traerLicencia(){
    if(this.registro_reto.id_persona!=undefined){
      this.apollo.use('backConductoressinToken').watchQuery({
        query: A.GET_LICENSE_BY_PERSON,
        variables: {
          person_id: this.registro_reto.id_persona,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarlicenciapreregistro(result);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });
    }

  }

  asignarlicenciapreregistro(data) {
    this.registro_reto['licencia'] = data.data.licenseByPerson;
  }

  traerCatalogoEstatus(){
    this.apollo.use('registroReto')
      .watchQuery({
        query: A.GET_CATALOGO_FORMATO,
        variables: {
          id: this.registro_reto.id_catalogo_estatus,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.agregarCatalogoEstatus(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  agregarCatalogoEstatus(data){
    this.registro_reto['estatus'] = data.catalogo_estatus;
    this.traerCatalogoFormatos()

  }


  traerConcesion(){
    this.apollo.use('backConsulta')
      .watchQuery({
        query: A.GET_CONCESION,
        variables: {
          id: this.registro_reto.id_concesion,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.agregarConcesion(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  agregarConcesion(data){
    this.registro_reto['concesion'] = data.concession;
  }

  traerConcesionario(){
    this.apollo.use('backConsulta')
      .watchQuery({
        query: A.GET_CONCESIONARIO,
        variables: {
          id: this.registro_reto.id_concesionario,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.agregarConcesionario(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  agregarConcesionario(data){
    this.registro_reto['concesionario'] = data.concessionaire;
  }


  traerPersona(){

    if(this.registro_reto.id_persona!=undefined){
      this.apollo.use('backConductoressinToken').watchQuery({
        query: A.GET_PERSON,
        variables: {
          id: this.registro_reto.id_persona,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarDatosConductor(result);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });
    }


  }


  asignarDatosConductor(data){
    this.registro_reto['persona'] = data.data.person;

  }


  traerModalidad(){
    this.apollo.use('backConsulta')
      .watchQuery({
        query: A.GET_MODALIDAD,
        variables: {
          id: this.registro_reto.id_modalidad,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarModalidad(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  asignarModalidad(data){
    this.registro_reto['modalidad'] = data.modality;

  }

  traerModulo(){
    this.serviciosService.getModulo(this.registro_reto.id_modulo).subscribe({
     next: (data) => {
       this.registro_reto['modulo'] = data;
      },
     error: (error) => {
      },
     complete: () => {
      }
    });
  }

  traerEncargado(){
    this.serviciosService.getUsuario(this.registro_reto.id_usuario).subscribe({
     next: (data) => {
       this.traerPersonal(data.id_persona);
       //this.registro_reto['modulo'] = data;
      },
     error: (error) => {
      },
     complete: () => {
      }
    });
  }


  traerPersonal(id){
    this.serviciosService.getPersona(id).subscribe({
     next: (data) => {
       this.registro_reto['personal'] = data.Personal;
      },
     error: (error) => {
      },
     complete: () => {
      }
    });
  }

  pintarprevisualizacion(file){
    this.fileseleccionado = file;
    this.mostrarformpago = false;


    $(document).ready(function(){
     $('#modalVisualizacionComprobantePago').modal('open');
     });

     this.mostrarframe = true;

    setTimeout(() => {
     // this.pdfSrc = "http://172.80.13.47:8092/attdoc/attached/" + file.file_name;
     this.pdfSrc = environment.URLRegistroRETO + "doc/" + file.documento.nombre;

       var f = document.getElementById('frame');
        f.setAttribute('src',this.pdfSrc);
     }, 5);



  }

  cerrarModal(){
    $('.modal').modal('close');

  }


  subirarchivo(){
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.CREATE_FORMATO,
       variables: {
         id_catalogo_formato: this.documentacionSeleccionada.id,
         id_registro_reto: this.registro_reto.id
       }
     })
     .subscribe(({ data }) => {
       this.getAttachedFolder(data);
      }, (error) => {
       console.log(error)
     });



  }


  getAttachedFolder(data){
    let testData:FormData = new FormData();
    testData.append('doc', this.file,this.file.name);
    testData.append('id_formato', data.formato.id);
    this.http.post<any>( environment.URLRegistroRETO + `doc/upload`, testData, {
      headers: { 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }` }
    }).subscribe(

     (res) => {
       window.location.reload();
     },
     (err) => {
       console.log(err);

     }
   );
/*
    this.apollo.use('tramites').watchQuery({
      query: A.GET_ATTACHED_FOLDER,variables: {
            folder_id: expediente.id
         }, fetchPolicy: 'network-only'
    })
    .valueChanges.subscribe(result => {
      this.asignarAttached(result,expediente);
    }, (error) => {
    });

    */

  }


  asignarAttached(data,expediente){
/*
    let testData:FormData = new FormData();
    testData.append('attdoc', this.file,this.file.name);
    testData.append('attached_file_id', data.data.attachedByfolder[data.data.attachedByfolder.length-1].id);
    testData.append('mode', 'new');
    this.http.post<any>( environment.URLRegistroRETO + `upload/attached`, testData, {
      headers: { 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }` }
    }).subscribe(

     (res) => {
       window.location.reload();
     },
     (err) => {
       console.log(err);

     }
   );

   */



  }

  funcioncrearfecha(stringdate: any){
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    let fecha = new Date(stringdate);

    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  dia21 +  "-" +  mes21 + "-" + anio21 ;
  }

  imprimirtarjetainformativa(){
    let diaActual = new Date();
    let fecha = diaActual.getDate() + ' de ' + this.meses[diaActual.getMonth()] + ' del ' + diaActual.getFullYear()
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    }
    let content_array = [];
    content_array.push(this.creadorComponentesPDFService.getDatosGenerales(this.registro_reto.observacion.informacion.concesionario, this.registro_reto.observacion.informacion.contactos));

    content_array.push(this.creadorComponentesPDFService.getDatosDeLaConcesion(this.registro_reto.observacion.informacion.concesionario, this.registro_reto.observacion.informacion.vehiculos.length));

    content_array.push(this.creadorComponentesPDFService.getDatosDeUbicacion(this.registro_reto.observacion.informacion.concesionario, this.registro_reto.observacion.informacion.contactos));
    if(this.registro_reto.observacion.informacion.saltos.saltoconductores){
      content_array.push({ text: '',pageBreak: 'after' });
    }
    content_array.push(this.creadorComponentesPDFService.getConductores(this.registro_reto.observacion.informacion.conductores));

      if(this.registro_reto.observacion.informacion.saltos.saltovehiculos){
        content_array.push({ text: '',pageBreak: 'after' });
      }
    content_array.push(this.creadorComponentesPDFService.getDatosVehiculos(this.registro_reto.observacion.informacion.vehiculos));
    if(this.registro_reto.observacion.informacion.saltos.saltobeneficiarios){
      content_array.push({ text: '',pageBreak: 'after' });

    }
    if(this.registro_reto.observacion.informacion.inconsistencias.length>0){
      content_array.push(this.creadorComponentesPDFService.getDatosInconsistencias(this.registro_reto.observacion.informacion.inconsistencias));


    }

    var dd = {
      pageSize: 'LETTER',
      pageMargins: [30, 110, 30, 60],
      background: {
        columns: [
          { width: 300, text: '' },
          { image: 'data:image/jpeg;base64,' + IMAGE.IMAGE_B, width: 256, height: 60, margin: [0, 20, 0, 60] },
        ]
      },
      footer: function(currentPage, pageCount) {
  return {
     margin:10,
     columns: [
     {
         fontSize: 9,
         text:[
         {
         text: '--------------------------------------------------------------------------' +
         '\n',
         margin: [0, 25]
         },
         {
         text: currentPage.toString() + ' de ' + pageCount,
         }
         ],
         alignment: 'center'
       }
     ]
   };
  },
        header: function(page) {
                if (page != 1)
                        return {
                          margin: 10,
                          columns: [
                            {
                              margin: [40, 10, 0, 0],
                              text: ['Secretaría de Movilidad \n Gobierno del Estado de Oaxaca \n', { text: "Unidad de Informática", fontSize: 12, bold: true }, '', { text: '\nSan Antonio de la Cal, Oax., a ' + fecha, fontSize: 8 }], fontSize: 14, bold: true,

                            },
                          ]
                        }
                      else
                              return {
                                margin: 10,
                                columns: [
                                  {
                                    margin: [40, 10, 0, 0],
                                    text: ['Secretaría de Movilidad \n Gobierno del Estado de Oaxaca \n', { text: "Unidad de Informática", fontSize: 12, bold: true }, '', { text: '\nSan Antonio de la Cal, Oax., a ' + fecha, fontSize: 8 }], fontSize: 14, bold: true,

                                  },
                                  {
                                    columns: [
                                      {
                                        margin: [-265, 76, 0, 0],
                                        width: '100%', text: 'INFORMACIÓN GENERAL DEL CONCESIONARIO', fontSize: 13, bold: true, alignment: 'center'
                                      }
                                    ]
                                  },
                                ]
                              }
        },
      content: content_array
    };
    pdfMake.createPdf(dd).download('Tarjeta Informativa.pdf');

  }

  imprimiracuse(){
     this.creadorComprobanteService.crearComprobante(this.registro_reto.observacion.informacion.concesionario);

  }

  calificarformato(value){

    if(this.fileseleccionado!=undefined){
      let  obj = {
        id:this.fileseleccionado.documento.id,
        validado: value
      }
      this.apollo.use('registroReto')
       .mutate({
         mutation:  N.VALID_FORMATO,
         variables: {
           formato: [obj]
         }
       })
       .subscribe(({ data }) => {
         window.location.reload();

         //this.traerRegistro()
         //this.cerrarModal();
        }, (error) => {
         console.log(error)
       });
    }else{
      this.traerRegistro()
      this.cerrarModal();
    }


  }

  cerrarModalPayments(){
    this.mostrarformpago = false;
    this.fileseleccionado = null;
    this.cerrarModal();

  }

  cancelarregistro(){
    $(document).ready(function(){
     $('#modalcrearcomentarios').modal('open');
     });
    /*
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.CREATE_OBSERVACION,
       variables: {
         observacion: this.motivocancelacion,
         informacion: this.registro_reto.observacion.informacion
       }
     })
     .subscribe(({ data }) => {
       this.asignarObservacion(data);

      }, (error) => {
       console.log(error)
     });

     */
    /*
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.CAMBIAR_ESTATUS,
       variables: {
         id: this.registro_reto.id,
         id_catalogo_estatus: 5
       }
     })
     .subscribe(({ data }) => {
       window.location.reload();
      }, (error) => {
       console.log(error)
     });
     */
  }


  asignarObservacion(data){
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.ASIGNAR_OBSERVACION_REGISTRO,
       variables: {
         id: this.registro_reto.id,
         id_observacion: data.observacion.id
       }
     })
     .subscribe(({ data }) => {
       this.cambiarstatuscancelado();

      }, (error) => {
       console.log(error)
     });
  }


  cambiarstatuscancelado(){
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.CAMBIAR_ESTATUS,
       variables: {
         id: this.registro_reto.id,
         id_catalogo_estatus: 5
       }
     })
     .subscribe(({ data }) => {
       window.location.reload();
      }, (error) => {
       console.log(error)
     });
  }

  correcionregistro(){
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.CAMBIAR_ESTATUS,
       variables: {
         id: this.registro_reto.id,
         id_catalogo_estatus: 6
       }
     })
     .subscribe(({ data }) => {
       window.location.reload();
      }, (error) => {
       console.log(error)
     });
  }

  omitirlineacaptura(){
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.CAMBIAR_ESTATUS,
       variables: {
         id: this.registro_reto.id,
         id_catalogo_estatus: 3
       }
     })
     .subscribe(({ data }) => {
       window.location.reload();
      }, (error) => {
       console.log(error)
     });
  }

  cambiarstatuslineacaptura(){
    //saguz
  }

  lineadecapturalibre(){
    $(document).ready(function(){
     $('#modalComprobantePagoDirecto').modal('open');
     });
  }

  subirlineadecaptura(){
    this.apollo.use('registroReto')
     .mutate({
       mutation:  N.CREATE_FORMATO,
       variables: {
         id_catalogo_formato: this.formatolineacaptura.id,
         id_registro_reto: this.registro_reto.id
       }
     })
     .subscribe(({ data }) => {
       this.getAttachedLineaCaptura(data);
      }, (error) => {
       console.log(error)
     });

  }

  getAttachedLineaCaptura(data){
    let testData:FormData = new FormData();
    testData.append('doc', this.file,this.file.name);
    testData.append('id_formato', data.formato.id);
    this.http.post<any>( environment.URLRegistroRETO + `doc/upload`, testData, {
      headers: { 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }` }
    }).subscribe(

     (res) => {
       window.location.reload();
     },
     (err) => {
       console.log(err);

     }
   );
  }


  mostrarvalidacionpago(){
    this.mostrarformpago = true;

  }

  public checkPayments($event){
    if($event.error){
      return M.toast({ html: 'Error en la comprobación de la Linea de Captura.' });

     }else{
     if($event.status){
       this.apollo.use('registroReto')
         .mutate({
           mutation: N.CREATE_LINEA_CAPTURA,
           variables: {
             linea_captura: this.paymentsModel.capture_line,
             amparados: parseInt(this.paymentsModel.valid_for)
           }
         })
         .subscribe(({ data }) => {
           this.asignarLineaCaptura(data)
         }, (error) => {
           M.toast({ html: 'Error al guardar la linea de captura.' });

         });
       //this.pagovalidado();
      }
     }
   }


   asignarLineaCaptura(lineacaptura) {
     this.apollo.use('registroReto')
       .mutate({
         mutation: N.ASIGNACION_LINEA_CAPTURA,
         variables: {
           id_linea_captura: lineacaptura.linea_captura.id,
           id: this.registro_reto.id
         }
       })
       .subscribe(({ data }) => {
         this.calificarformato(true);

       }, (error) => {
         M.toast({ html: 'Error en la asignación de la linea de captura' });

       });
   //  this.displayModalTermino = true;
   }

   inhabilitarregistro(){

     this.apollo.use('registroReto')
      .mutate({
        mutation:  N.AGREGAR_MOTIVO_CANCELACION,
        variables: {
          id_registro_reto: this.registro_reto.id,
          observacion: this.motivocancelacion
        }
      })
      .subscribe(({ data }) => {
        this.cambiarstatuscancelado();

       }, (error) => {
        console.log(error)
      });

     /*
     this.apollo.use('registroReto')
      .mutate({
        mutation:  N.CREATE_OBSERVACION,
        variables: {
          informacion: this.registro_reto.observacion.informacion
        }
      })
      .subscribe(({ data }) => {
        this.asignarObservacion(data);

       }, (error) => {
        console.log(error)
      });

      */

   }

   clear(){
     this.motivocancelacion = null;
     $('.modal').modal('close');

   }

   imprimirregistroinscrito(){
     console.log('Hola Mundo');
   }




}
