import gql from 'graphql-tag';


const GET_TOTALES = gql`
query totalsGeneral {
      totalsGeneral
    }
,
`;


const GET_TOTAL_CONDUCTORES_ACTIVOS = gql`
query totalAcumuladoConductoresFindFecha {
      totalAcumuladoConductoresFindFecha
    }
,
`;


const GET_TOTALES_CONDUCTORES_FECHAS = gql`
query totalConductoresFindFecha($fecha_inicio: Date!,$fecha_fin: Date!) {
      totalConductoresFindFecha(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
    }
,
`;


const GET_TOTALES_FECHAS = gql`
query totalsGeneralByFecha($fecha_inicio: Date!,$fecha_fin: Date!) {
      totalsGeneralByFecha(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
    }
,
`;


const GET_TOTALES_VENCIDOS_CANCELADOS_FECHAS = gql`
query totalsVencidoCancelado($fecha_inicio: Date!,$fecha_fin: Date!) {
      totalsVencidoCancelado(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
    }
,
`;

const GET_TOTALESCONSTANCIAS_X_MODALIDAD = gql`
query totalsModality($mode: String!) {
      totalsModality(mode: $mode)
    }
,
`;


const GET_TOTALESPREREGISTROS_X_MODALIDAD = gql`
query getTotalsPreCertificateModality {
      totalsPreCertificateModality {
        modality {
          id
          name
          description
          status
          abbreviation
        }
        active
        canceled
        completed
        total
      }
    }
,
`;



const GET_TOTALES_X_MODALIDAD_X_GENERO = gql`
query totalsSexByModality($modality_id: ID! , $mode: String!) {
      totalsSexByModality(modality_id: $modality_id , mode: $mode)
    }
,
`;


const GET_TOTALES_X_MODALIDAD_X_CONCESION = gql`
query totalsRegion($mode: String!) {
      totalsRegion(mode: $mode)
    }

,
`;

export { GET_TOTALES_CONDUCTORES_FECHAS,GET_TOTAL_CONDUCTORES_ACTIVOS,GET_TOTALES_VENCIDOS_CANCELADOS_FECHAS,GET_TOTALES_FECHAS,GET_TOTALES,GET_TOTALESCONSTANCIAS_X_MODALIDAD,GET_TOTALESPREREGISTROS_X_MODALIDAD,GET_TOTALES_X_MODALIDAD_X_GENERO,GET_TOTALES_X_MODALIDAD_X_CONCESION };
