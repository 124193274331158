import gql from 'graphql-tag';



const GET_PERMISOS_CONCESION = gql`
query rollByConcession($concession_id: ID!) {
  rollByConcession(concession_id: $concession_id) {
    permiso_id
    concessionaire
    municipality
    person_type
    nuc
    expedition_date
    expiration_date
    serial_number
    engine_number
    model_year
    brand
    model
    color
    capture_line
    payment_folio
    payment_date
    foliated_sheet
  }
}

,
`;


export { GET_PERMISOS_CONCESION};
