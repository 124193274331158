import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Variables
import { environment } from 'src/environments/environment';

// Services
import { AccountService } from '../../auth/autentication.service';

// Models
import { Account } from '../models/account.model';
import { Personal } from '../models/personal.interface';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {
  private readonly WebServiceURI: string = `${environment.URIServicios}`;

  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) {}

    perfil(): Observable<Account> {
      return this.http.post<Account>(`${this.WebServiceURI}/api/usuario/perfil`, {}, {
        headers: { 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }` }
      });
    }

    responsableArea(id_area: string): Observable<Personal> {
      return this.http.post<Personal>(`${this.WebServiceURI}/api/personal/responsable/area/${id_area}`, {
        headers: { 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }` }
      });
    }



      sendLogId(objeto): Observable<any> {
        return this.http.post<any>(`${this.WebServiceURI}/api/movimiento/usuarioLog/`, objeto);
      }

    crearaplicacion(aplicacion): Observable<any> {
      return this.http.post<any>(`${this.WebServiceURI}/api/aplicacion/add`, aplicacion, {
        headers: { "x-client-id": environment.client_id , 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }`  }
      });
    }


    asignacionaplicacionusuario(asignacion): Observable<any> {
      return this.http.post<any>(`${this.WebServiceURI}/api/aplicacion_usuario_rol/add`, asignacion, {
        headers: { "x-client-id": environment.client_id , 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }`  }
      });
    }


    crearunionmoduloarea(asignacion): Observable<any> {
      return this.http.post<any>(`${this.WebServiceURI}/api/modulos_area/add`, asignacion, {
        headers: { "x-client-id": environment.client_id , 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }`  }
      });
    }

    crearpersonal(personal): Observable<any> {
      return this.http.post<any>(`${this.WebServiceURI}/api/personal/add`, personal, {
        headers: { "x-client-id": environment.client_id , 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }`  }
      });
    }


    crearusuario(usuario): Observable<any> {
      return this.http.post<any>(`${this.WebServiceURI}/api/usuario/add`, usuario, {
        headers: { "x-client-id": environment.client_id , 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }`  }
      });
    }


    updatePersona(persona): Observable<any> {
      return this.http.put<any>(`${this.WebServiceURI}/api/personal/updateObligatorio`, persona, {
        headers: { "x-client-id": environment.client_id , 'Authorization': `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }`  }
      });
    }

    getAreas(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/areas/`);
    }

    getTipoDeArea(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/tipo_area/`+id);
    }

    getAreaSuperior(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/area/`+id);
    }

    getAplicaciones(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/aplicaciones/`);
    }

    getPersonal(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/personal/`);
    }

    getPersona(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/personal/`+id);
    }

    getUsuarios(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/usuarios/`);
    }

    getUsuario(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/usuario/`+id);
    }

    getDatosPersonal(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/personal/`+id);
    }

    getModalidadTrabajo(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/modalidad_trabajo/`+id);
    }

    getPuestoLaboral(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/puesto_laboral/`+id);
    }

    getUsuariosxAplicacion(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/aplicacion_usuario_rol/aplicacion/`+id);
    }

    getRoles(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/roles/`);
    }

    getRole(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/rol/`+id);
    }

    getPuestosLaborales(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/puesto_laboral`);
    }

    getModalidadesTrabajo(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/modalidad_trabajo`);
    }

    getModulosAreas(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/modulos_areas`);
    }

    getModulos(): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/modulos/`);
    }

    getModulo(id): Observable<any> {
      return this.http.get(`${this.WebServiceURI}/api/modulo/`+id);
    }

    cambiarEstadoSocket(info): Observable<any> {
      return this.http.put<any>(`${this.WebServiceURI}/api/sockets/updatedresponse`, info);
    }

  }
