
 export {
   CHANGE_BLOQUEADO_CONDUCTOR,CREATE_PERSONA,SET_PERSONA_DIRECCION,SET_PERSONA_CONTACTO,SET_PERSONA_DISCAPACIDAD,SET_PERSONA_IDENTIDAD,SET_PERSONA_LICENCIA,SET_PERSONA_PERSONAL,
   CREATE_PERSON , CREATE_CERTIFICADO, CANCEL_CERTIFICATE,NEW_CAPACITACIONES,CHANGE_ESTATUS,CREATE_PREREGISTRO,CHANGE_PREREGISTRO,CANCEL_PREREGISTRO,ACTIVATE_PREREGISTRO,UPDATE_DRIVER,ACTUALIZAR_DATOSGENERALES_DRIVER,ACTUALIZAR_DIRECCION_DRIVER,ACTUALIZAR_CONTACTO_DRIVER } from './person.gql';
 export { CREATE_DIRECCION,CREATE_ASENTAMIENTO,CREATE_NOMBREASENTAMIENTO,CREATE_PLACA,CHANGE_PLACA_PREREGISTRO } from './ubicaciones.gql';
 export { CREATE_OBSERVATION } from './observaciones.gql';
 export { CREATE_OBSERVACION_CONDUCTOR,CREATE_PLATE,CREATE_CERTIFICATE,CREATE_PRECERTIFICATE,CONVERT_PRE_TO_CERTIFICATE,CANCELAR_PREREGISTRO,CREATE_COMENTARIO } from './entidades.gql';
 export { CREATE_RESPONSABLE,ASIGNAR_RESPONSABLE_SITIO,REPORTE_SITIO_RESPONSABLE } from './sitios.gql';
 export { CREATE_LOG } from './insertLog.gql';
 export { AGREGAR_MOTIVO_CANCELACION,CAMBIAR_ESTATUS,VALID_FORMATO,CREATE_FORMATO,CREATE_REGISTRO,CREATE_OBSERVACION,ASIGNAR_OBSERVACION_REGISTRO }  from './registroreto.gql';
 export { CREATE_LINEA_CAPTURA,ASIGNACION_LINEA_CAPTURA } from './payments.gql';
