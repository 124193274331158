export type Maybe<T> = T | null;

// ====================================================
// Documents
// Registro de una persona con sus datos correspondientes
// ====================================================


////////////////////////////////////////////


export namespace CreateAsentamiento {
  export type Variables = {
    name: String;
    locality_id: String;
    settlement_type_id: String;
  };

  export type Mutation = {
    __typename?: "Mutation";

    createAsentamiento: Maybe<CreateAsentamiento>;
  };

  export type CreateAsentamiento = {
    __typename?: "asentamiento";

    name: Maybe<String>;
    locality_id: Maybe<String>;
    settlement_type_id: Maybe<String>;

  };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";

import gql from "graphql-tag";
// ====================================================
// Apollo Services
// ====================================================



@Injectable({
  providedIn: "root"
})
export class CreateAsentamientoGQL extends Apollo.Mutation<
  CreateAsentamiento.Mutation,
  CreateAsentamiento.Variables
> {
  document: any = gql`
  mutation newSettlement(
  $name: String!
  $locality_id: ID!
  $settlement_type_id: ID!
) {
  settlement(
    name: $name
    locality_id: $locality_id
    settlement_type_id: $settlement_type_id
  ) {
    id
    name
    locality {
      id
      name
      municipality {
        id
        name
        status
        created_at
      }
      status
      created_at
    }
    settlement_type {
      id
      name
      description
      status
      created_at
    }
    status
    created_at
  }
}

  `;
}


// ====================================================
// END: Apollo Angular template
// ====================================================
