import { Component, OnInit} from '@angular/core';
import {StorageService} from "./core/services/storage.service";
import {User} from "./core/models/user.model";
import { environment } from '../environments/environment';
import { AccountService } from './auth/autentication.service';
import { ServiciosService } from './core/services/servicios.service';
import { SessionStorageService } from './core/services/sessionstorage.service';

declare var M: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'retobusqueda';
 public user: any;
 loged: boolean = false;
 administrador: boolean = false;
 operativo: boolean = false;
 consulta: boolean = false;
 supervisor: boolean = false;
 modulo: boolean = false;
 project_version: any;
 capturista: boolean = false;

  constructor(
     private storageService: StorageService,
     private sessionStorageService: SessionStorageService,
     private accountService: AccountService,
     private serviciosService: ServiciosService,
  ) { }


  ngOnInit() {

    if(this.accountService.isLoggetIn()){
      this.user = JSON.parse(this.sessionStorageService.get('usuario'));
      if(this.user == null){
        this.getPerfil();
      }
      this.loged = true;
      setTimeout(() => {

        $(".dropdown-trigger").dropdown();

          $(document).ready(function(){
            $('.sidenav').sidenav();
          });
     }, 100);
    }else{
      this.loged = false;
    }

    if(this.user!=undefined){

      if(this.user.rol.nombre.toLowerCase()=='modulo'){
        this.modulo = true;

      }
      if(this.user.rol.nombre.toLowerCase()=='capturista'){
        this.capturista = true;

      }
      if(this.user.rol.nombre.toLowerCase()=='administrador' || this.user.rol.nombre.toLowerCase()=='superusuario'){
        this.administrador = true;

      }
    }
  }

  mandarmenu(){
    var elem= document.querySelector('.modal');
    var instance = M.Sidenav.getInstance(elem);
    instance.open();
  }

  public logout(): void{
    let ruta = "/";
    this.accountService.logout();
    this.sessionStorageService.clean();
    //this.storageService.logout();
    window.location.href = ruta;
  }

  getPerfil(){
     this.serviciosService.perfil().subscribe({
       next: (data) => {
          this.sessionStorageService.set("usuario", JSON.stringify(data))
         this.user = JSON.parse(this.sessionStorageService.get('usuario'));

       },
       error: (error) => {},
       complete: () => {
       }
     });
  }

  }
