  import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import { Observable,Observer } from 'rxjs';
  //import {Concesion} from "../../models/vo/concesion";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import gql from 'graphql-tag';
  /*
  import * as XLSX from 'xlsx';
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';
  */
  type AOA = any[][];
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  declare var M: any;

  export interface myinterface {
      remove(index: number,nameComponet: any);
  }

  @Component({
    selector: 'app-datosGenerales', templateUrl: './datosGenerales.component.html', styleUrls: ['./datosGenerales.component.css']
  })
export class DatosGenerales {
  public index: number;
  public selfRef: DatosGenerales;
  public compInteraction: myinterface;
  @Input() IModel: any;
  @Input() NameComponet: any;
  @Input() Operativo: any;
  @Input() ContactoConcesionario: any;

  constructor(){}
  verImodel(){
  }
  removeMe(index,nameComponet) {
    this.compInteraction.remove(index,nameComponet)
  }
}
