import gql from 'graphql-tag';

const CREATE_OBSERVATION = gql`
mutation newObservation($description: String!, $certificate_id: ID!) {
      observation(description: $description, certificate_id: $certificate_id) {
        id

      }
    }


`;

export { CREATE_OBSERVATION };
