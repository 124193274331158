import { Injectable } from '@angular/core';
 import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as M from '../../graphql/mutations';
import {Apollo} from 'apollo-angular';
import {FIRMA} from "../../core/key/firma";
import {CALIBRI} from "../../core/key/calibri";
import {CALIBRIB} from "../../core/key/calibrib";
import {UNIVIAB} from "../../core/key/univiaprob";
import {UNIVIABLACK} from "../../core/key/univiablack";
import {UNIVIANORMAL} from "../../core/key/univianormal";

import {IMGDEFAULT} from "../../core/key/imgdefault";
import * as jsPDF from 'jspdf';
import { environment } from '../../../environments/environment';

type AOA = any[][];
declare var saveAs:any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})

export class CreadorReportePDFService {
  meses: any = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");

  constructor() { }

  crearReporte(array,diacorte,sitio){
    let imageurl;
    setTimeout(() => {
      imageurl = this.getBase64ImageFromURL(environment.URIRecursos + '/assets/images/banner.png',array,diacorte,sitio);
    }, 100);
  }


  getBase64ImageFromURL(url,array,diacorte,sitio) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
       this.crearReporteConImagen(dataURL,array,diacorte,sitio);
       };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }


  crearReporteConImagen(dataURL,array,diacorte,sitio){
    let diaActual = new Date();
    let fecha =  diaActual.getDate()  + ' DE '+ this.meses[diaActual.getMonth()].toUpperCase() +' DEL ' + diaActual.getFullYear()

    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    }
    let content_array = [];
    content_array.push(this.getDatosTablaReporteLocalidad(array));
    var dd = {
       pageSize: 'LEGAL',
      pageOrientation: 'landscape',
      pageMargins: [ 15, 140, 20, 40 ],
    //  watermark: {  width: 600,text: 'CONSULTA', angle: 315 },

      background:{ columns: [
        { width: 700,text: ''},
        { image: dataURL,width: 256,height: 60, margin: [0, 20, 0, 60]},
      ]
    },
          footer: function(currentPage, pageCount) {
      return {
         margin:10,
         columns: [
         {
             fontSize: 9,
             text:[
             {
             text: '--------------------------------------------------------------------------' +
             '\n',
             margin: [0, 25]
             },
             {
             text: currentPage.toString() + ' de ' + pageCount,
             }
             ],
             alignment: 'center'
           }
         ]
       };
      },
    header: {
      margin: 10,
      columns: [
        {
          margin: [40, 10, 0, 0],
          text:[ 'Secretaría de Movilidad \n Gobierno del Estado de Oaxaca \n' , '',{text: '\nSan Antonio de la Cal, Oax., A ' + fecha, fontSize: 8},{text: '\n\n\nFecha de Corte:' + this.funcioncrearfechaString(diacorte) + ' Sitio:' + sitio, fontSize: 12}], fontSize: 14,bold: true,
        },
        { columns: [
          {
            margin: [-1005, 77, 0, 0],
            width: '200%', text: 'REPORTE DE CONDUCTORES ', fontSize: 13,bold: true,alignment: 'center' }
          ]
        },


      ]
    },
    content: content_array
    };
     pdfMake.createPdf(dd).download('Reporte.pdf');
  }


  getDatosTablaReporteLocalidad(array: any){
    let arrayParaTabla = [];
    let contador = 0;
    let arrayContadores = [];

    for(var i = 0; i < array.length; i++){
       arrayContadores = [];

      if(i==0){

        arrayContadores.push(this.cuantasvecessalta(array[i].concesionario,30));
        arrayContadores.push(this.cuantasvecessalta(array[i].modalidad,11));



        let mayor = Math.max.apply(null,arrayContadores);
        contador = contador + mayor;
      }else{
        if(i!=(array.length-1)){
/*
          arrayContadores.push(this.cuantasvecessalta(array[i+1].concessionaire,26));
          arrayContadores.push(this.cuantasvecessalta(array[i+1].site,20));
          arrayContadores.push(this.cuantasvecessalta(array[i+1].region,16));
          arrayContadores.push(this.cuantasvecessalta(array[i+1].district,11));
          arrayContadores.push(this.cuantasvecessalta(array[i+1].municipality,14));
          arrayContadores.push(this.cuantasvecessalta(array[i+1].locality,14));
          arrayContadores.push(this.cuantasvecessalta(array[i+1].agreement,23));
          arrayContadores.push(this.cuantasvecessalta(array[i].modality,11));
          */
          arrayContadores.push(this.cuantasvecessalta(array[i].concesionario,30));
          arrayContadores.push(this.cuantasvecessalta(array[i].modalidad,11));

          let mayor = Math.max.apply(null,arrayContadores);
          contador = contador + mayor;
        }else{
/*
arrayContadores.push(this.cuantasvecessalta(array[i].concessionaire,26));
arrayContadores.push(this.cuantasvecessalta(array[i].site,18));
arrayContadores.push(this.cuantasvecessalta(array[i].region,16));
arrayContadores.push(this.cuantasvecessalta(array[i].district,11));
arrayContadores.push(this.cuantasvecessalta(array[i].municipality,14));
arrayContadores.push(this.cuantasvecessalta(array[i].locality,14));
arrayContadores.push(this.cuantasvecessalta(array[i].agreement,23));
arrayContadores.push(this.cuantasvecessalta(array[i].modality,11));
*/
arrayContadores.push(this.cuantasvecessalta(array[i].concesionario,30));
arrayContadores.push(this.cuantasvecessalta(array[i].modalidad,11));


          let mayor = Math.max.apply(null,arrayContadores);
          contador = contador + mayor;
        }
      }


       if(contador > 30){
         if(array[i+1]!=undefined){
           arrayParaTabla.push(
             [ {text: (i+1), fontSize:7,alignment: 'center' } ,
             {text: array[i].sitio, fontSize:7,alignment: 'center' } ,
             { text: array[i].nuc, fontSize:7,alignment: 'center' },
             { text: array[i].modalidad, fontSize:7,alignment: 'center'},

             { text: array[i].concesionario, fontSize:7},
             { text: array[i].placas, fontSize:7},
             { text: array[i].folio, fontSize:7,alignment: 'center'},

             { text: array[i].nombre_conductor, fontSize:7 },
             { text: this.funcioncrearfecha(array[i].fecha_captura), fontSize:7,alignment: 'center' },
             { text: this.funcioncrearfecha(array[i].vigencia), fontSize:7,alignment: 'center'},
             { text: '',pageBreak: 'after' }
                ]
           );
         }else{
           arrayParaTabla.push(
             [  {text: (i+1), fontSize:7,alignment: 'center' } ,
             {text: array[i].sitio, fontSize:7,alignment: 'center' } ,
             { text: array[i].nuc, fontSize:7,alignment: 'center' },
             { text: array[i].modalidad, fontSize:7,alignment: 'center'},

             { text: array[i].concesionario, fontSize:7},
             { text: array[i].placas, fontSize:7},
             { text: array[i].folio, fontSize:7,alignment: 'center'},

             { text: array[i].nombre_conductor, fontSize:7 },
             { text: this.funcioncrearfecha(array[i].fecha_captura), fontSize:7,alignment: 'center' },
             { text: this.funcioncrearfecha(array[i].vigencia), fontSize:7,alignment: 'center'},
                ]
           );
         }

         contador = 0;
       }else{
         if(i == 0){
           arrayParaTabla.push(
             [ {text: 'No.', fontSize:9, bold: true,alignment: 'center' } ,
             { text: 'Sitio', fontSize:9, bold: true,alignment: 'center' }  ,
             { text: 'NUC', fontSize:9, bold: true,alignment: 'center' } ,
             { text: 'Modalidad', fontSize:9, bold: true,alignment: 'center' } ,

             { text: 'Concesionario', fontSize:9, bold: true,alignment: 'center' } ,
             { text: 'Placas', fontSize:9, bold: true,alignment: 'center' } ,
             { text: 'Folio', fontSize:9, bold: true,alignment: 'center' } ,
             { text: 'Nombre Conductor', fontSize:9 , bold: true,alignment: 'center'}   ,
             { text: 'Fecha de Captura', fontSize:9, bold: true,alignment: 'center' }  ,
             { text: 'Fecha de Vencimiento', fontSize:9 , bold: true,alignment: 'center'}

           ]
         );
       }
       arrayParaTabla.push(
         [ {text: (i+1), fontSize:7,alignment: 'center' } ,
         {text: array[i].sitio, fontSize:7,alignment: 'center' } ,
         { text: array[i].nuc, fontSize:7,alignment: 'center' },
         { text: array[i].modalidad, fontSize:7,alignment: 'center'},

         { text: array[i].concesionario, fontSize:7},
         { text: array[i].placas, fontSize:7},
         { text: array[i].folio, fontSize:7,alignment: 'center'},

         { text: array[i].nombre_conductor, fontSize:7 },
         { text: this.funcioncrearfecha(array[i].fecha_captura), fontSize:7,alignment: 'center' },
         { text: this.funcioncrearfecha(array[i].vigencia), fontSize:7,alignment: 'center'},
            ]
       );
       }


      }
      let datosTablaReporteLocalidad =
                {
                  style: 'tableExample',
                  table: {
                  headerRows: 1,
                  widths: [ '2%','14%', '10%','8%' , '20%','7%','6%', '20%','7%','7%','0%' ],
                  body:  arrayParaTabla,
                },
      			layout: {
      				fillColor: function (rowIndex, node, columnIndex) {
      					return (rowIndex === 0) ? '#CCCCCC' : null;
      				},
              hLineWidth: function (i, node) {
      					return (i === 0 || i === node.table.body.length) ? 2 : 1;
      				},
      				vLineWidth: function (i, node) {
      					return (i === 0 || i === node.table.widths.length) ? 2 : 1;
      				},
      				hLineColor: function (i, node) {
      					return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
      				},
      				vLineColor: function (i, node) {
      					return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
      				},
      			}
         };
        return datosTablaReporteLocalidad;
      }


  cuantasvecessalta(palabra: any, tamanio: any){
    let veces = 1;
    let tamaniopalabra = palabra.length;
    for(var i = 1; (i*tamanio) < tamaniopalabra; i++){
      veces = veces + 1;
    }
    return veces;
  }

  funcioncrearfecha(stringdate: any){

    if(stringdate == ''){
      return '';
    }
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    //let fecha = new Date(stringdate);
    var arrayDeCadenas = stringdate.split('T');
    var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
  //let fecha = new Date(stringdate);
    let fecha = new Date(parseInt(arrayDeCadenas2[0]),parseInt(arrayDeCadenas2[1])-1,parseInt(arrayDeCadenas2[2]));


    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  dia21 +  "-" + mes21 + "-" + anio21 ;
  }


  funcioncrearfechaString(stringdate: any){

    if(stringdate == ''){
      return '';
    }
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    //let fecha = new Date(stringdate);
    var arrayDeCadenas = stringdate.split('T');
    var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
  //let fecha = new Date(stringdate);
    let fecha = new Date(parseInt(arrayDeCadenas2[0]),parseInt(arrayDeCadenas2[1])-1,parseInt(arrayDeCadenas2[2]));


    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  dia21 +  "-" + meses[parseInt(arrayDeCadenas2[1])-1] + "-" + anio21 ;
  }
}
