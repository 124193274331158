import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { User } from "../core/models/user.model";
import { StorageService } from "../core/services/storage.service";
import { saveAs } from 'file-saver';
declare var M: any;
import * as N from '../graphql/mutations';
import * as A from '../graphql/queries';

import { ServiciosService } from '../core/services/servicios.service';
import { CreadorReporteExcel } from '../services/excel/creacionReporteExcel.service';

@Component({
  selector: 'app-totales', templateUrl: './totales.component.html', styleUrls: ['./totales.component.css']
})
export class TotalesComponent implements OnInit {
  modalidades: any;
  mostrardata: boolean = false;
  mostrarsubtabla: boolean = false;
  loading: boolean = false;
  objmodalidades: any;
  mostrartablaconcesiones: boolean = false;
  despuesbusqueda: boolean = false;
  despuesbusquedaxregistro: boolean = false;

  loadingconductores: boolean = false;
  hombres: any;
  mujeres: any;
  concesiones: any;
  objetosxregionxfecha: any;
  constanciasactivasfechas: any;
  constanciascanceladasfechas: any;
  constanciasvencidasfechas: any;

  preregistrosactivosxfecha: any;
  preregistroscanceladosxfecha: any;
  totalconstanciasactivas: any;
  totalpreregistrosactivas: any;
  totalconcesionesconstanciasactivas: any;
  totalconcesionespreregistrosactivas: any;

  totalconductoresactivos: any;
  conductoresactivosxfecha: any;

  totalregistrosrango: any;

  concessions_active_certificates: any;
  concessions_active_pre_certificates: any;

  modalidesconstancias: any;
  modalidadespreregistros: any;
  mensaje: any;
  date2: Date;
  date1: Date;
  date3: Date;
  date4: Date;
  maxDate: Date;

  arrayStatusTotales: any[];
  statuses: any[];
  arrayModulosTotales: any[];
  arrayModalidadesTotales: any[];

  arrayRegistrosReto: any[];

  //
  es: any;
  mensajeclick:any;
  constructor(
    private router?: Router,
    private apollo?: Apollo,
    private storageService?: StorageService,
    private serviciosService?: ServiciosService,
    public creadorReporteExcel?: CreadorReporteExcel,

  ) { }

  ngOnInit() {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    this.traerEstados();
    $(document).ready(function(){
     $('.tabs').tabs();
   });
   $(document).ready(function() {
     $('.modal').modal({ dismissible: false });
   });
   this.es = {
           firstDayOfWeek: 1,
           dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
           dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
           dayNamesMin: [ "D","L","M","X","J","V","S" ],
           monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
           monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
           today: 'Hoy',
           clear: 'Borrar'
       }
    this.buscarTotales();
    this.mensaje = '';
    this.totalconductoresactivos = 0;
    this.conductoresactivosxfecha = 0;
    this.primerBusquedaTotales();
    this.buscarconductorestotales();
  }

  buscarconductorestotales(){
    this.loadingconductores = true;
    this.apollo.use('backretoutilidades').watchQuery({
      query: A.GET_TOTAL_CONDUCTORES_ACTIVOS,
       fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarConductosActivos(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarConductosActivos(data){
    this.totalconductoresactivos = data.data.totalAcumuladoConductoresFindFecha;
    this.loadingconductores = false;

  }

  versubtabla(modalidad, modalidadespecifica, estado) {
    for (var i = 0; i < this.objmodalidades.length; i++) {
      this.objmodalidades[i][0].mostrarsubtabla = false;
    }
    modalidad.mostrarsubtabla == true ? modalidad.mostrarsubtabla = false : modalidad.mostrarsubtabla = true;
    this.buscarTotalesXGeneroXModalidad(modalidad, modalidadespecifica, estado);



  }

  mostrarConstancias() {
    this.mostrardata = true;
    this.mostrartablaconcesiones = false;
    this.mensaje = 'Constancias';
    var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
    div1.style.backgroundColor = "#4db6ac";
    var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
    div2.style.backgroundColor = null;

    var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
    div3.style.backgroundColor = null;
    var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
    div4.style.backgroundColor = null;
    this.buscarTotalesPorModalidad("CER");


  }

  mostrarPreregistros() {
    this.mostrardata = true;
    this.mostrartablaconcesiones = false;

    this.mensaje = 'Pre-Registros';
    var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
    div1.style.backgroundColor = null;
    var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
    div2.style.backgroundColor = "#4db6ac";

    var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
    div3.style.backgroundColor = null;
    var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
    div4.style.backgroundColor = null;
    this.buscarTotalesPorModalidad("PRE");
  }

  mostrarConcesiones(option) {
    this.mostrartablaconcesiones = true;
    this.mostrardata = false;


    var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
    div1.style.backgroundColor = null;
    var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
    div2.style.backgroundColor = null;

    if (option == 'CER') {
      this.mensaje = 'Total de concesiones con constancias activas por región';

      var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
      div3.style.backgroundColor = "#4db6ac";
      var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
      div4.style.backgroundColor = null;
    }

    if (option == 'PRE') {
      this.mensaje = 'Total de concesiones con preregistros activas por región';

      var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
      div3.style.backgroundColor = null;
      var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
      div4.style.backgroundColor = "#4db6ac";
    }
    this.loading = true;

    this.apollo.use('backretoutilidades').watchQuery({
      query: A.GET_TOTALES_X_MODALIDAD_X_CONCESION,
      variables: {
        mode: option
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asinarConsesiones(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
        M.toast({ html: toastHTML });
      });


  }

  buscarTotales() {
    this.loading = true;
    this.apollo.use('backretoutilidades').watchQuery({
      query: A.GET_TOTALES, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarTotales(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarTotales(data) {
    this.loading = false;
    this.totalconstanciasactivas = data.data.totalsGeneral.active_certificates;
    this.totalpreregistrosactivas = data.data.totalsGeneral.active_pre_certificates;
    this.totalconcesionesconstanciasactivas = data.data.totalsGeneral.concessions_active_certificates;
    this.totalconcesionespreregistrosactivas = data.data.totalsGeneral.concessions_active_pre_certificates;
  }

  buscarTotalesPorModalidad(option) {
    this.loading = true;

    this.apollo.use('backretoutilidades').watchQuery({
      query: A.GET_TOTALESCONSTANCIAS_X_MODALIDAD,
      variables: {
        mode: option
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarTotalesxModalidad(result, option);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarTotalesxModalidad(data, option) {

    this.objmodalidades = [];
    for (var i = 0; i < data.data.totalsModality.length; i++) {
      this.objmodalidades.push(
        [
          {
            nombre: data.data.totalsModality[i].modality,
            id: data.data.totalsModality[i].modality_id,
            entidad: option,
            mostrarsubtabla: false,
            caracteristicas: [],
            cancelados: {
              total: data.data.totalsModality[i].canceled,
              genero: {
                hombres: 0,
                mujeres: 0
              }


            },
            activos: {
              total: data.data.totalsModality[i].active,
              genero: {
                hombres: 0,
                mujeres: 0
              }

            },
            totales: {
              total: data.data.totalsModality[i].total,
              genero: {
                hombres: 0,
                mujeres: 0
              }

            }

          }
        ]

      );
    }


    this.loading = false;


  }


  buscarTotalesXGeneroXModalidad(modalidad, entidad, option) {
    this.apollo.use('backretoutilidades').watchQuery({
      query: A.GET_TOTALES_X_MODALIDAD_X_GENERO,
      variables: {
        modality_id: modalidad.id,
        mode: modalidad.entidad + '-' + option
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarGeneroModalidad(result, entidad);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
        M.toast({ html: toastHTML });
      });

  }

  asignarGeneroModalidad(data, entidad) {
    entidad.genero.hombres = data.data.totalsSexByModality[0].men;

    entidad.genero.mujeres = data.data.totalsSexByModality[0].women;
    this.hombres = entidad.genero.hombres;
    this.mujeres = entidad.genero.mujeres;
    this.loading = false;



  }

  mostrarConcesionesTotales() {
    this.loading = false;

  }


  asinarConsesiones(data) {
    this.loading = false;
    this.mostrartablaconcesiones = true;
    this.concesiones = data.data.totalsRegion;
  }

  buscarporfechas(){
    this.loading = true;
    var theBigDay = new Date();
    theBigDay.setDate(1);
    theBigDay.setMonth(0);
    theBigDay.setFullYear(2020);

    this.apollo.use('backretoutilidades').watchQuery({
      query: A.GET_TOTALES_FECHAS,
      variables: {
        fecha_inicio: theBigDay,
        fecha_fin: this.date2
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarTotalesxFechas(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
        M.toast({ html: toastHTML });
      });



      this.apollo.use('backretoutilidades').watchQuery({
        query: A.GET_TOTALES_VENCIDOS_CANCELADOS_FECHAS,
        variables: {
          fecha_inicio: this.date1,
          fecha_fin: this.date2
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarTotalesxFechasVencidosCancelados(result);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
          M.toast({ html: toastHTML });
        });


        this.apollo.use('backretoutilidades').watchQuery({
          query: A.GET_TOTALES_CONDUCTORES_FECHAS,
          variables: { 
            fecha_inicio: this.date1,
            fecha_fin: this.date2
          }, fetchPolicy: 'network-only'
        })
          .valueChanges.subscribe(result => {
            this.asignarTotalesConductoresxFechas(result);
          }, (error) => {
            var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
            M.toast({ html: toastHTML });
          });
  }

  asignarTotalesConductoresxFechas(data){
    this.conductoresactivosxfecha = data.data.totalConductoresFindFecha;
  }

  buscarporfechasxRegistro(){
    this.despuesbusquedaxregistro = true;

    this.apollo.use('registroReto').watchQuery({
      query: A.TOTALES_RANGO_FECHA,
      variables: {
        fecha_inicio: this.date3,
        fecha_fin: this.date4
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarTotalRangoRegistro(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
        M.toast({ html: toastHTML });
      });


      this.apollo.use('registroReto').watchQuery({
        query: A.TOTALES_RANGO_FECHA_X_STATUS,
        variables: {
          fecha_inicio: this.date3,
          fecha_fin: this.date4
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarTotalRangoxStatus(result);

        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
          M.toast({ html: toastHTML });
        });


        this.apollo.use('registroReto').watchQuery({
          query: A.TOTALES_RANGO_FECHA_X_MODULO,
          variables: {
            fecha_inicio: this.date3,
            fecha_fin: this.date4
          }, fetchPolicy: 'network-only'
        })
          .valueChanges.subscribe(result => {
            this.asignarTotalRangoxModulo(result);
          }, (error) => {
            var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
            M.toast({ html: toastHTML });
          });


          this.apollo.use('registroReto').watchQuery({
            query: A.TOTALES_RANGO_FECHA_X_MODALIDAD,
            variables: {
              fecha_inicio: this.date3,
              fecha_fin: this.date4
            }, fetchPolicy: 'network-only'
          })
            .valueChanges.subscribe(result => {
              this.asignarTotalRangoxModalidad(result);
            }, (error) => {
              var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
              M.toast({ html: toastHTML });
            });

  }

  asignarTotalRangoRegistro(data){
    this.totalregistrosrango = data.data.totalByRangoFecha;
    this.arrayRegistrosReto = [];

    for(var i = 0; this.totalregistrosrango >  (i*10) ;i++){
      this.apollo.use('registroReto').watchQuery({
        query: A.GET_REGISTROS_INCREMENTAL,
        variables: {
          fecha_inicio: this.date3,
          fecha_fin: this.date4,
          pagina: i + 1,
          numregistro:10
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarIncremental(result);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
          M.toast({ html: toastHTML });
        });
    }




  }

  asignarIncremental(data){
    for(var i = 0; i < data.data.totalByRangoFechaIncremental.length;i++){
      this.arrayRegistrosReto.push(
        {
          id: data.data.totalByRangoFechaIncremental[i].id,
          id_catalogo_estatus: data.data.totalByRangoFechaIncremental[i].id_catalogo_estatus,
          id_concesion: data.data.totalByRangoFechaIncremental[i].id_concesion,
          id_concesionario: data.data.totalByRangoFechaIncremental[i].id_concesionario,
          id_linea_captura: data.data.totalByRangoFechaIncremental[i].id_linea_captura,
          id_persona: data.data.totalByRangoFechaIncremental[i].id_persona,
          id_modalidad: data.data.totalByRangoFechaIncremental[i].id_modalidad,
          id_modulo: data.data.totalByRangoFechaIncremental[i].id_modulo,
          id_usuario: data.data.totalByRangoFechaIncremental[i].id_usuario,
          id_observacion: data.data.totalByRangoFechaIncremental[i].id_observacion,
          fecha_registro: data.data.totalByRangoFechaIncremental[i].fecha_registro,
          tipo_persona: data.data.totalByRangoFechaIncremental[i].tipo_persona,
          bandera_fase: data.data.totalByRangoFechaIncremental[i].bandera_fase,
          observacion: data.data.totalByRangoFechaIncremental[i].observacion
        }
      );
    }

    if(this.arrayRegistrosReto.length == this.totalregistrosrango){
      this.traerDatos();

    }

   }

   descargarExcel(){
     this.creadorReporteExcel.generarexcel(this.arrayRegistrosReto,this.date3,this.date4);
   }

   traerDatos(){
     for(var i = 0; i < this.arrayRegistrosReto.length;i++){
       let indice = i;

       this.apollo.use('backConsulta')
         .watchQuery({
           query: A.GET_CONCESION,
           variables: {
             id: this.arrayRegistrosReto[i].id_concesion,
           }, fetchPolicy: 'network-only'
         })
         .valueChanges.subscribe(result => {
           this.asignarConcesion(result.data,indice)
         }, (error) => {
           M.toast({ html: error })
         });


         this.apollo.use('registroReto')
           .watchQuery({
             query: A.GET_CATALOGO_FORMATO,
             variables: {
               id: this.arrayRegistrosReto[i].id_catalogo_estatus,
             }, fetchPolicy: 'network-only'
           })
           .valueChanges.subscribe(result => {
             this.agregarCatalogoEstatus(result.data,indice)
           }, (error) => {
             M.toast({ html: error })
           });

           this.serviciosService.getModulo(this.arrayRegistrosReto[i].id_modulo).subscribe({
            next: (data) => {
              this.arrayRegistrosReto[indice]['modulo'] = data;
             },
            error: (error) => {
             },
            complete: () => {
             }
           });

           this.serviciosService.getUsuario(this.arrayRegistrosReto[i].id_usuario).subscribe({
            next: (data) => {
              this.traerPersonal(data.id_persona,indice);
              //this.registro_reto['modulo'] = data;
             },
            error: (error) => {
             },
            complete: () => {
             }
           });


           this.apollo.use('registroReto').watchQuery({
             query: A.GET_HISTORIAL,
             variables: {
               id: this.arrayRegistrosReto[i].id,
             }, fetchPolicy: 'network-only'
           })
             .valueChanges.subscribe(result => {
               this.asignarHistorial(result,indice);
             }, (error) => {
               var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
               M.toast({ html: toastHTML });
             });

     }

   }

   asignarHistorial(data,indice){
     this.arrayRegistrosReto[indice]['last'] = data.data.historialCambioFases[data.data.historialCambioFases.length-1];

   }

   asignarConcesion(data,indice){
     this.arrayRegistrosReto[indice]['Concesion'] = data.concession;
   }

   agregarCatalogoEstatus(data,indice){
     this.arrayRegistrosReto[indice]['estatus'] = data.catalogo_estatus;

   }

   traerPersonal(id,indice){
     this.serviciosService.getPersona(id).subscribe({
      next: (data) => {
        this.arrayRegistrosReto[indice]['personal'] = data.Personal;
       },
      error: (error) => {
       },
      complete: () => {
       }
     });
   }

  asignarTotalRangoxStatus(data){
    this.arrayStatusTotales = [];
    for(var i = 0; i < data.data.totalesOfEstatusByDate.length;i++){
      this.arrayStatusTotales.push(
        {
          id_catalogo_estatus: data.data.totalesOfEstatusByDate[i].id_catalogo_estatus,
          count: data.data.totalesOfEstatusByDate[i].count,
        }
      );
    }

    for(var i = 0; i < this.arrayStatusTotales.length;i++){
      let indice = i;
      this.apollo.use('registroReto')
        .watchQuery({
          query: A.GET_CATALOGO_FORMATO,
          variables: {
            id: this.arrayStatusTotales[indice].id_catalogo_estatus,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.agregarCatalogoEstatusTotal(result.data,indice)
        }, (error) => {
          M.toast({ html: error })
        });
    }

  }

  agregarCatalogoEstatusTotal(data,indice){
    this.arrayStatusTotales[indice]['status'] = data.catalogo_estatus;
  }


  asignarTotalesxFechas(data) {
    this.loading = false;

    this.totalconstanciasactivas = data.data.totalsGeneralByFecha.active_certificates;
    this.totalpreregistrosactivas = data.data.totalsGeneralByFecha.active_pre_certificates;

    this.concessions_active_certificates = data.data.totalsGeneralByFecha.certificates_cancelados;
    this.concessions_active_pre_certificates = data.data.totalsGeneralByFecha.certificates_vencidas;



    this.despuesbusqueda = true;
  }

  asignarTotalesxFechasVencidosCancelados(data){
    this.loading = false;
    this.constanciasactivasfechas= data.data.totalsVencidoCancelado.certificates_creados;
    this.constanciascanceladasfechas= data.data.totalsVencidoCancelado.certificates_cancelados;
    this.constanciasvencidasfechas= data.data.totalsVencidoCancelado.certificates_vencidos;

    this.preregistrosactivosxfecha= data.data.totalsVencidoCancelado.pre_certificates_cancelados_nocompletos;
    this.preregistroscanceladosxfecha= data.data.totalsVencidoCancelado.pre_certificates_no_completos;
    this.despuesbusqueda = true;
  }

  reestablecerfechas(){
    this.despuesbusqueda = false;
    this.date1 = null;
    this.date2 = null;

  }

  traerEstados(){
    this.apollo.use('registroReto')
     .watchQuery({
       query: A.GET_CATALOGO_STATUS
     })
     .valueChanges.subscribe(result => {
       this.asignarEstados(result);

     }, (error) => {
     });
  }

  asignarEstados(data){
    this.statuses = [];
     for(var i = 0; i < data.data.catalogos_estatus.length;i++){
      this.statuses.push({id: data.data.catalogos_estatus[i].id, value:data.data.catalogos_estatus[i].id ,label: data.data.catalogos_estatus[i].nombre, name: data.data.catalogos_estatus[i].nombre});
    }
  }

  traerTotalxEstatus(status){
    let total = 0;
    if(this.arrayStatusTotales!=undefined){
      let estado = this.arrayStatusTotales.find(obj => obj.id_catalogo_estatus === status.id);
      if(estado!=undefined){
        total = estado.count;
      }
    }
    return total;
  }


  asignarTotalRangoxModulo(data){
    this.arrayModulosTotales = [];
     for(var i = 0; i < data.data.totalesOfModuloByDate.length;i++){
      this.arrayModulosTotales.push({id_modulo: data.data.totalesOfModuloByDate[i].id_modulo, count:data.data.totalesOfModuloByDate[i].count});
    }

    for(var i = 0; i < this.arrayModulosTotales.length;i++){
      let indice = i;

        this.serviciosService.getModulo(this.arrayModulosTotales[i].id_modulo).subscribe({
         next: (data) => {
           this.arrayModulosTotales[indice]['modulo'] = data;
          },
         error: (error) => {
          },
         complete: () => {
          }
        });

    }
  }

  asignarTotalRangoxModalidad(data){
    this.arrayModalidadesTotales = [];
     for(var i = 0; i < data.data.totalesOfModalidadByDate.length;i++){
      this.arrayModalidadesTotales.push({id_modalidad: data.data.totalesOfModalidadByDate[i].id_modalidad, count:data.data.totalesOfModalidadByDate[i].count});
    }

    for(var i = 0; i < this.arrayModalidadesTotales.length;i++){
      let indice = i;
      this.apollo.use('backConsulta')
        .watchQuery({
          query: A.GET_MODALIDAD,
          variables: {
            id: this.arrayModalidadesTotales[i].id_modalidad,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.asignarModalidadTotales(result.data,indice)
        }, (error) => {
          M.toast({ html: error })
        });
    }


  }


  asignarModalidadTotales(data,indice){
    this.arrayModalidadesTotales[indice]['modalidad'] = data.modality;
  }

  traerTotalXEstados(statuses){
    let total = 0;
    if(this.arrayStatusTotales!=undefined){
      for(var i = 0; i < this.arrayStatusTotales.length;i++){
        total = total + parseInt(this.arrayStatusTotales[i].count);
      }
    }

    return total;
  }

  primerBusquedaTotales(){
    this.date3 = new Date();
    this.date4 = new Date();
    this.buscarporfechasxRegistro();

  }

  descargarExcelXEstatus(){
    this.creadorReporteExcel.generarexcelxestatus(this.statuses,this.arrayStatusTotales,this.date3,this.date4);



  }

  descargarExcelXModulos(){
    this.creadorReporteExcel.generarexcelxmodulos(this.arrayModulosTotales,this.date3,this.date4);

  }

  descargarExcelXModalidades(){
    this.creadorReporteExcel.generarexcelxmodalidades(this.arrayModalidadesTotales,this.date3,this.date4);

  }

  abrirmodalxrangodefechas(mensaje){
    $('#modalverregionesxrangofecha').modal('open');
    this.mensajeclick = mensaje;
    this.objetosxregionxfecha = [ ]


    switch (this.mensajeclick) {
            case 'Constancias Registradas':
            this.apollo.use('backretoutilidades').watchQuery({
              query: A.GET_CONSTANCIAS_REGION_FECHAS,
              variables: {
                fecha_inicio: this.date1,
                fecha_fin: this.date2
              }, fetchPolicy: 'network-only'
            })
              .valueChanges.subscribe(result => {
                 this.asignarArray(result.data);
              }, (error) => {
                var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
                M.toast({ html: toastHTML });
              });
            break;
            case 'Constancias Canceladas':
            this.apollo.use('backretoutilidades').watchQuery({
              query: A.GET_CONSTANCIAS_CANCELADAS_REGION_FECHAS,
              variables: {
                fecha_inicio: this.date1,
                fecha_fin: this.date2
              }, fetchPolicy: 'network-only'
            })
              .valueChanges.subscribe(result => {
                this.asignarArray(result.data);
              }, (error) => {
                var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
                M.toast({ html: toastHTML });
              });
            break;
            case 'Pre-Registros Activos':
            this.apollo.use('backretoutilidades').watchQuery({
              query: A.GET_PREREGISTROS_REGION_FECHAS,
              variables: {
                fecha_inicio: this.date1,
                fecha_fin: this.date2
              }, fetchPolicy: 'network-only'
            })
              .valueChanges.subscribe(result => {
                this.asignarArray(result.data);
              }, (error) => {
                var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
                M.toast({ html: toastHTML });
              });
            break;
            case 'Pre-Registros Cancelados':
            this.apollo.use('backretoutilidades').watchQuery({
              query: A.GET_PREREGISTROS_CANCELADOS_REGION_FECHAS,
              variables: {
                fecha_inicio: this.date1,
                fecha_fin: this.date2
              }, fetchPolicy: 'network-only'
            })
              .valueChanges.subscribe(result => {
                this.asignarArray(result.data);
              }, (error) => {
                var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
                M.toast({ html: toastHTML });
              });
            break;
            default:
            M.toast({html: 'Error'})
            }




  }

  asignarArray(data){
    switch (this.mensajeclick) {
            case 'Constancias Registradas':
            this.objetosxregionxfecha = data.constanciasRegistradasRegionFecha;
            break;
            case 'Constancias Canceladas':
            this.objetosxregionxfecha = data.constanciasCanceladasRegionFecha;
            break;
            case 'Pre-Registros Activos':
            this.objetosxregionxfecha = data.preRegistrosRegionFecha;
            break;
            case 'Pre-Registros Cancelados':
            this.objetosxregionxfecha = data.preRegistrosCanceladosRegionFecha;
            break;
            default:
            M.toast({html: 'Error'})
            }
  }


  funcioncrearfecha(stringdate: any) {
    let fecha = new Date(stringdate);
    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";
    if ((fecha.getMonth() + 1) < 10) {
      mes21 = "0" + (fecha.getMonth() + 1).toString();
    } else {
      mes21 = (fecha.getMonth() + 1).toString();
    }
    if ((fecha.getDate() + 1) <= 10) {
      dia21 = "0" + (fecha.getDate()).toString();
    } else {
      dia21 = (fecha.getDate()).toString();
    }
    return anio21 + "-" + mes21 + "-" + dia21 + "";
  }




}
