  import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import { Observable,Observer } from 'rxjs';
  import gql from 'graphql-tag';
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  /*
  import * as XLSX from 'xlsx';
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';

  import {StorageService} from "../../core/services/storage.service";
  import { environment } from '../../../environments/environment';
    */
  type AOA = any[][];
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  declare var M: any;
  export interface myinterface {
    remove(index: number,nameComponet: any);
  }
 @Component({
  selector: 'app-contactos', templateUrl: './contactos.component.html', styleUrls: ['./contactos.component.css']
})
export class ContactosComponent implements OnInit {
    public index: number;
    public selfRef: ContactosComponent;
    public compInteraction: myinterface;
    @Input() IModel: any;
    @Input() NameComponet: any;
    @Input() Contactos: any;
    public contactoSeleccionado: any;
    cols: any[];

 constructor(){}
ngOnInit() {
  $(document).ready(function(){
    $('.modal').modal();
  });
}

seleccionarcontacto(data){
  this.contactoSeleccionado = data;

}



}
