import gql from 'graphql-tag';


const GET_TARIFAS = gql`
query getRollsTarjetonByConcession($concession_id: ID!) {
  rollsTarjetonByConcession(concession_id: $concession_id) {
    municipality_id
    locality_id
    foliated_sheet
    vehicle_id
    expedition_date
    routes {
      origin
      destiny
      amount
      amount_letter
    }
  }
}
`;


const GET_LOCALIDAD = gql`
query locality($id: ID!) {
      locality(id: $id) {
        id
    		name
      }
    }
`;


const GET_MUNICIPIO = gql`
query municipality($id: ID!) {
      municipality(id: $id) {
        id
    		name
      }
    }
`;

const GET_VEHICULO = gql`
query vehicle($id: ID!) {
      vehicle(id: $id) {
        id
    		serial_number
      }
    }
`;


export { GET_TARIFAS,GET_LOCALIDAD,GET_MUNICIPIO,GET_VEHICULO};
