import gql from 'graphql-tag';


const CREATE_DIRECCION = gql`
mutation address(
  $road_name: String!
  $road_type_id: ID!
  $outdoor_number: String!
  $indoor_number: String
  $postal_code: String!
  $settlement_id: ID!
  $sublevel_id: ID
) {
		address(
      road_name: $road_name,
      road_type_id: $road_type_id,
      outdoor_number: $outdoor_number,
      indoor_number: $indoor_number,
      postal_code: $postal_code,
      settlement_id: $settlement_id,
      sublevel_id: $sublevel_id
    ) {
		  id
      road_name
      outdoor_number
      indoor_number
      postal_code
      deprecated
		}
	  }

`;


const CREATE_ASENTAMIENTO = gql`
mutation settlement(
  $name: String!
  $locality_id: ID!
  $settlement_type_id: ID!
) {
		settlement(
      name: $name,
      locality_id: $locality_id,
      settlement_type_id: $settlement_type_id
    ) {
		  id
      name
		}
	  }

`;

const CREATE_NOMBREASENTAMIENTO = gql`
mutation newSettlement(
$name: String!
$locality_id: ID!
$settlement_type_id: ID!
) {
settlement(
  name: $name
  locality_id: $locality_id
  settlement_type_id: $settlement_type_id
) {
  id
  name
  locality {
    id
    name
    municipality {
      id
      name
      status
      created_at
    }
    status
    created_at
  }
  settlement_type {
    id
    name
    description
    status
    created_at
  }
  status
  created_at
}
}

`;


const CREATE_PLACA = gql`
mutation newPlate($folio: String!, $concession_id: ID!) {
      plate(folio: $folio, concession_id: $concession_id) {
        id
        folio
        status
        created_at
      }
    }


`;


const CHANGE_PLACA_PREREGISTRO = gql`
mutation setPreCertificatePlate($id: ID!, $plate_id: ID!) {
  setPreCertificatePlate(id: $id, plate_id: $plate_id)
}


`;

export { CREATE_DIRECCION,CREATE_ASENTAMIENTO,CREATE_NOMBREASENTAMIENTO,CREATE_PLACA,CHANGE_PLACA_PREREGISTRO };
