import gql from 'graphql-tag';

/*
image {
  base64
}
*/


const GET_OBSERVACIONES_X_CONDUCTOR = gql(`
  query observationfindIdPersons($persons_id: ID!) {
  observationfindIdPersons(persons_id: $persons_id) {
    id
    description
    capture_date
    usuario_id
    created_at
  }
}
`);

const GET_BENEFICIARIOS_SICAC = gql`
query searchBeneficiaries($params: JSON, $mode: String!){
    beneficiaries(params: $params,mode: $mode) {
      name, first_surname, second_surname, curp
    }
}
`;

const GET_BENEFICIARIOS = gql`
query searchBeneficiarios($nuc: String!){
  Concesion(nuc: $nuc){
    idConcesion,
    nuc,
    nombreconcesionario,
    FechaAlta,
    beneficiario,
    Parentesco,
    swActivo
  }
}
`;


export { GET_OBSERVACIONES_X_CONDUCTOR,GET_BENEFICIARIOS_SICAC,GET_BENEFICIARIOS };
