import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
type AOA = any[][];

@Injectable({
  providedIn: 'root'
})
export class CreadorReporteExcel {
   dataexcel: AOA = [ ];
  title = 'Reporte';
  excel=[];
  constructor() { }

  generarexcel(array: any, date3, date4){
    let datosexcel = [];

    datosexcel.push([]);
    datosexcel.push(['Secretaria de Movilidad','','','Reporte de Registros en RETO']);

    datosexcel.push(['Gobierno del Estado de Oaxaca','','']);
    datosexcel.push([]);

    datosexcel.push(['Reporte correspondiente a los días :',this.funcioncrearfecha(date3) + " al  " + this.funcioncrearfecha(date4) ,'','','','']);
    datosexcel.push([]);

    for(var i = 0; i < array.length; i++){
      if(i == 0){
        datosexcel.push(['No','Nombre del concesionario','NUC','Modalidad','Folio','Fecha de Registro','Estatus','Fecha del Estatus','Modulo','Usuario que realizo la Captura']);
      }
      let nombreconcesionario = '';
      let nombre = '';
      let primerapellido = '';
      let segundoapellido = '';
      if(array[i].Concesion.concessionaire.person_type=='F'){
        if(array[i].Concesion.concessionaire.name!=undefined){
          nombre = array[i].Concesion.concessionaire.name + ' ';
        }
        if(array[i].Concesion.concessionaire.first_surname!=undefined){
          primerapellido = array[i].Concesion.concessionaire.first_surname + ' ';
        }
        if(array[i].Concesion.concessionaire.second_surname!=undefined){
          segundoapellido = array[i].Concesion.concessionaire.second_surname;
        }
        nombreconcesionario = nombre + primerapellido + segundoapellido;
      }else{
        nombreconcesionario = array[i].Concesion.concessionaire.business_name
      }
      var arrayDeCadenas = array[i].fecha_registro.split('T');

      datosexcel.push( [(i+1),nombreconcesionario,array[i].Concesion.consecutive_number+'/'+array[i].Concesion.unique_number_key,array[i].Concesion.modality.name,array[i].id,arrayDeCadenas[0],array[i].estatus.nombre,this.funcioncrearfechaString(array[i].last.created_at),array[i].modulo.nombre.toUpperCase(),array[i].personal.nombre + ' ' + array[i].personal.primer_apellido + ' ' + array[i].personal.segundo_apellido]);

    }
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(datosexcel);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte');

     XLSX.writeFile(wb, 'Reporte de Registros en RETO.xlsx');

  }


  generarexcelxestatus(array: any,arrayEstatus: any, date3, date4){
    let datosexcel = [];

    let datosexcelestados = [];
    datosexcel.push([]);
    datosexcel.push(['Secretaria de Movilidad','','','Reporte de Registros por Estatus en RETO']);

    datosexcel.push(['Gobierno del Estado de Oaxaca','','']);
    datosexcel.push([]);
    datosexcel.push(['Reporte correspondiente a los días :',this.funcioncrearfecha(date3) + " al  " + this.funcioncrearfecha(date4) ,'','','','']);
    for(var i = 0; i < array.length; i++){
      datosexcelestados.push(array[i].label);
     }
     datosexcelestados.push('TOTAL');

     datosexcel.push(datosexcelestados);
     let datosexceldatos = [];

    for(var i = 0; i < array.length; i++){
        datosexceldatos.push( this.traerTotalxEstatus(array[i],arrayEstatus));
    }
    datosexceldatos.push( this.traerTotalXEstados(arrayEstatus));



    datosexcel.push(datosexceldatos);

      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(datosexcel);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte');

     XLSX.writeFile(wb, 'Reporte de Registros por Estatus en RETO.xlsx');

  }


  traerTotalXEstados(arrayEstatus){
    let total = 0;
    if(arrayEstatus!=undefined){
      for(var i = 0; i < arrayEstatus.length;i++){
        total = total + parseInt(arrayEstatus[i].count);
      }
    }

    return total;
  }


  generarexcelxmodulos(array: any, date3, date4){
    let datosexcel = [];

    datosexcel.push([]);
    datosexcel.push(['Secretaria de Movilidad','','','Reporte de Registros por Modulos en RETO']);

    datosexcel.push(['Gobierno del Estado de Oaxaca','','']);
    datosexcel.push([]);
    datosexcel.push(['Reporte correspondiente a los días :',this.funcioncrearfecha(date3) + " al  " + this.funcioncrearfecha(date4) ,'','','','']);
    for(var i = 0; i < array.length; i++){
      if(i == 0){
        datosexcel.push(['Modulo','Total' ]);
      }
      datosexcel.push( [array[i].modulo.nombre.toUpperCase(),array[i].count ]);

    }
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(datosexcel);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte');

     XLSX.writeFile(wb, 'Reporte de Registros por Modulos en RETO.xlsx');

  }


  generarexcelxmodalidades(array: any, date3, date4){
    let datosexcel = [];

    datosexcel.push([]);
    datosexcel.push(['Secretaria de Movilidad','','','Reporte de Registros por Modalidades en RETO']);

    datosexcel.push(['Gobierno del Estado de Oaxaca','','']);
    datosexcel.push([]);
    datosexcel.push(['Reporte correspondiente a los días :',this.funcioncrearfecha(date3) + " al  " + this.funcioncrearfecha(date4) ,'','','','']);
    for(var i = 0; i < array.length; i++){
      if(i == 0){
        datosexcel.push(['Modalidad','Total' ]);
      }
      datosexcel.push( [array[i].modalidad.name,array[i].count ]);

    }
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(datosexcel);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte');

     XLSX.writeFile(wb, 'Reporte de Registros por Modalidades en RETO.xlsx');

  }


  funcioncrearfechaString(stringdate: any){
    if(stringdate == ''){
      return '';
    }
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    //let fecha = new Date(stringdate);
    var arrayDeCadenas = stringdate.split('T');
    var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
  //let fecha = new Date(stringdate);
    let fecha = new Date(parseInt(arrayDeCadenas2[0]),parseInt(arrayDeCadenas2[1])-1,parseInt(arrayDeCadenas2[2]));



    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  arrayDeCadenas[0] ;
  }



  funcioncrearfecha(stringdate: any){
    if(stringdate == ''){
      return '';
    }
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    let fecha = stringdate;


    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  dia21 +  "-" + mes21 + "-" + anio21 ;
  }

  traerTotalxEstatus(status,arrayStatusTotales){
    let total = 0;
    if(arrayStatusTotales!=undefined){
      let estado = arrayStatusTotales.find(obj => obj.id_catalogo_estatus === status.id);
      if(estado!=undefined){
        total = estado.count;
      }
    }
    return total;
  }

}
