import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { AccountService } from '../autentication.service';

// Environments
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private account: AccountService
  ) { }

  /**
   * @description Si existe una cuenta verificada redirecciona al path configurado
   * @see environment En environments vea el diccionario contentfulConfig
   */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.account.isLoggetIn())
    window.location.href = "/informacion";
    return true;
  }
}
