import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AccountService } from '../autentication.service';
import { UtilsService } from '../utils/utils.service';
import { Router } from '@angular/router';
import { slideAnimation } from '../transition';
import { SessionStorageService } from '../../core/services/sessionstorage.service';
import { RelacionesInputValidacionesService } from '../../services/validaciones/relacioninputvalidaciones.service';
import { ServiciosService } from '../../core/services/servicios.service';
import { Subscription } from 'rxjs';

declare var M: any;

@Component({
  selector: 'app-collect',
  templateUrl: './collect.component.html',
  styleUrls: ['./collect.component.css'],
})
export class CollectComponent implements OnInit {
  form: FormGroup;
  pass: any;
  loading: boolean = false;
  user: any;
  arrextras: any;
  update_subscription: Subscription;
  sugerenciasPuestosLaboral: any[];
  sugerenciasModalidadesTrabajo: any[];
  sugerenciasAreas: any[];
  sugerenciasModulos: any[];
  puestosLaborales: any[];
  modalidadesTrabajo: any[];
  modulosAreas: any[];
  modulos: any[];
  areas: any[];
  union_subscription: Subscription;
  id_union_modulos_areas: any;

  constructor(
    private formBuilder: FormBuilder,
    private service: AccountService,
    private sessionStorageService: SessionStorageService,
    private utils: UtilsService,
    private serviciosService: ServiciosService,
    public relacionesInputValidacionesService: RelacionesInputValidacionesService,

    private router?: Router,

  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(this.sessionStorageService.get('usuario'));
    let obj = {};
    this.arrextras = [];
    this.puestosLaborales = [];
    this.modalidadesTrabajo = [];
    this.modulos = [];
    this.areas = [];
    this.traerPuestosLaborales();
    this.traerModalidadesTrabajo();
    this.traerModulos();
    this.traerAreas();

    if (this.user.persona.curp == null) {
      obj['curp'] = ['', this.relacionesInputValidacionesService.getValidacion('curp')];

      this.arrextras.push({
        nombre: 'CURP',
        modelo: 'curp',
        mensajeError: 'Formato de CURP incorrecto, favor de revisarlo.',
        obligatorio: true,
        incremental: false
      });
    }


    if (this.user.persona.rfc == null) {
      obj['rfc'] = ['', this.relacionesInputValidacionesService.getValidacion('rfc')];
      this.arrextras.push({
        nombre: 'RFC',
        modelo: 'rfc',
        mensajeError: 'Formato de RFC incorrecto, favor de revisarlo',
        obligatorio: true,
        incremental: false
      });
    }
    if (this.user.persona.telefono_local == null) {
      obj['telefono_local'] = ['', this.relacionesInputValidacionesService.getValidacion('telefono')];
      this.arrextras.push({
        nombre: 'Telefono Local',
        modelo: 'telefono_local',
        mensajeError: 'Número telefónico no valido. (10 números obligatorios)',
        obligatorio: true,
        incremental: false
      });
    }

    if (this.user.persona.telefono_celular == null) {
      obj['telefono_celular'] = ['', this.relacionesInputValidacionesService.getValidacion('telefono')];
      this.arrextras.push({
        nombre: 'Telefono Celular',
        modelo: 'telefono_celular',
        mensajeError: 'Número telefónico no valido. (10 números obligatorios)',
        obligatorio: true,
        incremental: false
      });
    }


    if (this.user.persona.id_modalidad_trabajo == null) {
      obj['id_modalidad_trabajo'] = ['', this.relacionesInputValidacionesService.getValidacion('id_modalidad_trabajo')];
      this.arrextras.push({
        nombre: 'Modalidad de Trabajo',
        modelo: 'id_modalidad_trabajo',
        mensajeError: 'Formato de CURP requerido, favor de revisarlo',
        obligatorio: true,
        incremental: true,
        arreglo: this.modalidadesTrabajo
      });
    }

    if (this.user.persona.id_puesto_laboral == null) {
      obj['id_puesto_laboral'] = ['', this.relacionesInputValidacionesService.getValidacion('id_puesto_laboral')];
      this.arrextras.push({
        nombre: 'Puesto Laboral',
        modelo: 'id_puesto_laboral',
        mensajeError: 'Puesto laboral requerido, favor de revisarlo',
        obligatorio: true,
        incremental: true,
        arreglo: this.puestosLaborales
      });
    }

    if (this.user.persona.id_union_modulos_areas == null) {
      obj['area'] = ['', this.relacionesInputValidacionesService.getValidacion('area')];
      this.arrextras.push({
        nombre: 'Área',
        modelo: 'area',
        mensajeError: 'Área de trabajo requerido, favor de revisarlo',
        obligatorio: true,
        incremental: true,
        arreglo: this.areas
      });
    }

    if (this.user.persona.id_union_modulos_areas == null) {
      obj['modulo'] = ['', this.relacionesInputValidacionesService.getValidacion('modulo')];
      this.arrextras.push({
        nombre: 'Módulo',
        modelo: 'area',
        mensajeError: 'Módulo de trabajo requerido, favor de revisarlo',
        obligatorio: true,
        incremental: true,
        arreglo: this.modulos

      });
    }

    if (this.user.persona.nup == null) {
      obj['nup'] = ['', []];
      this.arrextras.push({
        nombre: 'NUP',
        modelo: 'nup',
        obligatorio: false,
        incremental: false
      });
    }

    if (this.user.persona.nue == null) {
      obj['nue'] = ['', []];
      this.arrextras.push({
        nombre: 'NUE',
        modelo: 'nue',
        obligatorio: false,
        incremental: false
      });
    }

    if (this.user.persona.numero_seguro_social == null) {
      obj['numero_seguro_social'] = ['', []];
      this.arrextras.push({
        nombre: 'Número Seguro Social',
        modelo: 'numero_seguro_social',
        obligatorio: false,
        incremental: false
      });
    }

    if (this.user.persona.alergias == null) {
      obj['alergias'] = ['', []];
      this.arrextras.push({
        nombre: 'Alergias',
        modelo: 'alergias',
        obligatorio: false,
        incremental: false
      });
    }


    this.form = this.formBuilder.group(obj);

  }

  traerModulos() {
    this.serviciosService.getModulos().subscribe({
      next: (data) => {
        for (var i = 0; i < data.length; i++) {
          this.modulos.push(data[i]);
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  traerAreas() {
    this.serviciosService.getAreas().subscribe({
      next: (data) => {
        for (var i = 0; i < data.length; i++) {
          this.areas.push(data[i]);
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  traerPuestosLaborales() {
    this.serviciosService.getPuestosLaborales().subscribe({
      next: (data) => {
        for (var i = 0; i < data.length; i++) {
          this.puestosLaborales.push(data[i]);
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  traerModalidadesTrabajo() {
    this.serviciosService.getModalidadesTrabajo().subscribe({
      next: (data) => {
        for (var i = 0; i < data.length; i++) {
          this.modalidadesTrabajo.push(data[i]);
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  getStatusForm(form) {
    return this.form.controls[form].valid;
  }


  submit() {

    if (this.user.persona.id_union_modulos_areas == null) {
      this.crearunionmodulo();
    } else {
      this.enviarDatos();

    }


  }

  crearunionmodulo() {
    this.union_subscription = this.serviciosService.crearunionmoduloarea({
      id_modulo: this.form.controls['modulo'].value.id,
      id_area: this.form.controls['area'].value.id,
    }).subscribe({
      next: (data) => {
        this.asignarPersonal(data.data);
      },
      error: (error) => {
      },
      complete: () => {
      }
    });

  }

  asignarPersonal(data) {
    let arr = data.split(':');
    this.id_union_modulos_areas = parseInt(arr[1]);
    this.enviarDatos();

  }

  enviarDatos() {

    let obj = {};
    obj['id'] = this.user.persona.id;
    //id_modalidad_trabajo
    if (this.user.persona.id_modalidad_trabajo != null) {
      obj['id_modalidad_trabajo'] = this.user.persona.id_modalidad_trabajo;
    } else {
      if (this.form.controls['id_modalidad_trabajo'].value != undefined) {
        obj['id_modalidad_trabajo'] = this.form.controls['id_modalidad_trabajo'].value.id;
      }
    }

    //id_puesto_laboral
    if (this.user.persona.id_puesto_laboral != null) {
      obj['id_union_modulos_areas'] = parseInt(this.user.persona.id_union_modulos_areas);
    } else {
      if (this.form.controls['id_puesto_laboral'].value != undefined) {
        obj['id_puesto_laboral'] = this.form.controls['id_puesto_laboral'].value.id;
      }
    }

    //id_union_modulos_areas
    if (this.user.persona.id_union_modulos_areas != null) {
      obj['id_puesto_laboral'] = parseInt(this.user.persona.id_puesto_laboral);
    } else {
      if (this.form.controls['id_union_modulos_areas'].value != undefined) {
        obj['id_union_modulos_areas'] = this.id_union_modulos_areas;
      }
    }

    //curp
    if (this.user.persona.curp != null) {
      obj['curp'] = this.user.persona.curp;

    } else {
      if (this.form.controls['curp'].value != '') {
        obj['curp'] = this.form.controls['curp'].value;
      }
    }

    //rfc
    if (this.user.persona.rfc != null) {
      obj['rfc'] = this.user.persona.rfc;

    } else {
      if (this.form.controls['rfc'].value != '') {
        obj['rfc'] = this.form.controls['rfc'].value;
      }
    }

    //tipo_sangre
    obj['tipo_sangre'] = this.user.persona.tipo_sangre;
    //Telefono Celular
    obj['telefono_celular'] = this.form.controls['telefono_celular'].value;

    //Telefono Local
    obj['telefono_local'] = this.form.controls['telefono_local'].value;
    if (this.form.controls['nup'] != undefined) {
      if (this.form.controls['nup'].value != '') {
        obj['nup'] = this.form.controls['nup'].value;
      }
    }

    if (this.form.controls['nue'] != undefined) {
      if (this.form.controls['nue'].value != '') {
        obj['nue'] = this.form.controls['nue'].value;
      }
    }


    if (this.form.controls['numero_seguro_social'] != undefined) {
      if (this.form.controls['numero_seguro_social'].value != '') {
        obj['numero_seguro_social'] = this.form.controls['numero_seguro_social'].value;
      }
    }

    if (this.form.controls['alergias'] != undefined) {
      if (this.form.controls['alergias'].value != '') {
        obj['alergias'] = this.form.controls['alergias'].value;
      }
    }




    this.update_subscription = this.serviciosService.updatePersona(obj).subscribe({
      next: (data) => {
        this.getPerfil();
      },
      error: (error) => {
        M.toast({ html: '<i class="material-icons left">info</i> ' + error.error.message })
      },
      complete: () => {
        //  window.location.href = "/home";
        this.getPerfil();

      }
    });

  }


  getPerfil() {
    this.serviciosService.perfil().subscribe({
      next: (data) => {
        this.sessionStorageService.set("usuario", JSON.stringify(data))
        window.location.href = "/home";
      },
      error: (error) => { },
      complete: () => {
      }
    });
  }

  get getCorreoElectronico(): AbstractControl {
    return this.form.get('correo_electronico');
  }

  get getClavePrivada(): AbstractControl {
    return this.form.get('clave_privada');
  }
}
