import { Injectable } from '@angular/core';
declare var M: any;
import { AccountService } from './../../auth/autentication.service';
import { SessionStorageService } from './../../core/services/sessionstorage.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorApolloService {
  constructor(
    private sessionStorageService: SessionStorageService,
    private accountService: AccountService
  ) { }

  checkError(message){
    var divisiones = message.split(":", 2);
    if(divisiones[1].trim() == 'Información de usuario no ingresada'){
      this.accountService.logout();
      this.sessionStorageService.clean();

       var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; Sesión Caducada, Favor de logearse de nuevo </div></span>';
      M.toast({ html: toastHTML });
      setTimeout(() => {
        let ruta = "/";
         //this.storageService.logout();
        window.location.href = ruta;
     }, 5000);
    }else{
      var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;' + divisiones[1] + '</div></span>';
      M.toast({ html: toastHTML });
    }

  }


}
