import gql from 'graphql-tag';

const CONTACTOSBYCONCESIONARIO = gql`
query contactos($id_concesionario:ID!) {
  concesionarioByIDConcesionario(id_concesionario:$id_concesionario) {
    id
    id_concesionario
    contacto {
      id
      nombre
      primer_apellido
      segundo_apellido
      correo_electronico
      telefono_local
      telefono_celular
      deprecated
      created_at
      updated_at
      tipoPersona {
        id
        concepto
        deprecated
        created_at
        updated_at
      }
      direcciones {
        id
        calle
        numero_externo
        numero_interno
        codigo_postal
        colonia
        id_localidad
        latitud
        longitud
        maps_id
        actual
        deprecated
        created_at
        updated_at
      }
    }
    contactos {
      id
      nombre
      primer_apellido
      segundo_apellido
      correo_electronico
      telefono_local
      telefono_celular
      deprecated
      created_at
      updated_at
      tipoPersona {
        id
        concepto
        deprecated
        created_at
        updated_at
      }
      direcciones {
        id
        calle
        numero_externo
        numero_interno
        codigo_postal
        colonia
        id_localidad
        latitud
        longitud
        maps_id
        actual
        deprecated
        created_at
        updated_at
      }
    }
    deprecated
    created_at
    updated_at
  }
}
`;

export { CONTACTOSBYCONCESIONARIO };
