import gql from 'graphql-tag';

const AGREGAR_MOTIVO_CANCELACION = gql`
mutation agregarMotivoCancelacion($id_registro_reto: ID!,$observacion: String!) {
      agregarMotivoCancelacion(id_registro_reto: $id_registro_reto,observacion: $observacion) {
        id
      }
    }

`;

const CAMBIAR_ESTATUS = gql`
mutation update_estatus($id: ID!,$id_catalogo_estatus: ID!) {
      update_estatus(id: $id,id_catalogo_estatus: $id_catalogo_estatus) {
        id
      }
    }

`;

const VALID_FORMATO = gql`
mutation validarFormato($formato: [formato_input!]!) {
      validarFormato(formato: $formato ) {
        id
      }
    }

`;

const CREATE_FORMATO = gql`
mutation formato($id_catalogo_formato: ID!,$id_registro_reto: ID!) {
      formato(id_catalogo_formato: $id_catalogo_formato,id_registro_reto: $id_registro_reto ) {
        id
      }
    }

`;


const CREATE_REGISTRO = gql`
mutation registro_reto($id_concesion: ID!,$id_concesionario: ID!,$id_linea_captura: ID,$id_persona: ID,$id_modalidad: String!,$tipo_persona: String!) {
      registro_reto(id_concesion: $id_concesion,id_concesionario: $id_concesionario,id_linea_captura: $id_linea_captura,id_persona: $id_persona,id_modalidad: $id_modalidad,tipo_persona: $tipo_persona ) {
        id

      }
    }

`;


const CREATE_OBSERVACION = gql`
mutation observacion($informacion: JSON!) {
      observacion(informacion: $informacion) {
        id
      }
    }

`;


const ASIGNAR_OBSERVACION_REGISTRO = gql`
mutation update_observacion($id:  ID!,$id_observacion:  ID!) {
      update_observacion(id: $id,id_observacion: $id_observacion) {
    id
  }
}

`;


export { AGREGAR_MOTIVO_CANCELACION,CAMBIAR_ESTATUS,VALID_FORMATO,CREATE_FORMATO,CREATE_REGISTRO,CREATE_OBSERVACION,ASIGNAR_OBSERVACION_REGISTRO };
