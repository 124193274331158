import gql from 'graphql-tag';


const GET_DIRECCION = gql`
query address($id: ID!) {
		address(id: $id) {
		  id
		  road_name
		  road_type{
        id
        name
      }
      outdoor_number
      indoor_number
      postal_code
      settlement{
        id
        name
        locality{
          id
          name
          municipality{
            id
            name
            district{
              id
              name
            }

          }
        }
        settlement_type{
          id
          name
        }
      }
      sublevel{
        id
        name
      }

		}
	  }
`;


const GET_MUNICIPIOS_INPUT = gql`
query getMunicipalitiesLike($input: String!, $limit: Int) {
      municipalitiesLike(input: $input, limit: $limit) {
        id
        name
        district {
          id
          name
          region {
            id
            name
          }
          code
        }
        code
      }
    },
`;


const GET_LOCALIDADES_INPUT = gql`
query getLocalitiesLikeByMunicipality(
      $input: String!
      $municipality_id: ID!
      $limit: Int
    ) {
      localitiesLikeByMunicipality(
        input: $input
        municipality_id: $municipality_id
        limit: $limit
      ) {
        id
        name
        municipality {
          id
          name
          district {
            id
            name
            region {
              id
              name
            }
            code
          }
          code
        }
        code
      }
    }

`;

const GET_VIALIDADESTIPOS_INPUT = gql`
query getRoadsTypesByInput($input: String!, $limit: Int) {
        roadsTypesLike(input: $input, limit: $limit) {
          id
          name
          description


        }
      },
`;


const GET_ASENTAMIENTOSTIPOS_INPUT = gql`
query getSettlementTypesByInput($input: String!, $limit: Int) {
  settlementTypesLike(input: $input, limit: $limit) {
    id
    name
    description
  }
},
`;

const GET_ASENTAMIENTOSNOMBRES_INPUT = gql`
query getSettlementsLikeByLocalityAndSettlementType(
      $input: String!
      $locality_id: ID!
      $settlement_type_id: ID!
      $limit: Int
    ) {
      settlementsLikeByLocalityAndSettlementType(
        input: $input
        locality_id: $locality_id
        settlement_type_id: $settlement_type_id
        limit: $limit
      ) {
        id
        name
        locality {
          id
          name
          municipality {
            id
            name
            district {
              id
              name
              region {
                id
                name


              }
              code


            }
            code


          }
          code


        }
        settlement_type {
          id
          name
          description


        }


      }
    }

`;



const GET_FOLIO = gql`
query getFoliatedSheetByFolio($folio: String!) {
      foliatedSheetByFolio(folio: $folio) {
        id
        folio
      }
    }

`;



const GET_AGENCIA_INPUT = gql`
query getSublevelsByInputMunicipality(
  $input: String!
  $municipality_id: ID!
  $limit: Int
) {
  sublevelsLikeByMunicipality(
    input: $input
    municipality_id: $municipality_id
    limit: $limit
  ) {
    id
    name
  }
},
`;




const GET_AGENCIA_ALL = gql`
query getSublevelsByMunicipality($municipality_id: ID!) {
  sublevelsByMunicipality(municipality_id: $municipality_id) {
    id
    name
  }
},
`;


export { GET_MUNICIPIOS_INPUT,GET_LOCALIDADES_INPUT,GET_VIALIDADESTIPOS_INPUT,GET_ASENTAMIENTOSTIPOS_INPUT,GET_ASENTAMIENTOSNOMBRES_INPUT,GET_FOLIO,GET_AGENCIA_INPUT,GET_AGENCIA_ALL,GET_DIRECCION };
