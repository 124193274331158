export type Maybe<T> = T | null;

// ====================================================
// Documents
// Registro de una persona con sus datos correspondientes
// ====================================================


////////////////////////////////////////////


export namespace UpdateConcesionario {
  export type Variables = {
    nombre: String,
    primer_apellido: String,
    segundo_apellido: String,
    tipo_persona: String,
    razon_social: String,
    nuc: String,
    token: String
  };

  export type Mutation = {
    __typename?: "Mutation";

    updateconcesionario: Maybe<UpdateConcesionario>;
  };

  export type UpdateConcesionario = {
    __typename?: "concesionario";

    nombre: Maybe<String>;
    primer_apellido: Maybe<String>;
    segundo_apellido: Maybe<String>;
    tipo_persona: Maybe<String>; 
    razon_social: Maybe<String>;
    nuc: Maybe<String>;
    token: Maybe<String>;


  };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";
import {Concesionario} from '../models/concesionario';

import gql from "graphql-tag";
// ====================================================
// Apollo Services
// ====================================================



@Injectable({
  providedIn: "root"
})
export class UpdateConcesionarioGQL extends Apollo.Mutation<
  UpdateConcesionario.Mutation,
  UpdateConcesionario.Variables
> {
  document: any = gql`
  mutation createPagado($nombre:String,$primer_apellido:String,$segundo_apellido:String,$tipo_persona:String,$razon_social:String,$nuc:String,$token:String){
    crearPagado(nombre:$nombre,primer_apellido:$primer_apellido,segundo_apellido:$segundo_apellido,tipo_persona:$tipo_persona,razon_social:$razon_social,nuc:$nuc,token:$token){
      id,
      nombre,
      primer_apellido,
      segundo_apellido,
      tipo_persona,
      razon_social,
      nuc,
      estatus,
      createdAt
    }
  }

  `;
}


// ====================================================
// END: Apollo Angular template
// ====================================================
