import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
 selector: '[specialPlacas]'
})
export class OnlyPlacasDirective {

 constructor(private _el: ElementRef) { }

 @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.firstChild.firstElementChild.value;

   this._el.nativeElement.firstChild.firstElementChild.value = initalValue.replace(/[^a-zA-Z^0-9]*/g, '');

   if ( initalValue !== this._el.nativeElement.firstChild.firstElementChild.value) {
     event.stopPropagation();
   }
 }
}
