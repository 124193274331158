  import {Component, OnInit} from '@angular/core';
  import {Router} from '@angular/router';
  import {Concesionario} from '../models/concesionario';
  import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable } from 'rxjs';
  import { UpdateConcesionarioGQL } from '../graphqlconsultas/graphqlupdateconcesionario';
  import {User} from "../core/models/user.model";
  import {StorageService} from "../core/services/storage.service";
  declare var M: any;

@Component({
  selector: 'app-recuperar', templateUrl: './recuperar.component.html', styleUrls: ['./recuperar.component.css']
})
export class RecuperarComponent implements OnInit {

   public user: User;
   public password: String;
   public passwordconfirmar: String;

  constructor(
      private router?: Router,
      private apollo?: Apollo,
      private storageService?: StorageService

    ){}

  ngOnInit() {
    this.user = this.storageService.getCurrentUser();
    }

    comparar(){
      console.log(this.password);
      console.log(this.passwordconfirmar);
      console.log(this.password == this.passwordconfirmar);
      if(this.password == this.passwordconfirmar){
          this.mandarcontrasenianueva();
      }else{
        M.toast({html: "La contraseñas deben de ser iguales."})

      }
    }

    mandarcontrasenianueva(){

            var CryptoJS = require("crypto-js");
            var ciphertext = CryptoJS.AES.encrypt(this.password, 'llaveprueba').toString();

            console.log(ciphertext);
            /*
            this.apollo.use('endpoint2')
            .watchQuery({
              query: gql`
               ,

              ` ,
              variables: {
                      e: this.email,
                      p: ciphertext,

            }})
            .valueChanges.subscribe(result => {
               this.correctlogincheck(result.data);

             }, (error) => {
               M.toast({html: error})
             });
            */
    }

}
