/*
export { USER } from './user.gql';
export { CATEGORIES,ALLQUESTIONS,ALLLICENSE,ALLTEST,ALLTESTLINCENCIES,GETEXAM } from './test.gql';
export { SEARCH_PERSON } from './person.gql';
export { FOLIO_VALIDATION,INTENT_VALIDATION,GET_BLOQUEADOS } from './validation.gql';
*/

export { GET_CONDUCTOR,GET_CETIFICADO,GET_PREREGISTROS,BUSCAR_CONDUCTORES,GET_CERTIFICADOS_CONDUCTOR,GET_OBSERVACIONES_BY_CONSTANCIA,GET_PREREGISTROS_DRIVER,GET_EMPRESASPORID,GET_IMAGE_LICENSE,SEARCH_PERSON_BY_CURP,GET_PERSON,GET_LICENSE_BY_PERSON,GET_PERSONS } from './person.gql';


export {     GET_COMPROBACION,GET_MODALIDAD,LISTAR_CONCESIONARIOS,BUSCAR_COMPROBADO,GET_VERIFICACIONRETO,GET_CONDUCTORES,GET_CERTIFICATE_ACTIVATE,GET_TOTAL_ELEMENTOS,GET_INCREMENTAL_CONDUCTORES,GET_INCREMENTAL_PREREGISTROS } from './concessionaire.gql';
export { BUSCAR_DOCUMENTO,FIND_LICENSE } from './archive.gql';
export { GET_IMAGELICENSE_LATIN,GET_DOMICILIO_X_LICENCIA,GET_LICENSE_X_LICENCIA,GET_LICENSE,GET_IMAGELICENSE,GET_PLACAS,GET_LICENSENOMBRE,GET_LICENSEBYCURP } from './licencias.gql';
export {
  GET_MUNICIPIOS_INPUT,GET_LOCALIDADES_INPUT,
  GET_VIALIDADESTIPOS_INPUT,
  GET_ASENTAMIENTOSTIPOS_INPUT,
  GET_ASENTAMIENTOSNOMBRES_INPUT,
  GET_FOLIO,
  GET_AGENCIA_INPUT,
  GET_AGENCIA_ALL,
  GET_DIRECCION } from './direcciones.gql';

  export { GET_OBSERVACIONES_BY_DRIVER } from './comentarios.gql';
  export { PUEDE_ACTUALIZAR_CONDUCTOR,PUEDE_REGISTRAR } from './banderas.gql';
  export { GET_TOTALES_CONDUCTORES_FECHAS,GET_TOTAL_CONDUCTORES_ACTIVOS,GET_TOTALES_VENCIDOS_CANCELADOS_FECHAS,GET_TOTALES_FECHAS,GET_TOTALES,GET_TOTALESCONSTANCIAS_X_MODALIDAD,GET_TOTALESPREREGISTROS_X_MODALIDAD,GET_TOTALES_X_MODALIDAD_X_GENERO,GET_TOTALES_X_MODALIDAD_X_CONCESION } from './totales.gql';
  export {
    GET_CERTIFICATE,
     GET_LICENSE_CONDUCTOR,
    GET_CONCESION,
    GET_CONCESIONARIO,
    GET_CERTIFICADOS_X_CONCESION,
    GET_COUNT_CERTIFICADOS_X_CONCESION,
    GET_PLATES,
    VALID_FOLIO,
    GET_COUNT_PREREGISTROS_X_CONCESION,
    GET_PREREGISTROS_X_CONCESION,
    GET_OBSERVACIONES,
    VALID_PLACA,
    GET_ID_PLACA_FOLIO } from './entidades.gql';
    export { GET_REPRESENTANTE_CURP,GET_REPORTE,GET_SITIO,BUSCAR_REPORTE,GET_SITIOS_X_REPRESENTANTES,GET_REPRESENTANTES,GET_REPRESENTANTE,LISTAR_SITIOS,GET_REPORTES,GET_UNION_RESPONSABLE_SITIO } from './sitios.gql';
    export { LISTAR_VEHICULOS_CONCESION } from './listVehiculosXConcesion.gql';
    export { BUSCAR_TRAMITES } from './searchTramites.gql';
    export { GET_TARIFAS,GET_LOCALIDAD,GET_MUNICIPIO,GET_VEHICULO } from './tarifas.gql';
    export { GET_PERMISOS_CONCESION } from './permisos.gql';
    export {  GET_OBSERVACIONES_X_CONDUCTOR,GET_BENEFICIARIOS_SICAC,GET_BENEFICIARIOS } from './conductores.gql';
    export { CONTACTOSBYCONCESIONARIO } from './contacto.gql';
    export { LISTAR_MODALIDADES } from './listModalidades.gql';
    export { GET_CONSTANCIAS_REGION_FECHAS,GET_CONSTANCIAS_CANCELADAS_REGION_FECHAS,GET_PREREGISTROS_REGION_FECHAS,GET_PREREGISTROS_CANCELADOS_REGION_FECHAS } from './totalesxregiones.gql';

    export { BUSQUEDA_PERSONALIZADA,GET_REGISTROS_INCREMENTAL,GET_HISTORIAL,GET_REGISTROS_CONCESIONARIO,GET_REGISTROS_RETO_X_FASE,GET_CATALOGO_STATUS,GET_FORMATOS_REGISTRO,GET_CATALOGO_FORMATO,GET_REGISTRO_RETO,GET_CATALOGO_FORMATOS,GET_REGISTROS_RETO } from './registroreto.gql';
    export { TOTALES_RANGO_FECHA,TOTALES_RANGO_FECHA_X_STATUS,TOTALES_RANGO_FECHA_X_MODULO,TOTALES_RANGO_FECHA_X_MODALIDAD } from './totalesregistro.gql';
    export { GET_TOKEN } from './documentos.gql';
