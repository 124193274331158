import { createUploadLink } from 'apollo-upload-client';

import { environment } from '../../../environments/environment';
const uploadLink = createUploadLink({ uri: environment.URIRetoUtilidades});

const isFile = value =>
  (typeof File !== 'undefined' && value instanceof File) ||
  (typeof Blob !== 'undefined' && value instanceof Blob) ||
  (typeof FileList !== 'undefined' && value instanceof FileList);

const isUpload = ({ variables }) => Object.values(variables).some(isFile);

export { uploadLink, isFile, isUpload };
