  import {Component, OnInit} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable } from 'rxjs';
  import {User} from "../core/models/user.model";
  import {StorageService} from "../core/services/storage.service";
  import { saveAs  } from 'file-saver';
  declare var M: any;
  import kjua from "kjua";
  import * as A from '../graphql/mutations';
  import * as B from '../graphql/queries';

  import {ServiciosService} from "../core/services/servicios.service";
  import { CreadorComprobanteService } from '../services/pdfmake/creacionComprobante.service';
  import { environment } from '../../environments/environment';
  import { ErrorApolloService } from '../services/errors/error.service';

 @Component({
  selector: 'app-comentarios', templateUrl: './comentarios.component.html', styleUrls: ['./comentarios.component.css']
})
export class ComentariosComponent implements OnInit {
  divinformacion: boolean = false;
  mostrarbusqueda: boolean = true;
  conductorseleccionado: any;
  constanciasConductor: any;
  observacionesconductor: any;
  loading: boolean = false;
  comentario: any;
  conductoractivo: boolean = false;
  observacionxconstancia: any;
  constanciaseleccionada: any;
  arrayPreregistros: any;
  comentariosxconductor: any;
  mostrarcomentarios: boolean = false;


   static getBarcodeData(text: string, size = 900) {
    return kjua({
      render: "canvas", crisp: true, minVersion: 1, ecLevel: "Q", size: size, ratio: undefined, fill: "#333", back: "#fff",
      text, rounded: 10, quiet: 2, mode: "label",  mSize: 5, mPosX: 50, mPosY: 100, label: "", fontname: "sans-serif", fontcolor: "#3F51B5", image: undefined
    });
  }

  constructor(
      private router?: Router,
      private apollo?: Apollo,
      private storageService?: StorageService,
      private service?: ServiciosService,
      public creadorComprobanteService?: CreadorComprobanteService,
      public errorApolloService?: ErrorApolloService,

    ){}

  ngOnInit() {
    this.comentariosxconductor = [];
    $(document).ready(function(){
      $('.modal').modal({dismissible: false});
    });
  }

  public  check($event){
    this.divinformacion = true;
    this.conductorseleccionado = $event;
     this.traerConstanciasPorConductor();
     this.obtenerPreregistros();
     this.verComentarios();

  }

  public comprobar($event){
     this.divinformacion = false;
     this.constanciaseleccionada = null;
   }

   traerConstanciasPorConductor(){

     let params = {
       person_id: this.conductorseleccionado.id
     }
     this.apollo.use('backConstanciasSinToken').watchQuery({
       query: B.GET_CERTIFICADOS_X_CONCESION,
       variables: {
         params: params,
         mode: 'PER'
       }, fetchPolicy: 'network-only'
     })
       .valueChanges.subscribe(result => {
         this.asignarConstancias(result);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
         M.toast({ html: toastHTML });
       });

     /*
     this.apollo.use('backretoutilidades').watchQuery({
       query: B.GET_CERTIFICADOS_CONDUCTOR,
       variables: {
             driver_id: this.conductorseleccionado.id
          }, fetchPolicy: 'network-only'
        })
     .valueChanges.subscribe(result => {
        this.asignarConstancias(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({html: toastHTML});
       });

       */
   }

   asignarConstancias(data){
      //this.constanciasConductor = data.


      this.constanciasConductor = data.data.certificates;
     for(var i = 0; i < this.constanciasConductor.length;i++){
       this.constanciasConductor[i].numero = i+1;
       this.traerConcesion(this.constanciasConductor[i]);
     }


   }


   traerConcesion(constancia){
     this.apollo.use('backConsulta').watchQuery({
       query: B.GET_CONCESION,
       variables: {
         id: constancia.concession_id
       }, fetchPolicy: 'network-only'
     })
       .valueChanges.subscribe(result => {
         //this.pasosiguiente(result);
         this.asignarconcesion(result,constancia);

        }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
         M.toast({ html: toastHTML });

       });

   }

   asignarconcesion(data,constancia){
     constancia['concesion'] = data.data.concession;
   }

   verComentarios(){
     this.loading = true;
     this.observacionesconductor = [];
       let params = {
         person_id: this.conductorseleccionado.id
       }

       this.apollo.use('backretoutilidades').watchQuery({
         query: B.GET_OBSERVACIONES,
         variables: {
           params: params,
           mode: "PER"
         }, fetchPolicy: 'network-only'
       })
         .valueChanges.subscribe(result => {
           this.asignarComentarios(result);
         }, (error) => {
           var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de los preregistros.    </div></span>';
           M.toast({ html: toastHTML });
         });

   }

   asignarComentarios(data){

     this.observacionesconductor = [];

     for (var i = 0; i < data.data.observations.length; i++) {
       //this.observacionesconductor[i].numero = i + 1;
       this.observacionesconductor.push(
         {
           capture_date: data.data.observations[i].capture_date,
           certificate_id: data.data.observations[i].certificate_id,
           deprecated: data.data.observations[i].deprecated,
           description: data.data.observations[i].description,
           id: data.data.observations[i].id,
           log: data.data.observations[i].log,
           numero: (i + 1)
         }
       )
     }

     for (var i = 0; i < this.observacionesconductor.length; i++) {
       let indice = i;
       this.traerConstancia(indice);
      // this.observacionesconductor[i].numero = i + 1;
     }

     /*
     this.observacionesconductor = data.data.observations;
     for (var i = 0; i < this.observacionesconductor.length; i++) {
       this.observacionesconductor[i].numero = i + 1;
     }

     */

     this.buscarComentariosConductor();
     this.loading = false;
   }


   buscarComentariosConductor(){
     this.comentariosxconductor = [];

     this.apollo.use('backConductoressinToken').watchQuery({
       query: B.GET_OBSERVACIONES_X_CONDUCTOR,
       variables: {
         persons_id: this.conductorseleccionado.id
       }, fetchPolicy: 'network-only'
     })
       .valueChanges.subscribe(result => {
         this.asignarComentariosxConductor(result);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de los preregistros.    </div></span>';
         M.toast({ html: toastHTML });
       });
   }

   asignarComentariosxConductor(data){
      for(var i = 0; i < data.data.observationfindIdPersons.length;i++){
       this.comentariosxconductor.push(
         {
           id: data.data.observationfindIdPersons[i].id,
           capture_date: data.data.observationfindIdPersons[i].capture_date,
           description: data.data.observationfindIdPersons[i].description,
           usuario_id: data.data.observationfindIdPersons[i].usuario_id,
           certificate_id: data.data.observationfindIdPersons[i].certificate_id

         }
       );
     }

     this.comentariosxconductor.sort(function(a, b){return a.id - b.id});

     for(var i = 0; i < this.comentariosxconductor.length;i++){
       let indice = i;
       this.traerUsuario(indice);
       //this.traerConstancia(indice);
     }

    }


   traerConstancia(indice){
     this.apollo.use('backConstanciasSinToken').watchQuery({
       query: B.GET_CERTIFICATE,
       variables: {
         id: this.observacionesconductor[indice].certificate_id
       }, fetchPolicy: 'network-only'
     })
       .valueChanges.subscribe(result => {
         this.asignarConstanciaComentario(result,indice);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de los preregistros.    </div></span>';
         M.toast({ html: toastHTML });
       });
   }

   asignarConstanciaComentario(result,indice){
     this.observacionesconductor[indice]['constancia'] = result.data.certificate;
     let id_concession = result.data.certificate.concession_id;

     this.apollo.use('backConsulta')
       .watchQuery({
         query: B.GET_CONCESION,
         variables: {
           id: id_concession,
         }, fetchPolicy: 'network-only'
       })
       .valueChanges.subscribe(result => {
         this.asignarConcesionContancias(result.data,indice)
       }, (error) => {
         M.toast({ html: error })
       });

   }

   asignarConcesionContancias(data,indice){
     this.observacionesconductor[indice]['concesion'] = data.concession;

   }

   traerUsuario(indice){
     if(this.comentariosxconductor[indice].usuario_id!=undefined){
       this.service.getUsuario(this.comentariosxconductor[indice].usuario_id).subscribe({
         next: (data) => {
           this.asignarPersona(indice,data);
         },
         error: (error) => {},
         complete: () => {
           //this.loading = false;
         }
       });
     }

   }

   asignarPersona(indice,usuario){
     this.service.getPersona(usuario.id_persona).subscribe({
       next: (data) => {
         this.comentariosxconductor[indice]['personal'] = data.Personal;
       },
       error: (error) => {},
       complete: () => {
         //this.loading = false;
       }
     });
   }


   buscarUsuarioxComentario(){

   }

   funcioncrearfecha(stringdate: any){
     let fecha = new Date(stringdate);
     let dia21 = "";
     let anio21 = fecha.getFullYear().toString();
     let mes21 = "";
     if((fecha.getMonth()+1)<10){
      mes21 = "0"+(fecha.getMonth() + 1).toString();
     }else{
      mes21 = (fecha.getMonth() + 1).toString();
     }
      if((fecha.getDate()+1)<=10){
      dia21 = "0"+(fecha.getDate()).toString();
     }else{
      dia21 = (fecha.getDate()).toString();
     }
     return anio21  + "-" + mes21 + "-" + dia21 + "";
   }

   funcioncrearfechaDate(stringfecha){
     let fecha = new Date(stringfecha);

     let dia21 = "";
     let anio21 = fecha.getFullYear().toString();
     let mes21 = "";
     if((fecha.getMonth()+1)<10){
      mes21 = "0"+(fecha.getMonth() + 1).toString();
     }else{
      mes21 = (fecha.getMonth() + 1).toString();
     }
      if((fecha.getDate()+1)<=10){
      dia21 = "0"+(fecha.getDate()).toString();
     }else{
      dia21 = (fecha.getDate()).toString();
     }
     return anio21  + "-" + mes21 + "-" + dia21 + "";
   }

   habilitarbotones(constancia: any,event: any,indextabla: any){
     this.constanciaseleccionada = constancia;


     var matches = document.getElementsByClassName("sinfondo");
     let arry1 = Array.from(matches)

     for(var i = 0; i < arry1.length-1; i++){

      //  arry[i].style.backgroundColor = "#FFF";
        let element = <HTMLElement>(arry1[i]);
        element.style.backgroundColor = "#FFF";


      }


      var elementoapintar = <HTMLElement>document.getElementById("registrotabla"+indextabla);
      if(elementoapintar!=undefined){
        elementoapintar.style.backgroundColor = "#b2dfdb";
      }


      /*

      setTimeout(() => {
        if(event.target.tagName == "TD"){
          for(var i = 0; i < event.target.parentNode.parentNode.parentNode.rows.length; i++){
            event.target.parentNode.parentNode.parentNode.rows[i].style.backgroundColor = "#FFF";
            }
            event.target.parentNode.style.backgroundColor = "#b2dfdb";
        }
        if(event.target.tagName == "LABEL"){



          if(event.target.parentNode.parentNode.parentNode.parentNode.rows!=undefined){
            for(var i = 0; i < event.target.parentNode.parentNode.parentNode.parentNode.rows.length; i++){
              event.target.parentNode.parentNode.parentNode.parentNode.rows[i].style.backgroundColor = "#FFF";
              }
           event.target.parentNode.parentNode.parentNode.style.backgroundColor = "#b2dfdb";
          }

          if(event.target.parentNode.parentNode.parentNode.parentNode.parentNode.rows!=undefined){
            for(var i = 0; i < event.target.parentNode.parentNode.parentNode.parentNode.parentNode.rows.length; i++){
              event.target.parentNode.parentNode.parentNode.parentNode.parentNode.rows[i].style.backgroundColor = "#FFF";
              }
           event.target.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#b2dfdb";
          }



        }
        if(event.target.tagName == "DIV"){
          if(event.target.parentNode.parentNode.parentNode.rows!=undefined){
             for(var i = 0; i < event.target.parentNode.parentNode.parentNode.rows.length-1; i++){
             event.target.parentNode.parentNode.parentNode.rows[i].style.backgroundColor = "#FFF";
             }
             event.target.parentNode.parentNode.style.backgroundColor = "#b2dfdb";
          }else{
            if(event.target.parentNode.parentNode.parentNode.parentNode.rows!=undefined){

              var arr = [].slice.call(event.target.parentNode.parentNode.parentNode.parentNode.rows);
              let arry = Array.from(event.target.parentNode.parentNode.parentNode.parentNode.rows)
                 for(var i = 0; i < arry.length-1; i++){

                  //  arry[i].style.backgroundColor = "#FFF";
                    let element = <HTMLElement>(arry[i]);
                    element.style.backgroundColor = "#FFF";


                  }
                 event.target.parentNode.parentNode.parentNode.style.backgroundColor = "#b2dfdb";



            }
          }





        }
      }, 100);

      */



   }

   traerComentariosConstancia(){

     this.loading = true;
     let params = {
       certificate_id: this.constanciaseleccionada.id
     }
     this.apollo.use('backretoutilidades').watchQuery({
       query: B.GET_OBSERVACIONES,
       variables: {
         params: params,
         mode: "CER"
       }, fetchPolicy: 'network-only'
     })
       .valueChanges.subscribe(result => {
          this.asignarComentariosConstancia(result);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de los preregistros.    </div></span>';
         M.toast({ html: toastHTML });
       });

     /*
     this.apollo.use('backretoutilidades').watchQuery({
       query: B.GET_OBSERVACIONES_BY_CONSTANCIA,
       variables: {
             certificate_id: this.constanciaseleccionada.id
          }, fetchPolicy: 'network-only'
        })
     .valueChanges.subscribe(result => {
        this.asignarComentariosConstancia(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de los preregistros.    </div></span>';
        M.toast({html: toastHTML});
       });
       */
   }





   asignarComentariosConstancia(data){
     this.observacionxconstancia = data.data.observations;

     for(var i = 0; i < this.observacionxconstancia.length;i++){
       this.observacionxconstancia[i].numero = i+1;
     }
     this.mostrarcomentarios = false;

     this.loading = false;
   }

   agregarcomentario(){
     this.loading = true;
     this.apollo.use('backretoutilidades')
    .mutate({
     mutation: A.CREATE_OBSERVATION,
     variables: {
       description:this.comentario,
       certificate_id:this.constanciaseleccionada.id,
     }
   }).subscribe(result => {
     M.toast({html: 'Comentario realizado con exito'});
     this.comentario = null;
       this.traerComentariosConstancia();
       }, (error) => {
         this.errorApolloService.checkError(error.message);

     });
   }


   obtenerPreregistros(){
     this.loading = true;
     this.arrayPreregistros = [];

     let params = {
       person_id: this.conductorseleccionado.id
     }
     this.apollo.use('backConstanciasSinToken').watchQuery({
       query: B.GET_PREREGISTROS_X_CONCESION,
       variables: {
         params: params,
         mode: 'PER'
       }, fetchPolicy: 'network-only'
     })
       .valueChanges.subscribe(result => {
         this.asignarpreregistro(result);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
         M.toast({ html: toastHTML });
       });

     /*
     this.apollo.use('backretoutilidades').watchQuery({
       query: B.GET_PREREGISTROS_DRIVER,
       variables: {
             driver_id: this.conductorseleccionado.id
          }, fetchPolicy: 'network-only'
        })
     .valueChanges.subscribe(result => {
        this.asignarpreregistro(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de los preregistros.    </div></span>';
        M.toast({html: toastHTML});
       });
       */
    }

     asignarpreregistro(data: any){
       this.arrayPreregistros = [];
       let contador = 0;
       for(var i = 0; i < data.data.preCertificates.length;i++){
         if(data.data.preCertificates[i].is_completed == false && data.data.preCertificates[i].is_canceled == false){
           contador = contador + 1;
           this.arrayPreregistros.push(data.data.preCertificates[i]);
           this.arrayPreregistros[length].numero = contador;
           this.traerConcesion(this.arrayPreregistros[length]);
           this.traerLicencia(this.arrayPreregistros[length]);

          }
         if(i == data.data.preCertificates.length-1){
           this.loading = false;
         }
        }

        if(data.data.preCertificates.length == 0){
          this.loading = false;
        }


      }

      traerLicencia(preregistro){
        this.apollo.use('backConductoressinToken').watchQuery({
          query: B.GET_LICENSE_CONDUCTOR,
          variables: {
            person_id: preregistro.person_id,
          }, fetchPolicy: 'network-only'
        })
          .valueChanges.subscribe(result => {
            this.asignarLicencia(result, preregistro);
          }, (error) => {
            var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
            M.toast({ html: toastHTML });
          });
      }


      asignarLicencia(data,preregistro){
        preregistro['licencia'] = data.data.licenseByPerson;
      }

      agregarComentarioConductor(){
        this.mostrarcomentarios = true;

      }

      regresartabla(){
        this.mostrarcomentarios = false;
      }

      agregarComentario(){
        this.comentariosxconductor = [];

        this.apollo.use('backConductores')
        .mutate({
        mutation: A.CREATE_OBSERVACION_CONDUCTOR,
        variables: {
          description:this.comentario.toUpperCase(),
          persons_id:this.conductorseleccionado.id,
        }
        }).subscribe(result => {
        M.toast({html: 'Comentario realizado con exito'});
        this.comentario = null;
        //this.mostrarcomentarios = false;
        this.buscarComentariosConductoragregado();
          //this.traerComentariosConstancia();
          }, (error) => {
        });
      }


      buscarComentariosConductoragregado(){
        this.comentariosxconductor = [];

        this.apollo.use('backConductoressinToken').watchQuery({
          query: B.GET_OBSERVACIONES_X_CONDUCTOR,
          variables: {
            persons_id: this.conductorseleccionado.id
          }, fetchPolicy: 'network-only'
        })
          .valueChanges.subscribe(result => {
            this.asignarComentariosxConductoragregado(result);
          }, (error) => {
            var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de los preregistros.    </div></span>';
            M.toast({ html: toastHTML });
          });
      }

      asignarComentariosxConductoragregado(data){
        for(var i = 0; i < data.data.observationfindIdPersons.length;i++){
          let comentarioxconductor = this.comentariosxconductor.find(comentarios => comentarios.id == data.data.observationfindIdPersons[i].id);
          if(comentarioxconductor==undefined){
            this.comentariosxconductor.push(
              {
                id: data.data.observationfindIdPersons[i].id,
                capture_date: data.data.observationfindIdPersons[i].capture_date,
                description: data.data.observationfindIdPersons[i].description,
                usuario_id: data.data.observationfindIdPersons[i].usuario_id,

              }
            );
          }


        }

        this.comentariosxconductor.sort(function(a, b){return a.id - b.id});



        for(var i = 0; i < this.comentariosxconductor.length;i++){
          let indice = i;
          this.traerUsuarioagregado(indice);
        }
      }

      traerUsuarioagregado(indice){
        if(this.comentariosxconductor[indice].usuario_id!=undefined){
          this.service.getUsuario(this.comentariosxconductor[indice].usuario_id).subscribe({
            next: (data) => {
              this.asignarPersonaagregado(indice,data);
            },
            error: (error) => {},
            complete: () => {
              //this.loading = false;
            }
          });
        }

      }

      asignarPersonaagregado(indice,usuario){
        this.service.getPersona(usuario.id_persona).subscribe({
          next: (data) => {
            this.comentariosxconductor[indice]['personal'] = data.Personal;
            if(indice == (this.comentariosxconductor.length-1)){
               this.mostrarcomentarios = false;

            }
          },
          error: (error) => {},
          complete: () => {
            //this.loading = false;
          }
        });
      }

      mostrarComentariosmodal(){
        this.loading = true;
        setTimeout(() => {
          this.loading = false;

          $('#modalcomentarios').modal('open');

        }, 100);

      }

      descargarComprobante(){

        this.loading = true;



        this.apollo.use('backConductoressinToken').watchQuery({
          query: B.GET_PERSON,
          variables: {
            id: this.constanciaseleccionada.person_id,
          }, fetchPolicy: 'network-only'
        })
          .valueChanges.subscribe(result => {
            this.asignarconductor(result);
          }, (error) => {
            var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
            M.toast({ html: toastHTML });
          });

        //this.creadorComprobanteService.crearConstanciaComprobante(this.conductorseleccionado,cancelada);
      }


      asignarconductor(data){

        this.constanciaseleccionada['persona'] = data.data.person;

        this.constanciaseleccionada['image'] = this.constanciaseleccionada.image_name;
        this.constanciaseleccionada['hojafoliada'] = this.constanciaseleccionada.foliated_sheet.folio;
        this.constanciaseleccionada['placa'] = this.constanciaseleccionada.plate.folio;

                this.apollo.use('documentos').watchQuery({
                  query: B.GET_TOKEN,
                  variables: {
                        codigo_sistema: 'RQG68',
                        id_tramite: this.constanciaseleccionada.id
                     }, fetchPolicy: 'network-only'
                })
                .valueChanges.subscribe(result => {
                  this.asignarTokenComprobante(result);
                 });


      //  this.creadorComprobanteService.crearConstanciaComprobante(this.constanciaseleccionada,cancelada);
      }

      asignarTokenComprobante(data){
        let qrmensagge = 'https://verificacion.semovioaxaca.gob.mx/validar/' + data.data.getToken;
        let cancelada = true
        if (this.constanciaseleccionada.is_canceled) {
          cancelada = false;
        } else {
          cancelada = true;
        }
          //this.creadorComprobanteService.crearConstanciaComprobante(this.constanciaseleccionada,cancelada,qrmensagge);

          this.crearConstanciaComprobante(this.constanciaseleccionada,cancelada,qrmensagge);

          }


          crearConstanciaComprobante(conductor,conductoractivo,qrmensagge){
            let imageurl;
            setTimeout(() => {
              imageurl = this.getBase64ImageFromURLImage(environment.URIImage + 'image/' + this.constanciaseleccionada.image_name,conductor,conductoractivo,qrmensagge);
            }, 100);
          }


          getBase64ImageFromURLImage(url,conductor,conductoractivo,qrmensagge) {
            return new Promise((resolve, reject) => {
              var img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
               this.traerImagenBanner(dataURL,conductor,conductoractivo,qrmensagge);
               };
              img.onerror = error => {
                reject(error);
              };
              img.src = url;
            });
          }

          traerImagenBanner(dataURL,conductor,conductoractivo,qrmensagge){
            let imageurl;
            setTimeout(() => {
              imageurl = this.getBase64ImageFromURLBanner('https://resource.semovioaxaca.gob.mx/assets/images/banner.png',dataURL,conductor,conductoractivo,qrmensagge);
            }, 100);
          }


          getBase64ImageFromURLBanner(url,imageconstancia,conductor,conductoractivo,qrmensagge) {
            return new Promise((resolve, reject) => {
              var img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
               //this.construirConstanciaComprobante(dataURL,imageconstancia,conductor,conductoractivo,qrmensagge);

               this.creadorComprobanteService.download(() => {
                 this.loading = false;
                 M.toast({html: 'Proceso finalizado exitosamente!'});
               },dataURL,imageconstancia,conductor,conductoractivo,qrmensagge);

               };
              img.onerror = error => {
                reject(error);
              };
              img.src = url;
            });
          }


    agregarComentarioConstancia(){
    }


}
