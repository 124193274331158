import gql from 'graphql-tag';


const CHANGE_BLOQUEADO_CONDUCTOR = gql`
mutation updateBloqueado($id: ID!,$bloqueado: Boolean!) {
		updateBloqueado(id: $id,bloqueado: $bloqueado)
	  }
`;

const CREATE_PERSON = gql`
mutation newPerson(
$name: String!
$first_surname: String!
$second_surname: String
$uprc: String!
$contact: ContactInput!
) {
person(
  name: $name
  first_surname: $first_surname
  second_surname: $second_surname
  uprc: $uprc
  contact: $contact
) {
  id
  name
  first_surname
  second_surname
  uprc
  status
  created_at
}
}
`;

const CREATE_PERSONA = gql`
mutation person($curp: String!) {
		person(curp: $curp) {
		  id
		}
	  }
`;



const SET_PERSONA_DIRECCION = gql`
mutation addressPerson($person_id: ID!,$address_id: ID!) {
		addressPerson(person_id: $person_id,address_id: $address_id){
			id
		}
	  }
`;

const SET_PERSONA_CONTACTO = gql`
mutation contact($email: String,$phone: String,$person_id: ID!) {
		contact(email: $email,phone: $phone,person_id: $person_id){
			id
		}
	  }
`;

const SET_PERSONA_DISCAPACIDAD = gql`
mutation disabilities($person_id: ID!,$disabilities: [ID!]!) {
		disabilities(person_id: $person_id,disabilities: $disabilities){
			id
		}
	  }
`;

const SET_PERSONA_IDENTIDAD = gql`
mutation identity($name: String!,$first_surname: String!,$second_surname: String,$person_id: ID!) {
		identity(name: $name,first_surname: $first_surname,second_surname: $second_surname,person_id: $person_id){
			id
		}
	  }
`;


const SET_PERSONA_LICENCIA = gql`
mutation license($license_number: String!,$person_id: ID!) {
		license(license_number: $license_number,person_id: $person_id){
			id
		}
	  }
`;


const SET_PERSONA_PERSONAL = gql`
mutation personal($birthday: Date!,$sex: String!,$education_level_id: ID,$marital_status_id: ID,$person_id: ID!) {
		personal(birthday: $birthday,sex: $sex,education_level_id: $education_level_id,marital_status_id: $marital_status_id,person_id: $person_id){
			id
		}
	  }
`;



const CREATE_CERTIFICADO = gql`
mutation newCertificate(
      $concession_id: ID!
      $driver_id: ID
      $driver: DriverInput
      $address: AddressInput
      $contact: ContactInput
      $plate_id: ID!
      $image: ImageInput!
      $foliated_sheet: FoliatedSheetInput!
      $expedition_date: Date!
      $expiration_date: Date!
      $modality_id: ID!
      $trainings: [TrainingInput]
    ) {
      certificate(
        concession_id: $concession_id
        driver_id: $driver_id
        driver: $driver
        address: $address
        contact: $contact
        plate_id: $plate_id
        image: $image
        foliated_sheet: $foliated_sheet
        expedition_date: $expedition_date
        expiration_date: $expiration_date
        modality_id: $modality_id
        trainings: $trainings
      ) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          term
          main_notes
          delivery_certificate_notes
          route
          authorized_vehicles
          status
          modality {
            id
            name
            description
            status
            abbreviation
          }
          site {
            id
            name
            legal_representative
            address
            authorized_colors
            status
          }
          consecutive_number
          unique_number_key
          attached {
            active
            new_vehicle_auth
            new_concession_auth
            locked {
              status
              stolen_folio
            }
            reto {
              status
            }
          }
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
            status
            created_at
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              settlement_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              sublevel_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            status
            created_at
          }
          trainings {
            id
            folio
            description
            expiration_date
            status
            created_at
          }
          status
          created_at
        }
        plate {
          id
          folio
          status
          created_at
        }
        image {
          id
          name
          original_name
          extension
          type_content
          route
          base64
          status
          created_at
        }
        foliated_sheet {
          id
          folio
          status
          created_at
        }
        expedition_date
        expiration_date
        modality {
          id
          name
          description
          status
          abbreviation
        }
        log {
          id
          description
          id_user
          user_description
          workplace
          region
          ip_address
          user_agent
          http_method
          status
          created_at
        }
        is_canceled
        cancel_description
        status
        created_at
      }
    }

`;



const CANCEL_CERTIFICATE = gql`
mutation cancelCertificate($id: ID!, $cancel_description: String!) {
      cancelCertificate(id: $id, cancel_description: $cancel_description)
    }

`;



const NEW_CAPACITACIONES = gql`
mutation newTrainings($trainings: [TrainingInput]!, $driver_id: ID!) {
      trainings(trainings: $trainings, driver_id: $driver_id) {
        id
        folio
        description
        expiration_date
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
            status
            created_at
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              settlement_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              sublevel_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            status
            created_at
          }
          status
          created_at
        }
        status
        created_at
      }
    }

`;



const CHANGE_ESTATUS = gql`
mutation createPagado($nombre:String,$primer_apellido:String,$segundo_apellido:String,$tipo_persona:String,$razon_social:String,$nuc:String,$token:String){
  crearPagado(nombre:$nombre,primer_apellido:$primer_apellido,segundo_apellido:$segundo_apellido,tipo_persona:$tipo_persona,razon_social:$razon_social,nuc:$nuc,token:$token){
    id,
    nombre,
    primer_apellido,
    segundo_apellido,
    tipo_persona,
    razon_social,
    nuc,
    estatus,
    createdAt
  }
}
`;


const CREATE_PREREGISTRO = gql`
mutation newPreCertificate(
      $concession_id: ID!
      $driver_id: ID
      $driver: DriverInput
      $address: AddressInput
      $contact: ContactInput
      $plate_id: ID!
      $image: ImageInput!
      $modality_id: ID!
      $trainings: [TrainingInput]
    ) {
      preCertificate(
        concession_id: $concession_id
        driver_id: $driver_id
        driver: $driver
        address: $address
        contact: $contact
        plate_id: $plate_id
        image: $image
        modality_id: $modality_id
        trainings: $trainings
      ) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          term
          main_notes
          delivery_certificate_notes
          route
          authorized_vehicles
          status
          modality {
            id
            name
            description
            status
            abbreviation
          }
          site {
            id
            name
            legal_representative
            address
            authorized_colors
            status
          }
          consecutive_number
          unique_number_key
          attached {
            active
            new_vehicle_auth
            new_concession_auth
            locked {
              status
              stolen_folio
            }
            reto {
              status
            }
          }
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
            status
            created_at
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              settlement_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              sublevel_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            status
            created_at
          }
          trainings {
            id
            folio
            description
            expiration_date
            status
            created_at
          }
          status
          created_at
        }
        plate {
          id
          folio
          status
          created_at
        }
        image {
          id
          name
          original_name
          extension
          type_content
          route
          base64
          status
          created_at
        }
        is_canceled
        is_completed
        expedition_date
        modality {
          id
          name
          description
          status
          abbreviation
        }
        log {
          id
          description
          id_user
          user_description
          workplace
          region
          ip_address
          user_agent
          http_method
          status
          created_at
        }
        status
        created_at
      }
    }

`;


const CHANGE_PREREGISTRO = gql`
mutation preCertificateToCertificate(
      $pre_certificate_id: ID!
      $foliated_sheet: FoliatedSheetInput!
      $expedition_date: Date!
      $expiration_date: Date!
    ) {
      preCertificateToCertificate(
        pre_certificate_id: $pre_certificate_id
        foliated_sheet: $foliated_sheet
        expedition_date: $expedition_date
        expiration_date: $expiration_date
      ) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          term
          main_notes
          delivery_certificate_notes
          route
          authorized_vehicles
          status
          modality {
            id
            name
            description
            status
            abbreviation
          }
          site {
            id
            name
            legal_representative
            address
            authorized_colors
            status
          }
          consecutive_number
          unique_number_key
          attached {
            active
            new_vehicle_auth
            new_concession_auth
            locked {
              status
              stolen_folio
            }
            reto {
              status
            }
          }
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
            status
            created_at
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              settlement_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              sublevel_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            status
            created_at
          }
          trainings {
            id
            folio
            description
            expiration_date
            status
            created_at
          }
          status
          created_at
        }
        plate {
          id
          folio
          status
          created_at
        }
        image {
          id
          name
          original_name
          extension
          type_content
          route
          base64
          status
          created_at
        }
        foliated_sheet {
          id
          folio
          status
          created_at
        }
        expedition_date
        expiration_date
        modality {
          id
          name
          description
          status
          abbreviation
        }
        log {
          id
          description
          id_user
          user_description
          workplace
          region
          ip_address
          user_agent
          http_method
          status
          created_at
        }
        is_canceled
        cancel_description
        status
        created_at
      }
    }

`;


const CANCEL_PREREGISTRO = gql`
mutation CancelPreCertificate($id: ID!) {
  cancelPreCertificate(id: $id)
}
`;


const ACTIVATE_PREREGISTRO = gql`
mutation ActivatePreCertificate($id: ID!) {
  activatePreCertificate(id: $id)
}
`;


const UPDATE_DRIVER = gql`
mutation UpdateDriver(
  $id: ID!
  $name: String!
  $first_surname: String!
  $second_surname: String
  $curp: String!
  $birthday: Date!
  $license_number: String!
) {
  updateDriver(
    id: $id
    name: $name
    first_surname: $first_surname
    second_surname: $second_surname
    curp: $curp
    birthday: $birthday
    license_number: $license_number
  ) {
    id
    name
    first_surname
    second_surname
    curp
    birthday
    license_number
  }
}
`;



const ACTUALIZAR_DATOSGENERALES_DRIVER = gql`
mutation updateDriver(
      $id: ID!
      $name: String!
      $first_surname: String!
      $second_surname: String
      $curp: String!
      $birthday: Date!
    ) {
      updateDriver(
        id: $id
        name: $name
        first_surname: $first_surname
        second_surname: $second_surname
        curp: $curp
        birthday: $birthday
      ) {
        id
        name
        first_surname
        second_surname
        curp
        birthday
        license_number
        contact {
          id
          email
          celphone
          status
          created_at
        }
        address {
          id
          road_name
          road_type {
            id
            name
            description
            status
            created_at
          }
          outdoor_number
          indoor_number
          postal_code
          settlement {
            id
            name
            locality {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              code
              status
              created_at
            }
            settlement_type {
              id
              name
              description
              status
              created_at
            }
            status
            created_at
          }
          sublevel {
            id
            name
            municipality {
              id
              name
              district {
                id
                name
                region {
                  id
                  name
                  status
                  created_at
                }
                code
                status
                created_at
              }
              code
              status
              created_at
            }
            sublevel_type {
              id
              name
              description
              status
              created_at
            }
            status
            created_at
          }
          status
          created_at
        }
        trainings {
          id
          folio
          description
          expiration_date
          status
          created_at
        }
        status
        created_at
      }
    }

`;



const ACTUALIZAR_DIRECCION_DRIVER = gql`
mutation updateAddress($driver_id: ID!, $address: AddressInput!) {
      driverAddress(driver_id: $driver_id, address: $address) {
        id
        road_name
        road_type {
          id
          name
          description
          status
          created_at
        }
        outdoor_number
        indoor_number
        postal_code
        settlement {
          id
          name
          locality {
            id
            name
            municipality {
              id
              name
              district {
                id
                name
                region {
                  id
                  name
                  status
                  created_at
                }
                code
                status
                created_at
              }
              code
              status
              created_at
            }
            code
            status
            created_at
          }
          settlement_type {
            id
            name
            description
            status
            created_at
          }
          status
          created_at
        }
        sublevel {
          id
          name
          municipality {
            id
            name
            district {
              id
              name
              region {
                id
                name
                status
                created_at
              }
              code
              status
              created_at
            }
            code
            status
            created_at
          }
          sublevel_type {
            id
            name
            description
            status
            created_at
          }
          status
          created_at
        }
        status
        created_at
      }
    }
`;

const ACTUALIZAR_CONTACTO_DRIVER = gql`
mutation updateContact($id: ID!, $email: String, $celphone: String!) {
      updateContact(id: $id, email: $email, celphone: $celphone) {
        id
        email
        celphone
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              settlement_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              sublevel_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            status
            created_at
          }
          trainings {
            id
            folio
            description
            expiration_date
            status
            created_at
          }
          status
          created_at
        }
        status
        created_at
      }
    }

`;

export { CHANGE_BLOQUEADO_CONDUCTOR,CREATE_PERSONA,CREATE_PERSON,SET_PERSONA_DIRECCION,SET_PERSONA_CONTACTO,SET_PERSONA_DISCAPACIDAD,SET_PERSONA_IDENTIDAD,SET_PERSONA_LICENCIA,SET_PERSONA_PERSONAL, CREATE_CERTIFICADO, CANCEL_CERTIFICATE,NEW_CAPACITACIONES,CHANGE_ESTATUS,CREATE_PREREGISTRO,CHANGE_PREREGISTRO,CANCEL_PREREGISTRO,ACTIVATE_PREREGISTRO,UPDATE_DRIVER,ACTUALIZAR_DATOSGENERALES_DRIVER,ACTUALIZAR_DIRECCION_DRIVER,ACTUALIZAR_CONTACTO_DRIVER };
