import { ComponentRef, ComponentFactoryResolver,ChangeDetectorRef, ViewContainerRef, ViewChild, Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
  import {Router} from '@angular/router';
   import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable,Observer } from 'rxjs';
   declare var M: any;
  import * as B from '../graphql/queries';
  import * as N from '../graphql/mutations';
  import  Swal from 'sweetalert2';
  import { environment } from '../../environments/environment';

@Component({
  selector: 'app-search', templateUrl: './search.component.html', styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {

  divdebusqueda: boolean = true;
  busquedapornombre: boolean = true;
  busquedapornuc: boolean = false;
  busquedaporsitio: boolean = false;
  registroabuscar: any;
  data: [];
  divplantillas: boolean = false;
  loading: boolean = false;

  registroamostrar: any;
  @Output() Ostatus = new EventEmitter<any>();
  @Output() Result = new EventEmitter<any>();
  @Input() Hidden: any;
  @Input() Change: any;

  opBusquedaConcesionario: boolean = true;
  opBusquedaVehiculo: boolean = false;
  opBusquedaSitio: boolean = false;
  divdebusquedaSitio: boolean = false;
  divsitio: boolean = false;
  sitios: any;
  tamaniodivsarriba: any;
  busquedaporneconomico: boolean = false;
  busquedaporserie: boolean = false;
  busquedapormotor: boolean = false;
  busquedaporplaca: boolean = false;
  vehiculos : any;
  sitio: any;
  modalidadesSitio: any;
  modalidadesSitioVencidos:any;
  arrayBusquedas: any = [];
  constructor(
      private router?: Router,
      private apollo?: Apollo,

    ){}

  ngOnInit() {
   }

  asignarBusquedaXNombre(){
    this.busquedapornombre=true;
    this.busquedapornuc=false;
    this.busquedaporsitio=false;
  }
  asignarBusquedaXNUC(){
    this.busquedapornombre=false;
    this.busquedaporsitio=false;
    this.busquedapornuc=true;
  }

  mychange(event){
      if( this.registroabuscar.length != 0 && this.registroabuscar.length != 1){
       if(this.busquedapornombre==true){
         this.divdebusqueda = true;
         this.llamarregistros(1);
       }
       if(this.busquedapornuc==true){
         this.divdebusqueda = true;
         this.llamarregistros(2);
       }
       //this.busquedapornombre=true;
     }




  }

  llamarregistros(decision: any){
    this.loading = true;

     this.apollo.use('backConsulta').watchQuery({
      query: B.LISTAR_CONCESIONARIOS,
      variables: {
        input:  this.registroabuscar,
        option: decision,
        limit: 10
      }, fetchPolicy: 'network-only'
    })
    .valueChanges.subscribe(result => {
      this.crearregistros(result.data)
    });
  }


  crearregistros(registrosencontrados: any){
    this.data = registrosencontrados.concessionsLike;
    this.loading = false;

  }


  seleccionarregistro(registro: any){
    this.registroamostrar = registro;
    let splitobservacion = this.registroamostrar.expiration_date.split("T");
    let splitdia = splitobservacion[0].split("-");
    if(this.registroamostrar.consecutive_number != '' || this.registroamostrar.unique_number_key != ''){
      var d = new Date(parseInt(splitdia[0]),parseInt(splitdia[1])-1,parseInt(splitdia[2]),0,0,0,0);
      var di = new Date();
       if (di.getTime() < d.getTime()){

         if(this.registroamostrar.attached.locked.status){
           this.apollo.use('backConsulta')
             .watchQuery({
               query: B.GET_COMPROBACION,
               variables: {
                 id_concesion: this.registroamostrar.id,
                 id_concesionario: this.registroamostrar.concessionaire.id,
                 id_aplicacion: environment.ID_Aplicacion,
               }, fetchPolicy: 'network-only'
             })
             .valueChanges.subscribe(result => {
               this.comprobacionaplicacion(result.data);
             }, (error) => {
               M.toast({ html: error })
             });
         }else{
           this.Ostatus.emit(this.registroamostrar);
           this.divdebusqueda = false;
           this.divplantillas = true;
         }


/*
         this.apollo.use('backConsulta')
           .watchQuery({
             query: B.GET_COMPROBACION,
             variables: {
               id_concesion: this.registroamostrar.id,
               id_concesionario: this.registroamostrar.concessionaire.id,
               id_aplicacion: environment.ID_Aplicacion,
             }, fetchPolicy: 'network-only'
           })
           .valueChanges.subscribe(result => {
             this.comprobacionaplicacion(result.data);
           }, (error) => {
             M.toast({ html: error })
           });

           */

/*

         if(this.registroamostrar.attached.locked.status){
           const swalWithBootstrapButtons = Swal.mixin({
             customClass: {
               confirmButton: 'btn btn-success btnok',
               cancelButton: 'btn btn-danger btncancel'
             },
             buttonsStyling: false
           })
           swalWithBootstrapButtons.fire({
                 icon: 'warning',
                 title: 'Información',
                 html: 'La concesión esta bloqueada, ¿Desea continuar?',
                 confirmButtonText: 'Si',
                 showCancelButton: true,
                 cancelButtonText: 'No, cerrar',
               }).then((result) => {
                 if (result.isConfirmed) {
                   this.Ostatus.emit(this.registroamostrar);
                   this.divdebusqueda = false;
                   this.divplantillas = true;
                 }
               })
         }else{
           this.Ostatus.emit(this.registroamostrar);
           this.divdebusqueda = false;
           this.divplantillas = true;
         }

         */

       }else{
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Concesión vencida. Dia de Vencimiento ' + splitdia + '    </div></span>';
         M.toast({html: toastHTML});
        }

    }else{
      var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Concesiones sin NUC no puede ser seleccionadas para este proceso.    </div></span>';
      M.toast({html: toastHTML});

    }





  }


  comprobacionaplicacion(data){
    if(data.concesion_bloqueadaPersonalizada.length>0){


      this.Ostatus.emit(this.registroamostrar);
      this.divdebusqueda = false;
      this.divplantillas = true;
    }else{
      Swal.fire({
        title: 'Información',
        text: "La concesión esta bloqueada para realizar trámites en este sistema",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: "Cerrar",
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.value) {
        }
      })
    }

  }




  buscarOtroConcesionario(){
    this.Result.emit(false);
    this.registroamostrar = undefined;
    this.divdebusqueda = false;
    this.busquedapornuc = false;
    this.busquedapornombre = true;
    this.divplantillas = false;
    this.Hidden = false;
    this.opBusquedaSitio = false;
    this.divdebusqueda = true;
    this.registroabuscar = undefined;
    this.data = [];

   }

   limpiarbusqueda(){
   }




}
