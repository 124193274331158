import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AccountService } from '../autentication.service';
import { UtilsService } from '../utils/utils.service';
import { Router } from '@angular/router';
import { slideAnimation } from '../transition';
import { ServiciosService } from '../../core/services/servicios.service';
import { SessionStorageService } from '../../core/services/sessionstorage.service';
declare var M: any;
import { environment } from 'src/environments/environment';
import { RelacionesInputValidacionesService } from '../../services/validaciones/relacioninputvalidaciones.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  pass: any;
  loading: boolean = false;
  environment: any;

  constructor(
    private serviciosService: ServiciosService,
    private formBuilder: FormBuilder,
    private service: AccountService,
    private utils: UtilsService,
    private sessionStorageService: SessionStorageService,
    private router?: Router,
    public relacionesInputValidacionesService?: RelacionesInputValidacionesService,

  ) { }

  ngOnInit(): void {
    this.environment = environment;
    this.form = this.formBuilder.group({
      correo_electronico: this.formBuilder.control('', this.relacionesInputValidacionesService.getValidacion('correorequerido')),
      clave_privada: this.formBuilder.control('', {
        validators: [Validators.required]
      })
    });
  }

  submit(event) {
    this.service.autenticacion({
      correo_electronico: this.getCorreoElectronico.value,
      clave_privada: this.utils.hashing(this.getClavePrivada.value),
      id_aplicacion:  environment.ID_Aplicacion
    }).subscribe({
      next: () => { },
      error: (error) => {



        if(error.error.mensaje == 'Claves de acceso invalidas'){
          M.toast({html: '<i class="material-icons left">info</i> Acceso denegado!'})
          this.enviarIntento();
        }

        if(error.error.mensaje == "El usuario esta bloqueado"){
          M.toast({html: '<i class="material-icons left">info</i> Acceso denegado!'})
        }


      },
      complete: () => {
        this.getPerfil()
        //window.location.href = "/home";
        //window.location.href = "/home";
        //this.redirect();
        /*
        this.serviciosService.perfil().subscribe({
          next: (data) => {
             this.sessionStorageService.set("usuario", JSON.stringify(data))
             window.location.href = "/home";
          },
          error: (error) => {},
          complete: () => {
          }
        });

        */
      }
    });
  }


  enviarIntento(){
    this.serviciosService.sendLogId({
      correo_electronico:  this.getCorreoElectronico.value,
      id_aplicacion: environment.ID_Aplicacion
    }).subscribe({
      next: (data) => {

      let obj = JSON.parse(data);
        if(obj.Bloqueado){
          M.toast({html: '<i class="material-icons left">info</i> Acceso denegado!'})

        }else{
          if(obj.Intentos!=undefined){
            if(obj.Intentos>3){
              M.toast({html: '<i class="material-icons left">info</i> Comunicarse con el Departamento de Sistemas '})
            }else{
               M.toast({html: '<i class="material-icons left">info</i>  Favor de revisar sus credenciales'})
            }


          }else{
            M.toast({html: '<i class="material-icons left">info</i>  Correo no registrado,Favor de revisar sus credenciales'})

          }
        }



      },
      error: (error) => {

        var textoerror = error.error.mensaje.split(':');
        if(textoerror[0] =='USUARIO BLOQUEADO INTENTA ACCEDER'){
          M.toast({html: '<i class="material-icons left">info</i>  Comunicarse con el Departamento de Sistemas.'})

        }else{
          if( textoerror[0] == 'INTENTO DE ACCESO CON CORREO INVALIDO'){
            M.toast({html: '<i class="material-icons left">info</i>  Correo no registrado.'})

          }else{

            if( textoerror[0] == 'USUARIO BLOQUEADO'){
            }else{
              M.toast({html: '<i class="material-icons left">info</i> Acceso denegado!'})
              M.toast({html: '<i class="material-icons left">info</i>  Comunicarse con el Departamento de Sistemas.'})

            }


          }

        }


      },
      complete: () => {

      }
    });
  }

  getPerfil(){
     this.serviciosService.perfil().subscribe({
       next: (data) => {
          this.sessionStorageService.set("usuario", JSON.stringify(data))
         window.location.href = "/informacion";
       },
       error: (error) => {},
       complete: () => {
       }
     });
  }

  get getCorreoElectronico(): AbstractControl {
    return this.form.get('correo_electronico');
  }

  get getClavePrivada(): AbstractControl {
    return this.form.get('clave_privada');
  }
}
