import gql from 'graphql-tag';

const BUSCAR_DOCUMENTO = gql`
query findDocumento($url:String){
  documento(url:$url){
      data
    }
  }
`;


const FIND_LICENSE = gql`
query getLicenseByNumber($numero:String!){
licenseByNumber(numero:$numero){
  id
  contribuyente{
    nombre
    primer_apellido
    segundo_apellido
    curp
    ubicacion{
      calle
      numero_exterior
      numero_interior
      codigo_postal
      asentamiento
      colonia
      municipio
      estado
    }
  }
  tipo
  vigencia
  fecha_captura
  fecha_impresion
  fecha_vencimiento
  fecha_antiguedad
}
}
`;

export { BUSCAR_DOCUMENTO,FIND_LICENSE };
