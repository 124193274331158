import gql from 'graphql-tag';

const GET_SITIOS_X_REPRESENTANTES = gql`
query getSitiosRepresentantes($params: JSON, $mode: String!) {
		union_sitios_responsables(params: $params,mode: $mode) {
      id
    	id_sitio
    	id_responsable
    	deprecated
		}
}
`;


const GET_REPRESENTANTES = gql`
query getResponsables($params: JSON, $mode: String!) {
		responsables(params: $params,mode: $mode) {
      id
      nombre
      primer_apellido
      segundo_apellido
      telefono
      correo_electronico
			curp
		}
}
`;


const GET_REPRESENTANTE = gql`
query getResponsable($id: ID!) {
		responsable(id: $id) {
      id
      nombre
      primer_apellido
      segundo_apellido
      telefono
      correo_electronico
			curp
		}
}
`;



const LISTAR_SITIOS = gql`
query getSitesLike($input: String!, $limit: Int) {
      sitesLike(input: $input, limit: $limit) {
        id
        name
        legal_representative
        address
        authorized_colors
        locality {
          id
          name
          municipality {
            id
            name
            district {
              id
              name
              region {
                id
                name
              }
            }
          }
        }
        status
      }
    }

,
`;


const GET_REPORTES = gql`
query reportes($params: JSON, $mode: String!) {
		reportes(params: $params,mode: $mode) {
      id
      id_union_sitio_responsable
      report_date
      send_date
      validity_date
      deprecated
		}
}
`;


const GET_UNION_RESPONSABLE_SITIO = gql`
query union_sitio_responsable($id: ID!) {
		union_sitio_responsable(id: $id) {
      id
      id_sitio
      id_responsable
      deprecated
		}
}
`;


const BUSCAR_REPORTE = gql`
query findReporte($id_reporte: ID!) {
		findReporte(id_reporte: $id_reporte) {
			id
	    concession_id
	    person_id
	    plate{
	      id
	      folio
	    }
	    foliated_sheet{
	      id
	      folio
	    }
	    expedition_date
	    expiration_date
	    is_canceled
	    cancel_description
	    image_name
	    modality_id
	    is_expired
	    deprecated
		}
}
`;


const GET_SITIO = gql`
query site($id: ID!){
    site(id: $id) {
      name
    }
}
`;


const GET_REPORTE = gql`
query reporte($id: ID!){
    reporte(id: $id) {
      id
			id_union_sitio_responsable
			report_date
    }
}
`;


const GET_REPRESENTANTE_CURP = gql`
query responsableByCurp($curp: String!) {
		responsableByCurp(curp: $curp) {
      id
			nombre
			primer_apellido
			segundo_apellido
			telefono
			correo_electronico
			curp
		}
}
`;


export { GET_REPRESENTANTE_CURP,GET_REPORTE,GET_SITIO,BUSCAR_REPORTE,GET_SITIOS_X_REPRESENTANTES,GET_REPRESENTANTES,GET_REPRESENTANTE,LISTAR_SITIOS,GET_REPORTES,GET_UNION_RESPONSABLE_SITIO };
