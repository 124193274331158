import { ComponentRef, ComponentFactoryResolver,ChangeDetectorRef, ViewContainerRef, ViewChild, Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
  import {Router} from '@angular/router';
   import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable,Observer } from 'rxjs';
   declare var M: any;
  import * as B from '../graphql/queries';
  import * as N from '../graphql/mutations';

@Component({
  selector: 'app-searchConductor', templateUrl: './searchConductor.component.html', styleUrls: ['./searchConductor.component.css']
})

export class SearchConductorComponent implements OnInit {

  divdebusqueda: boolean = true;
  busquedapornombre: boolean = true;
  busquedaporcurp: boolean = false;
  busquedaporlicencia: boolean = false;
  busquedaporfolio: boolean = false;
  busquedaporplaca: boolean = false;

  registroabuscar: any;
  data = [];
  divplantillas: boolean = false;
  loading: boolean = false;
  registroamostrar: any;
  @Output() Ostatus = new EventEmitter<any>();
  @Output() Result = new EventEmitter<any>();
  @Input() Hidden: any;

  opBusquedaConcesionario: boolean = true;
  opBusquedaVehiculo: boolean = false;
  opBusquedaSitio: boolean = false;
  divdebusquedaSitio: boolean = false;
  divsitio: boolean = false;
  sitios: any;
  tamaniodivsarriba: any;
  busquedaporneconomico: boolean = false;
  busquedaporserie: boolean = false;
  busquedapormotor: boolean = false;
  vehiculos : any;
  sitio: any;
  modalidadesSitio: any;
  modalidadesSitioVencidos:any;
  arrayBusquedas: any = [];
  constructor(
      private router?: Router,
      private apollo?: Apollo,

    ){}

  ngOnInit() {
   }

  asignarBusquedaXNombre(){
    this.busquedapornombre=true;
    this.busquedaporcurp=false;
    this.busquedaporlicencia=false;
    this.busquedaporplaca = false;
    this.busquedaporfolio = false;
  }
  asignarBusquedaXCURP(){
    this.busquedapornombre=false;
    this.busquedaporcurp=true;
    this.busquedaporlicencia=false;
    this.busquedaporplaca = false;
    this.busquedaporfolio = false;
  }
  asignarBusquedaXLicencia(){
    this.busquedapornombre=false;
    this.busquedaporcurp=false;
    this.busquedaporlicencia=true;
    this.busquedaporplaca = false;
    this.busquedaporfolio = false;
  }
  asignarBusquedaXPlaca(){
    this.busquedapornombre=false;
    this.busquedaporcurp=false;
    this.busquedaporlicencia=false;
    this.busquedaporplaca = true;
    this.busquedaporfolio = false;
  }
  asignarBusquedaXFolio(){
    this.busquedapornombre=false;
    this.busquedaporcurp=false;
    this.busquedaporlicencia=false;
    this.busquedaporplaca = false;
    this.busquedaporfolio = true;
  }

  resultado = [];
  longitudResultado: number;
  mychange(event){
      if( this.registroabuscar.length > 2){
        
       if(this.busquedapornombre==true){
         this.divdebusqueda = true;
         this.llamarregistros('INC-NAME');
       }
       if(this.busquedaporcurp==true){
         this.divdebusqueda = true;
         this.llamarregistros('INC-CURP');
       }
       if(this.busquedaporlicencia==true){
         this.divdebusqueda = true;
         this.llamarregistros('INC-LIC');
       }
       if(this.busquedaporplaca == true){
         this.divdebusqueda = true;
         this.llamar('PLACA-INC');
       }
       if(this.busquedaporfolio == true){
        this.divdebusqueda = true;
        this.llamar('FOLIO-INC');
      }
       //this.busquedapornombre=true;
     }
  }

  llamar(decision:any){
    this.resultado = [];
    let obj = {
      input: this.registroabuscar
    }
    this.loading = true;
     this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_ID_PLACA_FOLIO,
      variables: {
        params:  obj,
        mode: decision
      }, fetchPolicy: 'network-only'
    })
    .valueChanges.subscribe(result => {
      this.desglosarResultadosPlates(result.data);
    });
  }
  desglosarResultadosPlates(resultado){
    this.longitudResultado = resultado.persons.length;
    for(let index=0; index < resultado.persons.length; index++){
      this.apollo.use('backConductoressinToken').watchQuery({
        query: B.GET_PERSON,
        variables: {
          id: resultado.persons[index].person_id,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
       this.regresaratributosPersona(result.data,resultado.persons[index].plate, resultado.persons[index].foliated_sheet);       
      });
    }
  }

  regresaratributosPersona(persona, plate, foliated_sheet){
    let existe = false
    persona.person.plate = plate;
    persona.person.foliated_sheet = foliated_sheet; 
    for(let i = 0; i < this.resultado.length; i++){
      if(persona.person.id == this.resultado[i].id){
        existe = true;
      }
    }
    if(existe == false){
      this.resultado.push(persona.person);
    }
    if(this.resultado.length == this.longitudResultado){
      this.crearregistros(this.resultado, 'PF')
    }
  }

  llamarregistros(decision: any){
    let obj = {
      input: this.registroabuscar,
      limit: 10
    }
    this.loading = true;

     this.apollo.use('backConductores').watchQuery({
      query: B.GET_PERSONS,
      variables: {
        params:  obj,
        mode: decision
      }, fetchPolicy: 'network-only'
    })
    .valueChanges.subscribe(result => {
      this.crearregistros(result.data,'NLC');
    });
  }

  crearregistros(registrosencontrados: any, decision){
    if(decision == 'PF'){
      this.data = registrosencontrados
    }else{
      this.data = registrosencontrados.persons;
    }
     this.loading = false;
  }


  seleccionarregistro(registro: any){
    this.registroamostrar = registro;
    this.Ostatus.emit(this.registroamostrar);
    this.divdebusqueda = false;
    this.divplantillas = true;
  }

  buscarOtroConcesionario(){
    this.Result.emit(false);
    this.registroamostrar = undefined;
    this.divdebusqueda = false;
    this.busquedapornombre = true;
    this.busquedaporcurp=false;
    this.busquedaporlicencia=false;
    this.busquedaporplaca = false;
    this.busquedaporfolio = false;
    this.divplantillas = false;
    this.Hidden = false;
    this.opBusquedaSitio = false;
    this.divdebusqueda = true;
    this.registroabuscar = undefined;
    this.limpiarbusqueda();
   }


   limpiarbusqueda(){
     this.divdebusqueda = true;
     this.registroamostrar = undefined;
     this.divplantillas = false;
     this.data = [];
     this.resultado = [];
   }

}
