import gql from 'graphql-tag';


const TOTALES_RANGO_FECHA = gql`
query totalByRangoFecha($fecha_inicio: Date!,$fecha_fin: Date!) {
  totalByRangoFecha(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;


const TOTALES_RANGO_FECHA_X_STATUS = gql`
query totalesOfEstatusByDate($fecha_inicio: Date!,$fecha_fin: Date!) {
  totalesOfEstatusByDate(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;


const TOTALES_RANGO_FECHA_X_MODULO = gql`
query totalesOfModuloByDate($fecha_inicio: Date!,$fecha_fin: Date!) {
  totalesOfModuloByDate(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;


const TOTALES_RANGO_FECHA_X_MODALIDAD = gql`
query totalesOfModalidadByDate($fecha_inicio: Date!,$fecha_fin: Date!) {
  totalesOfModalidadByDate(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;


export { TOTALES_RANGO_FECHA,TOTALES_RANGO_FECHA_X_STATUS,TOTALES_RANGO_FECHA_X_MODULO,TOTALES_RANGO_FECHA_X_MODALIDAD  };
