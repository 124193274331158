import gql from 'graphql-tag';


const CREATE_RESPONSABLE = gql`
mutation responsable($nombre: String!, $primer_apellido: String!,$segundo_apellido: String!,$telefono: String,$correo_electronico: String!,$curp: String!) {
  responsable(nombre: $nombre, primer_apellido: $primer_apellido, segundo_apellido: $segundo_apellido, telefono: $telefono, correo_electronico: $correo_electronico, curp: $curp){
    id
  }
}
`;


const ASIGNAR_RESPONSABLE_SITIO = gql`
mutation union_sitio_responsable($id_sitio: String!, $id_responsable: ID!) {
  union_sitio_responsable(id_sitio: $id_sitio, id_responsable: $id_responsable){
    id
  }
}
`;


const REPORTE_SITIO_RESPONSABLE = gql`
mutation reporte($reportes: [reporte_input!]!) {
  reporte(reportes: $reportes){
    id
  }
}
`;

export { CREATE_RESPONSABLE,ASIGNAR_RESPONSABLE_SITIO,REPORTE_SITIO_RESPONSABLE };
