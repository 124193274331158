import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


//import { LoginComponent } from './login/login.component';
//import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { CollectComponent } from './auth/collect/collect.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { InformacionComponent } from './informacion/informacion.component';

import { RecuperarComponent } from './recuperar/recuperar.component';
 import { SearchComponent } from './search/search.component';
 import { SearchRetoComponent } from './searchreto/searchreto.component';


import { SearchConductorComponent } from './searchConductor/searchConductor.component';
import { SearchSitioComponent } from './searchSitio/searchSitio.component';

 //import {CoreModule} from "./core/core.module";
 import { RegisterComponent } from './register/register.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CoreModule} from "./core/core.module";
import {CalendarModule} from 'primeng/calendar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as $ from 'jquery';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { OnlyAlphaSpecialDirective  } from './only-alphaspecial.directive';
import { OnlynumberDirective  } from './only-numeric.directive';

import { OnlyPlacasDirective  } from './only-placas.directive';


import * as crypto from 'crypto-js';

import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {PaginatorModule} from 'primeng/paginator';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {SliderModule} from 'primeng/slider';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';


import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConductoresComponent } from './conductores/conductores.component';
import { RegistroRetoComponent } from './bandejareto/registroreto/registroreto.component';
import { DetallesRegistroComponent } from './bandejareto/detallesregistro/detallesregistro.component';

import { BandejaRetoComponent } from './bandejareto/bandejareto.component';

import { ComentariosComponent } from './comentarios/comentarios.component';
import { SitiosComponent } from './sitios/sitios.component';
import { ImpresionComponent } from './impresion/impresion.component';

import { TotalesComponent } from './totales/totales.component';
import { DiplomaComponent } from './diploma/diploma.component';

import {CardModule} from 'primeng/card';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FieldsetModule} from 'primeng/fieldset';


import { environment } from '../environments/environment';
import { UpdateWorkerService } from './core/services/update-worker.service';


//Modulos dinamicos para menu de concesion
import { DatosGenerales } from './components/datosGenerales/datosGenerales.component';
import { EstatusConcesion } from './components/estatusConcesion/estatusConcesion.component';
import { DatosUbicacion } from './components/datosUbicacion/datosUbicacion.component';
import { DatosTramitesComponent } from './components/datosTramites/datosTramites.component';
import { ConductoresTarjetaComponent } from './components/conductorestarjeta/conductorestarjeta.component';
import { VehiculosComponent } from './components/vehiculos/vehiculos.component';
import { PermisoDC } from './components/permisoDC/permisoDC.component';
import { Beneficiarios } from './components/beneficiarios/beneficiarios.component';
import { ContactosComponent } from './components/contactos/contactos.component';
import { Inconsistencias } from './components/inconsistencias/inconsistencias.component';
import { PaymentsComponent } from './payments/payments.component';

/*
import { DatosGenerales } from './components/datosGenerales/datosGenerales.component';
import { DatosTramitesComponent } from './components/datosTramites/datosTramites.component';
import { EstatusConcesion } from './components/estatusConcesion/estatusConcesion.component';
import { DatosUbicacion } from './components/datosUbicacion/datosUbicacion.component';
import { SearchComponent } from './search/search.component';
import { SearchLocalidadComponent } from './searchLocalidad/searchLocalidad.component';
import { TarjetaSeleccionComponent } from './components/tarjetaSeleccion/tarjetaSeleccion.component';
import { PermisoDC } from './components/permisoDC/permisoDC.component';
import { PermisoDLE } from './components/permisoDLE/permisoDLE.component';
import { Tarifa } from './components/tarifa/tarifa.component';
import { ExpedientesDC } from './components/expedientesDC/expedientesDC.component';
import { RevistaT } from './components/revistaT/revistaT.component';
import { VehiculosComponent } from './components/vehiculos/vehiculos.component';
import { ContactosComponent } from './components/contactos/contactos.component';
import { Siamov } from './components/siamov/siamov.component';
import { Beneficiarios } from './components/beneficiarios/beneficiarios.component';
*/


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    InformacionComponent,
    DiplomaComponent,
    OnlyAlphaSpecialDirective,
    OnlyPlacasDirective,
    OnlynumberDirective,
    RecuperarComponent,
    ResetPasswordComponent,
    ConductoresComponent,
    RegistroRetoComponent,
    DetallesRegistroComponent,
    SearchComponent,
    SearchRetoComponent,
    SearchConductorComponent,
    SearchSitioComponent,
    ComentariosComponent,
    TotalesComponent,
    CollectComponent,
    SitiosComponent,
    ImpresionComponent,
    DatosGenerales,
    EstatusConcesion,
    DatosUbicacion,
    DatosTramitesComponent,
    ConductoresTarjetaComponent,
    VehiculosComponent,
    PermisoDC,
    Beneficiarios,
    Inconsistencias,
    ContactosComponent,
    BandejaRetoComponent,
    PaymentsComponent,

  ],
  entryComponents: [
    DatosGenerales,
    EstatusConcesion,
    DatosUbicacion,
    DatosTramitesComponent,
    ConductoresTarjetaComponent,
    VehiculosComponent,
    PermisoDC,
    Beneficiarios,
    ContactosComponent,
    Inconsistencias

    /*
    DatosUbicacion,
    EstatusConcesion,
    DatosTramitesComponent,
    PermisoDC,
    Tarifa,
    ExpedientesDC,
    RevistaT,
    TramitesModalComponent,
    TarjetaSeleccionComponent,
    VehiculosComponent,
    ConductoresComponent,
    ContactosComponent,
    Siamov,
    Beneficiarios
    */
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    CalendarModule,
    SliderModule,
    PaginatorModule,
    GraphQLModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DropdownModule,
    PdfViewerModule,
    InputSwitchModule,
    CheckboxModule,
    CardModule,
    AutoCompleteModule,
    ToggleButtonModule,
    FieldsetModule,
    SelectButtonModule,
    RadioButtonModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
 
  ],
  providers: [
    SearchComponent,SearchSitioComponent,SearchRetoComponent,UpdateWorkerService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
