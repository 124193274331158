import { Component, OnInit} from "@angular/core";

import { Router } from '@angular/router';

import { Apollo } from 'apollo-angular';

import * as A from '../graphql/queries';

declare var M: any;
import { FormBuilder, FormGroup } from '@angular/forms';
import { SessionStorageService } from '../core/services/sessionstorage.service';
import { ServiciosService } from '../core/services/servicios.service';

@Component({
  selector: 'app-bandejareto',
  templateUrl: './bandejareto.component.html',
  styleUrls: ['./bandejareto.component.css']
})
export class BandejaRetoComponent implements OnInit {
  registros: any[];
  mostrarbandeja: boolean = true;
  user: any;
  concesionarioSeleccionado: any;
  concesiones: any[];
  statuses: any[];
  tipotramite: any;
  administrador: boolean = false;
  mostrarmodulos: boolean = false;
  validacionavanzada: boolean = false;
  supervisor: boolean = false;
  modulo: boolean = false;
  loading: boolean = false;
  city: string;
  modalidad: any;
  sugerenciasModalidades: any[];
  modalidadobj: any;
  modalidades: any;
  modulos: any[];
  moduloSeleccionado: any;
  date2: Date;
  date1: Date;
  es: any;
  form: FormGroup;

  constructor(
    private apollo?: Apollo,
    private fb?: FormBuilder,
    private sessionStorageService?: SessionStorageService,
    private serviciosService?: ServiciosService,
    private router?: Router,
    private formBuilder?: FormBuilder,


  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      fecha_inicio: [null, []],
      fecha_fin: [{ value: null, disabled: true }, []],
      tipotramite: [-1, []],
      modulo: [-1, []],
      city: [null, []],
      concesionario: [-1, []],
      modalidad: [-1, []],
      folio: [null, []],

    });

    this.form.valueChanges.subscribe((values) => {
      //this.busquedaavanzada();
      this.checkvalidacion();
    });
    $(document).ready(function() {
      $('.modal').modal({ dismissible: false });
    });

    this.user = JSON.parse(this.sessionStorageService.get('usuario'));
    this.modulos = [];
    this.traerRegistros();
    this.traerModulos();

    if (this.user.rol.nombre.toLowerCase() == 'modulo') {
      this.modulo = true;
    }
    if (this.user.rol.nombre.toLowerCase() == 'supervisor') {
      this.supervisor = true;
    }
    if (this.user.rol.nombre.toLowerCase() == 'administrador' || this.user.rol.nombre.toLowerCase() == 'superusuario') {
      this.administrador = true;
    }

    this.traerEstados();

    this.es = {
      firstDayOfWeek: 1,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar'
    }
  }

  mostrarregistroreto() {
    this.mostrarbandeja = false;
  }

  cambioFechaInicial() {
    this.form.get('fecha_fin').enable();
  }


  checkvalidacion() {

    this.validacionavanzada = false;
    if (this.form.get('fecha_inicio').value != undefined) {
      if (this.form.get('fecha_fin').value == undefined) {
        this.validacionavanzada = false;
      } else {
        this.validacionavanzada = true;
      }
    }

  }

  cambioFechaLimite() {
    if (this.form.get('fecha_inicio').value.getTime() <= this.form.get('fecha_fin').value.getTime()) {
    } else {
      this.form.get('fecha_fin').setValue(null);
    }
  }

  traerModulos() {

    this.serviciosService.getModulos().subscribe({
      next: (data) => {
        for (var i = 0; i < data.length; i++) {
          //this.modulos.push(data[i]);
          this.modulos.push({ id: data[i].id, value: data[i].id, label: data[i].nombre, name: data[i].nombre });

        }
        this.mostrarmodulos = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });

  }
  check(event) {
    this.mostrarbandeja = true;

  }


  buscarxModulo(event) {
    console.log(event);
  }

  traerRegistros() {

    this.loading = true;

    this.apollo.use('registroReto')
      .watchQuery({
        query: A.GET_REGISTROS_RETO
      })
      .valueChanges.subscribe(result => {
        this.asignarRegistros(result);

      }, (error) => {
      });

  }


  traerEstados() {
    this.apollo.use('registroReto')
      .watchQuery({
        query: A.GET_CATALOGO_STATUS
      })
      .valueChanges.subscribe(result => {
        this.asignarEstados(result);

      }, (error) => {
      });
  }

  asignarEstados(data) {
    this.statuses = [];

    this.statuses.push({ id: -1, label: 'GENERAL', name: 'GENERAL', value: null });

    for (var i = 0; i < data.data.catalogos_estatus.length; i++) {
      this.statuses.push({ id: data.data.catalogos_estatus[i].id, value: data.data.catalogos_estatus[i].id, label: data.data.catalogos_estatus[i].nombre, name: data.data.catalogos_estatus[i].nombre });
    }
  }


  asignarRegistros(data) {

    this.registros = [];
    this.registros = data.data.registros_reto;
    for (var i = 0; i < this.registros.length; i++) {
      let indice = i;
      this.traerConcesion(indice);
      this.traerConcesionario(indice);

      this.traerPersona(indice);
      this.traerModalidad(indice);
      this.traerModulo(indice);
      this.traerCatalogoEstatus(indice);

    }

    setTimeout(() => {
      this.loading = false;

    }, 100);
  }


  traerCatalogoEstatus(indice) {
    this.apollo.use('registroReto')
      .watchQuery({
        query: A.GET_CATALOGO_FORMATO,
        variables: {
          id: this.registros[indice].id_catalogo_estatus,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.agregarCatalogoEstatus(result.data, indice)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  agregarCatalogoEstatus(data, indice) {
    this.registros[indice]['estatus'] = data.catalogo_estatus;

  }

  traerConcesion(indice) {

    if (this.registros[indice]! != undefined) {
      this.apollo.use('backConsulta')
        .watchQuery({
          query: A.GET_CONCESION,
          variables: {
            id: this.registros[indice].id_concesion,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.agregarConcesion(result.data, indice)
        }, (error) => {
          M.toast({ html: error })
        });
    }

  }


  traerConcesionario(indice) {
    this.apollo.use('backConsulta')
      .watchQuery({
        query: A.GET_CONCESIONARIO,
        variables: {
          id: this.registros[indice].id_concesionario,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.agregarConcesionario(result.data, indice)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  agregarConcesionario(data, indice) {
    this.registros[indice]['concesionario'] = data.concessionaire;
  }


  agregarConcesion(data, indice) {
    this.registros[indice]['concesion'] = data.concession;
  }

  traerPersona(indice) {
    if(this.registros[indice].id_persona!=undefined){
      this.apollo.use('backConductoressinToken').watchQuery({
        query: A.GET_PERSON,
        variables: {
          id: this.registros[indice].id_persona,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarDatosConductor(result, indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });
    }

  }


  asignarDatosConductor(data, indice) {
    this.registros[indice]['persona'] = data.data.person;

  }

  traerModalidad(indice) {
    this.apollo.use('backConsulta')
      .watchQuery({
        query: A.GET_MODALIDAD,
        variables: {
          id: this.registros[indice].id_modalidad,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarModalidad(result.data, indice)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  asignarModalidad(data, indice) {
    this.registros[indice]['modalidad'] = data.modality;

  }

  traerModulo(indice) {
    this.serviciosService.getModulo(this.registros[indice].id_modulo).subscribe({
      next: (data) => {
        this.registros[indice]['modulo'] = data;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }


  filterCountry(event) {
    if (typeof this.form.get('concesionario').value == 'object') {
      this.form.get('concesionario').setValue(null);
    }

    // this.form.get('concesionario').setValue(null);

    let indice = 1;

    if (this.form.get('city').value == 'Nuc') {
      indice = 2;
    }

    this.apollo.use('backConsulta').watchQuery({
      query: A.LISTAR_CONCESIONARIOS,
      variables: {
        input: event.query,
        option: indice,
        limit: 10
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.crearregistros(result.data, event)
      });

  }

  crearregistros(data, event) {

    let filtered: any[] = [];
    let query = event.query;

    let arr = data.concessionsLike;
    for (let i = 0; i < data.concessionsLike.length; i++) {
      let country = data.concessionsLike[i];
      let nombre = data.concessionsLike[i].concessionaire.name + ' ' + data.concessionsLike[i].concessionaire.first_surname;
      if (data.concessionsLike[i].concessionaire.second_surname != undefined && data.concessionsLike[i].concessionaire.second_surname != null) {
        nombre = nombre + ' ' + data.concessionsLike[i].concessionaire.second_surname;
      }

      if (this.form.get('city').value == 'Nombre') {
        let obj = {
          name: nombre + ' - ' + data.concessionsLike[i].consecutive_number + '/' + data.concessionsLike[i].unique_number_key, id: data.concessionsLike[i].id
        }
        if (nombre.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(obj);
        }
      }
      if (this.form.get('city').value == 'Nuc') {
        let obj = {
          name: nombre + ' - ' + data.concessionsLike[i].consecutive_number + '/' + data.concessionsLike[i].unique_number_key, id: data.concessionsLike[i].id
        }
        filtered.push(obj);
      }




    }


    this.concesiones = filtered;

  }
  buscarXConcesion(concesionario) {
    //let obj = {concession_id: this.concesionarioSeleccionado.id};
    let mode = 'con';
    //  this.conseguirExpedienteObj(obj,mode);
    /*
    this.loading = true;
      this.apollo.use('registroReto')
        .watchQuery({
          query: A.GET_REGISTROS_CONCESIONARIO,
          variables: {
            id_concesion: this.concesionarioSeleccionado.id,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.asignarRegistrosxConcesion(result.data)
        }, (error) => {
          M.toast({ html: error })
        });

        */

  }

  buscarxTipoTramiteGeneral(event) {
    if (event.value == null) {
      this.traerRegistros();

    } else {
      this.loading = true;
      this.apollo.use('registroReto')
        .watchQuery({
          query: A.GET_REGISTROS_RETO_X_FASE,
          variables: {
            id_catalogo_estatus: parseInt(event.value),
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.asignarRegistrosxFase(result.data)
        }, (error) => {
          M.toast({ html: error })
        });
      // this.conseguirExpedienteObj(obj,mode);
    }

  }

  asignarRegistrosxFase(data) {
    this.registros = [];
    this.registros = data.registro_retoByFase;
    for (var i = 0; i < this.registros.length; i++) {
      let indice = i;
      this.traerConcesion(indice);
      this.traerConcesionario(indice);

      this.traerPersona(indice);
      this.traerModalidad(indice);
      this.traerModulo(indice);
      this.traerCatalogoEstatus(indice);

    }


    setTimeout(() => {
      this.loading = false;

    }, 100);

  }

  asignarRegistrosxConcesion(data) {
    this.registros = [];
    this.registros = [data.registro_retoByConcesion];

    for (var i = 0; i < this.registros.length; i++) {
      let indice = i;
      this.traerConcesion(indice);
      this.traerConcesionario(indice);

      this.traerPersona(indice);
      this.traerModalidad(indice);
      this.traerModulo(indice);
      this.traerCatalogoEstatus(indice);

    }

    setTimeout(() => {
      this.loading = false;

    }, 100);



    /*
       this.registros.sort(function(a, b) {
        return a.id - b.id;
      });
      */
  }

  limpiarConcesion() {
    this.concesionarioSeleccionado = null;

    let obj = {};
    let mode = 'all';
    //this.conseguirExpedienteObj(obj,mode);
    this.traerRegistros();
  }

  iradetalle(registro) {
    this.router.navigateByUrl('/inbox/detalle' + registro.id);
  }

  funcioncrearfecha(stringdate: any) {
    let fecha = new Date(stringdate);
    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";
    if ((fecha.getMonth() + 1) < 10) {
      mes21 = "0" + (fecha.getMonth() + 1).toString();
    } else {
      mes21 = (fecha.getMonth() + 1).toString();
    }
    if ((fecha.getDate() + 1) <= 10) {
      dia21 = "0" + (fecha.getDate()).toString();
    } else {
      dia21 = (fecha.getDate()).toString();
    }
    return anio21 + "-" + mes21 + "-" + dia21 + "";
  }

  filterModalidad(event) {
    //  if(this.form.get('modalidad').value > 0){
    if (typeof this.form.get('modalidad').value == 'object') {
      this.form.get('modalidad').setValue(null);
    }

    this.apollo.use('backConsulta').watchQuery({
      query: A.LISTAR_MODALIDADES,
      variables: {
        input: this.form.get('modalidad').value,
        limit: 10,
      }
    })
      .valueChanges.subscribe(result => {
        this.crearregistrosXModalidad(result.data, event)
      });
    //  }
  }

  modalidadSeleccionada(event) {
    this.modalidadobj = event;
  }

  crearregistrosXModalidad(data, event) {
    this.modalidades = data.modalitiesLike;

    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.modalidades.length; i++) {
      filtered.push(
        {
          name: this.modalidades[i].name + ' (' + this.modalidades[i].abbreviation + ')',
          id: this.modalidades[i].id
        }

      );

    }
    this.sugerenciasModalidades = filtered;
    this.loading = false;
  }


  busquedaavanzada() {
    let objenvio = {};
    let diainicial;
    let diaFinal;
    let tipotramite_id;
    let modulo_id;
    let concesionario_id;
    let modalidad_id;
    let folio;

    if (this.form.get('tipotramite').value == null) {
      tipotramite_id = -1;
    } else {
      tipotramite_id = this.form.get('tipotramite').value;
    }
    if (this.form.get('modulo').value == null) {
      modulo_id = -1;
    } else {
      modulo_id = this.form.get('modulo').value;
    }
    if (typeof this.form.get('concesionario').value == 'object') {
      concesionario_id = this.form.get('concesionario').value.id;

    } else {
      concesionario_id = -1;
    }

    if (typeof this.form.get('modalidad').value == 'object') {
      if(this.form.get('modalidad').value!=undefined){
        modalidad_id = this.form.get('modalidad').value.id;

      }

    } else {
      modalidad_id = -1;
    }

    if (this.form.get('folio').value == null || this.form.get('folio').value == '') {
      folio = -1;
    } else {
      folio = this.form.get('folio').value;
    }
    objenvio['id_modulo'] = modulo_id;
    objenvio['id_catalogo_estatus'] = tipotramite_id;
    objenvio['id_modalidad'] = String(modalidad_id);
    objenvio['id_concesionario'] = String(concesionario_id);
    objenvio['id'] = folio;
    objenvio['fecha_inicio'] = this.form.get('fecha_inicio').value;
    objenvio['fecha_fin'] = this.form.get('fecha_fin').value;


    this.apollo.use('registroReto')
      .watchQuery({
        query: A.BUSQUEDA_PERSONALIZADA,
        variables: {
          filtros: objenvio,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarRegistrosBusquedaAvanzada(result.data)
      }, (error) => {
        M.toast({ html: error })
      });



  }

  asignarRegistrosBusquedaAvanzada(data) {
    this.registros = [];
    this.registros = data.busquedaPersonalizada;
    for (var i = 0; i < this.registros.length; i++) {
      let indice = i;
      this.traerConcesion(indice);
      this.traerConcesionario(indice);

      this.traerPersona(indice);
      this.traerModalidad(indice);
      this.traerModulo(indice);
      this.traerCatalogoEstatus(indice);

    }

    setTimeout(() => {
      this.loading = false;

    }, 100);


  }

  noSeleccionado() {
    if (typeof this.form.get('concesionario').value !== 'object') {
      this.form.get('concesionario').setValue(null);
    }
  }

  noSeleccionadoModalidad() {
    if (typeof this.form.get('modalidad').value !== 'object') {
      this.form.get('modalidad').setValue(null);
    }
  }


}
