import gql from 'graphql-tag';

const BUSCAR_TRAMITES = gql`
query getTransactionsByConcession($concession_id: ID!) {
	  transactionsByConcession(concession_id: $concession_id) {
	    concession {
	      id
	      concessionaire {
	        id
	        person_type
	        name
	        first_surname
	        second_surname
	        curp
	        rfc
	        business_name
	        address
	        contact {
	          email
	          cel_number
	        }
	        locality {
	          id
	          name
	          municipality {
	            id
	            name
	            district {
	              id
	              name
	              region {
	                id
	                name
	              }
	            }
	          }
	        }
	      }
	      agreement
	      agreement_date
	      expiration_date
	      capture_date
	      start_date
	      term
	      main_notes
	      delivery_certificate_notes
	      route
	      authorized_vehicles
	      status
	      modality {
	        id
	        name
	        description
	        status
	        abbreviation
	      }
	      site {
	        id
	        name
	        legal_representative
	        address
	        authorized_colors
	        status
	        locality {
	          id
	          name
	          municipality {
	            id
	            name
	            district {
	              id
	              name
	              region {
	                id
	                name
	              }
	            }
	          }
	        }
	      }
	      consecutive_number
	      unique_number_key
	      attached {
	        active
	        new_vehicle_auth
	        new_concession_auth
	        locked {
	          status
	          stolen_folio
	        }
	        reto {
	          status
	        }
	      }
	    }
	    abbreviation
	    movement
	    reference
	    description
	    capture_date
	    status
	    vehicles {
	      id
	      model
	      engine_number
	      serial_number
	      doors_number
	      capacity
	      unit_capacity {
	        id
	        name
	      }
	      economic_number
	      status
	      brand {
	        id
	        name
	      }
	      vehicle_type {
	        id
	        name
	      }
	      insurance_number
	      insurance_expiration
	      plates {
	        id
	        folio
	      }
	    }
	    print {
	      print_date
	      status
	    }
	    attached
	  }
	}
,
`;

export { BUSCAR_TRAMITES };
