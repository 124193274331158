export type Maybe<T> = T | null;

// ====================================================
// Documents
// Registro de una persona con sus datos correspondientes
// ====================================================


////////////////////////////////////////////


export namespace CreateUser {
  export type Variables = {
    n: String;
    pA: String;
    sA: String;
    c: String;
    p: String;
    r: number;
    cT: number;
  };

  export type Mutation = {
    __typename?: "Mutation";

    createUser: Maybe<CreateUser>;
  };

  export type CreateUser = {
    __typename?: "user";

    n: Maybe<String>;
    pA: Maybe<String>;
    sA: Maybe<String>;
    c: Maybe<String>;
    p: Maybe<String>;
    r: Maybe<number>;
    cT: Maybe<number>;

  };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";

import gql from "graphql-tag";
// ====================================================
// Apollo Services
// ====================================================



@Injectable({
  providedIn: "root"
})
export class CreateUserGQL extends Apollo.Mutation<
  CreateUser.Mutation,
  CreateUser.Variables
> {
  document: any = gql`
  mutation nuevoUsuario($n:String,$pA:String,$sA:String,$c:String,$p:String,$r:ID,$cT:ID){
  crearUsuario(nombre:$n,primer_apellido:$pA,segundo_apellido:$sA,correo:$c,password:$p,id_rol:$r,id_centro_trabajo:$cT){
    id,
    nombre,
    primer_apellido,
    segundo_apellido,
    correo,
    password,
    rol{
      id,
      nombre,
      estatus,
      createdAt
    },
    centro_trabajo{
      id,
      nombre,
      region,
      estatus,
      createdAt
    },
    estatus,
    createdAt
  }
}

  `;
}


// ====================================================
// END: Apollo Angular template
// ====================================================
