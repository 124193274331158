
export class Vialidad {
    nombre: String;
    tipo: String;

    num_interior: String;
    num_exterior: String;

    constructor(
      nombre?: String,
      tipo?: String,
      num_interior?: String,
      num_exterior?: String
    ) {}
}
