import {Component, OnInit} from '@angular/core';
 import {Router} from '@angular/router';
  import {StorageService} from "../core/services/storage.service";
  import {Session} from "../core/models/session.model";
   import {Apollo} from 'apollo-angular';
 import gql from 'graphql-tag';
 import {User} from "../core/models/user.model";
 import {Role} from "../core/models/role.model";
 import {Usuario} from '../models/usuario';
 import { CreateUserGQL } from '../graphqlconsultas/graphqlcreateuser';
 declare var M: any;

 const submitRepository = gql`
 mutation nuevoUsuario($n:String,$pA:String,$sA:String,$c:String,$p:String,$r:ID,$cT:ID){
 crearUsuario(nombre:$n,primer_apellido:$pA,segundo_apellido:$sA,correo:$c,password:$p,id_rol:$r,id_centro_trabajo:$cT){
   id,
   nombre,
   primer_apellido,
   segundo_apellido,
   correo,
   password,
   rol{
     id,
     nombre,
     estatus,
     createdAt
   },
   centro_trabajo{
     id,
     nombre,
     region{
       id,
       nombre
     },
     estatus,
     createdAt
   },
   estatus,
   createdAt
 }
}
 `;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public submitted: Boolean = false;
  public error: {code: number, message: string} = null;
  public name: string;
  public apellido: string;
  public user: User;
  loged: boolean = false;
  public usuario: Usuario;
  public password: string;
  arrayroles = new Array();
  arraycentros_trabajos = new Array();
  arrayregiones = new Array();
  arraycentrosdetrabajoenregion = new Array();

  formvalited: boolean = false;
  rolename: string = "";
  regiontomada: string = "";

  constructor(private apollo?: Apollo,  private createUserGQL?: CreateUserGQL)
              {}

  ngOnInit() {

    this.traerroles();
    this.traerregiones();
    this.traercentros_trabajo();

    //creacion del objeto usuario para que no vaya con null

    this.usuario = new Usuario();
    var datos = new Object();
    this.usuario.nombre = "";
    this.usuario.primer_apellido = "";
    this.usuario.segundo_apellido = "";
    this.usuario.correo = "";
    this.usuario.password = "";
    this.usuario.id_rol = 0;
    this.usuario.id_centro_trabajo = 1;
    this.usuario.id_region = 0;
  }

/*
Funcion que se encargara de traer los roles que existen en el sistema
*/

  traerroles(){

      this.apollo.use('endpoint2')
      .watchQuery({
        query: gql`
        query listRoles{
          roles{
            id,
            nombre
          }
        },
        ` ,})
      .valueChanges.subscribe(result => {
          this.crearrolesparaselect(result.data)
      });

  }

  /*
  Coloca los roles traidos de la anterior funcion y los coloca en el input autocomplete
  */

  crearrolesparaselect(rolesaux: any){

    this.arrayroles = rolesaux.roles;


    var datos = new Object();

    datos = {};


    for(var i = 0; i <this.arrayroles.length;i++){
          datos[this.arrayroles[i].nombre] = null;
    }

     $(document).ready(function(){
     $('#rol').autocomplete(
     { data: datos }
     );
     });

  }

  /*
  Funcion que coloca el id del rol elegido en el objeto usuario
  */

  conseguirIdRol(){
    var inputmarca = (<HTMLInputElement>document.getElementById("rol")).value;


    for(var i = 0; i <this.arrayroles.length;i++){
      if(this.arrayroles[i].nombre == inputmarca){
        this.usuario.id_rol = this.arrayroles[i].id;
      }
    }

  }

  /*
  Funcion que se encargara de traer los centros de trabajo dependiendo de la region que existen en el sistema
  */

  traercentros_trabajo(){
      this.apollo.use('endpoint2')
      .watchQuery({
        query: gql`
        query listCentrosTrabajo{
  centrosTrabajo{
    id,
    nombre,
    region{
      id,
      nombre,
      estatus,
      createdAt
    },
    estatus,
    createdAt
             }
        },

        ` })
      .valueChanges.subscribe(result => {
          this.crearcentros_trabajoparaselect(result.data)

      });

  }

  crearcentros_trabajoparaselect(c_trabajoaux: any){
    this.arraycentros_trabajos = c_trabajoaux.centrosTrabajo;

  }

  conseguirIdCentroTrabajo(){
    var inputc_trabajo = (<HTMLInputElement>document.getElementById("centro_trabajo")).value;
    for(var i = 0; i <this.arraycentros_trabajos.length;i++){
      if(this.arraycentros_trabajos[i].nombre == inputc_trabajo){
        this.usuario.id_centro_trabajo = this.arraycentros_trabajos[i].id;
      }
    }
  }
  traerregiones(){
      this.apollo.use('endpoint2')
      .watchQuery({
        query: gql`
        query listRegiones{
          regiones{
            id,
            nombre,
            estatus,
            createdAt
          }
        },
        ` ,})
      .valueChanges.subscribe(result => {
         this.crearregionesparaselect(result.data)

      });


  }

  crearregionesparaselect(regionesaux: any){

    this.arrayregiones = regionesaux.regiones;


        var datos = new Object();

        datos = {};


        for(var i = 0; i <this.arrayregiones.length;i++){
              datos[this.arrayregiones[i].nombre] = null;
        }

         $(document).ready(function(){
         $('#region').autocomplete(
         { data: datos }
         );
         });


  }

  conseguirIdRegion(){
    var inputregion = (<HTMLInputElement>document.getElementById("region")).value;


    for(var i = 0; i <this.arrayregiones.length;i++){
      if(this.arrayregiones[i].nombre == inputregion){
        this.usuario.id_region = this.arrayregiones[i].id;
      }
    }

  }

  submitLogin() {

    this.conseguirIdRol();
    this.conseguirIdRegion();
    this.conseguirIdCentroTrabajo();

    this.usuario.nombre = this.usuario.nombre.toUpperCase();
    this.usuario.primer_apellido = this.usuario.primer_apellido.toUpperCase();
    this.usuario.segundo_apellido = this.usuario.segundo_apellido.toUpperCase();
    var CryptoJS = require("crypto-js");

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(this.password, 'FXqJI1pAxNEUXbP').toString();
    this.usuario.password =  ciphertext;
    // Decrypt
    var bytes  = CryptoJS.AES.decrypt(this.usuario.password, 'FXqJI1pAxNEUXbP');
    var originalText = bytes.toString(CryptoJS.enc.Utf8);

      this.apollo.use('endpoint2').mutate({
      mutation: submitRepository,
      variables: {
        n: this.usuario.nombre,
        pA: this.usuario.primer_apellido,
        sA: this.usuario.segundo_apellido,
        c: this.usuario.correo,
        p: ciphertext,
        r: this.usuario.id_rol,
        cT: this.usuario.id_centro_trabajo
      }
      }).subscribe(({ data }) => {
        M.toast({html: 'Se ha creado un nuevo Usuario'})
      },(error) => {
       M.toast({html: error})

       });



  }

  mandarpedircentro_trabajo(){
         this.traercentros_trabajo();
  }

  buscarcentrosdetrabajo(){

    this.arraycentrosdetrabajoenregion = new Array();

    (<HTMLInputElement>document.getElementById("centro_trabajo")).value = "";

    var inputc_trabajo = (<HTMLInputElement>document.getElementById("region")).value;


    for(var i = 0; i <this.arraycentros_trabajos.length;i++){
      if(this.arraycentros_trabajos[i].region.nombre == inputc_trabajo){

        this.arraycentrosdetrabajoenregion.push(this.arraycentros_trabajos[i]);
      }
    }

    var datos = new Object();

    datos = {};


    for(var i = 0; i <this.arraycentrosdetrabajoenregion.length;i++){
          datos[this.arraycentrosdetrabajoenregion[i].nombre] = null;
    }

     $(document).ready(function(){
     $('#centro_trabajo').autocomplete(
     { data: datos }
     );
     });


  }





}
