  import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import { Observable,Observer } from 'rxjs';
  import gql from 'graphql-tag';
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  /*
  import * as XLSX from 'xlsx';
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';
  */
  type AOA = any[][];
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  declare var M: any;
  export interface myinterface {
    remove(index: number,nameComponet: any);
  }
 @Component({
  selector: 'app-datosUbicacion', templateUrl: './datosUbicacion.component.html', styleUrls: ['./datosUbicacion.component.css']
})
export class DatosUbicacion implements OnInit {
  public index: number;
  public selfRef: DatosUbicacion;
  public compInteraction: myinterface;
  @Input() IModel: any;
  @Input() NameComponet: any;
  @Input() ContactoConcesionario: any;

  constructor(){}

  ngOnInit() {
  }

  verImodel(){
  }

  removeMe(index,nameComponet) {
    this.compInteraction.remove(index,nameComponet)
  }

}
