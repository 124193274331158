import { ComponentRef, ComponentFactoryResolver, ChangeDetectorRef, ViewContainerRef, ViewChild, Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, Observer } from 'rxjs';
declare var M: any;
import * as B from '../graphql/queries';
import * as N from '../graphql/mutations';

@Component({
  selector: 'app-searchreto', templateUrl: './searchreto.component.html', styleUrls: ['./searchreto.component.css']
})

export class SearchRetoComponent implements OnInit {

  divdebusqueda: boolean = true;
  busquedapornombre: boolean = true;
  busquedapornuc: boolean = false;
  busquedaporsitio: boolean = false;
  registroabuscar: any;
  data: [];
  divplantillas: boolean = false;
  loading: boolean = false;

  registroamostrar: any;
  @Output() Ostatus = new EventEmitter<any>();
  @Output() Result = new EventEmitter<any>();
  @Input() Hidden: any;
  @Input() Change: any;

  opBusquedaConcesionario: boolean = true;
  opBusquedaVehiculo: boolean = false;
  opBusquedaSitio: boolean = false;
  divdebusquedaSitio: boolean = false;
  divsitio: boolean = false;
  sitios: any;
  tamaniodivsarriba: any;
  busquedaporneconomico: boolean = false;
  busquedaporserie: boolean = false;
  busquedapormotor: boolean = false;
  busquedaporplaca: boolean = false;
  vehiculos: any;
  sitio: any;
  modalidadesSitio: any;
  modalidadesSitioVencidos: any;
  arrayBusquedas: any = [];
  constructor(
    private router?: Router,
    private apollo?: Apollo,

  ) { }

  ngOnInit() {
  }

  asignarBusquedaXNombre() {
    this.busquedapornombre = true;
    this.busquedapornuc = false;
    this.busquedaporsitio = false;
  }
  asignarBusquedaXNUC() {
    this.busquedapornombre = false;
    this.busquedaporsitio = false;
    this.busquedapornuc = true;
  }

  mychange(event) {
    if (this.registroabuscar.length >= 5) {
      if (this.busquedapornombre == true) {
        this.divdebusqueda = true;
        this.llamarregistros(1);
      }
      if (this.busquedapornuc == true) {
        this.divdebusqueda = true;
        this.llamarregistros(2);
      }
    }
  }

  llamarregistros(decision: any) {
    this.loading = true;

    this.apollo.use('backConsulta').watchQuery({
      query: B.LISTAR_CONCESIONARIOS,
      variables: {
        input: this.registroabuscar,
        option: decision,
        limit: 10
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.crearregistros(result.data)
      });
  }


  crearregistros(registrosencontrados: any) {
    this.data = registrosencontrados.concessionsLike;
    this.loading = false;
    let transporte = '';
    if (this.busquedapornombre == true) {
      transporte = 'BÚSQUEDA POR NOMBRE';
    }
    if (this.busquedapornuc == true) {
      transporte = 'BÚSQUEDA POR NUC';

    }



  }


  seleccionarregistro(registro: any) {
    this.apollo.use('registroReto')
      .watchQuery({
        query: B.GET_REGISTROS_CONCESIONARIO,
        variables: {
          id_concesion: registro.id,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarRegistrosxConcesion(result.data, registro)
      }, (error) => {
        M.toast({ html: error })
      });
    /*
    this.apollo.use('backConsulta')
   .mutate({
    mutation: N.CREATE_LOG,
    variables: {
      description: 'Se busco una concesión por ' + transporte + ' concesionario: ' + mensajeparaenviar + ' Sistema: Constancias RETO'
    }
   }).subscribe(result => {
      }, (error) => {

    });

    */


  }


  asignarRegistrosxConcesion(data, registro) {
    if (data.registro_retoByConcesion == null) {
      this.registroamostrar = registro;
      this.Ostatus.emit(this.registroamostrar);
      this.divdebusqueda = false;
      this.divplantillas = true;
    } else {
      M.toast({ html: 'Concesión con un registro activo.' })
    }
  }




  buscarOtroConcesionario() {
    this.Result.emit(false);
    this.registroamostrar = undefined;
    this.divdebusqueda = false;
    this.busquedapornuc = false;
    this.busquedapornombre = true;
    this.divplantillas = false;
    this.Hidden = false;
    this.opBusquedaSitio = false;
    this.divdebusqueda = true;
    this.registroabuscar = undefined;
    this.data = [];

  }

  limpiarbusqueda() {
  }


  crearLogIncremental(transporte) {
    this.apollo.use('backConsulta')
      .mutate({
        mutation: N.CREATE_LOG,
        variables: {
          description: 'Se busco una concesión por ' + transporte + ' mediante input: ' + this.registroabuscar + ' Sistema: Constancias RETO'
        }
      }).subscribe(result => {
      }, (error) => {

      });
  }

}
