import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InformacionComponent } from './informacion/informacion.component';
import { RecuperarComponent } from './recuperar/recuperar.component';

//import { LoginComponent } from './login/login.component';
 import {AuthorizatedGuard} from "./core/guards/authorizated.guard";
 import { RegisterComponent } from './register/register.component';
 import {AuthorizatedAdminGuard} from "./core/guards/authorizatedAdmin.guard";
 //import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
 import {AuthorizatedSupervisorGuard} from "./core/guards/authorizatedSupervisor.guard";

 import {AuthorizatedAfterLoginGuard} from "./core/guards/authorizatedafterlogin.guard";
 import { ConductoresComponent } from './conductores/conductores.component';

 import { ComentariosComponent } from './comentarios/comentarios.component';
 import { SitiosComponent } from './sitios/sitios.component';

 import { TotalesComponent } from './totales/totales.component';
 import { DiplomaComponent } from './diploma/diploma.component';


 import {CheckAuthGuard} from "./auth/guards/check-auth.guard";
 import {VerifyAuthGuard} from "./auth/guards/verify-auth.guard";
 import { LoginComponent } from './auth/login/login.component';
 import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
 import { CollectComponent } from './auth/collect/collect.component';
 import { ImpresionComponent } from './impresion/impresion.component';
 import { BandejaRetoComponent } from './bandejareto/bandejareto.component';
 import { DetallesRegistroComponent } from './bandejareto/detallesregistro/detallesregistro.component';

const routes: Routes = [
   { path: 'informacion', component: InformacionComponent, canActivate: [ VerifyAuthGuard ] },
   { path: 'constancias', component: ConductoresComponent, canActivate: [ VerifyAuthGuard ]},

   { path: 'conductores', component: ComentariosComponent, canActivate: [ VerifyAuthGuard ]},
   { path: 'totales', component: TotalesComponent, canActivate: [ VerifyAuthGuard ]},
   { path: 'sitios', component: SitiosComponent, canActivate: [ VerifyAuthGuard ]},
   { path: 'impresion/:prop', component: ImpresionComponent, canActivate: [ VerifyAuthGuard ]},
   { path: 'collect', component: CollectComponent, pathMatch: 'full'},
   //{ path: 'registroreto', component: RegistroRetoComponent, pathMatch: 'full'},
   { path: 'inbox', component: BandejaRetoComponent, canActivate: [ VerifyAuthGuard ]},
   { path: 'inbox/detalle/:id_registro', component: DetallesRegistroComponent, canActivate: [ VerifyAuthGuard ]},

   //{ path: 'capacitaciones', component: DiplomaComponent, pathMatch: 'full'},

  // { path: 'registro', component: RegisterComponent, canActivate: [ VerifyAuthGuard ]},
   { path: 'login', component: LoginComponent, canActivate: [ CheckAuthGuard ] },
   { path: 'resetpassword', component: ResetPasswordComponent, canActivate: [ VerifyAuthGuard ]},
   { path: '', redirectTo: '/login', pathMatch: 'full' },
   { path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
