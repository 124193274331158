  import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import { Observable,Observer } from 'rxjs';
  import gql from 'graphql-tag';
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  /*
  import * as XLSX from 'xlsx';
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';
  */
  type AOA = any[][];
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  declare var M: any;
  export interface myinterface {
    remove(index: number,nameComponet: any);
  }
 @Component({
  selector: 'app-inconsistencias', templateUrl: './inconsistencias.component.html', styleUrls: ['./inconsistencias.component.css']
})
export class Inconsistencias implements OnInit {
  public index: number;
  public selfRef: Inconsistencias;
  public compInteraction: myinterface;
  @Input() IModel: any;
  @Input() NameComponet: any;
  @Input() InconsistenciasConcesionario: any;
  constructor(){}

  ngOnInit() {
  }

  verImodel(){
  }

  removeMe(index,nameComponet) {
    this.compInteraction.remove(index,nameComponet)
  }

  funcioncrearfecha(stringdate: any){
     if(stringdate == ''){
      return '';
    }
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    /*
    var arrayDeCadenas = stringdate.split('T');
    var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
    let fecha2 = new Date(parseInt(arrayDeCadenas2[0]),parseInt(arrayDeCadenas2[1]),parseInt(arrayDeCadenas2[2]));
    */
    var arrayDeCadenas = stringdate.split('T');
    var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
  //let fecha = new Date(stringdate);
    let fecha = new Date(parseInt(arrayDeCadenas2[0]),parseInt(arrayDeCadenas2[1])-1,parseInt(arrayDeCadenas2[2]));

    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  dia21 +  "-" + mes21 + "-" + anio21 ;
  }

}
