import {NgModule, Optional, SkipSelf} from '@angular/core';
 import {StorageService} from "./services/storage.service";
import {AuthorizatedGuard} from "./guards/authorizated.guard";
import {AuthorizatedAdminGuard} from "./guards/authorizatedAdmin.guard";
import {AuthorizatedAfterLoginGuard} from "./guards/authorizatedafterlogin.guard";
import {AuthorizatedSupervisorGuard} from "./guards/authorizatedSupervisor.guard";

import { AuthService } from './auth.service';
 import { SubscriptionService } from './subscription.service';
@NgModule({
  declarations: [  ],
  imports: [],
  providers: [
    AuthService,
    SubscriptionService,
    StorageService,
    AuthorizatedGuard,
    AuthorizatedAdminGuard,
    AuthorizatedAfterLoginGuard,
    AuthorizatedSupervisorGuard
  ],
  bootstrap: []
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
