import gql from 'graphql-tag';


const GET_CONSTANCIAS_REGION_FECHAS = gql`
query constanciasRegistradasRegionFecha($fecha_inicio: Date!,$fecha_fin: Date!) {
  constanciasRegistradasRegionFecha(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;

const GET_CONSTANCIAS_CANCELADAS_REGION_FECHAS = gql`
query constanciasCanceladasRegionFecha($fecha_inicio: Date!,$fecha_fin: Date!) {
  constanciasCanceladasRegionFecha(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;


const GET_PREREGISTROS_REGION_FECHAS = gql`
query preRegistrosRegionFecha($fecha_inicio: Date!,$fecha_fin: Date!) {
  preRegistrosRegionFecha(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;

const GET_PREREGISTROS_CANCELADOS_REGION_FECHAS = gql`
query preRegistrosCanceladosRegionFecha($fecha_inicio: Date!,$fecha_fin: Date!) {
  preRegistrosCanceladosRegionFecha(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin)
}
`;

export { GET_CONSTANCIAS_REGION_FECHAS,GET_CONSTANCIAS_CANCELADAS_REGION_FECHAS,GET_PREREGISTROS_REGION_FECHAS,GET_PREREGISTROS_CANCELADOS_REGION_FECHAS };
