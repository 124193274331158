import { NgModule } from '@angular/core';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule , HttpLink} from 'apollo-angular-link-http';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { split, from } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { OperationDefinitionNode } from 'graphql';
import {StorageService} from "./core/services/storage.service";
import { environment } from '../environments/environment';
import { AuthService } from './core/auth.service';
import { SubscriptionService } from './core/subscription.service';
import { AccountService } from './auth/autentication.service';

import { WebSocketLink } from 'apollo-link-ws';
import { errorLink, uploadLink, createAuthLink } from './graphql/middlewares';
 import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { setContext } from 'apollo-link-context';
@NgModule({
  declarations: [],
  imports: [],
  exports: [ApolloModule, HttpLinkModule],
  providers: []
})
export class GraphQLModule {

  constructor(
    apollo: Apollo,
    authService: AuthService,
    subscriptionService: SubscriptionService,
    httpLink: HttpLink,
    storageService: StorageService,
    private accountService: AccountService

  ) {
    let token = '';
    if(this.accountService.getTokensAccount!=undefined){
     token = `Bearer ${this.accountService.getTokensAccount.X_TOKEN_ACCOUNT }`
     }

    const WS_URI = `wss://${environment.HOST}`;

    const wsClient = subscriptionService.getWSClient(WS_URI, {
      lazy: true,
      // When connectionParams is a function, it gets evaluated before each connection.
      connectionParams: () => {
        return {
          authorization: token,
          service: environment.Cabecera
         };
      },
      reconnect: true,
      reconnectionAttempts: 5,
      connectionCallback: (error: Error[]) => {
        if (error) {
          console.log(error);
        }

        console.log('connectionCallback');
      },
      inactivityTimeout: 1000
    });

    const wsLink = new WebSocketLink(wsClient);

    const networkLink = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(
          query
        ) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      uploadLink
    );

    const authLink = createAuthLink(authService,this.accountService);



    const optionsreto: any = { uri: environment.URIRetoUtilidades,headers: new HttpHeaders({
                 authorization: token   })
 };

 const basic = setContext((operation, context) => ({
   headers: {
     Accept: 'charset=utf-8'
   }
 }));


 const auth = setContext((operation, context) => {
 if (token === null) {
   return {};
 } else {
   return {
     headers: {
       authorization: `${token}`,
       service: environment.Cabecera
     }
   };
 }
});




    apollo.create({
      link: from([auth, errorLink, networkLink]),
      cache: new InMemoryCache()
    });

    const options2: any = { uri: environment.URIServicios };
    apollo.createNamed('endpoint2', {
      link: httpLink.create(options2),
      cache: new InMemoryCache()
    });

    const options3: any = { uri: environment.URIBackLicencias };
    apollo.createNamed('backLicencias', {
      link: httpLink.create(options3),
      cache: new InMemoryCache()
    });


    const options4: any = { uri: environment.URIRetoUtilidades,headers: new HttpHeaders({
                 authorization: token   })
 };
    apollo.createNamed('backretoutilidades', {
      link: httpLink.create(options4),
      cache: new InMemoryCache()
    });

    const options5: any = { uri: environment.URIConsultaReto };
    apollo.createNamed('backconsultareto', {
      link: httpLink.create(options5),
      cache: new InMemoryCache()
    });

    const options6: any = { uri: environment.URISicac };
    apollo.createNamed('backsicac', {
      link: httpLink.create(options6),
      cache: new InMemoryCache()
    });

    const optionsConsulta: any = { uri: environment.URIConsultas,headers: new HttpHeaders({
                 authorization: token, service: environment.Cabecera   }) };
    apollo.createNamed('backConsulta', {
      link: httpLink.create(optionsConsulta),
      cache: new InMemoryCache()
    });

    const options7: any = { uri: environment.URIConductores,headers: new HttpHeaders({
                 authorization: token, service: environment.Cabecera   })
 };
    apollo.createNamed('backConductores', {
      link: httpLink.create(options7),
      cache: new InMemoryCache()
    });

    const options8: any = { uri: environment.URIDirecciones,headers: new HttpHeaders({
                 authorization: token, service: environment.Cabecera   })
 };
    apollo.createNamed('backDirecciones', {
      link: httpLink.create(options8),
      cache: new InMemoryCache()
    });

    const options9: any = { uri: environment.URIRetoUtilidades,headers: new HttpHeaders({
                 service: environment.Cabecera   }) };

    apollo.createNamed('backConstanciasSinToken', {
      link: httpLink.create(options9),
      cache: new InMemoryCache()
    });


    const options10: any = { uri: environment.URIDirecciones,headers: new HttpHeaders({
                 service: environment.Cabecera   }) };
    apollo.createNamed('backDireccionessinToken', {
      link: httpLink.create(options10),
      cache: new InMemoryCache()
    });

    const options11: any = { uri: environment.URIConductores,headers: new HttpHeaders({
                 service: environment.Cabecera   }) };
    apollo.createNamed('backConductoressinToken', {
      link: httpLink.create(options11),
      cache: new InMemoryCache()
    });

    const options12: any = { uri: environment.URIRegistroRETO,headers: new HttpHeaders({
                 authorization: token, service: environment.Cabecera   })};
    apollo.createNamed('registroReto', {
      link: httpLink.create(options12),
      cache: new InMemoryCache()
    });


    const options13: any = { uri: environment.URLDocumentos};

    apollo.createNamed('documentos', {
      link: httpLink.create(options13),
      cache: new InMemoryCache()
    });

    const optionsLicenciasLatin: any = { uri: environment.URIBackLicenciasLatin };
    apollo.createNamed('backLicenciasLatin', {
      link: httpLink.create(optionsLicenciasLatin),
      cache: new InMemoryCache()
    });


  }
}
