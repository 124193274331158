import {Component, OnInit,ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable,Observer } from 'rxjs';
declare var M: any;
import {MenuItem} from 'primeng/api';
import * as B from '../graphql/queries';
import { environment } from '../../environments/environment';
import {CreadorReportePDFService} from '../services/pdfmake/creacionReporte.service';

 @Component({
  selector: 'app-impresion', templateUrl: './impresion.component.html', styleUrls: ['./impresion.component.css']
})
export class ImpresionComponent implements OnInit {
  token: any;
  loading: boolean = true;
  datos: any;
  errortoken: boolean = false;
  constancias:any;
  reporteSeleccionado: any;
  sitio: any;

     constructor(
    private cdref: ChangeDetectorRef,
      private router?: Router,
      private apollo?: Apollo,
      public creadorReportePDFService?: CreadorReportePDFService
    ){}

    ngOnInit() {
      this.constancias = [];
      this.token = '';
        //this.creadorComponentesPDFService.getConstancia();
                 var arrayDeCadenas = this.router.url.split('/');
               this.token = arrayDeCadenas[arrayDeCadenas.length-1];

               this.apollo.use('backConstanciasSinToken').watchQuery({
                query: B.GET_REPORTE , variables: {
                  id: this.token
                },fetchPolicy: 'network-only'
              })
                .valueChanges.subscribe(result => {
                  this.asignarDatosUnionReporteget(result);

                }, (error) => {
                  M.toast({ html: 'Link no válido.' });
                  this.loading = false;
                  this.errortoken = true;

                })
/*
                this.apollo.use('backConstanciasSinToken').watchQuery({
                 query: B.BUSCAR_REPORTE , variables: {
                   id_reporte: this.token
                 },fetchPolicy: 'network-only'
               })
                 .valueChanges.subscribe(result => {
                   this.verObjDatos(result);

                 }, (error) => {
                   M.toast({ html: 'Link no válido.' });
                   this.loading = false;
                   this.errortoken = true;

                 })

                 */

        }

        asignarDatosUnionReporteget(data){
          this.reporteSeleccionado = data.data.reporte;
          this.buscarDatosUnionReporte2();
        }


        buscarDatosUnionReporte2(){
          this.apollo.use('backConstanciasSinToken').watchQuery({
            query: B.GET_UNION_RESPONSABLE_SITIO,
            variables: {
              id: this.reporteSeleccionado.id_union_sitio_responsable,
            }, fetchPolicy: 'network-only'
          })
            .valueChanges.subscribe(result => {
              this.asignarDatosUnionReporte(result);
            }, (error) => {
              var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
              M.toast({ html: toastHTML });
            });
        }

        asignarDatosUnionReporte(data){
           this.buscarSitioReporte(data.data.union_sitio_responsable.id_sitio);

        }

        buscarSitioReporte(indice){

          this.apollo.use('backConsulta').watchQuery({
            query: B.GET_SITIO,
            variables: {
              id: indice,
            }, fetchPolicy: 'network-only'
          })
            .valueChanges.subscribe(result => {
              this.asignarSitioReporte(result,indice);
            }, (error) => {
              var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
              M.toast({ html: toastHTML });
            });
        }

        asignarSitioReporte(data,indice){
          this.sitio = data.data.site;

          this.apollo.use('backConstanciasSinToken').watchQuery({
           query: B.BUSCAR_REPORTE , variables: {
             id_reporte: this.token
           },fetchPolicy: 'network-only'
         })
           .valueChanges.subscribe(result => {
             this.verObjDatos(result);

           }, (error) => {
             M.toast({ html: 'Link no válido.' });
             this.loading = false;
             this.errortoken = true;

           })
        }

        verObjDatos(data){

          if(data.data.findReporte.length>0){
            for(var i = 0; i < data.data.findReporte.length;i++){
              this.constancias.push(
                {
                  id: data.data.findReporte[i].id,
                  concession_id: data.data.findReporte[i].concession_id,
                  person_id: data.data.findReporte[i].person_id,
                  plate: {
                    folio: data.data.findReporte[i].plate.folio
                  },
                  foliated_sheet: {
                    folio: data.data.findReporte[i].foliated_sheet.folio
                  },
                  expedition_date: data.data.findReporte[i].expedition_date,
                  expiration_date: data.data.findReporte[i].expiration_date,
                  is_canceled: data.data.findReporte[i].is_canceled,
                  cancel_description: data.data.findReporte[i].cancel_description,
                  image_name: data.data.findReporte[i].image_name,
                  modality_id: data.data.findReporte[i].modality_id,
                  is_expired: data.data.findReporte[i].is_expired,
                  deprecated: data.data.findReporte[i].deprecated,
                }
              );
            }
            for(var i = 0; i < this.constancias.length;i++){
              this.traerConcesion(i);
              this.traerConductor(i);
              this.traerModalidad(i);
            }
          }else{
            this.loading = false;
          }







        }

        traerConcesion(indice){
          this.apollo.use('backConsulta')
            .watchQuery({
              query: B.GET_CONCESION,
              variables: {
                id: this.constancias[indice].concession_id,
              }, fetchPolicy: 'network-only'
            })
            .valueChanges.subscribe(result => {
              this.asignarConcesion(result.data,indice)
            }, (error) => {
              M.toast({ html: error })
            });
        }

        asignarConcesion(data,indice){
          this.constancias[indice]['concesion'] = data.concession;
          this.detectardatoscompletos();

        }

        traerConductor(indice){
          this.apollo.use('backConductoressinToken').watchQuery({
            query: B.GET_PERSON,
            variables: {
              id: this.constancias[indice].person_id,
            }, fetchPolicy: 'network-only'
          })
            .valueChanges.subscribe(result => {
              this.asignarConductor(result.data,indice);
            }, (error) => {
              var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
              M.toast({ html: toastHTML });
            });
        }

        asignarConductor(data,indice){
          this.constancias[indice]['conductor'] = data.person;
          this.detectardatoscompletos();

        }


        traerModalidad(indice) {
          this.apollo.use('backConsulta')
            .watchQuery({
              query: B.GET_MODALIDAD,
              variables: {
                id: this.constancias[indice].modality_id,
              }, fetchPolicy: 'network-only'
            })
            .valueChanges.subscribe(result => {
              this.asignarModalidad(result.data,indice)
            }, (error) => {
              M.toast({ html: error })
            });
        }

        asignarModalidad(data,indice){
          this.constancias[indice]['modalidad'] = data.modality;
          this.detectardatoscompletos();

        }


        detectardatoscompletos(){
          let validacion = this.constancias.every(elem => elem.modalidad != undefined && elem.conductor != undefined && elem.concesion != undefined);
          if(validacion){
            this.loading = false;
            let array = [];
            for(var i = 0; i < this.constancias.length;i++){
              let sitio = this.constancias[i].concesion.site.name;
              let nombreConcesionario = '';
              if (this.constancias[i].concesion.concessionaire.business_name == '') {
                let nombre = '';
                let primerApellido = '';
                let segundoApellido = '';
                if (this.constancias[i].concesion.concessionaire.name != undefined && this.constancias[i].concesion.concessionaire.name != '') {
                  nombre = this.constancias[i].concesion.concessionaire.name;
                }
                if (this.constancias[i].concesion.concessionaire.first_surname != undefined && this.constancias[i].concesion.concessionaire.first_surname != '') {
                  primerApellido = ' ' + this.constancias[i].concesion.concessionaire.first_surname;
                }
                if (this.constancias[i].concesion.concessionaire.second_surname != undefined && this.constancias[i].concesion.concessionaire.second_surname != '') {
                  segundoApellido = ' ' + this.constancias[i].concesion.concessionaire.second_surname;
                }
                nombreConcesionario = nombre + primerApellido + segundoApellido;
              } else {
                nombreConcesionario = this.constancias[i].concesion.concessionaire.business_name;
              }


              var nombreConductor = this.constancias[i].conductor.identity.name;
              var primer_apellido = this.constancias[i].conductor.identity.first_surname;
              var segundo_apellido = '';
              if (this.constancias[i].conductor.identity.second_surname != undefined || this.constancias[i].conductor.identity.second_surname != null) {
                var segundo_apellido = ' ' + this.constancias[i].conductor.identity.second_surname;
              }

              let nombreCompleto = nombreConductor + ' ' + primer_apellido + segundo_apellido;
              array.push(
                {
                  sitio: sitio,
                  nuc: this.constancias[i].concesion.consecutive_number + '/' + this.constancias[i].concesion.unique_number_key,
                  concesionario: nombreConcesionario,
                  placas: this.constancias[i].plate.folio,
                  folio: this.constancias[i].foliated_sheet.folio,
                  nombre_conductor: nombreCompleto,
                  modalidad: this.constancias[i].modalidad.name,
                  fecha_captura: this.constancias[i].expedition_date,
                  vigencia: this.constancias[i].expiration_date
                }
              );
            }
            this.creadorReportePDFService.crearReporte(array,this.reporteSeleccionado.report_date,this.sitio.name);
          }

        }


    ngAfterViewInit() {
      this.cdref.detectChanges();
    }




}
