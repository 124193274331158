  import {Component, OnInit} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable } from 'rxjs';
   import {User} from "../core/models/user.model";
  import {StorageService} from "../core/services/storage.service";
  import { saveAs  } from 'file-saver';
  declare var M: any;
  import * as N from '../graphql/mutations';
  import * as A from '../graphql/queries';
@Component({
  selector: 'app-diploma', templateUrl: './diploma.component.html', styleUrls: ['./diploma.component.css']
})
export class DiplomaComponent implements OnInit {
  modalidades: any;
  mostrardata: boolean = false;
  mostrarsubtabla: boolean = false;
  loading: boolean = false;
  objmodalidades: any;
  mostrartablaconcesiones: boolean = false;
  hombres: any;
  mujeres: any;
  concesiones: any;
  totalconstanciasactivas: any;
  totalpreregistrosactivas: any;
  totalconcesionesconstanciasactivas: any;
  totalconcesionespreregistrosactivas: any;
  modalidesconstancias: any;
  modalidadespreregistros: any;
  mensaje: any;
  constructor(
      private router?: Router,
      private apollo?: Apollo,
      private storageService?: StorageService
    ){}

  ngOnInit() {
    this.buscarTotales();
    //this.buscarTotalesPorModalidad();
    //this.buscarTotalesPreregistrosPorModalidad();
    this.mensaje = '';
    /*
    this.concesiones = [
      {
        nombre: 'SERTEXA',
        constancias: 19
      },
      {
        nombre: 'CHOFERES DEL SUR',
        constancias: 11
      }
    ];
    */

    /*
    this.objmodalidades = [
      [{
        nombre: 'TAXI',
        mostrarsubtabla: false,
        caracteristicas: [],
        cancelados: {
          total: 10,
          genero: {
            hombres: 4,
            mujeres: 6
          }


        },
        activos: {
          total: 66,
          genero: {
            hombres: 30,
            mujeres: 36
          }

        },
        totales: {
          total: 76,
          genero: {
            hombres: 34,
            mujeres: 42
          }

        }

      }],[{
        nombre: 'URBANO',
        mostrarsubtabla: false,
        caracteristicas: [],

        cancelados: {
          total: 8,
          genero: {
            hombres: 4,
            mujeres: 4
          }
        },
        activos: {
          total: 54,
          genero: {
            hombres: 20,
            mujeres: 34
          }
        },
        totales: {
          total: 62,
          genero: {
            hombres: 24,
            mujeres: 38
          }


        }

      }]
    ];
    */
    /*
    this.modalidades = [
      {
        nombre: 'TAXI',
        cancelados: {
          total: 60,
          genero: {
            hombre: 40,
            mujeres: 30
          }
        },
        activos: {
          total: 60,
          genero: {
            hombre: 40,
            mujeres: 30
          }
        },
        totales: {
          total: 60,
          genero: {
            hombre: 40,
            mujeres: 30
          }
        }

      },
      {
        nombre: 'URBANOS',
        cancelados: {
          total: 60,
          genero: {
            hombre: 40,
            mujeres: 30
          }
        },
        activos: {
          total: 60,
          genero: {
            hombre: 40,
            mujeres: 30
          }
        },
        totales: {
          total: 60,
          genero: {
            hombre: 40,
            mujeres: 30
          }
        }

      }
    ];

    */
    /*
    this.modalidades = [
      {
        nombre: 'TAXI',
        total: 70,
        cancelados: 10,
        activos: 60,
        caracteristicas: [
          {
            genero: {
              hombre: 40,
              mujeres: 30
            }
          }
        ]

      },
      {
        nombre: 'URBANO',
        total: 80,
        cancelados: 5,
        activos: 75,
        caracteristicas: [
          {
            genero: {
              hombre: 60,
              mujeres: 20
            }
          }
        ]
      }
    ];

    */
      }

      versubtabla(modalidad,modalidadespecifica,estado){
        for(var i = 0; i < this.objmodalidades.length;i++){
          this.objmodalidades[i][0].mostrarsubtabla = false;
          }
         modalidad.mostrarsubtabla == true ?  modalidad.mostrarsubtabla = false :  modalidad.mostrarsubtabla = true;

         //this.hombres = modalidadespecifica.genero.hombres;
         //this.mujeres = modalidadespecifica.genero.mujeres;
         this.buscarTotalesXGeneroXModalidad(modalidad,modalidadespecifica,estado);



      }

    mostrarConstancias(){
      this.mostrardata = true;
      this.mostrartablaconcesiones = false;
      this.mensaje = 'Constancias';
       /*
       var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
      div1.style.border = "thick solid #0000FF";
      var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
      div2.style.border = null;
      */
      var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
     div1.style.backgroundColor  = "#4db6ac";
     var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
     div2.style.backgroundColor = null;

     var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
     div3.style.backgroundColor = null;
     var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
     div4.style.backgroundColor = null;


     //this.objmodalidades = this.modalidesconstancias;
     this.buscarTotalesPorModalidad(1);


    }

    mostrarPreregistros(){
      this.mostrardata = true;
      this.mostrartablaconcesiones = false;

      this.mensaje = 'Pre-Registros';

      /*
      var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
      div1.style.border = null;
      var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
      div2.style.border = "thick solid #0000FF";
      */

      var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
     div1.style.backgroundColor  = null;
     var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
     div2.style.backgroundColor = "#4db6ac";

     var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
     div3.style.backgroundColor = null;
     var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
     div4.style.backgroundColor = null;


     //this.objmodalidades = this.modalidadespreregistros;
     this.buscarTotalesPorModalidad(2);
    }

    mostrarConcesiones(option){
      this.mostrartablaconcesiones = true;
      this.mostrardata = false;


     var div1 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[0]);
     div1.style.backgroundColor  = null;
     var div2 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[1]);
     div2.style.backgroundColor = null;

     if(option==1){
       this.mensaje = 'Total de concesiones con constancias activas por región';

       var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
       div3.style.backgroundColor = "#4db6ac";
       var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
       div4.style.backgroundColor = null;
     }

     if(option==2){
       this.mensaje = 'Total de concesiones con preregistros activas por región';

       var div3 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[2]);
       div3.style.backgroundColor = null;
       var div4 = (<HTMLInputElement>document.getElementsByClassName("ui-card-body")[3]);
       div4.style.backgroundColor = "#4db6ac";
     }


           this.apollo.use('backretoutilidades').watchQuery({
             query: A.GET_TOTALES_X_MODALIDAD_X_CONCESION,
             variables: {
                   option:  option
                }, fetchPolicy: 'network-only'
              })
           .valueChanges.subscribe(result => {
              this.asinarConsesiones(result);
            }, (error) => {
              var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
              M.toast({html: toastHTML});
             });

    }

    buscarTotales(){
      this.loading = true;
      this.apollo.use('backretoutilidades').watchQuery({
        query: A.GET_TOTALES, fetchPolicy: 'network-only'
         })
      .valueChanges.subscribe(result => {
         this.asignarTotales(result);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
         M.toast({html: toastHTML});
        });
    }

    asignarTotales(data){
      this.loading = false;

      this.totalconstanciasactivas = data.data.totalsEntity.certificates;
      this.totalpreregistrosactivas = data.data.totalsEntity.pre_certificates;
      this.totalconcesionesconstanciasactivas = data.data.totalsEntity.active_concessions_certificate;
      this.totalconcesionespreregistrosactivas = data.data.totalsEntity.active_concessions_pre_certificate;
    }

    /*

    buscarTotalesPreregistrosPorModalidad(){
      this.apollo.use('backretoutilidades').watchQuery({
        query: A.GET_TOTALESPREREGISTROS_X_MODALIDAD, fetchPolicy: 'network-only'
         })
      .valueChanges.subscribe(result => {
         this.asignarTotalesPreregistrosxModalidad(result);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
         M.toast({html: toastHTML});
        });
    }

    */


    buscarTotalesPorModalidad(option){
      this.loading = true;

      this.apollo.use('backretoutilidades').watchQuery({
        query: A.GET_TOTALESCONSTANCIAS_X_MODALIDAD,
        variables: {
              option:  option
           }, fetchPolicy: 'network-only'
         })
      .valueChanges.subscribe(result => {
         this.asignarTotalesxModalidad(result,option);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
         M.toast({html: toastHTML});
        });
    }

/*
    asignarTotalesPreregistrosxModalidad(data){
      console.log(data);
      this.modalidadespreregistros = [];
      for(var i = 0; i < data.data.totalsPreCertificateModality.length; i++){
        this.modalidadespreregistros.push(
          [
          {
            nombre: data.data.totalsPreCertificateModality[i].modality.name,
            id:data.data.totalsPreCertificateModality[i].modality.id,
            entidad: 2,
            mostrarsubtabla: false,
            caracteristicas: [],
            cancelados: {
              total: data.data.totalsPreCertificateModality[i].canceled,
              genero: {
                hombres: 0,
                mujeres: 0
              }


            },
            activos: {
              total: data.data.totalsPreCertificateModality[i].active,
              genero: {
                hombres: 0,
                mujeres: 0
              }

            },
            totales: {
              total: data.data.totalsPreCertificateModality[i].total,
              genero: {
                hombres: 0,
                mujeres: 0
              }

            }

          }
          ]

        );
      }
    }

    */

    asignarTotalesxModalidad(data,option){
      this.objmodalidades = [];
      for(var i = 0; i < data.data.totalsModality.length; i++){
        this.objmodalidades.push(
          [
          {
            nombre: data.data.totalsModality[i].modality.name,
            id:data.data.totalsModality[i].modality.id,
            entidad: option,
            mostrarsubtabla: false,
            caracteristicas: [],
            cancelados: {
              total: data.data.totalsModality[i].canceled,
              genero: {
                hombres: 0,
                mujeres: 0
              }


            },
            activos: {
              total: data.data.totalsModality[i].active,
              genero: {
                hombres: 0,
                mujeres: 0
              }

            },
            totales: {
              total: data.data.totalsModality[i].total,
              genero: {
                hombres: 0,
                mujeres: 0
              }

            }

          }
          ]

        );
      }

      this.loading = false;


    }


    buscarTotalesXGeneroXModalidad(modalidad,entidad,option){
      this.loading = false;

      this.apollo.use('backretoutilidades').watchQuery({
        query: A.GET_TOTALES_X_MODALIDAD_X_GENERO,
        variables: {
              modality_id: modalidad.id,
              option:  modalidad.entidad,
              mode:option
           }, fetchPolicy: 'network-only'
         })
      .valueChanges.subscribe(result => {
         this.asignarGeneroModalidad(result,entidad);
       }, (error) => {
         var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al obtener los totales.    </div></span>';
         M.toast({html: toastHTML});
        });
    }

    asignarGeneroModalidad(data,entidad){
      entidad.genero.hombres =data.data.totalsSexByModality.men;
      entidad.genero.mujeres = data.data.totalsSexByModality.women;
      this.hombres = entidad.genero.hombres;
      this.mujeres = entidad.genero.mujeres;
      this.loading = false;


    }

    mostrarConcesionesTotales(){
      this.loading = false;

    }


    asinarConsesiones(data){
      this.mostrartablaconcesiones = true;
      this.concesiones = data.data.totalsRegion;
      /*
      this.concesiones = [
        {
          nombre: 'SERTEXA',
          constancias: 19
        },
        {
          nombre: 'CHOFERES DEL SUR',
          constancias: 11
        }
      ];
      */
    }




}
