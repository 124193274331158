  import {Component, OnInit} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable } from 'rxjs';
  import {User} from "../core/models/user.model";
  import {StorageService} from "../core/services/storage.service";
  import { saveAs  } from 'file-saver';
  declare var M: any;
  import kjua from "kjua";
  import * as A from '../graphql/mutations';
  import * as B from '../graphql/queries';
  import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
  import { RelacionesInputValidacionesService } from '../services/validaciones/relacioninputvalidaciones.service';
  import {SearchSitioComponent} from "../searchSitio/searchSitio.component";
  import {CreadorReportePDFService} from '../services/pdfmake/creacionReporte.service';
  import { ErrorApolloService } from '../services/errors/error.service';


 @Component({
  selector: 'app-sitios', templateUrl: './sitios.component.html', styleUrls: ['./sitios.component.css']
})
export class SitiosComponent implements OnInit {
  verificarerrores: boolean = false;

  divinformacion: boolean = false;
  mostrarbusqueda: boolean = true;
  conductorseleccionado: any;
  constanciasConductor: any;
  observacionesconductor: any;
  loading: boolean = false;
  mostrartablasitios: boolean = false;
  mostrartabla: boolean = false;
  comentario: any;
  conductoractivo: boolean = false;
  observacionxconstancia: any;
  constanciaseleccionada: any;
  arrayPreregistros: any;
  sitiosxrepresentantes: any;
  representates: any;
  reporteSeleccionado: any;
  selectedResponsable: any;
  sitio: any;
  public boxForm: FormGroup;
  idResponsable: any;
  datetoday: Date = new Date();
  fechaCorte: Date;
  es: any;
  unionresposanblesitio: any;
  reportes: any;
    unionesseleccionadas: any[];
    constancias:any;

   static getBarcodeData(text: string, size = 900) {
    return kjua({
      render: "canvas", crisp: true, minVersion: 1, ecLevel: "Q", size: size, ratio: undefined, fill: "#333", back: "#fff",
      text, rounded: 10, quiet: 2, mode: "label",  mSize: 5, mPosX: 50, mPosY: 100, label: "", fontname: "sans-serif", fontcolor: "#3F51B5", image: undefined
    });
  }

  constructor(
    private searchComponent?: SearchSitioComponent,
      private router?: Router,
      private apollo?: Apollo,
      private storageService?: StorageService,
      private formBuilder?: FormBuilder,
      public relacionesInputValidacionesService?: RelacionesInputValidacionesService,
      public creadorReportePDFService?: CreadorReportePDFService,
      public errorApolloService?: ErrorApolloService,

    ){}

  ngOnInit() {
    this.constancias = [];

    this.es = {
      firstDayOfWeek: 1,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar'
    }
    this.boxForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createBox()])
    });


    $(document).ready(function(){
     $('.tabs').tabs();
   });
    $(document).ready(function(){
      $('.modal').modal({dismissible: false});
    });

    this.sitiosxrepresentantes = [];
    this.representates = [];
    this.reportes = [];

    this.traerArregloSitios();

    this.traerRepresentantes();

    this.traerReportes();
  }

  get items(): FormArray {
    return this.boxForm.get('items') as FormArray;
  }
  createBox(): FormGroup {
      return this.formBuilder.group({
        nombre: ['',this.relacionesInputValidacionesService.getValidacion('nombre')],
        primerApellido: ['',this.relacionesInputValidacionesService.getValidacion('nombre')],
        segundoApellido: ['',[]],
        curp: ['', this.relacionesInputValidacionesService.getValidacion('curp') ],
        telefono: ['',this.relacionesInputValidacionesService.getValidacion('telefono')],
        email: ['',this.relacionesInputValidacionesService.getValidacion('correo')],
      });


  }

  traerRepresentantes(){
    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_REPRESENTANTES,
      variables: {
        params: {},
        mode: 'all'
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarRepresentantes(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarRepresentantes(data){
    for(var i = 0; i < data.data.responsables.length;i++){
      let name = '';
      if(data.data.responsables[i].nombre!=undefined){
        name = name + data.data.responsables[i].nombre;
      }
      if(data.data.responsables[i].primer_apellido!=undefined){
        name = name + ' ' + data.data.responsables[i].primer_apellido;
      }
      if(data.data.responsables[i].segundo_apellido!=undefined){
        name = name + ' ' + data.data.responsables[i].segundo_apellido;
      }
      let found = this.representates.find(element => element.id == data.data.responsables[i].id);
      if(found==undefined){
        this.representates.push(
          {
            id: data.data.responsables[i].id,
            correo_electronico: data.data.responsables[i].correo_electronico,
            nombre: data.data.responsables[i].nombre,
            curp: data.data.responsables[i].curp,
            name: name,
            label: name,
            value: data.data.responsables[i].id,
            primer_apellido: data.data.responsables[i].primer_apellido,
            segundo_apellido: data.data.responsables[i].segundo_apellido,
            telefono: data.data.responsables[i].telefono
          }
        )
      }

    }
  }

  traerArregloSitios(){

    this.loading = true;

    this.mostrartablasitios = false;

    this.sitiosxrepresentantes = [];

    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_SITIOS_X_REPRESENTANTES,
      variables: {
        params: {},
        mode: 'all'
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarSitios(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });

  }

  asignarSitios(data){
    //this.sitiosxrepresentantes = data.data.union_sitios_responsables;
    for(var i = 0; i < data.data.union_sitios_responsables.length;i++){
      //this.traerResponsable();
      let found = this.sitiosxrepresentantes.find(element => element.id == data.data.union_sitios_responsables[i].id);
      if(found==undefined){
        this.sitiosxrepresentantes.push(
          {
            id: data.data.union_sitios_responsables[i].id,
            id_responsable: data.data.union_sitios_responsables[i].id_responsable,
            id_sitio: data.data.union_sitios_responsables[i].id_sitio,
            deprecated: data.data.union_sitios_responsables[i].deprecated
          }
        );
      }

    }

    for(var i = 0; i < this.sitiosxrepresentantes.length;i++){
      this.traerResponsable(i);
      this.traerSitio(i);
    }
     this.loading = false;

    this.mostrartablasitios = true;

  }

  traerResponsable(indice){
    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_REPRESENTANTE,
      variables: {
        id: this.sitiosxrepresentantes[indice].id_responsable,
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarRepresentante(result,indice);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarRepresentante(data,indice){
    this.sitiosxrepresentantes[indice]['responsable'] = data.data.responsable;
  }


  traerSitio(indice){
    this.apollo.use('backConsulta').watchQuery({
      query: B.GET_SITIO,
      variables: {
        id: this.sitiosxrepresentantes[indice].id_sitio,
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarSitio(result,indice);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarSitio(data,indice){
    this.sitiosxrepresentantes[indice]['sitio'] = data.data.site;
  }

  seleccionarResponsable(){
    if(this.selectedResponsable!=null){
      let found = this.representates.find(element => element.id == this.selectedResponsable);


      this.boxForm.get('items')['controls'][0]['controls'].nombre.setValue(found.nombre);
      this.boxForm.get('items')['controls'][0]['controls'].primerApellido.setValue(found.primer_apellido);
      this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.setValue(found.segundo_apellido);
      this.boxForm.get('items')['controls'][0]['controls'].telefono.setValue(found.telefono);
      this.boxForm.get('items')['controls'][0]['controls'].email.setValue(found.correo_electronico);
      this.boxForm.get('items')['controls'][0]['controls'].curp.setValue(found.curp);
      this.boxForm.get('items')['controls'][0]['controls'].nombre.disable();
      this.boxForm.get('items')['controls'][0]['controls'].primerApellido.disable();
      this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.disable();
      this.boxForm.get('items')['controls'][0]['controls'].telefono.disable();
      this.boxForm.get('items')['controls'][0]['controls'].email.disable();
      this.boxForm.get('items')['controls'][0]['controls'].curp.disable();

    }else{
      this.boxForm.get('items')['controls'][0]['controls'].nombre.setValue(null);
      this.boxForm.get('items')['controls'][0]['controls'].primerApellido.setValue(null);
      this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.setValue(null);
      this.boxForm.get('items')['controls'][0]['controls'].telefono.setValue(null);
      this.boxForm.get('items')['controls'][0]['controls'].email.setValue(null);
      this.boxForm.get('items')['controls'][0]['controls'].curp.setValue(null);

      this.boxForm.get('items')['controls'][0]['controls'].nombre.enable();
      this.boxForm.get('items')['controls'][0]['controls'].primerApellido.enable();
      this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.enable();
      this.boxForm.get('items')['controls'][0]['controls'].telefono.enable();
      this.boxForm.get('items')['controls'][0]['controls'].email.enable();
      this.boxForm.get('items')['controls'][0]['controls'].curp.enable();

    }
  }


  public check($event) {
    this.sitio = $event;
   }

  public comprobar($event) {
  //  this.divinformacion = false;
  //  this.conductoractivo = false;
    this.searchComponent.limpiarbusqueda();
  }


  continuarregistroresponsable(){
    if (!this.boxForm.invalid) {
      if(this.selectedResponsable!=null){
        let found = this.representates.find(element => element.id == this.selectedResponsable);
         this.idResponsable = found.id;
        this.asignarReponsableAlSitio()

      }else{
        this.crearresponsable()
      }
    } else {
      this.verificarerrores = this.boxForm.invalid;
    }
    /*

    */

  }

  crearresponsable(){
    this.apollo.use('backretoutilidades').watchQuery({
      query: B.GET_REPRESENTANTE_CURP,
      variables: {
        curp: this.boxForm.get('items')['controls'][0]['controls'].curp.value,
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.verificarcurp(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
    /*
    let segundo_apellido = null;
    if(this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.value=='' || this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.value==undefined){
      segundo_apellido = null;
    }else{
      segundo_apellido = this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.value.toUpperCase()
    }
    let telefono = null;
    if(this.boxForm.get('items')['controls'][0]['controls'].telefono.value=='' || this.boxForm.get('items')['controls'][0]['controls'].telefono.value==undefined){
      telefono = null;
    }else{
      telefono = this.boxForm.get('items')['controls'][0]['controls'].telefono.value
    }
    this.apollo.use('backretoutilidades')
      .mutate({
        mutation: A.CREATE_RESPONSABLE,
        variables: {
          nombre: this.boxForm.get('items')['controls'][0]['controls'].nombre.value,
          primer_apellido: this.boxForm.get('items')['controls'][0]['controls'].primerApellido.value.toUpperCase(),
          segundo_apellido: segundo_apellido,
          telefono: telefono,
          correo_electronico: this.boxForm.get('items')['controls'][0]['controls'].email.value
        }
      })
      .subscribe(({ data }) => {
        this.asignarnuevorepresentante(data)
      }, (error) => {
        M.toast({ html: error });
      });
    */

  }

  verificarcurp(data){
    if(data.data.responsableByCurp!=undefined){
      this.idResponsable = data.data.responsableByCurp.id;
      this.asignarReponsableAlSitio();
    }else{
      this.crearregistro();
    }
  }

  crearregistro(){
    let segundo_apellido = null;
    if(this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.value=='' || this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.value==undefined){
      segundo_apellido = null;
    }else{
      segundo_apellido = this.boxForm.get('items')['controls'][0]['controls'].segundoApellido.value.toUpperCase()
    }
    let telefono = null;
    if(this.boxForm.get('items')['controls'][0]['controls'].telefono.value=='' || this.boxForm.get('items')['controls'][0]['controls'].telefono.value==undefined){
      telefono = null;
    }else{
      telefono = this.boxForm.get('items')['controls'][0]['controls'].telefono.value
    }
    this.apollo.use('backretoutilidades')
      .mutate({
        mutation: A.CREATE_RESPONSABLE,
        variables: {
          nombre: this.boxForm.get('items')['controls'][0]['controls'].nombre.value,
          primer_apellido: this.boxForm.get('items')['controls'][0]['controls'].primerApellido.value.toUpperCase(),
          segundo_apellido: segundo_apellido,
          telefono: telefono,
          curp: this.boxForm.get('items')['controls'][0]['controls'].curp.value,
          correo_electronico: this.boxForm.get('items')['controls'][0]['controls'].email.value
        }
      })
      .subscribe(({ data }) => {
        this.asignarnuevorepresentante(data)
      }, (error) => {
        this.errorApolloService.checkError(error.message);
      });
  }


  asignarnuevorepresentante(data){
    this.idResponsable = data.responsable.id;
    this.asignarReponsableAlSitio()
  }

  asignarReponsableAlSitio(){
    this.apollo.use('backretoutilidades')
      .mutate({
        mutation: A.ASIGNAR_RESPONSABLE_SITIO,
        variables: {
          id_sitio: this.sitio.idSitio,
          id_responsable: this.idResponsable

        }
      })
      .subscribe(({ data }) => {
         $('.modal').modal('close');
         this.ngOnInit();

       }, (error) => {
         this.errorApolloService.checkError(error.message);
      });
  }

  seleccionarUnionResponsableSitio(union){
    this.unionresposanblesitio = union;
  }

  enviarCorreo(){

    let arrenvio = [];
    for(var i = 0; i < this.unionesseleccionadas.length;i++){
      arrenvio.push(
        {
          id_union_sitio_responsable: this.unionesseleccionadas[i].id,
          report_date: this.funcioncrearfecha(this.fechaCorte)
        }
      );
    }


    this.apollo.use('backretoutilidades')
      .mutate({
        mutation: A.REPORTE_SITIO_RESPONSABLE,
        variables: {
          reportes: arrenvio
        }
      })
      .subscribe(({ data }) => {
        this.unionesseleccionadas = [];
         $('.modal').modal('close');
         this.ngOnInit();

       }, (error) => {
         this.errorApolloService.checkError(error.message);
      });


  }

  traerReportes(){
    this.mostrartabla = false;
     this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_REPORTES,
      variables: {
        params: {},
        mode: 'all'
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarReportes(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarReportes(data){
    for(var i = 0; i < data.data.reportes.length;i++){
      let found = this.reportes.find(element => element.id == data.data.reportes[i].id);
      if(found==undefined){
        this.reportes.push(
          {
            id: data.data.reportes[i].id,
            id_union_sitio_responsable: data.data.reportes[i].id_union_sitio_responsable,
            report_date: data.data.reportes[i].report_date,
            send_date: data.data.reportes[i].send_date,
            validity_date: data.data.reportes[i].validity_date
          }
        )
      }


    }


    this.mostrartabla = true;



    for(var i = 0; i < this.reportes.length;i++){
     this.buscarDatosUnionReporte(i);

    }



  }

  buscarSitioReporte(indice){
    if(this.reportes[indice].union!=undefined){
      this.apollo.use('backConsulta').watchQuery({
        query: B.GET_SITIO,
        variables: {
          id: this.reportes[indice].union.id_sitio,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarSitioReporte(result,indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
          M.toast({ html: toastHTML });
        });
    }


  }

  asignarSitioReporte(data,indice){
    this.reportes[indice]['sitio'] = data.data.site;
  }


  buscarDatosUnionReporte(indice){
    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_UNION_RESPONSABLE_SITIO,
      variables: {
        id: this.reportes[indice].id_union_sitio_responsable,
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarDatosUnionReporte(result,indice);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarDatosUnionReporte(data,indice){
    this.reportes[indice]['union'] = data.data.union_sitio_responsable;
    this.traerResponsableReporte(indice);
    this.buscarSitioReporte(indice);

  }

  traerResponsableReporte(indice){
    if(this.reportes[indice].union!=undefined){
      this.apollo.use('backConstanciasSinToken').watchQuery({
        query: B.GET_REPRESENTANTE,
        variables: {
          id: this.reportes[indice].union.id_responsable,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarRepresentanteReporte(result,indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse a la Base de Datos, contactar al Departamento de Sistematización.    </div></span>';
          M.toast({ html: toastHTML });
        });
    }

  }

  asignarRepresentanteReporte(data,indice){
    this.reportes[indice]['responsable'] = data.data.responsable;
  }

  funcioncrearfecha(stringdate: any) {
    let fecha = new Date(stringdate);
    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";
    if ((fecha.getMonth() + 1) < 10) {
      mes21 = "0" + (fecha.getMonth() + 1).toString();
    } else {
      mes21 = (fecha.getMonth() + 1).toString();
    }
    if ((fecha.getDate() + 1) <= 10) {
      dia21 = "0" + (fecha.getDate()).toString();
    } else {
      dia21 = (fecha.getDate()).toString();
    }
    return anio21 + "-" + mes21 + "-" + dia21 + "";
  }

  clear(){
    this.boxForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createBox()])
    });
  }

  limpiarmodalunion(){
    this.boxForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createBox()])
    });
    this.sitio = undefined;
    this.searchComponent.limpiarbusqueda();

  }

  imprimirReporte(reporte){
    this.reporteSeleccionado = reporte;
    this.constancias = [];

    this.apollo.use('backConstanciasSinToken').watchQuery({
     query: B.BUSCAR_REPORTE , variables: {
       id_reporte: reporte.id,
     },fetchPolicy: 'network-only'
   })
     .valueChanges.subscribe(result => {
       this.verObjDatos(result);

     }, (error) => {
       M.toast({ html: 'Reporte no válido.' });
       this.loading = false;

     })

  }


  verObjDatos(data){

    if(data.data.findReporte.length>0){
      for(var i = 0; i < data.data.findReporte.length;i++){
        this.constancias.push(
          {
            id: data.data.findReporte[i].id,
            concession_id: data.data.findReporte[i].concession_id,
            person_id: data.data.findReporte[i].person_id,
            plate: {
              folio: data.data.findReporte[i].plate.folio
            },
            foliated_sheet: {
              folio: data.data.findReporte[i].foliated_sheet.folio
            },
            expedition_date: data.data.findReporte[i].expedition_date,
            expiration_date: data.data.findReporte[i].expiration_date,
            is_canceled: data.data.findReporte[i].is_canceled,
            cancel_description: data.data.findReporte[i].cancel_description,
            image_name: data.data.findReporte[i].image_name,
            modality_id: data.data.findReporte[i].modality_id,
            is_expired: data.data.findReporte[i].is_expired,
            deprecated: data.data.findReporte[i].deprecated,
          }
        );
      }
      for(var i = 0; i < this.constancias.length;i++){
        this.traerConcesion(i);
        this.traerConductor(i);
        this.traerModalidad(i);
      }
    }else{
      var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;  Sin registros de constancias   </div></span>';
      M.toast({ html: toastHTML });
      this.loading = false;
    }
  }

    traerConcesion(indice){
      this.apollo.use('backConsulta')
        .watchQuery({
          query: B.GET_CONCESION,
          variables: {
            id: this.constancias[indice].concession_id,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.asignarConcesion(result.data,indice)
        }, (error) => {
          M.toast({ html: error })
        });
    }

    asignarConcesion(data,indice){
      this.constancias[indice]['concesion'] = data.concession;
      this.detectardatoscompletos();

    }

    traerConductor(indice){
      this.apollo.use('backConductoressinToken').watchQuery({
        query: B.GET_PERSON,
        variables: {
          id: this.constancias[indice].person_id,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarConductor(result.data,indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });
    }

    asignarConductor(data,indice){
      this.constancias[indice]['conductor'] = data.person;
      this.detectardatoscompletos();

    }


    traerModalidad(indice) {
      this.apollo.use('backConsulta')
        .watchQuery({
          query: B.GET_MODALIDAD,
          variables: {
            id: this.constancias[indice].modality_id,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.asignarModalidad(result.data,indice)
        }, (error) => {
          M.toast({ html: error })
        });
    }

    asignarModalidad(data,indice){
      this.constancias[indice]['modalidad'] = data.modality;
      this.detectardatoscompletos();

    }

    detectardatoscompletos(){

      let validacion = this.constancias.every(elem => elem.modalidad != undefined && elem.conductor != undefined && elem.concesion != undefined);
      if(validacion){
        this.loading = false;
        let array = [];
        for(var i = 0; i < this.constancias.length;i++){
          let sitio = this.constancias[i].concesion.site.name;
          let nombreConcesionario = '';
          if (this.constancias[i].concesion.concessionaire.business_name == '') {
            let nombre = '';
            let primerApellido = '';
            let segundoApellido = '';
            if (this.constancias[i].concesion.concessionaire.name != undefined && this.constancias[i].concesion.concessionaire.name != '') {
              nombre = this.constancias[i].concesion.concessionaire.name;
            }
            if (this.constancias[i].concesion.concessionaire.first_surname != undefined && this.constancias[i].concesion.concessionaire.first_surname != '') {
              primerApellido = ' ' + this.constancias[i].concesion.concessionaire.first_surname;
            }
            if (this.constancias[i].concesion.concessionaire.second_surname != undefined && this.constancias[i].concesion.concessionaire.second_surname != '') {
              segundoApellido = ' ' + this.constancias[i].concesion.concessionaire.second_surname;
            }
            nombreConcesionario = nombre + primerApellido + segundoApellido;
          } else {
            nombreConcesionario = this.constancias[i].concesion.concessionaire.business_name;
          }


          var nombreConductor = this.constancias[i].conductor.identity.name;
          var primer_apellido = this.constancias[i].conductor.identity.first_surname;
          var segundo_apellido = '';
          if (this.constancias[i].conductor.identity.second_surname != undefined || this.constancias[i].conductor.identity.second_surname != null) {
            var segundo_apellido = ' ' + this.constancias[i].conductor.identity.second_surname;
          }

          let nombreCompleto = nombreConductor + ' ' + primer_apellido + segundo_apellido;
          array.push(
            {
              sitio: sitio,
              nuc: this.constancias[i].concesion.consecutive_number + '/' + this.constancias[i].concesion.unique_number_key,
              concesionario: nombreConcesionario,
              placas: this.constancias[i].plate.folio,
              folio: this.constancias[i].foliated_sheet.folio,
              nombre_conductor: nombreCompleto,
              modalidad: this.constancias[i].modalidad.name,
              fecha_captura: this.constancias[i].expedition_date,
              vigencia: this.constancias[i].expiration_date
            }
          );
        }
        this.creadorReportePDFService.crearReporte(array,this.reporteSeleccionado.report_date,this.reporteSeleccionado.sitio.name);
      }


    }




}
