import gql from 'graphql-tag';



const GET_LICENSE_X_LICENCIA = gql`
query findIncrementalNoLicenciaTipoC($no_licencia: String!){
findIncrementalNoLicenciaTipoC(no_licencia:$no_licencia){
   tramite_id
  conductor{
    conductor_id
    nombre
    paterno
    materno
    curp
    suspendido
  }
  licencia{
    licencia_id
    tipo_licencia{
      tipo_licencia_id
      tipo
    }
    vigencia_id
    no_licencia
    fecha_antiguedad
    fecha_expedicion
    fecha_vencimiento
  }
}
}
`;




const GET_LICENSE = gql`
query getLicenseByNumber($numero: String!) {
	licenseByNumber(numero: $numero) {
		id
		contribuyente {
			nombre
			primer_apellido
			segundo_apellido
			curp
			ubicacion {
				calle
				numero_exterior
				numero_interior
				codigo_postal
				asentamiento
				colonia
				municipio
				estado
			}
		}
		tipo
		vigencia
		fecha_captura
		fecha_impresion
		fecha_vencimiento
		fecha_antiguedad
	}
}
`;


const GET_LICENSEBYCURP = gql`
query getLicenseByCurp($curp: String!) {
	licenseByCurp(curp: $curp) {
		id
		contribuyente {
			nombre
			primer_apellido
			segundo_apellido
			curp
			ubicacion {
				calle
				numero_exterior
				numero_interior
				codigo_postal
				asentamiento
				colonia
				municipio
				estado
			}
		}
		tipo
		vigencia
		fecha_captura
		fecha_impresion
		fecha_vencimiento
		fecha_antiguedad
	}
}
`;


const GET_DOMICILIO_X_LICENCIA = gql`
query domicilioByIdLicencia(
	$licencia_id: String!
) {
domicilioByIdLicencia(
	licencia_id: $licencia_id
) {
		calle
		numero_exterior
		numero_interior
		codigo_postal
		asentamiento
		colonia
		municipio
		estado
}
}
`;


const GET_LICENSENOMBRE = gql`
query getLicenseByName(
	$nombre: String!
	$primer_apellido: String
	$segundo_apellido: String
) {
licenseByName(
	nombre: $nombre
	primer_apellido: $primer_apellido
	segundo_apellido: $segundo_apellido
) {
	id
	contribuyente {
	nombre
	primer_apellido
	segundo_apellido
	curp
	ubicacion {
		calle
		numero_exterior
		numero_interior
		codigo_postal
		asentamiento
		colonia
		municipio
		estado
	}
	}
	tipo
	vigencia
	fecha_captura
	fecha_impresion
	fecha_vencimiento
	fecha_antiguedad
	}
}
`;


const GET_IMAGELICENSE_LATIN = gql`
query getImageLicense($id: ID!) {
  imageLicense(id: $id)
}

`;

const GET_IMAGELICENSE = gql`
query getImageLicense($id: String!) {
  imageLicense(id: $id)
}
`;


const GET_PLACAS = gql`
query getPlatesByInputConcession(
  $input: String!
  $concession_id: ID!
  $limit: Int
) {
  platesLikeByConcession(
    input: $input
    concession_id: $concession_id
    limit: $limit
  ) {
    id
    folio
    status
    created_at
  }
}
`;


export { GET_IMAGELICENSE_LATIN,GET_DOMICILIO_X_LICENCIA,GET_LICENSE_X_LICENCIA,GET_LICENSE,GET_IMAGELICENSE,GET_PLACAS,GET_LICENSENOMBRE,GET_LICENSEBYCURP };
