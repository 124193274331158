import { Component, ComponentRef, OnInit, ViewContainerRef, Output, EventEmitter, ViewChild, ChangeDetectorRef, ComponentFactoryResolver, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Concesionario } from '../../models/concesionario';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { UpdateConcesionarioGQL } from '../../graphqlconsultas/graphqlupdateconcesionario';
import { User } from "../../core/models/user.model";
import { StorageService } from "../../core/services/storage.service";
import { UserInput } from '../../models/useraux';
import { saveAs } from 'file-saver';
declare var M: any;
import { Ubicacion } from '../../models/ubicacion';
import { Conductor } from '../../models/conductor';
import { Capacitacion } from '../../models/capacitacion';
import { Asentamiento } from '../../models/asentamiento';
import { Vialidad } from '../../models/vialidad';
import { Contacto } from '../../models/contacto';
import { Sitio } from '../../models/sitio';
import kjua from "kjua";
import { IMAGE } from "../../core/key/imglogo";

import { CALIBRI } from "../../core/key/calibri";
import { CALIBRIB } from "../../core/key/calibrib";
import { UNIVIAB } from "../../core/key/univiaprob";
import { UNIVIABLACK } from "../../core/key/univiablack";
import { IMGDEFAULT } from "../../core/key/imgdefault";
import { CreadorComponentesPDFService } from '../../services/pdfmake/creacionComponentes.service';
import { CreadorComprobanteService } from '../../services/pdfmake/creacionComprobante.service';

import * as jsPDF from 'jspdf';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
type AOA = any[][];
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as A from '../../graphql/mutations';
import * as B from '../../graphql/queries';
import { CreateAsentamientoGQL } from '../../graphqlconsultas/graphqlcreateasentamiento';
import { SearchComponent } from '../../search/search.component';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { RelacionesInputValidacionesService } from '../../services/validaciones/relacioninputvalidaciones.service';
import { environment } from '../../../environments/environment';
import { DatosGenerales } from '../../components/datosGenerales/datosGenerales.component'
import { EstatusConcesion } from '../../components/estatusConcesion/estatusConcesion.component'
import { DatosUbicacion } from '../../components/datosUbicacion/datosUbicacion.component'
import { DatosTramitesComponent } from '../../components/datosTramites/datosTramites.component'
import { ConductoresTarjetaComponent } from '../../components/conductorestarjeta/conductorestarjeta.component'
import { VehiculosComponent } from '../../components/vehiculos/vehiculos.component'
import { PermisoDC } from '../../components/permisoDC/permisoDC.component'
import { Beneficiarios } from '../../components/beneficiarios/beneficiarios.component'
import { ContactosComponent } from '../../components/contactos/contactos.component'
import { Inconsistencias } from '../../components/inconsistencias/inconsistencias.component'

import html2canvas from 'html2canvas';
import { log } from 'console';



@Component({
  selector: 'app-registroreto', templateUrl: './registroreto.component.html', styleUrls: ['./registroreto.component.css']
})
export class RegistroRetoComponent implements OnInit {

  registroamostrar: any;
  mostrarbusqueda: boolean = true;
  mostrarframe: boolean = false;
  quitarbotonconsesionario: boolean = true;
  saltoconductores: boolean = false;
  saltovehiculos: boolean = false;
  saltobeneficiarios: boolean = false;
  cargacompleta: boolean = false;
  variablevalidarsinlicencia: boolean = true;
  aftervalidacionlicencias: boolean = false;
  personaencontrada: boolean = false;
  validcurpPR: boolean = false;
  fromProcedencia: any;
  objConductor: any;
  conductorencontrado: boolean = false;
  afterprevisualizacion: boolean = false;
  validacionnombres: boolean = true;

  municipio: any;
  es: any;

  vehiculos: any = [];
  tramites: any = [];
  totalRecords: any;
  listTarifas: any = [];
  listConductores: any = [];
  listConductoresTotal: any = [];
  listpermisosDC: any = [];
  beneficiariosReto: any;
  beneficiariosSicac: any;
  quejaactual: any;
  quejas: any;
  contactoConcesionario: any;
  meses: any;
  licencia: any;
  jsonobj: any;
  totalpaginas: any;
  iniciopaginationtotal: any = 1;
  loading: boolean = false;
  rfctarjetaboolean: boolean = false;
  mostrarformulario: boolean = false;
  verificarerrores: boolean = false;

  sexo: any;
  objMunicipio: any;
  objLocalidad: any;
  objVialidad: any;
  objAsentamiento: any;
  objNombreAsentamiento: any;
  validMunicipio: boolean = false;
  localidades: any;
  tiposasentamientos: any;

  objlicencia: any;

  public ubicacion: Ubicacion;

  public conductor: Conductor;
  public capacitacion: Capacitacion;
  public asentamiento: Asentamiento;
  public contacto: Contacto;
  public vialidad: Vialidad;
  contactencontrado: boolean = false;
  municipios: any;
  mostraragencia: boolean = false;
  agencias: any;
  sugerenciasMunicipios: any[];
  sugerenciasLocalidades: any[];
  sugerenciasAgencias: any[];
  sugerenciasTipoVialidades: any[];
  sugerenciasNombreVialidades: any[];
  sugerenciasTipoAsentamientos: any[];
  sugerenciasNombreAsentamientos: any[];
  public boxForm: FormGroup;
  nombresasentamientos: any;
  tiposvialidades: any;

  nombre: any;
  primerapellido: any;
  segundoapellido: any;
  objPersona: any;
  objDireccion: any;
  jsonContent: any;

  sendNombreAsentamiento: boolean = false;

  val: number;
  listContactos: any = [];
  listInconsistencias: any = [];
  user: any;
  @Output() Ostatus = new EventEmitter<any>();

  @Input() IModel: any;
  @Input() NameComponet: any;
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: false }) VCR: ViewContainerRef;
  index: number = 0;
  componentsReferences = [];

  constructor(
    private CFR: ComponentFactoryResolver,
    private cdref?: ChangeDetectorRef,
    private fb?: FormBuilder,
    private router?: Router,
    private apollo?: Apollo,
    private updateConcesionarioGQL?: UpdateConcesionarioGQL,
    private storageService?: StorageService,
    public creadorComponentesPDFService?: CreadorComponentesPDFService,
    public creadorComprobanteService?: CreadorComprobanteService,

    private createAsentamientoGQL?: CreateAsentamientoGQL,
    private searchComponent?: SearchComponent,
    private formBuilder?: FormBuilder,
    public relacionesInputValidacionesService?: RelacionesInputValidacionesService,
  ) { }

  ngOnInit() {
    this.meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    this.listConductoresTotal = [];
    this.conductor = new Conductor();
    this.ubicacion = new Ubicacion();
    this.asentamiento = new Asentamiento();
    this.vialidad = new Vialidad();
    this.contacto = new Contacto();
    this.licencia = '';
    this.es = {
      firstDayOfWeek: 1,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar'
    }
    $(document).ready(function () {
      $('.modal').modal({ dismissible: false });
    });
  }

  comprobar($event) {
    this.limpiartarjetas();
    this.ngOnInit();
    this.IModel = null;
    this.cargacompleta = false;
    this.mostrarformulario = false;
    this.aftervalidacionlicencias = false;
    this.listInconsistencias = [];
    this.boxForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createBox()])
    });
  }

  public check($event) {

    this.loading = true;

    this.registroamostrar = $event;
    console.log(this.registroamostrar);
    let obj = {};

    this.revisarInconsistencias();



  }

  revisarInconsistencias() {

    /*


    let splitobservacion = this.registroamostrar.expiration_date.split("T");
    let splitdia = splitobservacion[0].split("-");
    var d = new Date(parseInt(splitdia[0]),parseInt(splitdia[1])-1,parseInt(splitdia[2]),0,0,0,0);
    var di = new Date();
    if (di.getTime() > d.getTime()){
      this.listInconsistencias.push({informacion: 'Concesión vencida. Dia de Vencimiento ' + splitdia})

    }

    if(this.registroamostrar.agreement.substr(0,1) == 'P'){
      this.listInconsistencias.push({informacion: 'Concesión de tipo permisionario'})

    }


    //if(this.registroamostrar.concessionaire.contact.email == null || this.registroamostrar.concessionaire.contact.email == 'null' || this.registroamostrar.concessionaire.contact.email == ''){

    if(this.registroamostrar.concessionaire.contact.email == null || this.registroamostrar.concessionaire.contact.email == 'null' || this.registroamostrar.concessionaire.contact.email == ''){
      this.listInconsistencias.push({informacion: 'Correo electrónico no registrado'})
    }

    if(this.registroamostrar.concessionaire.contact.cel_number == null || this.registroamostrar.concessionaire.contact.cel_number == 'null' || this.registroamostrar.concessionaire.contact.cel_number == ''){
      this.listInconsistencias.push({informacion: 'Número teléfonico no registrado'})
    }

    if(this.registroamostrar.concessionaire.address == null || this.registroamostrar.concessionaire.address == 'null' || this.registroamostrar.concessionaire.address == ''){
      this.listInconsistencias.push({informacion: 'Dirección no registrada'})
    }

    if(this.registroamostrar.consecutive_number == '' || this.registroamostrar.unique_number_key == ''){
      this.listInconsistencias.push({informacion: 'Concesión sin NUC'})

    }
    if(this.registroamostrar.concessionaire.rfc == '' || this.registroamostrar.concessionaire.rfc == undefined){
      this.listInconsistencias.push({informacion: 'Concesión sin RFC'})

    }

    if(this.registroamostrar.attached.locked.status){
      this.listInconsistencias.push({informacion: 'Concesión bloqueada'})

    }

    let RFC_REGEXP = /^([A-Z&Ññ]{3}|[A-Z][AEIOU][A-Z]{2})\d{2}((01|03|05|07|08|10|12)(0[1-9]|[12]\d|3[01])|02(0[1-9]|[12]\d)|(04|06|09|11)(0[1-9]|[12]\d|30))([A-Z0-9]{2}[0-9A])$/;
    if(this.registroamostrar.concessionaire.person_type=='F'){
      if (!RFC_REGEXP.test(this.registroamostrar.concessionaire.rfc) || this.registroamostrar.concessionaire.rfc.length != 13) {
        this.listInconsistencias.push({informacion: 'RFC no valido'})

      }

    }

    if(this.registroamostrar.concessionaire.person_type=='M'){
      if (!RFC_REGEXP.test(this.registroamostrar.concessionaire.rfc) || this.registroamostrar.concessionaire.rfc.length != 12) {
        this.listInconsistencias.push({informacion: 'RFC no valido'})

      }

    }

    */

    this.IModel = this.registroamostrar;
    console.log(this.IModel);
    console.log(this.listInconsistencias);

    //this.crearDatosGenerales();
    this.cargarVehiculos();

  }

  crearDatosGenerales() {
    let componentFactory = this.CFR.resolveComponentFactory(DatosGenerales);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.NameComponet = 'CONCESIONARIO';
    currentComponent.index = 1;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);
    this.crearTarjetaEstatusConcesion();
  }

  trarContactoConcesionario() {
    this.apollo.use('backContactos')
      .watchQuery({
        query: B.CONTACTOSBYCONCESIONARIO,
        variables: {
          id_concesionario: this.IModel.concessionaire.id
        }
      })
      .valueChanges.subscribe(result => {
        this.asignarContactoConcesionario(result);

      }, (error) => {
      });
  }

  asignarContactoConcesionario(data) {

    if (data.data.concesionarioByIDConcesionario != null) {

      this.contactoConcesionario = data.data.concesionarioByIDConcesionario.contacto;

      this.listContactos = data.data.concesionarioByIDConcesionario.contactos;

    }
    this.cargarVehiculos();

  }

  get items(): FormArray {
    return this.boxForm.get('items') as FormArray;
  }

  cargarVehiculos() {
    this.vehiculos = [];

    this.apollo.use('backConsulta').watchQuery({
      query: B.LISTAR_VEHICULOS_CONCESION,
      variables: {
        concession_id: this.IModel.id
        //  concession_id: 'CON0000945'

      }
    })
      .valueChanges.subscribe(result => {
        this.crearregistrosVehiculos(result.data)
      });
  }


  crearregistrosVehiculos(registrosencontrados: any) {


    if (registrosencontrados.vehiclesByConcession.length > 0) {
      for (let i = 0; i < registrosencontrados.vehiclesByConcession.length; i++) {
        this.vehiculos.push({
          serie: registrosencontrados.vehiclesByConcession[i].serial_number,
          numeroEconomico: registrosencontrados.vehiclesByConcession[i].economic_number,
          motor: registrosencontrados.vehiclesByConcession[i].engine_number,
          marca: registrosencontrados.vehiclesByConcession[i].brand.name,
          tipo: registrosencontrados.vehiclesByConcession[i].vehicle_type.name,
          modelo: registrosencontrados.vehiclesByConcession[i].model,
          placa: registrosencontrados.vehiclesByConcession[i].plates[0].folio,

        });
      }
    } else {
      this.listInconsistencias.push({ informacion: 'Sin vehículos registrados' })

    }
    //  this.crearTarjetaEstatusConcesion();
    this.obtenerTotalConstancias(1);

  }

  crearTarjetaEstatusConcesion() {
    let componentFactory = this.CFR.resolveComponentFactory(EstatusConcesion);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.Vehiculos = this.vehiculos;

    componentRef.instance.NameComponet = 'DATOS GENERALES';
    currentComponent.index = 2;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);
    //saguz
    this.crearComponenteInconsistencia();
  }

  crearTarjetaUbicacion() {
    this.obtenerTotalConstancias(1);

  }


  obtenerTramites() {
    this.apollo.use('backConsulta').watchQuery({
      query: B.BUSCAR_TRAMITES,
      variables: {
        concession_id: this.IModel.id
      }
    })
      .valueChanges.subscribe(result => {
        this.crearregistrosTramites(result.data)
      }, (error) => {

        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de Trámites, favor de contactarse al departamento de Informática.    </div></span>';
        M.toast({ html: toastHTML });
        //  this.removeTramites();
      });

  }

  crearregistrosTramites(registrosencontrados: any) {

    for (let i = 0; i < registrosencontrados.transactionsByConcession.length; i++) {
      let print;
      if (registrosencontrados.transactionsByConcession[i].print != null) {
        print = {
          fechaImp: registrosencontrados.transactionsByConcession[i].print.print_date,
          estatusIMP: registrosencontrados.transactionsByConcession[i].print.status
        };
      } else {
        print = {
          fechaImp: '',
          estatusIMP: ''
        }
      }

      let modelo = '';
      let statusvehiculo = '';
      if (registrosencontrados.transactionsByConcession[i].vehicles != null) {
        if (registrosencontrados.transactionsByConcession[i].vehicles.length > 0) {
          modelo = registrosencontrados.transactionsByConcession[i].vehicles[0].model;
          statusvehiculo = registrosencontrados.transactionsByConcession[i].vehicles[0].status;

        }
      }

      this.tramites.push({
        referencia: registrosencontrados.transactionsByConcession[i].reference,
        descripcion: registrosencontrados.transactionsByConcession[i].description,
        estatusTramite: registrosencontrados.transactionsByConcession[i].status,
        fecha: registrosencontrados.transactionsByConcession[i].capture_date,
        modelo: modelo,
        estatusVehiculo: statusvehiculo,
        fechaImp: print.fechaImp,
        estatusIMP: print.estatusIMP,
      });
    }
    this.crearTarjetaTramites();


  }

  crearTarjetaTramites() {
    let componentFactory = this.CFR.resolveComponentFactory(DatosTramitesComponent);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.Tramites = this.tramites;

    componentRef.instance.NameComponet = 'TRÁMITES';
    currentComponent.index = 4;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);
    this.traerPermisosDC();
  }

  traerPermisosDC() {

    this.apollo.use('backPermisosDC')
      .watchQuery({
        query: B.GET_PERMISOS_CONCESION,
        variables: {
          concession_id: this.IModel.id
        }
      })
      .valueChanges.subscribe(result => {
        this.asignarPermisosDc(result);

      }, (error) => {
      });


    //this.obtenerTotalConstancias(1);
  }

  asignarPermisosDc(data: any) {

    this.listpermisosDC = data.data.rollByConcession;
    this.obtenerfolios();

  }

  obtenerfolios() {

    for (var i = 0; i < this.listpermisosDC.length; i++) {
      if (this.listpermisosDC[i].permiso_id.length < 6) {
        var idstring = "" + this.listpermisosDC[i].permiso_id;
        var concatenado = "";
        for (var x = idstring.length; x < 6; x++) {
          concatenado = concatenado + "0";
        }
        this.listpermisosDC[i].id = "PC" + concatenado + idstring;
      }
    }

    let componentFactory = this.CFR.resolveComponentFactory(PermisoDC);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.NameComponet = 'PERMISOS DC';
    componentRef.instance.ListPermisosDC = this.listpermisosDC;
    currentComponent.index = 5;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);
    this.obtenerTotalConstancias(1);
  }

  obtenerTotalConstancias(indice) {
    console.log(indice);

    this.apollo.use('backConstanciasSinToken')
      .watchQuery({
        query: B.GET_COUNT_CERTIFICADOS_X_CONCESION,
        variables: {
          concession_id: this.registroamostrar.id,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.asignarTotalConstancias(result.data, indice);
      }, (error) => {
        M.toast({ html: error })
      });
  }

  asignarTotalConstancias(data, indice) {
    console.log(data);

    this.totalRecords = data.certificatesCountByConcession;
    console.log(this.totalRecords);

    //this.obtenerConductores(indice);
    this.traerTodoslosConductores();

  }

  obtenerConductores(indice) {
    this.listConductores = [];
    let params = {
      concession_id: this.IModel.id,
      page_number: indice,
      limit: 10
    }


    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_CERTIFICADOS_X_CONCESION,
      variables: {
        params: params,
        mode: 'PAG-CON'
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarconductores(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }


  asignarconductores(data) {

    this.listConductores = [];
    if (data.data.certificates != null) {
      for (var i = 0; i < data.data.certificates.length; i++) {
        let indice = i;
        this.listConductores.push(
          {
            id: data.data.certificates[i].id,
            placa: data.data.certificates[i].plate.folio,
            hojafoliada: data.data.certificates[i].foliated_sheet.folio,
            //image: environment.URImage + 'image/' + data.data.certificates[i].image_name,
            expedition_date: data.data.certificates[i].expedition_date,
            expiration_date: data.data.certificates[i].expiration_date,
            is_canceled: data.data.certificates[i].is_canceled,
            is_expired: data.data.certificates[i].is_expired,
            modality_id: data.data.certificates[i].modality_id,
            id_driver: data.data.certificates[i].person_id,
            motivocancelacion: data.data.certificates[i].cancel_description

          }
        );
      }
    }

    for (var i = 0; i < this.listConductores.length; i++) {
      let indice = i;
      this.apollo.use('backConductoressinToken').watchQuery({
        query: B.GET_PERSON,
        variables: {
          id: this.listConductores[i].id_driver,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarconductortabla(result, indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });

      this.apollo.use('backConductoressinToken').watchQuery({
        query: B.GET_LICENSE_BY_PERSON,
        variables: {
          person_id: this.listConductores[i].id_driver,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarlicenciapreregistro(result, indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });

    }
    this.traerTarifas()



  }

  asignarlicenciapreregistro(data, indice) {
    if (this.listConductores[indice] != undefined) {
      this.listConductores[indice]['licencia'] = data.data.licenseByPerson;
    }

  }

  asignarconductortabla(data, indice) {
    if (this.listConductores[indice] != undefined) {
      this.listConductores[indice]['persona'] = data.data.person;
    }

  }

  revisarsisontodos() {
    let personavacia = this.listConductoresTotal.find(statu => statu.persona === undefined);
    let licenciavacia = this.listConductoresTotal.find(statu => statu.licencia === undefined);

    if (licenciavacia == undefined && personavacia == undefined) {
      this.traerVehiculos();

    }
  }

  obtenerPaginacionConductoresJSON(indiceTabla) {
    this.listConductores = [];
    let indiceinicio = 0;
    let limite = 10;
    if (indiceTabla > 1) {
      indiceinicio = ((indiceTabla - 1) * 10);
      limite = ((indiceTabla - 1) * 10) + 10;
    }
    for (var i = indiceinicio; i < limite; i++) {
      if (this.listConductoresTotal[i] != undefined) {
        this.listConductores.push(
          this.listConductoresTotal[i]
        );
      }

    }
    let componentRef = this.componentsReferences.filter(x => x.instance.NameComponet == 'CONDUCTORES')[0];
    componentRef._component.Conductores = this.listConductores;
  }

  obtenerPaginacionConductores(indiceTabla) {
    //saguz
    this.listConductores = [];
    let params = {
      concession_id: this.IModel.id,
      page_number: indiceTabla,
      limit: 10
    }


    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_CERTIFICADOS_X_CONCESION,
      variables: {
        params: params,
        mode: 'PAG-CON'
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarconductoresPagination(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });

  }


  asignarconductoresPagination(data) {

    this.listConductores = [];
    if (data.data.certificates != null) {
      for (var i = 0; i < data.data.certificates.length; i++) {
        let indice = i;


        this.listConductores.push(
          {
            id: data.data.certificates[i].id,
            placa: data.data.certificates[i].plate.folio,
            hojafoliada: data.data.certificates[i].foliated_sheet.folio,
            //image: environment.URImage + 'image/' + data.data.certificates[i].image_name,
            expedition_date: data.data.certificates[i].expedition_date,
            expiration_date: data.data.certificates[i].expiration_date,
            is_canceled: data.data.certificates[i].is_canceled,
            is_expired: data.data.certificates[i].is_expired,
            modality_id: data.data.certificates[i].modality_id,
            id_driver: data.data.certificates[i].person_id,
            motivocancelacion: data.data.certificates[i].cancel_description

          }
        );
      }
    }

    for (var i = 0; i < this.listConductores.length; i++) {
      let indice = i;
      this.apollo.use('backConductoressinToken').watchQuery({
        query: B.GET_PERSON,
        variables: {
          id: this.listConductores[i].id_driver,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarconductortabla(result, indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });

      this.apollo.use('backConductoressinToken').watchQuery({
        query: B.GET_LICENSE_BY_PERSON,
        variables: {
          person_id: this.listConductores[i].id_driver,
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.asignarlicenciapreregistro(result, indice);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
          M.toast({ html: toastHTML });
        });

    }

    let componentRef = this.componentsReferences.filter(x => x.instance.NameComponet == 'CONDUCTORES')[0];
    componentRef._component.Conductores = this.listConductores;

  }

  traerTarifas() {

  }


  asignarTarifas(data) {

    this.listTarifas = [];
    for (var i = 0; i < data.data.rollsTarjetonByConcession.length; i++) {
      this.listTarifas.push(
        {
          expedition_date: data.data.rollsTarjetonByConcession[i].expedition_date,
          foliated_sheet: data.data.rollsTarjetonByConcession[i].foliated_sheet,
          routes: data.data.rollsTarjetonByConcession[i].routes,
          id_municipio: data.data.rollsTarjetonByConcession[i].municipality_id,
          id_localidad: data.data.rollsTarjetonByConcession[i].locality_id,
          id_vehiculo: data.data.rollsTarjetonByConcession[i].vehicle_id


        }
      );
    }

    for (var i = 0; i < this.listTarifas.length; i++) {
      let indice = i;
      this.traerLocalidad(this.listTarifas[i]);
      this.traerMunicipio(this.listTarifas[i]);
      this.traerSerieVehiculo(this.listTarifas[i]);
    }


  }

  traerLocalidad(data) {
    this.apollo.use('backConsulta').watchQuery({
      query: B.GET_LOCALIDAD,
      variables: {
        id: data.id_localidad
      }
    })
      .valueChanges.subscribe(result => {
        this.asignarLocalidadTarifa(data, result.data)
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de Trámites, favor de contactarse al departamento de Informática.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarLocalidadTarifa(tarifa, data) {
    tarifa['locality'] = data.locality.name;
    this.vercompletotarifas()

  }

  traerMunicipio(data) {
    this.apollo.use('backConsulta').watchQuery({
      query: B.GET_MUNICIPIO,
      variables: {
        id: data.id_municipio
      }
    })
      .valueChanges.subscribe(result => {
        this.asignarMunicipioTarifa(data, result.data)
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de Trámites, favor de contactarse al departamento de Informática.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarMunicipioTarifa(tarifa, data) {
    tarifa['municipality'] = data.municipality.name;
    this.vercompletotarifas()

  }

  traerSerieVehiculo(data) {
    this.apollo.use('backConsulta').watchQuery({
      query: B.GET_VEHICULO,
      variables: {
        id: data.id_vehiculo
      }
    })
      .valueChanges.subscribe(result => {
        this.asignarVehiculoTarifa(data, result.data)
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de Trámites, favor de contactarse al departamento de Informática.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }

  asignarVehiculoTarifa(tarifa, data) {
    tarifa['serial_number'] = data.vehicle.serial_number;
    this.vercompletotarifas()
  }

  vercompletotarifas() {
    let validacion = this.listTarifas.every(elem => elem.serial_number != undefined && elem.municipality != undefined && elem.locality != undefined);
    if (validacion) {


    }
  }

  traerVehiculos() {
    this.obtenerDatosBebenficiarios();

  }

  obtenerDatosBebenficiarios() {
    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_BENEFICIARIOS,
      variables: {
        nuc: this.IModel.consecutive_number + '/' + this.IModel.unique_number_key
      }
    })
      .valueChanges.subscribe(result => {
        this.asignarBeneficiarios(result.data)
      }, (error) => {

        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de SIAMOV, favor de contactarse al departamento de Informática.    </div></span>';
        M.toast({ html: toastHTML });
        //this.removeTramites();
      });
  }

  asignarBeneficiarios(data) {
    console.log(data.Concesion);
    this.beneficiariosReto = data.Concesion;

    let obj = {
      concession_id: this.IModel.id
    }

    this.apollo.use('backConsulta').watchQuery({
      query: B.GET_BENEFICIARIOS_SICAC,
      variables: {
        params: obj,
        mode: "con"
      }
    })
      .valueChanges.subscribe(result => {
        this.asignarBeneficiariosSicac(result.data)
      }, (error) => {

        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al pedir los datos de SIAMOV, favor de contactarse al departamento de Informática.    </div></span>';
        M.toast({ html: toastHTML });
        //this.removeTramites();
      });

  }

  asignarBeneficiariosSicac(data) {
    this.beneficiariosSicac = data.beneficiaries;

    this.crearDatosGenerales()

    this.loading = false;
    this.cargacompleta = true;

  }

  crearComponenteInconsistencia() {
    if (this.listInconsistencias.length > 0) {

    }

    this.mostrarformulario = true;


    let obj = {};

    if (this.registroamostrar.concessionaire.person_type == 'F') {
      obj['licencia'] = ['', this.relacionesInputValidacionesService.getValidacion('licencia')];
    }

    //this.createComponent(componentFactory, 0);

    // this.trarContactoConcesionario()


    this.user = this.fb.group(obj);


  }

  traerTodoslosConductores() {
    this.totalpaginas = Math.ceil(this.totalRecords / 10);
    this.empezarapedir(this.iniciopaginationtotal);
  }



  empezarapedir(indice) {

    let params = {
      concession_id: this.IModel.id,
      page_number: indice,
      limit: 10
    }
    this.apollo.use('backConstanciasSinToken').watchQuery({
      query: B.GET_CERTIFICADOS_X_CONCESION,
      variables: {
        params: params,
        mode: 'PAG-CON'
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarconductoresTotales(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });


  }

  asignarconductoresTotales(data) {
    if (this.iniciopaginationtotal <= this.totalpaginas) {
      if (data.data.certificates != null) {
        for (var i = 0; i < data.data.certificates.length; i++) {
          let indice = i;

          if (data.data.certificates[i].is_canceled != true && data.data.certificates[i].is_expired != true) {



            this.listConductoresTotal.push(
              {
                id: data.data.certificates[i].id,
                placa: data.data.certificates[i].plate.folio,
                hojafoliada: data.data.certificates[i].foliated_sheet.folio,
                //image: environment.URImage + 'image/' + data.data.certificates[i].image_name,
                expedition_date: data.data.certificates[i].expedition_date,
                expiration_date: data.data.certificates[i].expiration_date,
                is_canceled: data.data.certificates[i].is_canceled,
                is_expired: data.data.certificates[i].is_expired,
                modality_id: data.data.certificates[i].modality_id,
                id_driver: data.data.certificates[i].person_id,
                motivocancelacion: data.data.certificates[i].cancel_description

              }
            );
          }


        }
      }

      this.iniciopaginationtotal = this.iniciopaginationtotal + 1;

      this.empezarapedir(this.iniciopaginationtotal);

    } else {
      this.traerMasDatos();
    }

  }

  traerMasDatos() {

    if (this.listConductoresTotal.length > 0) {
      for (var i = 0; i < this.listConductoresTotal.length; i++) {
        let indice = i;

        this.apollo.use('backConductoressinToken').watchQuery({
          query: B.GET_PERSON,
          variables: {
            id: this.listConductoresTotal[i].id_driver,
          }, fetchPolicy: 'network-only'
        })
          .valueChanges.subscribe(result => {
            this.asignarconductortablatotal(result, indice);
          }, (error) => {
            var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
            M.toast({ html: toastHTML });
          });

        this.apollo.use('backConductoressinToken').watchQuery({
          query: B.GET_LICENSE_BY_PERSON,
          variables: {
            person_id: this.listConductoresTotal[i].id_driver,
          }, fetchPolicy: 'network-only'
        })
          .valueChanges.subscribe(result => {
            this.asignarlicenciapreregistrototal(result, indice);
          }, (error) => {
            var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp; ' + error + '   </div></span>';
            M.toast({ html: toastHTML });
          });

      }
    } else {

      this.traerVehiculos();
    }



  }


  asignarlicenciapreregistrototal(data, indice) {
    if (this.listConductoresTotal[indice] != undefined) {
      this.listConductoresTotal[indice]['licencia'] = data.data.licenseByPerson;
    }
    this.revisarsisontodos();

  }

  asignarconductortablatotal(data, indice) {
    if (this.listConductoresTotal[indice] != undefined) {
      this.listConductoresTotal[indice]['persona'] = data.data.person;

      let nombreCompleto = data.data.person.identity.name + ' ' + data.data.person.identity.first_surname;
      if (data.data.person.identity.second_surname != undefined) {
        nombreCompleto = nombreCompleto + ' ' + data.data.person.identity.second_surname;
      }
      this.listConductoresTotal[indice]['persona']['nombre'] = nombreCompleto;

    }



    this.revisarsisontodos();

  }

  crearRegistro() {

    console.log('Entra aqui');

    this.apollo.use('registroReto')
      .mutate({
        mutation: A.CREATE_REGISTRO,
        variables: {
          id_concesion: this.registroamostrar.id,
          id_concesionario: this.registroamostrar.concessionaire.id,
          id_modalidad: this.registroamostrar.modality.id,
          tipo_persona: this.registroamostrar.concessionaire.person_type
        }
      }).subscribe(result => {
        M.toast({ html: 'Registro agregado con exito' });
        this.crearObservacion(result);

      }, (error) => {
      });



  }

  crearObservacion(registro) {

    let obj = {
      concesionario: this.IModel,
      contactos: this.contactoConcesionario,
      vehiculos: this.vehiculos,
      conductores: this.listConductoresTotal,
      inconsistencias: this.listInconsistencias,
      saltos: {
        saltoconductores: this.saltoconductores,
        saltovehiculos: this.saltovehiculos,
        saltobeneficiarios: this.saltobeneficiarios
      }
    }
    //saguz

    this.apollo.use('registroReto')
      .mutate({
        mutation: A.CREATE_OBSERVACION,
        variables: {
          informacion: obj,
        }
      }).subscribe(result => {
        this.asignarObservacionRegitro(registro, result.data);
      }, (error) => {
      });

  }

  asignarObservacionRegitro(registro, observacion) {
    this.apollo.use('registroReto')
      .mutate({
        mutation: A.ASIGNAR_OBSERVACION_REGISTRO,
        variables: {
          id: registro.data.registro_reto.id,
          id_observacion: observacion.observacion.id,
        }
      }).subscribe(result => {
        this.reedirecionar(registro);
        this.generaformatoconmakepdf();
      }, (error) => {
      });

  }

  generaformatoconmakepdf() {


    let diaActual = new Date();
    let fecha = diaActual.getDate() + ' de ' + this.meses[diaActual.getMonth()] + ' del ' + diaActual.getFullYear()
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    }
    let content_array = [];
    content_array.push(this.creadorComponentesPDFService.getDatosGenerales(this.IModel, this.contactoConcesionario));

    content_array.push(this.creadorComponentesPDFService.getDatosDeLaConcesion(this.IModel, this.vehiculos.length));

    content_array.push(this.creadorComponentesPDFService.getDatosDeUbicacion(this.IModel, this.contactoConcesionario));

    //content_array.push(this.creadorComponentesPDFService.getTramites(this.tramites));

    //content_array.push(this.creadorComponentesPDFService.getDatosPermisosDC(this.listpermisosDC));
    if (this.saltoconductores) {
      content_array.push({ text: '', pageBreak: 'after' });
    }
    content_array.push(this.creadorComponentesPDFService.getConductores(this.listConductoresTotal));

    if (this.saltovehiculos) {
      content_array.push({ text: '', pageBreak: 'after' });
    }
    content_array.push(this.creadorComponentesPDFService.getDatosVehiculos(this.vehiculos));
    if (this.saltobeneficiarios) {
      content_array.push({ text: '', pageBreak: 'after' });

    }
    if (this.listInconsistencias.length > 0) {
      content_array.push(this.creadorComponentesPDFService.getDatosInconsistencias(this.listInconsistencias));


    }

    var dd = {
      pageSize: 'LETTER',
      pageMargins: [30, 110, 30, 60],
      background: {
        columns: [
          { width: 300, text: '' },
          { image: 'data:image/jpeg;base64,' + IMAGE.IMAGE_B, width: 256, height: 60, margin: [0, 20, 0, 60] },
        ]
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: '--------------------------------------------------------------------------' +
                    '\n',
                  margin: [0, 25]
                },
                {
                  text: currentPage.toString() + ' de ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      header: function (page) {
        if (page != 1)
          return {
            margin: 10,
            columns: [
              {
                margin: [40, 10, 0, 0],
                text: ['Secretaría de Movilidad \n Gobierno del Estado de Oaxaca \n', { text: "Unidad de Informática", fontSize: 12, bold: true }, '', { text: '\nSan Antonio de la Cal, Oax., a ' + fecha, fontSize: 8 }], fontSize: 14, bold: true,

              },
            ]
          }
        else
          return {
            margin: 10,
            columns: [
              {
                margin: [40, 10, 0, 0],
                text: ['Secretaría de Movilidad \n Gobierno del Estado de Oaxaca \n', { text: "Unidad de Informática", fontSize: 12, bold: true }, '', { text: '\nSan Antonio de la Cal, Oax., a ' + fecha, fontSize: 8 }], fontSize: 14, bold: true,

              },
              {
                columns: [
                  {
                    margin: [-265, 76, 0, 0],
                    width: '100%', text: 'INFORMACIÓN GENERAL DEL CONCESIONARIO', fontSize: 13, bold: true, alignment: 'center'
                  }
                ]
              },
            ]
          }
      },
      content: content_array
    };
    pdfMake.createPdf(dd).download('Tarjeta Informativa.pdf');


    if (this.listInconsistencias.length == 0) {
      this.checkFinalizacion();

    }




  }

  checkFinalizacion() {
    this.creadorComprobanteService.crearComprobante(this.registroamostrar);
  }

  obtenerimagen() {
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    }
    const ref = document.getElementById('divBody');
    html2canvas(ref, {
      allowTaint: true
    }).then(function (canvas) {
      const dataURL = canvas.toDataURL();
      const docDefinition = {
        pageSize: {
          width: 595.28,
          height: 1000
        },
        pageMargins: [85, 90, 70, 140],
        content: [
          {
            image: dataURL,
            fit: [400, 1200],
          }
        ]
      };
      pdfMake.createPdf(docDefinition).download('fileName.pdf');
    }).catch(function (error) {
      console.error(error);
    });
  }

  creadorjson() {
    //saguz
    let objjson = {};
    objjson['Concesion'] = this.IModel;
    objjson['Vehiculos'] = this.vehiculos;
    objjson['Conductores'] = this.listConductoresTotal;
    objjson['Beneficiarios'] = {
      reto: this.beneficiariosReto,
      sicac: this.beneficiariosSicac
    }
    let saltosobj = {};
    saltosobj['conductores'] = this.saltoconductores
    saltosobj['vehiculos'] = this.saltovehiculos;
    saltosobj['beneficiarios'] = this.saltobeneficiarios;
    objjson['Saltos'] = saltosobj;



    this.jsonobj = objjson;
  }

  reconstruirTarjetasConJson() {

    this.IModel = this.jsonobj.Concesion;
    let componentFactory = this.CFR.resolveComponentFactory(DatosGenerales);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.NameComponet = 'CONCESIONARIO';
    currentComponent.index = 1;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);

    this.vehiculos = this.jsonobj.Vehiculos;
    this.saltoconductores = this.jsonobj.Saltos.conductores;
    this.saltovehiculos = this.jsonobj.Saltos.vehiculos;
    this.saltobeneficiarios = this.jsonobj.Saltos.beneficiarios;
    this.crearTarjetaestatusJSON();


  }

  crearTarjetaestatusJSON() {

    let componentFactory = this.CFR.resolveComponentFactory(EstatusConcesion);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.Vehiculos = this.vehiculos;

    componentRef.instance.NameComponet = 'DATOS GENERALES';
    currentComponent.index = 2;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);

    this.creartarjetaubicacionJSON();

  }

  creartarjetaubicacionJSON() {

    let componentFactory = this.CFR.resolveComponentFactory(DatosUbicacion);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.NameComponet = 'ÚBICACION';
    currentComponent.index = 3;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);
    this.listConductores = [];
    this.listConductoresTotal = this.jsonobj.Conductores;

    this.reconstruccionConductores()
  }

  reconstruccionConductores() {
    let componentFactory = this.CFR.resolveComponentFactory(ConductoresTarjetaComponent);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.Conductores = this.listConductoresTotal;
    componentRef.instance.TotalRecords = this.listConductoresTotal.length;
    componentRef.instance.Ostatus.subscribe(val => this.obtenerPaginacionConductoresJSON(val));
    componentRef.instance.NameComponet = 'CONDUCTORES';
    currentComponent.index = 6;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);

    this.reconstruirVehiculos();

  }

  reconstruirVehiculos() {
    let componentFactory = this.CFR.resolveComponentFactory(VehiculosComponent);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.Vehiculos = this.vehiculos;

    componentRef.instance.NameComponet = 'VEHÍCULOS';
    currentComponent.index = 7;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);

    this.beneficiariosReto = this.jsonobj.Beneficiarios.reto;
    this.beneficiariosSicac = this.jsonobj.Beneficiarios.sicac;

    this.reconstruirBeneficiarios()

  }

  reconstruirBeneficiarios() {
    let componentFactory = this.CFR.resolveComponentFactory(Beneficiarios);
    let componentRef: ComponentRef<any> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.selfRef = currentComponent;
    componentRef.instance.IModel = this.IModel;
    componentRef.instance.BeneficiariosReto = this.beneficiariosReto;
    componentRef.instance.BeneficiariosSICAC = this.beneficiariosSicac;
    componentRef.instance.NameComponet = 'Beneficiarios';
    currentComponent.index = 8;
    currentComponent.compInteraction = this;
    this.componentsReferences.push(componentRef);


  }



  limpiartarjetas() {
    let items = this.componentsReferences;

    for (var i = 0; i < items.length; i++) {
      this.remove(i, items[i]._component.NameComponet);

    }

  }

  remove(index: number, nameComponet: any) {
    if (this.VCR.length < 1)
      return;
    let componentRef = this.componentsReferences.filter(x => x.instance.NameComponet == nameComponet)[0];
    let component: any = <any>componentRef.instance;
    let vcrIndex: number = this.VCR.indexOf(componentRef);
    this.VCR.remove(vcrIndex);
    this.componentsReferences = this.componentsReferences.filter(x => x.instance.NameComponet !== nameComponet);

  }

  verprevisualizacion() {



    $('#previsualizacion').modal('open');
    this.mostrarframe = false;
    let diaActual = new Date();
    let fecha = diaActual.getDate() + ' de ' + this.meses[diaActual.getMonth()] + ' del ' + diaActual.getFullYear()
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    }
    let content_array = [];
    content_array.push(this.creadorComponentesPDFService.getDatosGenerales(this.IModel, this.contactoConcesionario));

    content_array.push(this.creadorComponentesPDFService.getDatosDeLaConcesion(this.IModel, this.vehiculos.length));

    content_array.push(this.creadorComponentesPDFService.getDatosDeUbicacion(this.IModel, this.contactoConcesionario));

    //content_array.push(this.creadorComponentesPDFService.getTramites(this.tramites));

    //content_array.push(this.creadorComponentesPDFService.getDatosPermisosDC(this.listpermisosDC));

    if (this.saltoconductores) {
      content_array.push({ text: '', pageBreak: 'after' });
    }

    content_array.push(this.creadorComponentesPDFService.getConductores(this.listConductoresTotal));

    if (this.saltovehiculos) {
      content_array.push({ text: '', pageBreak: 'after' });
    }


    content_array.push(this.creadorComponentesPDFService.getDatosVehiculos(this.vehiculos));
    if (this.saltobeneficiarios) {
      content_array.push({ text: '', pageBreak: 'after' });

    }
    //   content_array.push(this.creadorComponentesPDFService.getDatosBeneficiarios(this.beneficiariosSicac, this.beneficiariosReto));

    if (this.listInconsistencias.length > 0) {
      content_array.push(this.creadorComponentesPDFService.getDatosInconsistencias(this.listInconsistencias));


    }


    var dd = {
      pageSize: 'LETTER',
      pageMargins: [30, 110, 30, 60],
      background: {
        columns: [
          { width: 300, text: '' },
          { image: 'data:image/jpeg;base64,' + IMAGE.IMAGE_B, width: 256, height: 60, margin: [0, 20, 0, 60] },
        ]
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: '--------------------------------------------------------------------------' +
                    '\n',
                  margin: [0, 25]
                },
                {
                  text: currentPage.toString() + ' de ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      header: function (page) {
        if (page != 1)
          return {
            margin: 10,
            columns: [
              {
                margin: [40, 10, 0, 0],
                text: ['Secretaría de Movilidad \n Gobierno del Estado de Oaxaca \n', { text: "Unidad de Informática", fontSize: 12, bold: true }, '', { text: '\nSan Antonio de la Cal, Oax., a ' + fecha, fontSize: 8 }], fontSize: 14, bold: true,

              },
            ]
          }
        else
          return {
            margin: 10,
            columns: [
              {
                margin: [40, 10, 0, 0],
                text: ['Secretaría de Movilidad \n Gobierno del Estado de Oaxaca \n', { text: "Unidad de Informática", fontSize: 12, bold: true }, '', { text: '\nSan Antonio de la Cal, Oax., a ' + fecha, fontSize: 8 }], fontSize: 14, bold: true,

              },
              {
                columns: [
                  {
                    margin: [-265, 76, 0, 0],
                    width: '100%', text: 'INFORMACIÓN GENERAL DEL CONCESIONARIO', fontSize: 13, bold: true, alignment: 'center'
                  }
                ]
              },
            ]
          }
      },
      content: content_array
    };
    this.jsonContent = content_array;

    this.mostrarframe = true;
    setTimeout(() => {
      var doc = pdfMake.createPdf(dd);
      var f = document.getElementById('frameprevisualizacion');
      var callback = function (url) { f.setAttribute('src', url); }
      doc.getDataUrl(callback, doc);
    }, 2);

  }

  volverapintar() { }

  registroDatos() {
  }

  validarLicencia() {
    if (this.user['controls'].licencia.value.length == 8) {

      this.apollo.use('backLicencias').watchQuery({
        query: B.GET_LICENSE,
        variables: {
          numero: this.user['controls'].licencia.value
        }, fetchPolicy: 'network-only'
      })
        .valueChanges.subscribe(result => {
          this.revisarLicencia(result);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
          M.toast({ html: toastHTML });
        });
    }

  }

  revisarLicencia(data) {
    this.user['controls'].licencia.validacion = false;

    let pos = this.listInconsistencias.map(function (e) { return e.informacion; }).indexOf('Licencia vencida en el tiempo aproximado del registro');
    if (pos > -1) {
      this.listInconsistencias.splice(pos, 1);
    }

    let pos2 = this.listInconsistencias.map(function (e) { return e.informacion; }).indexOf('Licencia no valida');
    if (pos2 > -1) {
      this.listInconsistencias.splice(pos2, 1);
    }
    if (data.data.licenseByNumber != undefined && data.data.licenseByNumber != null) {
      var arrayDeCadenas = data.data.licenseByNumber.fecha_vencimiento.split('T');
      var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
      let fechaVencimientoLicencia = new Date(parseInt(arrayDeCadenas2[0]), parseInt(arrayDeCadenas2[1]) - 1, parseInt(arrayDeCadenas2[2]));
      let fechanow = new Date();
      fechanow.setDate(fechanow.getDate() + 15);

      if (fechaVencimientoLicencia.getTime() <= fechanow.getTime()) {
        let yaexiste1 = this.listInconsistencias.filter(x => x.informacion == 'Licencia vencida en el tiempo aproximado del registro')[0];
        if (yaexiste1 == undefined) {
          this.listInconsistencias.push({ informacion: 'Licencia vencida' })

        }

      } else {
        this.user['controls'].licencia.validacion = true;
      }
    } else {
      let yaexiste2 = this.listInconsistencias.filter(x => x.informacion == 'Licencia no valida')[0];
      if (yaexiste2 == undefined) {
        this.listInconsistencias.push({ informacion: 'Licencia no valida' })

      }

    }



    setTimeout(() => {
      if (this.listInconsistencias.length > 0) {

        let componentRef = this.componentsReferences.filter(x => x.instance.NameComponet == 'Inconsistencias')[0];

        if (componentRef != undefined) {
          componentRef._component.Conductores = this.listConductores;
        } else {

        }


      } else {
        let items = this.componentsReferences;

        for (var i = 0; i < items.length; i++) {
          if (items[i]._component.NameComponet == 'Inconsistencias') {
            this.remove(i, items[i]._component.NameComponet);

          }

        }
      }
    }, 100);




  }

  regresar() {
    this.Ostatus.emit(true);

  }

  salir() {
    $('.modal').modal('close');

  }

  regresarinbox() {
    this.router.navigate(["/inbox"]);

  }

  cambioserie() {

    this.aftervalidacionlicencias = false;

    var licenciainput = (<HTMLInputElement>document.getElementById('licencia')).value;
    this.licencia = licenciainput;

  }

  cambiarestadolicencia() {
    console.log("licencia");
    console.log(this.licencia);
    
    
    this.loading = true;
    this.apollo.use('backLicencias').watchQuery({
      query: B.GET_LICENSE_X_LICENCIA,
      variables: {
        no_licencia: this.licencia
      }, fetchPolicy: 'network-only'
    })
      .valueChanges.subscribe(result => {
        this.asignarLicencia(result);
      }, (error) => {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
        M.toast({ html: toastHTML });
      });
  }


  asignarLicencia(data: any) {
    this.afterprevisualizacion = true;
    this.loading = false;
    this.conductor.curp = null;

    if (data.data.findIncrementalNoLicenciaTipoC.length > 0) {
      if (data.data.findIncrementalNoLicenciaTipoC[0].conductor.suspendido == 0) {

        this.boxForm = this.formBuilder.group({
          items: this.formBuilder.array([this.createBox()])
        });

        this.objlicencia = data.data.findIncrementalNoLicenciaTipoC[0];
        this.aftervalidacionlicencias = true;
        this.curpgenero(this.objlicencia.conductor.curp);
        this.conductor.nombre = this.objlicencia.conductor.nombre;
        let auxiliar = '';
        if (this.isEmpty(this.objlicencia.conductor.paterno)) {
          this.conductor.primer_apellido = this.objlicencia.conductor.materno;
          this.conductor.segundo_apellido = '';
        } else {
          this.conductor.primer_apellido = this.objlicencia.conductor.paterno;
          this.conductor.segundo_apellido = this.objlicencia.conductor.materno;
        }
        let nombreaux = this.objlicencia.conductor.nombre;
        let primerapellido = this.objlicencia.conductor.primer_apellido;
        let segundoapeliido = this.objlicencia.conductor.segundo_apellido;
        this.conductor.curp = this.objlicencia.conductor.curp;
        this.conductor.fecha_nacimiento = this.curp2date(this.conductor.curp);

        this.curpgenero(this.conductor.curp);
        var regex = "^[A-Z][AEIOUX][A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|B[CS]|C[CHLMS]|D[FG]|G[RT]|HG|JC|M[CNS]|N[LT]|OC|PL|Q[RT]|S[LPR]|T[CLS]|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z][0-9]$";
        let regexp = new RegExp(regex);
        var inputlocalidad = '' + this.conductor.curp.toUpperCase() + '';
        var arrayDeCadenas = this.objlicencia.licencia.fecha_vencimiento.split('T');
        var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
        let fechaVencimientoLicencia = new Date(parseInt(arrayDeCadenas2[0]), parseInt(arrayDeCadenas2[1]) - 1, parseInt(arrayDeCadenas2[2]));
        let fechanow = new Date();
        if (fechaVencimientoLicencia.getTime() >= fechanow.getTime()) {
          if (regexp.test(this.conductor.curp.toUpperCase())) {
            setTimeout(() => {
              this.traermeeldomicilio();
            }, 100);
          } else {
            M.toast({ html: 'Error en la CURP, favor de contactar al departamento de Licencias.' });
            this.loading = false;
          }
        } else {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia vencida, fecha de vencimiento ' + fechaVencimientoLicencia.getDate() + '/' + this.meses[fechaVencimientoLicencia.getMonth()] + '/' + fechaVencimientoLicencia.getFullYear() + ' </div></span>';
          M.toast({ html: toastHTML });
        }

      } else {
        //  this.buscarconductorparabloquearlo();

      }


    } else {
      var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia no valida    </div></span>';
      M.toast({ html: toastHTML });
      this.loading = false;
      this.licencia = "";
    }

    /*

     if (data.data.licenseByNumber != null) {

      this.boxForm = this.formBuilder.group({
        items: this.formBuilder.array([this.createBox()])
      });

      this.objlicencia = data.data.licenseByNumber;
      this.aftervalidacionlicencias = true;
      this.curpgenero(this.objlicencia.contribuyente.curp);
      this.conductor.nombre = this.objlicencia.contribuyente.nombre;
      let auxiliar = '';
      if (this.isEmpty(this.objlicencia.contribuyente.primer_apellido)) {
        this.conductor.primer_apellido = this.objlicencia.contribuyente.segundo_apellido;
        this.conductor.segundo_apellido = '';
      } else {
        this.conductor.primer_apellido = this.objlicencia.contribuyente.primer_apellido;
        this.conductor.segundo_apellido = this.objlicencia.contribuyente.segundo_apellido;
      }
      let nombreaux = this.objlicencia.contribuyente.nombre;
      let primerapellido = this.objlicencia.contribuyente.primer_apellido;
      let segundoapeliido = this.objlicencia.contribuyente.segundo_apellido;
      this.conductor.curp = this.objlicencia.contribuyente.curp;
      this.municipio = this.reemplazarAcentos(this.objlicencia.contribuyente.ubicacion.municipio);
      this.vialidad.nombre = this.objlicencia.contribuyente.ubicacion.calle;
      this.vialidad.num_interior = this.objlicencia.contribuyente.ubicacion.numero_interior;
      this.vialidad.num_exterior = this.objlicencia.contribuyente.ubicacion.numero_exterior;
      this.asentamiento.nombre = this.objlicencia.contribuyente.ubicacion.colonia;
      this.asentamiento.tipo = this.objlicencia.contribuyente.ubicacion.asentamiento;
      this.ubicacion.codigo_postal = this.objlicencia.contribuyente.ubicacion.codigo_postal;
      this.conductor.fecha_nacimiento = this.curp2date(this.conductor.curp);
      var regex = "^[A-Z][AEIOUX][A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|B[CS]|C[CHLMS]|D[FG]|G[RT]|HG|JC|M[CNS]|N[LT]|OC|PL|Q[RT]|S[LPR]|T[CLS]|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z][0-9]$";
      let regexp = new RegExp(regex);
      var inputlocalidad = '' + this.objlicencia.contribuyente.curp.toUpperCase() + '';
      if (regexp.test(this.objlicencia.contribuyente.curp.toUpperCase())) {
        this.validcurpPR = false;
      } else {
        this.validcurpPR = true;
        M.toast({ html: 'Error en la CURP, favor de contactar al departamento de Licencias.' });
      }
        var arrayDeCadenas = data.data.licenseByNumber.fecha_vencimiento.split('T');
      var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
      let fechaVencimientoLicencia = new Date(parseInt(arrayDeCadenas2[0]), parseInt(arrayDeCadenas2[1]) - 1, parseInt(arrayDeCadenas2[2]));
      let fechanow = new Date();
      if (fechaVencimientoLicencia.getTime() >= fechanow.getTime()) {
        this.buscarConductor();
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia valida</div></span>';
        M.toast({ html: toastHTML });
      } else {
        var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia vencida, fecha de vencimiento ' + fechaVencimientoLicencia.getDate() + '/' + this.meses[fechaVencimientoLicencia.getMonth()] + '/' + fechaVencimientoLicencia.getFullYear() + ' </div></span>';
        M.toast({ html: toastHTML });
        this.aftervalidacionlicencias = false;
      }
    } else {
      var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia no valida    </div></span>';
      M.toast({ html: toastHTML });
      this.aftervalidacionlicencias = false;
    }

    */
  }

  traermeeldomicilio() {
    this.apollo.use('backLicencias').watchQuery({
      query: B.GET_DOMICILIO_X_LICENCIA,
      variables: {
        licencia_id: this.objlicencia.licencia.licencia_id.toLowerCase()
      }
    })
      .valueChanges.subscribe(result => {
        this.asignardatosdomicilio(result);
      }, (error) => {
        M.toast({ html: 'Error en la obtención de datos del domicilio de la Licencia.' });
        this.loading = false;
      })
  }

  asignardatosdomicilio(data) {
    this.objlicencia['ubicacion'] = data.data.domicilioByIdLicencia;
    this.municipio = this.reemplazarAcentos(data.data.domicilioByIdLicencia.municipio);
    this.vialidad.nombre = data.data.domicilioByIdLicencia.calle;
    this.vialidad.num_interior = data.data.domicilioByIdLicencia.numero_interior;
    this.vialidad.num_exterior = data.data.domicilioByIdLicencia.numero_exterior;
    this.asentamiento.nombre = data.data.domicilioByIdLicencia.colonia;
    this.asentamiento.tipo = data.data.domicilioByIdLicencia.asentamiento;
    this.ubicacion.codigo_postal = data.data.domicilioByIdLicencia.codigo_postal;
    this.asignarDatosDesdeLicencias();
    this.buscarConductor();

    //  this.traerPlacasXConcesion();
    //  this.traemelaImg();
  }




  buscarConductor() {
    this.loading = true;
    setTimeout(() => {
      this.apollo.use('backConductoressinToken').watchQuery({
        query: B.SEARCH_PERSON_BY_CURP,
        variables: {
          curp: this.conductor.curp
        }, fetchPolicy: 'no-cache'
      })
        .valueChanges.subscribe(result => {
          this.asignarConductor(result);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
          M.toast({ html: toastHTML });
        });
    }, 100);

  }


  asignarConductor(data) {
    let nombre;
    let primerapellido;
    let segundoapellido;
    this.fromProcedencia = '';
    this.loading = false;

    this.objConductor = data.data.personByCurp;
    //this.asignarDatosDesdeLicencias();




    if (data.data.personByCurp != null) {
      this.conductorencontrado = true;
      this.afterprevisualizacion = false;
      //  this.crearRegistroReto();
      this.revisionnombrescurp();

    } else {

      this.asignarDatosDesdeLicencias();

    }

  }

  createBox(): FormGroup {

    return this.formBuilder.group({
      nombre: ['', this.relacionesInputValidacionesService.getValidacion('nombre')],
      primerapellido: ['', this.relacionesInputValidacionesService.getValidacion('primerapellido')],
      segundoapellido: ['', []],
      curp: ['', this.relacionesInputValidacionesService.getValidacion('curp')],
      fechanacimiento: ['', this.relacionesInputValidacionesService.getValidacion('fechanacimiento')],
      telefono: ['', this.relacionesInputValidacionesService.getValidacion('telefono')],
      correo: ['', []],
      municipio: ['', this.relacionesInputValidacionesService.getValidacionComprobacion('municipio')],
      localidad: [{ value: '', disabled: true }, this.relacionesInputValidacionesService.getValidacionComprobacion('localidad')],
      agencia: ['', []],
      checked2: [false, []],
      tipoVialidad: ['', this.relacionesInputValidacionesService.getValidacionComprobacion('tipoVialidad')],
      nombreVialidad: ['', this.relacionesInputValidacionesService.getValidacion('nombreVialidad')],
      numeroInterior: ['', this.relacionesInputValidacionesService.getValidacion('numeroInterior')],
      numeroExterior: ['', this.relacionesInputValidacionesService.getValidacion('numeroExterior')],
      tipoAsentamiento: ['', this.relacionesInputValidacionesService.getValidacionComprobacion('tipoAsentamiento')],
      nombreAsentamiento: [{ value: ' ', disabled: true }, this.relacionesInputValidacionesService.getValidacion('nombreAsentamiento')],
      codigoPostal: ['', this.relacionesInputValidacionesService.getValidacion('codigoPostal')],

    });

  }

  asignarDatosDesdeLicencias() {

    let nombreaux = this.objlicencia.conductor.nombre;
    let primerapellido = this.objlicencia.conductor.paterno;
    let segundoapeliido = this.objlicencia.conductor.materno;
    if (this.objlicencia.conductor.materno == '') {
      this.nombre = nombreaux.trim().toUpperCase();
      if (segundoapeliido != '') {
        this.primerapellido = segundoapeliido.trim().toUpperCase();

      } else {
        this.primerapellido = primerapellido.trim().toUpperCase();

      }
      this.segundoapellido = '';
    } else {
      this.nombre = nombreaux.trim().toUpperCase();
      this.primerapellido = primerapellido.trim().toUpperCase();
      this.segundoapellido = segundoapeliido.trim().toUpperCase();
    }




    this.boxForm.get('items')['controls'][0]['controls'].nombre.setValue(nombreaux);
    this.boxForm.get('items')['controls'][0]['controls'].primerapellido.setValue(primerapellido);
    this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.setValue(segundoapeliido);
    this.boxForm.get('items')['controls'][0]['controls'].curp.setValue(this.objlicencia.conductor.curp);
    this.boxForm.get('items')['controls'][0]['controls'].fechanacimiento.setValue(this.curp2date(this.objlicencia.conductor.curp));

    this.boxForm.get('items')['controls'][0]['controls'].nombre.disable();
    this.boxForm.get('items')['controls'][0]['controls'].primerapellido.disable();
    this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.disable();
    this.boxForm.get('items')['controls'][0]['controls'].curp.disable();
    this.boxForm.get('items')['controls'][0]['controls'].fechanacimiento.disable();


    this.revisionnombrescurp();


    this.boxForm.get('items')['controls'][0]['controls'].municipio.setValue({ name: this.reemplazarAcentos(this.objlicencia.ubicacion.municipio), id: 0 });
    this.boxForm.get('items')['controls'][0]['controls'].nombreVialidad.setValue(this.objlicencia.ubicacion.calle);
    //this.boxForm.get('items')['controls'][0]['controls'].numeroInterior.setValue(this.objlicencia.contribuyente.ubicacion.numero_interior);
    this.boxForm.get('items')['controls'][0]['controls'].numeroExterior.setValue(this.objlicencia.ubicacion.numero_exterior);
    //this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.setValue({ name: this.objlicencia.contribuyente.ubicacion.colonia });
    this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.setValue({ name: this.objlicencia.ubicacion.asentamiento, id: 0 });
    this.boxForm.get('items')['controls'][0]['controls'].codigoPostal.setValue(this.objlicencia.ubicacion.codigo_postal);
    this.filterMunicipioInicio();
    this.filterTipoAsentamientosInicial();
    this.buscarOnlyNombreAsentamiento();

  }

  asignarDatosConductorEncontrado(data) {
    this.objConductor = data;

  }


  filterMunicipioInicio() {
    this.loading = true;
    let nombreMunicipio = this.objlicencia.ubicacion.municipio.split('-');
    this.apollo.use('backDireccionessinToken')
      .watchQuery({
        query: B.GET_MUNICIPIOS_INPUT,
        variables: {
          input: this.reemplazarAcentos(this.objlicencia.ubicacion.municipio),
          limit: 10,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.cargarAutocompletadoMunicipiosInicio(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  cargarAutocompletadoMunicipiosInicio(data) {
    this.municipios = new Array();
    let distrito;
    this.mostraragencia = false;
    if (data.municipalitiesLike.length > 0) {
      for (var i = 0; i < data.municipalitiesLike.length; i++) {
        if (data.municipalitiesLike[i].district != undefined) {
          distrito = '-' + data.municipalitiesLike[i].district.name;
        } else {
          distrito = '';
        }
        if (this.municipio == data.municipalitiesLike[i].name) {
          this.boxForm.get('items')['controls'][0]['controls'].municipio.setValue({ name: data.municipalitiesLike[i].name + distrito, id: data.municipalitiesLike[i].id });
          this.boxForm.get('items')['controls'][0]['controls'].municipio.objmunicipio = data.municipalitiesLike[i];
          this.boxForm.get('items')['controls'][0]['controls'].localidad.enable();
          this.apollo.use('backDireccionessinToken')
            .watchQuery({
              query: B.GET_AGENCIA_ALL,
              variables: {
                municipality_id: parseInt(data.municipalitiesLike[i].id),
                limit: 10,
              }, fetchPolicy: 'network-only'
            })
            .valueChanges.subscribe(result => {
              this.cargarAutocompletadoAgenciasAuxiliar(result.data)
            }, (error) => {
              M.toast({ html: error })
            });
        }
        this.municipios.push(
          { name: data.municipalitiesLike[i].name, id: data.municipalitiesLike[i].id }
        );
      }
    } else {
      this.boxForm.get('items')['controls'][0]['controls'].agencia.setValidators(null);
      this.boxForm.get('items')['controls'][0]['controls'].agencia.updateValueAndValidity();

      this.mostraragencia = false;
    }
    this.boxForm.get('items')['controls'][0]['controls'].municipio.arreglo = this.municipios;
    this.loading = false;
  }


  cargarAutocompletadoAgenciasAuxiliar(data: any) {
    this.mostraragencia = false;

    if (data.sublevelsByMunicipality.length > 0) {
      this.boxForm.get('items')['controls'][0]['controls'].agencia.enable();
      this.mostraragencia = true;
      this.agencias = new Array();
      for (var i = 0; i < data.sublevelsByMunicipality.length; i++) {
        this.agencias.push(
          { name: data.sublevelsByMunicipality[i].name, id: data.sublevelsByMunicipality[i].id }
        );
      }
      this.boxForm.get('items')['controls'][0]['controls'].agencia.arreglo = this.agencias;
      this.boxForm.get('items')['controls'][0]['controls'].agencia.setValidators(this.relacionesInputValidacionesService.getValidacionComprobacion('agencia'));
      this.boxForm.get('items')['controls'][0]['controls'].agencia.updateValueAndValidity();
      this.sugerenciasAgencias = [];
    } else {
      this.boxForm.get('items')['controls'][0]['controls'].agencia.setValidators(null);
      this.boxForm.get('items')['controls'][0]['controls'].agencia.updateValueAndValidity();

      this.mostraragencia = false;
    }
    this.loading = false;
  }


  filterTipoAsentamientosInicial() {
    this.loading = true;
    this.apollo.use('backDireccionessinToken')
      .watchQuery({
        query: B.GET_ASENTAMIENTOSTIPOS_INPUT,
        variables: {
          input: this.objlicencia.ubicacion.asentamiento,
          limit: 10,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.cargarAsentamientosInicial(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  cargarAsentamientosInicial(data) {
    for (var i = 0; i < data.settlementTypesLike.length; i++) {
      if (data.settlementTypesLike[i].name == this.objlicencia.ubicacion.asentamiento) {
        this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.setValue({ name: data.settlementTypesLike[i].name, id: data.settlementTypesLike[i].id });
        this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.objtipoAsentamiento = data.settlementTypesLike[i];
      }
    }
    this.loading = false;
  }


  buscarOnlyNombreAsentamiento() {
    if (this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.objtipoAsentamiento != undefined) {
      if (this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.value.name != undefined) {
        this.apollo.use('backDireccionessinToken')
          .watchQuery({
            query: B.GET_ASENTAMIENTOSNOMBRES_INPUT,
            variables: {
              input: this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.value.name.toUpperCase(),
              locality_id: this.boxForm.get('items')['controls'][0]['controls'].localidad.objlocalidad.id,
              settlement_type_id: this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.objtipoAsentamiento.id,
              limit: 10,
            }, fetchPolicy: 'network-only'
          })
          .valueChanges.subscribe(result => {
            this.cargarNombresAsentamientosOnly(result.data)
          }, (error) => {
            M.toast({ html: error })
          });
      } else {
        if (this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.objtipoAsentamiento != undefined && this.boxForm.get('items')['controls'][0]['controls'].localidad.objlocalidad != undefined) {
          this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.enable();
        }
      }

    }


  }

  cargarNombresAsentamientosOnly(data) {
    this.nombresasentamientos = new Array();
    if (data.settlementsLikeByLocalityAndSettlementType != null) {
      for (var i = 0; i < data.settlementsLikeByLocalityAndSettlementType.length; i++) {
        this.nombresasentamientos.push(
          { name: data.settlementsLikeByLocalityAndSettlementType[i].name, id: data.settlementsLikeByLocalityAndSettlementType[i].id }
        );
      }
      this.sugerenciasNombreAsentamientos = this.nombresasentamientos;
    } else {
      this.sugerenciasNombreAsentamientos = [];
    }

    this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.arreglo = this.nombresasentamientos;
    this.loading = false;
  }


  curpgenero(curp) {
    var cadena2 = curp.slice(10, 11);
    this.sexo = cadena2;
  }


  isEmpty(str) {
    return (!str || 0 === str.length);
  }
  reemplazarAcentos(cadena) {
    var chars = { "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u", "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U", "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U" }
    var expr = /[áàéèíìóòúùñ]/ig;
    var res = cadena.replace(expr, function (e) { return chars[e] });
    return res;
  }

  curp2date(curp) {
    var m = curp.match(/^\w{4}(\w{2})(\w{2})(\w{2})/);
    var anyo = parseInt(m[1], 10) + 1900;
    if (anyo < 1950) anyo += 100;
    var mes = parseInt(m[2], 10) - 1;
    var dia = parseInt(m[3], 10);
    return (new Date(anyo, mes, dia));
  }

  filterMunicipio(event) {
    this.loading = true;
    this.boxForm.controls.items.value[0].municipio = this.boxForm.controls.items.value[0].municipio.toUpperCase()
    this.apollo.use('backDireccionessinToken')
      .watchQuery({
        query: B.GET_MUNICIPIOS_INPUT,
        variables: {
          input: this.boxForm.controls.items.value[0].municipio,
          limit: 10,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.cargarAutocompletadoMunicipios(result.data, event)
      }, (error) => {
        M.toast({ html: error })
      });
  }

  cargarAutocompletadoMunicipios(data, event) {
    this.municipios = new Array();
    let distrito;
    for (var i = 0; i < data.municipalitiesLike.length; i++) {
      if (data.municipalitiesLike[i].district != undefined) {
        distrito = '-' + data.municipalitiesLike[i].district.name;
      } else {
        distrito = '';
      }
      this.municipios.push(
        { name: data.municipalitiesLike[i].name + distrito, id: data.municipalitiesLike[i].id }
      );
    }
    this.boxForm.get('items')['controls'][0]['controls'].municipio.arreglo = this.municipios;
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.municipios.length; i++) {
      let municipio = this.municipios[i];
      if (municipio.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(municipio);
      }
    }
    this.sugerenciasMunicipios = filtered;
    this.loading = false;
  }


  changeMunicipio($event) {
    if (typeof this.boxForm.get('items')['controls'][0]['controls'].municipio.value !== 'object') {
      this.boxForm.get('items')['controls'][0]['controls'].localidad.disable();
      this.boxForm.get('items')['controls'][0]['controls'].agencia.disable();
    }
  }

  municipioSeleccionado(event: any) {
    this.objMunicipio = event;
    this.boxForm.get('items')['controls'][0]['controls'].municipio.objmunicipio = this.objMunicipio;
    if (typeof this.objMunicipio === 'object') {
      this.boxForm.get('items')['controls'][0]['controls'].localidad.enable();
    }
    this.apollo.use('backDireccionessinToken')
      .watchQuery({
        query: B.GET_AGENCIA_ALL,
        variables: {
          municipality_id: parseInt(event.id),
          limit: 10,
        }, fetchPolicy: 'network-only'
      })
      .valueChanges.subscribe(result => {
        this.cargarAutocompletadoAgenciasAuxiliar(result.data)
      }, (error) => {
        M.toast({ html: error })
      });
    this.validMunicipio = true;
    this.loading = true;
  }


  filterLocalidad(event) {
    if (typeof this.boxForm.controls.items.value[0].localidad === 'object') {
      this.sugerenciasLocalidades = [];
    } else {
      this.loading = true;
      this.boxForm.controls.items.value[0].localidad = this.boxForm.controls.items.value[0].localidad.toUpperCase()
      this.apollo.use('backDireccionessinToken')
        .watchQuery({
          query: B.GET_LOCALIDADES_INPUT,
          variables: {
            input: this.boxForm.controls.items.value[0].localidad,
            municipality_id: this.boxForm.get('items')['controls'][0]['controls'].municipio.objmunicipio.id,
            limit: 10,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.cargarAutocompletadoLocalidades(result.data, event)
        }, (error) => {
          M.toast({ html: error })
        });
    }
  }

  cargarAutocompletadoLocalidades(data, event) {
    this.localidades = new Array();
    for (var i = 0; i < data.localitiesLikeByMunicipality.length; i++) {
      this.localidades.push(
        { name: data.localitiesLikeByMunicipality[i].name, id: data.localitiesLikeByMunicipality[i].id }
      );
    }
    this.boxForm.get('items')['controls'][0]['controls'].localidad.arreglo = this.localidades;
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.localidades.length; i++) {
      let localidad = this.localidades[i];
      if (localidad.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(localidad);
      }
    }
    this.sugerenciasLocalidades = filtered;
    this.loading = false;
  }

  changeLocalidad($event) {
    //this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.disable();
  }

  filterAgencia(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.agencias.length; i++) {
      let agencia = this.agencias[i];
      if (agencia.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(agencia);
      }
    }
    this.sugerenciasAgencias = filtered;
  }

  filterTipoVialidades($event) {
    if (typeof this.boxForm.controls.items.value[0].tipoVialidad === 'object') {
      this.sugerenciasTipoVialidades = [];
    } else {
      this.boxForm.controls.items.value[0].tipoVialidad = this.boxForm.controls.items.value[0].tipoVialidad.toUpperCase()
      this.apollo.use('backDireccionessinToken')
        .watchQuery({
          query: B.GET_VIALIDADESTIPOS_INPUT,
          variables: {
            input: this.boxForm.controls.items.value[0].tipoVialidad,
            limit: 10,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.cargarTiposVialidad(result.data, $event)
        }, (error) => {
          M.toast({ html: error })
        });
    }
  }

  cargarTiposVialidad(data, event) {
    this.tiposvialidades = new Array();
    for (var i = 0; i < data.roadsTypesLike.length; i++) {
      this.tiposvialidades.push(
        { name: data.roadsTypesLike[i].name, id: data.roadsTypesLike[i].id }
      );
    }
    this.boxForm.get('items')['controls'][0]['controls'].tipoVialidad.arreglo = this.tiposvialidades;
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.tiposvialidades.length; i++) {
      let tipovaliadad = this.tiposvialidades[i];
      if (tipovaliadad.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(tipovaliadad);
      }
    }
    this.sugerenciasTipoVialidades = filtered;
  }

  changeTipoAsentamiento($event) {
    //this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.disable();
  }


  filterTipoAsentamientos(event) {
    this.loading = true;
    if (typeof this.boxForm.controls.items.value[0].tipoAsentamiento !== 'object') {
      this.boxForm.controls.items.value[0].tipoAsentamiento = this.boxForm.controls.items.value[0].tipoAsentamiento.toUpperCase()
      this.apollo.use('backDireccionessinToken')
        .watchQuery({
          query: B.GET_ASENTAMIENTOSTIPOS_INPUT,
          variables: {
            input: this.boxForm.controls.items.value[0].tipoAsentamiento,
            limit: 10,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.cargarAsentamientos(result.data, event)
        }, (error) => {
          M.toast({ html: error })
        });
    }

  }

  cargarAsentamientos(data, event) {
    this.tiposasentamientos = new Array();
    for (var i = 0; i < data.settlementTypesLike.length; i++) {
      this.tiposasentamientos.push(
        {
          name: data.settlementTypesLike[i].name,
          id: data.settlementTypesLike[i].id
        }
      );
    }
    this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.arreglo = this.tiposasentamientos;
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.tiposasentamientos.length; i++) {
      let tipoasentamiento = this.tiposasentamientos[i];
      if (tipoasentamiento.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(tipoasentamiento);
      }
    }
    this.sugerenciasTipoAsentamientos = filtered;
    this.loading = false;

  }

  tipoAsentamientoSeleccionado(event: any) {
    this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.objtipoAsentamiento = event;
    setTimeout(() => {
      this.buscarOnlyNombreAsentamiento();

    }, 100);
  }

  filterNombreAsentamientos(event) {
    this.loading = true;
    let valor = '';

    if (typeof this.boxForm.controls.items.value[0].nombreAsentamiento === 'object') {
      valor = this.boxForm.controls.items.value[0].nombreAsentamiento.name;
    } else {
      valor = this.boxForm.controls.items.value[0].nombreAsentamiento;
    }


    if (valor.length > 1) {
      this.apollo.use('backDireccionessinToken')
        .watchQuery({
          query: B.GET_ASENTAMIENTOSNOMBRES_INPUT,
          variables: {
            input: valor,
            locality_id: this.boxForm.get('items')['controls'][0]['controls'].localidad.objlocalidad.id,
            settlement_type_id: this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.objtipoAsentamiento.id,
            limit: 10,
          }, fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(result => {
          this.cargarNombresAsentamientos(result.data, event)
        }, (error) => {
          M.toast({ html: error })
        });
    }

  }

  cargarNombresAsentamientos(data: any, event) {
    this.nombresasentamientos = new Array();
    if (data.settlementsLikeByLocalityAndSettlementType != null) {
      for (var i = 0; i < data.settlementsLikeByLocalityAndSettlementType.length; i++) {
        this.nombresasentamientos.push(
          { name: data.settlementsLikeByLocalityAndSettlementType[i].name, id: data.settlementsLikeByLocalityAndSettlementType[i].id }
        );
      }
    }

    this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.arreglo = this.nombresasentamientos;
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.nombresasentamientos.length; i++) {
      let nombreasentamiento = this.nombresasentamientos[i];
      if (nombreasentamiento.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(nombreasentamiento);
      }
    }
    this.sugerenciasNombreAsentamientos = filtered;
    this.loading = false;
  }

  localidadSeleccionada(event: any) {
    this.boxForm.get('items')['controls'][0]['controls'].localidad.objlocalidad = event;
    this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.enable();
    this.buscarOnlyNombreAsentamiento();
    this.loading = false;
  }


  segundopaso() {
    if (!this.loading) {
      if (!this.boxForm.invalid) {
        this.crearPersona();
      } else {
        this.verificarerrores = this.boxForm.invalid;
      }


    }
  }


  crearPersona() {
    this.apollo.use('backConductoressinToken')
      .mutate({
        mutation: A.CREATE_PERSONA,
        variables: {
          curp: this.conductor.curp.toUpperCase()
        }
      })
      .subscribe(({ data }) => {
        this.asignarPersona(data)
      }, (error) => {
        M.toast({ html: error });
      });
  }

  asignarPersona(data) {
    this.objPersona = data.person;
    let validacionnombreasentamiento = this.verificarNombreAsentamiento();
    if (!validacionnombreasentamiento) {
      this.crearNuevoAsentamiento();
    } else {
      this.crearNuevaDireccion();
    }
  }

  verificarNombreAsentamiento() {
    this.sendNombreAsentamiento = false;
    if (typeof this.boxForm.controls.items.value[0].nombreAsentamiento !== 'object') {
      for (var i = 0; i < this.sugerenciasNombreAsentamientos.length; i++) {
        if (this.sugerenciasNombreAsentamientos[i].name == this.boxForm.controls.items.value[0].nombreAsentamiento) {
          this.sendNombreAsentamiento = true;
          this.objAsentamiento = this.sugerenciasNombreAsentamientos[i];
        }
      }
    } else {
      for (var i = 0; i < this.sugerenciasNombreAsentamientos.length; i++) {
        if (this.sugerenciasNombreAsentamientos[i].name == this.boxForm.controls.items.value[0].nombreAsentamiento.name) {
          this.sendNombreAsentamiento = true;
          this.objAsentamiento = this.sugerenciasNombreAsentamientos[i];
        }
      }
    }
    return this.sendNombreAsentamiento;
  }

  crearNuevoAsentamiento() {
    if (this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.value.name != undefined) {
      this.apollo.use('backDireccionessinToken')
        .mutate({
          mutation: A.CREATE_ASENTAMIENTO,
          variables: {
            name: this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.value.name.toUpperCase(),
            locality_id: this.boxForm.get('items')['controls'][0]['controls'].localidad.objlocalidad.id,
            settlement_type_id: this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.value.id
          }
        })
        .subscribe(({ data }) => {
          this.asignarAsentamiento(data)
        }, (error) => {
          M.toast({ html: error });
        });
    } else {
      this.apollo.use('backDireccionessinToken')
        .mutate({
          mutation: A.CREATE_ASENTAMIENTO,
          variables: {
            name: this.boxForm.get('items')['controls'][0]['controls'].nombreAsentamiento.value.toUpperCase(),
            locality_id: this.boxForm.get('items')['controls'][0]['controls'].localidad.objlocalidad.id,
            settlement_type_id: this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.value.id
          }
        })
        .subscribe(({ data }) => {
          this.asignarAsentamiento(data)
        }, (error) => {
          M.toast({ html: error });
        });
    }

  }

  crearNuevaDireccion() {
    let agencia;
    if (this.boxForm.get('items')['controls'][0]['controls'].agencia.value != undefined) {
      agencia = this.boxForm.get('items')['controls'][0]['controls'].agencia.value.id;
    } else {
      agencia = null;
    }
    let numeroInterior;
    if (this.boxForm.get('items')['controls'][0]['controls'].numeroInterior.value != '') {
      numeroInterior = this.boxForm.get('items')['controls'][0]['controls'].numeroInterior.value;
    } else {
      numeroInterior = null;
    }
    this.apollo.use('backDireccionessinToken')
      .mutate({
        mutation: A.CREATE_DIRECCION,
        variables: {
          road_name: this.boxForm.get('items')['controls'][0]['controls'].nombreVialidad.value.toUpperCase(),
          road_type_id: this.boxForm.get('items')['controls'][0]['controls'].tipoAsentamiento.value.id,
          outdoor_number: this.boxForm.get('items')['controls'][0]['controls'].numeroExterior.value,
          indoor_number: numeroInterior,
          postal_code: String(this.boxForm.get('items')['controls'][0]['controls'].codigoPostal.value),
          settlement_id: this.objAsentamiento.id,
          sublevel_id: agencia
        }
      })
      .subscribe(({ data }) => {
        this.asignarDireccion(data);
      }, (error) => {
        M.toast({ html: error });
      });
  }

  asignarDireccion(data) {
    this.objDireccion = data.address;
    this.asignarDireccionAPersona();
  }

  asignarDireccionAPersona() {
    this.apollo.use('backConductoressinToken')
      .mutate({
        mutation: A.SET_PERSONA_DIRECCION,
        variables: {
          person_id: this.objPersona.id,
          address_id: this.objDireccion.id
        }
      })
      .subscribe(({ data }) => {
        this.asignarContactoPersona();
      }, (error) => {
        M.toast({ html: error });
      });
  }

  asignarContactoPersona() {
    let correo;
    if (this.boxForm.get('items')['controls'][0]['controls'].correo.value == undefined || this.boxForm.get('items')['controls'][0]['controls'].correo.value == '') {
      correo = null;
    } else {
      correo = this.boxForm.get('items')['controls'][0]['controls'].correo.value;
    }
    let phone = this.boxForm.get('items')['controls'][0]['controls'].telefono.value;
    if (phone == '' || phone == undefined) {
      phone = null;
    }
    this.apollo.use('backConductoressinToken')
      .mutate({
        mutation: A.SET_PERSONA_CONTACTO,
        variables: {
          email: correo,
          phone: phone,
          person_id: this.objPersona.id,
        }
      })
      .subscribe(({ data }) => {
        this.asignarIdentidadPersona();
      }, (error) => {
        M.toast({ html: error });
      });
  }


  asignarIdentidadPersona() {
    let segundoapellido;
    if (this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value == '' || this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value == undefined) {
      segundoapellido = null;
    } else {
      segundoapellido = this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value.toUpperCase();
    }

    this.apollo.use('backConductoressinToken')
      .mutate({
        mutation: A.SET_PERSONA_IDENTIDAD,
        variables: {
          name: this.boxForm.get('items')['controls'][0]['controls'].nombre.value.toUpperCase(),
          first_surname: this.boxForm.get('items')['controls'][0]['controls'].primerapellido.value.toUpperCase(),
          second_surname: segundoapellido,
          person_id: this.objPersona.id,
        }
      })
      .subscribe(({ data }) => {
        this.asignarLicenciaPersona();
      }, (error) => {
        M.toast({ html: error });
      });




  }


  asignarLicenciaPersona() {
    this.apollo.use('backConductoressinToken')
      .mutate({
        mutation: A.SET_PERSONA_LICENCIA,
        variables: {
          person_id: this.objPersona.id,
          license_number: this.licencia
        }
      })
      .subscribe(({ data }) => {
        this.asignarPersonalPersona();
      }, (error) => {
        M.toast({ html: error });
      });
  }


  asignarPersonalPersona() {
    let sexoCompleto;
    if (this.sexo == 'H') {
      sexoCompleto = 'Hombre';
    } else {
      sexoCompleto = 'Mujer';
    }
    let personal = {
      birthday: this.conductor.fecha_nacimiento,
      sex: sexoCompleto,
      education_level_id: null,
      marital_status_id: null
    }
    this.apollo.use('backConductoressinToken')
      .mutate({
        mutation: A.SET_PERSONA_PERSONAL,
        variables: {
          birthday: this.conductor.fecha_nacimiento,
          sex: sexoCompleto,
          education_level_id: null,
          marital_status_id: null,
          person_id: this.objPersona.id
        }
      })
      .subscribe(({ data }) => {
        this.verprevisualizacion();
      }, (error) => {
        M.toast({ html: error });
      });
  }


  asignarAsentamiento(data) {
    this.objAsentamiento = data.settlement;
    this.crearNuevaDireccion();
  }


  crearRegistroReto() {
    let idPersona;
    if (this.objConductor != undefined) {
      idPersona = this.objConductor.id;
    }
    if (this.objPersona != undefined) {
      idPersona = this.objPersona.id;
    }
    this.apollo.use('registroReto')
      .mutate({
        mutation: A.CREATE_REGISTRO,
        variables: {
          id_concesion: this.registroamostrar.id,
          id_concesionario: this.registroamostrar.concessionaire.id,
          id_persona: idPersona,
          id_modalidad: this.registroamostrar.modality.id,
          tipo_persona: this.registroamostrar.concessionaire.person_type
        }
      }).subscribe(result => {
        M.toast({ html: 'Registro agregado con exito' });
        this.crearObservacion(result);

      }, (error) => {
      });




  }

  revisionnombrescurp() {
    if (this.registroamostrar.concessionaire.person_type == 'F') {
      let nombreConsultas = this.registroamostrar.concessionaire.name + this.registroamostrar.concessionaire.first_surname + this.registroamostrar.concessionaire.second_surname;

      if (this.objConductor != undefined) {

        if (this.objConductor.curp != this.registroamostrar.concessionaire.curp) {
          this.listInconsistencias.push({ informacion: 'Curp diferente en los registros de Conductor y Concesionario.' })
          this.validacionnombres = false;
        }
        let nombreConductor = this.objConductor.identity.name + this.objConductor.identity.first_surname + this.objConductor.identity.second_surname;
        if (nombreConductor != nombreConsultas) {
          this.listInconsistencias.push({ informacion: 'Nombre diferente en los registros de Conductor y Concesionario.' })
          this.validacionnombres = false;

        }

      }

      if (this.objPersona != undefined) {
        let segundoapellido;
        if (this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value == '' || this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value == undefined) {
          segundoapellido = null;
        } else {
          segundoapellido = this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value.toUpperCase();
        }

        let name = this.boxForm.get('items')['controls'][0]['controls'].nombre.value.toUpperCase();

        let first_surname = this.boxForm.get('items')['controls'][0]['controls'].primerapellido.value.toUpperCase();
        let nombreConcesion = name + first_surname + segundoapellido;

        if (this.conductor.curp != this.registroamostrar.concessionaire.curp) {
          this.validacionnombres = false;

          this.listInconsistencias.push({ informacion: 'Curp diferente en los registros de Conductor y Concesionario.' })
        }

        if (nombreConcesion != nombreConsultas) {
          this.validacionnombres = false;

          this.listInconsistencias.push({ informacion: 'Nombre diferente en los registros de Conductor y Concesionario.' })
        }

      }
      console.log('Entra aqui');

      if (this.objConductor == undefined && this.objPersona == undefined) {
        console.log('Entra aqui');
        let segundoapellido;
        if (this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value == '' || this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value == undefined) {
          segundoapellido = null;
        } else {
          segundoapellido = this.boxForm.get('items')['controls'][0]['controls'].segundoapellido.value.toUpperCase();
        }

        let name = this.boxForm.get('items')['controls'][0]['controls'].nombre.value.toUpperCase();

        let first_surname = this.boxForm.get('items')['controls'][0]['controls'].primerapellido.value.toUpperCase();
        let nombreConcesion = name + first_surname + segundoapellido;

        if (this.conductor.curp != this.registroamostrar.concessionaire.curp) {
          this.validacionnombres = false;

          this.listInconsistencias.push({ informacion: 'Curp diferente en los registros de Conductor y Concesionario.' })
        }

        if (nombreConcesion != nombreConsultas) {
          this.validacionnombres = false;

          this.listInconsistencias.push({ informacion: 'Nombre diferente en los registros de Conductor y Concesionario.' })
        }
      }


    } else {
      this.validacionnombres = true;
    }

  }


  reedirecionar(result) {
    setTimeout(() => {
      window.location.href = '/inbox/detalle/' + result.data.registro_reto.id;

    }, 2000);
  }




}
