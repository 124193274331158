export class Usuario {
    nombre: String;
    primer_apellido: String;
    segundo_apellido: String;
    correo: String;
    password: String;
    id_rol: number;
    id_centro_trabajo: number;
    id_region: number;

    constructor(
      nombre?: String,
      primer_apellido?: String,
      segundo_apellido?: String,
      correo?: String,
      password?: String,
      id_rol?: number,
      id_centro_trabajo?: number,
      id_region?: number

    ) {}
}
