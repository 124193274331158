import { setContext } from 'apollo-link-context';
import { GraphQLRequest } from 'apollo-link';

import { AuthService } from '../../core/auth.service';
import { AccountService } from '../../auth/autentication.service';




function createAuthLink(authService: AuthService,accountService: AccountService ) {

  const authLink = setContext((operation: GraphQLRequest, prevContext: any) => {

      return {
      //  headers: { Authorization: `Bearer ${ accountService.getTokensAccount.X_TOKEN_ACCOUNT }` }
      };

  });
  return authLink;
}

export { createAuthLink };
