import { Ubicacion } from './ubicacion'
import { Capacitacion } from './capacitacion'

export class Conductor {
    nombre: String;
    primer_apellido: String;
    segundo_apellido: String;
    fecha_nacimiento: Date;
    estado_civil: String;

    curp: String;
     ubicacion: Ubicacion;

    capacitacion: Capacitacion;
    constructor(
      nombre?: String,
      primer_apellido?: String,
      segundo_apellido?: String,
      fecha_nacimiento?: Date,
      estado_civil?: String,
      curp?: String,
      ubicacion?: Ubicacion,
      capacitacion?: Capacitacion
    ) {}
}
