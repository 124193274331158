import { Injectable } from '@angular/core';
 import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as M from '../../graphql/mutations';
import {Apollo} from 'apollo-angular';
import {FIRMA} from "../../core/key/firma";
import {CALIBRI} from "../../core/key/calibri";
import {CALIBRIB} from "../../core/key/calibrib";
import {UNIVIAB} from "../../core/key/univiaprob";
import {UNIVIABLACK} from "../../core/key/univiablack";
import {UNIVIANORMAL} from "../../core/key/univianormal";

import {IMGDEFAULT} from "../../core/key/imgdefault";
import * as jsPDF from 'jspdf';
import { environment } from '../../../environments/environment';

type AOA = any[][];
declare var saveAs:any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})

export class CreadorComponentesPDFService {

  constructor() { }

crearConstanciaTaxiPrueba(barcodeData,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,folio,encargadoreto){
  let calibri_url = CALIBRI.CALIBRI;
  let calibrib_url = CALIBRIB.CALIBRIB;
  var pdf = new jsPDF('l', 'mm', [396,   792]);
  pdf.addFileToVFS("Calibri.ttf",calibri_url);
  pdf.addFileToVFS("Calibrib.ttf",calibrib_url);
  pdf.setFontSize(18);
  let tmo= 10;
  pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
  pdf.setFont("Calibrib");
  let saltosnombre = this.cuantasvecessalta(nombre, 18);
  pdf.setLineWidth(0.1);
  pdf.line(44 + tmo, 14, 44 + tmo, 78);
  var lines = pdf.splitTextToSize(nombre, 70);
  let tamaniosaltosnombre;
  if(saltosnombre==1){
    tamaniosaltosnombre = 0;
  }else{
    tamaniosaltosnombre = (saltosnombre*-3.4);
  }
  if(saltosnombre>2){
    pdf.setFontSize(14);
    tamaniosaltosnombre = (saltosnombre*-3.4);
  }
  pdf.text(lines, (43+tmo)  + tamaniosaltosnombre, 78, null, 90);
  pdf.setFontSize(9);
  pdf.addFont("Calibri.ttf", "Calibri", "normal");
  pdf.setFont("Calibri");
  var titlenombre= 'NOMBRE (S)';
  var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
  pdf.text(linetitlenombre, 47+tmo, 78, null, 90);
  pdf.setFontSize(18);
  pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
  pdf.setFont("Calibrib");
  let saltosapellido= this.cuantasvecessalta(primer_apellido, 20);
  let tamaniosaltosprimerapellido;
  if(saltosapellido==1){
    tamaniosaltosprimerapellido = 0;
  }else{
    tamaniosaltosprimerapellido = (saltosapellido*-3.4);
  }
  if(saltosnombre>2){
    pdf.setFontSize(14);
    tamaniosaltosprimerapellido = (saltosapellido*-3.4);
  }
  var lines2 = pdf.splitTextToSize(primer_apellido, 70);
  pdf.text(lines2, (58+tmo) + tamaniosaltosprimerapellido, 78, null, 90);
  pdf.setLineWidth(0.1);
  pdf.line(59+tmo, 14, 59+tmo, 78);
  pdf.setFontSize(9);
  pdf.addFont("Calibri.ttf", "Calibri", "normal");
  pdf.setFont("Calibri");
  var titlenombre= 'PRIMER APELLIDO';
  var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
  pdf.text(linetitlenombre, 62+tmo, 78, null, 90);
  pdf.setFontSize(18);
  pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
  pdf.setFont("Calibrib");
  let saltosapellidosegundo= this.cuantasvecessalta(segundo_apellido, 20);
  let tamaniosaltossegundoapellido;
  if(saltosapellidosegundo==1){
    tamaniosaltossegundoapellido = 0;
  }else{
    tamaniosaltossegundoapellido = (saltosapellidosegundo*-3.4);
  }
  if(saltosnombre>2){
    pdf.setFontSize(14);
    tamaniosaltossegundoapellido = (saltosapellidosegundo*-3.4);
  }
  var lines3 = pdf.splitTextToSize(segundo_apellido, 70);
  pdf.text(lines3, (75+tmo) + tamaniosaltossegundoapellido, 78, null, 90);
  pdf.addFont("Calibri.ttf", "Calibri", "normal");
  pdf.setFont("Calibri");
  pdf.setLineWidth(0.1);
  pdf.line(76+tmo, 14, 76+tmo, 78);
  pdf.setFontSize(9);
  titlenombre= 'SEGUNDO APELLIDO';
  var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
  pdf.text(linetitlenombre, 79+tmo, 78, null, 90);
  pdf.setFontSize(13);
  pdf.setLineWidth(0.1);
  pdf.line(94+tmo, 14, 94+tmo, 78);
  pdf.setFontSize(9);
  var titlenombre= 'SITIO';
  var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
  pdf.text(linetitlenombre, 97+tmo, 78, null, 90);
  pdf.setFontSize(13);
  let saltos = this.cuantasvecessalta(sitio, 24);
  let espacio = 52;
  if(saltos==1){
    pdf.setFontSize(12);
    espacio = 0;
  }
  if(saltos==2){
    pdf.setFontSize(9);
    espacio = saltos*-1.3;
  }
  if(saltos==3){
    pdf.setFontSize(8);
    espacio = saltos*-1.1;
  }
  if(saltos>3){
    pdf.setFontSize(7);
    espacio = saltos*-1.1;
  }
  var lines5 = pdf.splitTextToSize(sitio, 70);
  pdf.text(lines5, (93+tmo)+espacio, 78, null, 90);
  pdf.setFontSize(11);
  pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
  pdf.setFont("Calibrib");
  let saltosnombre2 = this.cuantasvecessalta(nombreConcesionario, 28);
  let tamaniosaltosnombre2 = 0;
  if(saltosnombre2==1){
    tamaniosaltosnombre2 = 0;
  }
  if(saltosnombre2>1){
    tamaniosaltosnombre2 = (saltosnombre2*-1.6);
    pdf.setFontSize(10);
  }
  if(saltosnombre2>3){
    pdf.setFontSize(8);
    tamaniosaltosnombre2 = (saltosnombre2*-1.1);
  }
  var lines6 = pdf.splitTextToSize(nombreConcesionario, 70);
  pdf.text(lines6, (105+tmo)+tamaniosaltosnombre2, 78, null, 90);
  pdf.setLineWidth(0.1);
  pdf.line(106+tmo, 14, 106+tmo, 78);
  pdf.setFontSize(9);
  pdf.addFont("Calibri.ttf", "Calibri", "normal");
  pdf.setFont("Calibri");
  var titlenombre= 'CONCESIONARIO VINCULADO';
  var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
  pdf.text(linetitlenombre, 109+tmo, 78, null, 90);
  pdf.setFontSize(13);
  var lines6 = pdf.splitTextToSize(nuc, 60);
  pdf.text(lines6, 116+tmo, 78, null, 90);
  pdf.setLineWidth(0.1);
  pdf.line(117+tmo, 14, 117+tmo, 78);
  pdf.setFontSize(9);
  var titlenombre= 'NUC';
  var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
  pdf.text(linetitlenombre, 120+tmo, 78, null, 90);
  pdf.setFontSize(13);
  var lines7 = pdf.splitTextToSize(vigencia, 60);
  pdf.text(lines7, 125+tmo, 78, null, 90);
  pdf.setLineWidth(0.1);
  pdf.line(126+tmo, 14, 126+tmo, 78);
  pdf.setFontSize(9);
  var titlenombre= 'FECHA DE VENCIMIENTO';
  var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
  pdf.text(linetitlenombre, 129+tmo, 78, null, 90);
  pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
  pdf.setFont("Calibrib");
  pdf.setFontSize(20);
/*
      var placa = data.certificate.plate.folio;

          var lines4 = pdf.splitTextToSize(placa, 40);
          pdf.text(lines4, 141+tmo, 78, null, 90);

          pdf.setLineWidth(0.1);
          pdf.line(142+tmo, 14, 142+tmo, 78);


          pdf.setFontSize(9);
          pdf.addFont("Calibri.ttf", "Calibri", "normal");
          pdf.setFont("Calibri");
          var titlenombre= 'PLACAS';
          var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
          pdf.text(linetitlenombre, 145+tmo, 78, null, 90);


      pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
      pdf.setFont("Calibrib");
      pdf.setFontSize(20);

*/

      var lines4 = pdf.splitTextToSize(placa, 40);
      pdf.text(lines4, 141+tmo, 78, null, 90);
      pdf.setLineWidth(0.1);
      pdf.line(142+tmo, 14, 142+tmo, 78);
      pdf.setFontSize(9);
      pdf.addFont("Calibri.ttf", "Calibri", "normal");
      pdf.setFont("Calibri");
      var titlenombre= 'PLACAS';
      var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
      pdf.text(linetitlenombre, 145+tmo, 78, null, 90);
      pdf.setLineWidth(0.1);
      pdf.line(176, 40, 176, 106);
      pdf.setFontSize(12);
      pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
      pdf.setFont("Calibrib");
      var titlenombre= 'ING. DAVID LÓPEZ HERNÁNDEZ';
      var linetitlenombre = pdf.splitTextToSize(titlenombre, 80);
      pdf.text(linetitlenombre, 180, 100, null, 90);
      pdf.setFontSize(11);
      pdf.addFont("Calibri.ttf", "Calibri", "normal");
      pdf.setFont("Calibri");
      var titlenombre= 'COORDINADOR TÉCNICO Y ENCARGADO';
      var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
      pdf.text(linetitlenombre, 184, 103+2, null, 90);
      var titlenombre= 'DEL REGISTRO ESTATAL DE TRANSPORTE';
      var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
      pdf.text(linetitlenombre, 187, 103+2, null, 90);
      //pdf.addImage(urlimage, 'JPG', 130, 64, 50, 70, undefined, undefined, 90 );
      pdf.addImage(barcodeData, "JPG", 211-10, 5, 36, 36, undefined, undefined, 90  );
      pdf.save('Constancia'+folio+".pdf");
}


  crearConstanciaTaxi(urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto){
    let imageurl;
    setTimeout(() => {
      imageurl = this.getBase64ImageFromURL(environment.URIImage + 'image/' + urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto);
    }, 100);
  }


  crearConstanciaUrbano(urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto){
    let imageurl;
    setTimeout(() => {
      imageurl = this.getBase64ImageFromURLUrbano(environment.URIImage + 'image/' + urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto);
    }, 100);
  }

  getBase64ImageFromURL(url,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
       this.construirConstanciaTaxi(dataURL,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto);
       };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  getBase64ImageFromURLUrbano(url,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
       this.construirConstanciaUrbano(dataURL,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto);
       };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }



  construirConstanciaTaxi(urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto){
    let calibri_url = CALIBRI.CALIBRI;
    let calibrib_url = CALIBRIB.CALIBRIB;
    var pdf = new jsPDF('l', 'mm', [396,   792]);
    pdf.addFileToVFS("Calibri.ttf",calibri_url);
    pdf.addFileToVFS("Calibrib.ttf",calibrib_url);
    pdf.setFontSize(18);
    let tmo= 10;
    pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
    pdf.setFont("Calibrib");
    let saltosnombre = this.cuantasvecessalta(nombre, 18);
    pdf.setLineWidth(0.1);
    pdf.line(44 + tmo, 14, 44 + tmo, 78);
    var lines = pdf.splitTextToSize(nombre, 70);
    let tamaniosaltosnombre;
    if(saltosnombre==1){
      tamaniosaltosnombre = 0;
    }else{
      tamaniosaltosnombre = (saltosnombre*-3.4);
    }
    if(saltosnombre>2){
      pdf.setFontSize(14);
      tamaniosaltosnombre = (saltosnombre*-3.4);
    }
    pdf.text(lines, (43+tmo)  + tamaniosaltosnombre, 78, null, 90);
    pdf.setFontSize(9);
    pdf.addFont("Calibri.ttf", "Calibri", "normal");
    pdf.setFont("Calibri");
    var titlenombre= 'NOMBRE (S)';
    var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
    pdf.text(linetitlenombre, 47+tmo, 78, null, 90);
    pdf.setFontSize(18);
    pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
    pdf.setFont("Calibrib");
    let saltosapellido= this.cuantasvecessalta(primer_apellido, 20);
    let tamaniosaltosprimerapellido;
    if(saltosapellido==1){
      tamaniosaltosprimerapellido = 0;
    }else{
      tamaniosaltosprimerapellido = (saltosapellido*-3.4);
    }
    if(saltosnombre>2){
      pdf.setFontSize(14);
      tamaniosaltosprimerapellido = (saltosapellido*-3.4);
    }
    var lines2 = pdf.splitTextToSize(primer_apellido, 70);
    pdf.text(lines2, (58+tmo) + tamaniosaltosprimerapellido, 78, null, 90);
    pdf.setLineWidth(0.1);
    pdf.line(59+tmo, 14, 59+tmo, 78);
    pdf.setFontSize(9);
    pdf.addFont("Calibri.ttf", "Calibri", "normal");
    pdf.setFont("Calibri");
    var titlenombre= 'PRIMER APELLIDO';
    var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
    pdf.text(linetitlenombre, 62+tmo, 78, null, 90);
    pdf.setFontSize(18);
    pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
    pdf.setFont("Calibrib");
    let saltosapellidosegundo= this.cuantasvecessalta(segundo_apellido, 20);
    let tamaniosaltossegundoapellido;
    if(saltosapellidosegundo==1){
      tamaniosaltossegundoapellido = 0;
    }else{
      tamaniosaltossegundoapellido = (saltosapellidosegundo*-3.4);
    }
    if(saltosnombre>2){
      pdf.setFontSize(14);
      tamaniosaltossegundoapellido = (saltosapellidosegundo*-3.4);
    }
    var lines3 = pdf.splitTextToSize(segundo_apellido, 70);
    pdf.text(lines3, (75+tmo) + tamaniosaltossegundoapellido, 78, null, 90);
    pdf.addFont("Calibri.ttf", "Calibri", "normal");
    pdf.setFont("Calibri");
    pdf.setLineWidth(0.1);
    pdf.line(76+tmo, 14, 76+tmo, 78);
    pdf.setFontSize(9);
    titlenombre= 'SEGUNDO APELLIDO';
    var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
    pdf.text(linetitlenombre, 79+tmo, 78, null, 90);
    pdf.setFontSize(13);
    pdf.setLineWidth(0.1);
    pdf.line(94+tmo, 14, 94+tmo, 78);
    pdf.setFontSize(9);
    var titlenombre= 'SITIO';
    var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
    pdf.text(linetitlenombre, 97+tmo, 78, null, 90);
    pdf.setFontSize(13);
    let saltos = this.cuantasvecessalta(sitio, 24);
    let espacio = 52;
    if(saltos==1){
      pdf.setFontSize(12);
      espacio = 0;
    }
    if(saltos==2){
      pdf.setFontSize(9);
      espacio = saltos*-1.3;
    }
    if(saltos==3){
      pdf.setFontSize(8);
      espacio = saltos*-1.1;
    }
    if(saltos>3){
      pdf.setFontSize(7);
      espacio = saltos*-1.1;
    }
    var lines5 = pdf.splitTextToSize(sitio, 70);
    pdf.text(lines5, (93+tmo)+espacio, 78, null, 90);
    pdf.setFontSize(11);
    pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
    pdf.setFont("Calibrib");
    let saltosnombre2 = this.cuantasvecessalta(nombreConcesionario, 28);
    let tamaniosaltosnombre2 = 0;
    if(saltosnombre2==1){
      tamaniosaltosnombre2 = 0;
    }
    if(saltosnombre2>1){
      tamaniosaltosnombre2 = (saltosnombre2*-1.6);
      pdf.setFontSize(10);
    }
    if(saltosnombre2>3){
      pdf.setFontSize(8);
      tamaniosaltosnombre2 = (saltosnombre2*-1.1);
    }
    var lines6 = pdf.splitTextToSize(nombreConcesionario, 70);
    pdf.text(lines6, (105+tmo)+tamaniosaltosnombre2, 78, null, 90);
    pdf.setLineWidth(0.1);
    pdf.line(106+tmo, 14, 106+tmo, 78);
    pdf.setFontSize(9);
    pdf.addFont("Calibri.ttf", "Calibri", "normal");
    pdf.setFont("Calibri");
    var titlenombre= 'CONCESIONARIO VINCULADO';
    var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
    pdf.text(linetitlenombre, 109+tmo, 78, null, 90);
    pdf.setFontSize(13);
    var lines6 = pdf.splitTextToSize(nuc, 60);
    pdf.text(lines6, 116+tmo, 78, null, 90);
    pdf.setLineWidth(0.1);
    pdf.line(117+tmo, 14, 117+tmo, 78);
    pdf.setFontSize(9);
    var titlenombre= 'NUC';
    var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
    pdf.text(linetitlenombre, 120+tmo, 78, null, 90);
    pdf.setFontSize(13);
    var lines7 = pdf.splitTextToSize(vigencia, 60);
    pdf.text(lines7, 125+tmo, 78, null, 90);
    pdf.setLineWidth(0.1);
    pdf.line(126+tmo, 14, 126+tmo, 78);
    pdf.setFontSize(9);
    var titlenombre= 'FECHA DE VENCIMIENTO';
    var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
    pdf.text(linetitlenombre, 129+tmo, 78, null, 90);
    pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
    pdf.setFont("Calibrib");
    pdf.setFontSize(20);
/*
        var placa = data.certificate.plate.folio;

            var lines4 = pdf.splitTextToSize(placa, 40);
            pdf.text(lines4, 141+tmo, 78, null, 90);

            pdf.setLineWidth(0.1);
            pdf.line(142+tmo, 14, 142+tmo, 78);


            pdf.setFontSize(9);
            pdf.addFont("Calibri.ttf", "Calibri", "normal");
            pdf.setFont("Calibri");
            var titlenombre= 'PLACAS';
            var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
            pdf.text(linetitlenombre, 145+tmo, 78, null, 90);


        pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
        pdf.setFont("Calibrib");
        pdf.setFontSize(20);

*/

        var lines4 = pdf.splitTextToSize(placa, 40);
        pdf.text(lines4, 141+tmo, 78, null, 90);
        pdf.setLineWidth(0.1);
        pdf.line(142+tmo, 14, 142+tmo, 78);
        pdf.setFontSize(9);
        pdf.addFont("Calibri.ttf", "Calibri", "normal");
        pdf.setFont("Calibri");
        var titlenombre= 'PLACAS';
        var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
        pdf.text(linetitlenombre, 145+tmo, 78, null, 90);
        pdf.setLineWidth(0.1);
        pdf.line(176, 40, 176, 106);
        pdf.setFontSize(12);
        pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
        pdf.setFont("Calibrib");
        var titlenombre= 'ING. DAVID LÓPEZ HERNÁNDEZ';
        var linetitlenombre = pdf.splitTextToSize(titlenombre, 80);
        pdf.text(linetitlenombre, 180, 100, null, 90);
        pdf.setFontSize(11);
        pdf.addFont("Calibri.ttf", "Calibri", "normal");
        pdf.setFont("Calibri");
        var titlenombre= 'COORDINADOR TÉCNICO Y ENCARGADO';
        var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
        pdf.text(linetitlenombre, 184, 103+2, null, 90);
        var titlenombre= 'DEL REGISTRO ESTATAL DE TRANSPORTE';
        var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
        pdf.text(linetitlenombre, 187, 103+2, null, 90);
        pdf.addImage(urlimage, 'JPG', 130, 64, 50, 70, undefined, undefined, 90 );
        pdf.addImage(barcodeData, "JPG", 211-10, 5, 36, 36, undefined, undefined, 90  );
        pdf.save('Constancia'+folio+".pdf");
  }

  construirConstanciaUrbano(urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto){
    let univiablack_url = UNIVIABLACK.UNIVIABLACK;
    var pdf = new jsPDF();
    pdf.addFileToVFS('UniviaPro-Black-normal.ttf', univiablack_url);
    pdf.addFont('UniviaPro-Black-normal.ttf', 'UniviaPro-Black', 'normal');
    let univia_url = UNIVIAB.UNIVIAB;
    pdf.addFileToVFS('UniviaPro-Bold-bold.ttf', univia_url);
    pdf.addFont('UniviaPro-Bold-bold.ttf', 'UniviaPro-Bold', 'normal');
    pdf.setFont("UniviaPro-Black");
    pdf.setFont("UniviaPro-Bold");

        let univia_url_normal = UNIVIANORMAL.UNIVIANORMAL;
        pdf.addFileToVFS('UniviaPro-Normal.ttf', univia_url_normal);
        pdf.addFont('UniviaPro-Normal.ttf', 'UniviaPro-Normal', 'normal');
        pdf.setFont("UniviaPro-Normal");
        pdf.setFontSize(14);


        pdf.text('NOMBRE DEL OPERADOR', 90, 70);

        let saltosnombre = this.cuantasvecessalta(nombre, 8);
        let tmo1 = 0
         if(saltosnombre<2){
          pdf.setFontSize(34);
       }else{
         if(saltosnombre<4){
           pdf.setFontSize(24);
           tmo1 = 3;
         }else{
           pdf.setFontSize(22);
           tmo1 = 5;
         }

       }
       pdf.setFont("UniviaPro-Bold");

        var lines = pdf.splitTextToSize(nombre, 120);
        pdf.text(lines, 90, 84 - tmo1, null, 0);
        pdf.setFont("UniviaPro-Normal");
        pdf.setFontSize(14);

        pdf.text('PRIMER APELLIDO', 90, 92);
        pdf.setFontSize(34);


        let saltosapellido = this.cuantasvecessalta(primer_apellido, 13);
        let tmo2 = 0
        if(saltosapellido<2){
          pdf.setFontSize(34);
       }else{
         if(saltosapellido<4){
           pdf.setFontSize(28);
           tmo2 = 3;
         }else{
           pdf.setFontSize(24);
           tmo2 = 5;
         }

       }

       pdf.setFont("UniviaPro-Black");

        var lines = pdf.splitTextToSize(primer_apellido, 120);
        pdf.text(lines, 90, 104 - tmo2, null, 0);
        pdf.setFont("UniviaPro-Normal");
        pdf.setFontSize(14);

        pdf.text('SEGUNDO APELLIDO', 90, 112);

        pdf.setFontSize(34);
        let saltossegundoapellido = this.cuantasvecessalta(segundo_apellido, 13);
        let tmo3 = 0
        if(saltossegundoapellido<2){
          pdf.setFontSize(34);
       }else{
         if(saltossegundoapellido<4){
           pdf.setFontSize(28);
           tmo3 = 3;
         }else{
           pdf.setFontSize(24);
           tmo3 = 5;
         }

       }
       pdf.setFont("UniviaPro-Black");

        var lines = pdf.splitTextToSize(segundo_apellido, 120);
        pdf.text(lines, 90, 124 - tmo3, null, 0);
        pdf.setFont("UniviaPro-Normal");

        pdf.setFontSize(14);

        pdf.text('EMPRESA VINCULADA', 90, 132);
        let saltosnombreConcesionario = this.cuantasvecessalta(nombreConcesionario, 18);
       let tamaniosaltosnombreConcesionario;
        if(saltosnombreConcesionario<5){
          pdf.setFontSize(26);
       }else{
         if(saltosnombreConcesionario<7){
           pdf.setFontSize(23);

         }else{

           pdf.setFontSize(21);

         }

        }

        pdf.setFont("UniviaPro-Black");

       var lines = pdf.splitTextToSize(nombreConcesionario, 120);

       pdf.text(lines, 90, 144, null, 0);


       pdf.setFont("UniviaPro-Normal");

        pdf.setFontSize(16);


        pdf.text('FECHA DE VENCIMIENTO', 90, 192);


        pdf.setFontSize(20);
        pdf.setFont("UniviaPro-Black");

        var lines = pdf.splitTextToSize(vigencia, 120);
        pdf.text(lines, 90, 200, null, 0);
        pdf.setFont("UniviaPro-Normal");


         pdf.setFontSize(16);

         pdf.text('NUC:', 10, 192);

        pdf.setFontSize(18);
        pdf.setFont("UniviaPro-Black");

       var lines = pdf.splitTextToSize(nuc, 120);
       pdf.text(lines, 10, 200, null, 0);
       pdf.setFont("UniviaPro-Normal");


       pdf.setFontSize(12);

       var lines = pdf.splitTextToSize(encargadoreto, 120);
       pdf.text(lines, 75, 230, null, 0);

       var lines = pdf.splitTextToSize('ENCARGADO DEL REGISTRO ESTATAL', 120);
       pdf.text(lines, 68, 236, null, 0);

       var lines = pdf.splitTextToSize('DE TRANSPORTE DE OAXACA', 120);
       pdf.text(lines, 77, 242, null, 0);
       pdf.setFont("UniviaPro-Bold");

       pdf.addImage(urlimage, 'JPG', 8, 86, 75, 90, undefined, undefined, 0 );


       pdf.addImage(barcodeData, "JPG", 160, 220, 40, 40, undefined, undefined, 0  );


    pdf.save('Constancia'+folio+".pdf");

  }
/*
    crearConstanciaUrbano(urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,vigencia,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto){
      let univiablack_url = UNIVIABLACK.UNIVIABLACK;
      var pdf = new jsPDF();
      pdf.addFileToVFS('UniviaPro-Black-normal.ttf', univiablack_url);
      pdf.addFont('UniviaPro-Black-normal.ttf', 'UniviaPro-Black', 'normal');
      let univia_url = UNIVIAB.UNIVIAB;
      pdf.addFileToVFS('UniviaPro-Bold-bold.ttf', univia_url);
      pdf.addFont('UniviaPro-Bold-bold.ttf', 'UniviaPro-Bold', 'normal');
      pdf.setFont("UniviaPro-Black");
      let tmo = 10
      let saltosnombre = this.cuantasvecessalta(nombre, 14);
      let tamaniosaltosnombre;
      if(saltosnombre==1){
        tamaniosaltosnombre = 0;
        pdf.setFontSize(30);
      }else{
        tamaniosaltosnombre = (saltosnombre*3);
        pdf.setFontSize(25);
      }
      if(saltosnombre>3){
        tamaniosaltosnombre = (saltosnombre*0.4);
        pdf.setFontSize(23);
      }


      var lines = pdf.splitTextToSize(nombre + ' ' +primer_apellido + ' '+ segundo_apellido, 120);
      pdf.text(lines, 90, 103, null, 0);
      pdf.setFont("UniviaPro-Bold");
      var apellidopaterno= primer_apellido + ' '+ segundo_apellido;
      pdf.setFontSize(30);
      pdf.setFont("UniviaPro-Black");
      pdf.text(vigencia, 90, 156);
      pdf.addImage(urlimage, 'JPG', 11, 84, 70, 82, undefined, undefined, 0 );


      pdf.addImage(barcodeData, "JPG", 150, 164, 32, 32, undefined, undefined, 0  );
      pdf.setFont("UniviaPro-Bold");
      let saltosencargadoreto = this.cuantasvecessalta(encargadoreto, 27);
      let tamaniosaltossaltosencargadoreto;
      if(saltosencargadoreto==1){
        tamaniosaltossaltosencargadoreto = 0;
        pdf.setFontSize(6);
      }else{
        tamaniosaltossaltosencargadoreto = (saltosencargadoreto*1.4);
        pdf.setFontSize(6);
      }
      if(saltosencargadoreto>2){
        tamaniosaltossaltosencargadoreto = (saltosencargadoreto*0.6);
        pdf.setFontSize(6);
      }
      var lines = pdf.splitTextToSize(encargadoreto, 34);
      pdf.text(lines, 106, 219 - tamaniosaltossaltosencargadoreto, null, 0);
      var nombrecompleto= ''+nombrea + primerApellidoa + segundoApellidoa;
      let saltosnombrecompleto = this.cuantasvecessalta(nombrecompleto, 31);
      let tamaniosaltosnombrecompleto;
      if(saltosnombrecompleto==1){
        tamaniosaltosnombrecompleto = 0;
        pdf.setFontSize(6);
      }else{
        tamaniosaltosnombrecompleto = (saltosnombrecompleto*1.2);
        pdf.setFontSize(6);
      }
      if(saltosnombrecompleto>2){
        tamaniosaltosnombrecompleto = (saltosnombrecompleto*0.6);
        pdf.setFontSize(6);
      }
      var lines = pdf.splitTextToSize(nombrecompleto , 40);
      pdf.text(lines, 158, 219 - tamaniosaltosnombrecompleto, null, 0);



      let saltosnombreConcesionario = this.cuantasvecessalta(nombreConcesionario, 40);
     let tamaniosaltosnombreConcesionario;
     if(saltosnombreConcesionario==1){
       tamaniosaltosnombreConcesionario = 0;
       pdf.setFontSize(10);
     }else{
       tamaniosaltosnombreConcesionario = (saltosnombreConcesionario*1.2);
       pdf.setFontSize(10);
     }
     if(saltosnombreConcesionario>2){
       tamaniosaltosnombreConcesionario = (saltosnombreConcesionario*1.2);
       pdf.setFontSize(10);
     }
     var lines = pdf.splitTextToSize(nombreConcesionario, 70);

     pdf.text(lines, 12, 178 - tamaniosaltosnombreConcesionario, null, 0);


      pdf.save('Constancia'+folio+".pdf");
    }

    */

    darVueltaReeimpresionUrbano(data,urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto,vigencia){
      let univiablack_url = UNIVIABLACK.UNIVIABLACK;
      var pdf = new jsPDF();
      pdf.addFileToVFS('UniviaPro-Black-normal.ttf', univiablack_url);
      pdf.addFont('UniviaPro-Black-normal.ttf', 'UniviaPro-Black', 'normal');
      let univia_url = UNIVIAB.UNIVIAB;
      pdf.addFileToVFS('UniviaPro-Bold-bold.ttf', univia_url);
      pdf.addFont('UniviaPro-Bold-bold.ttf', 'UniviaPro-Bold', 'normal');
      pdf.setFont("UniviaPro-Black");


      let tmo = 10
      let saltosnombre = this.cuantasvecessalta(nombre, 9);
      let tamaniosaltosnombre;
      if(saltosnombre==1){
        tamaniosaltosnombre = 0;
        pdf.setFontSize(30);
      }else{
        tamaniosaltosnombre = (saltosnombre*3);
        pdf.setFontSize(25);
      }
      if(saltosnombre>3){
        tamaniosaltosnombre = (saltosnombre*0.4);
        pdf.setFontSize(23);
      }

      var lines = pdf.splitTextToSize(nombre + ' ' +primer_apellido + ' '+ segundo_apellido, 120);
      pdf.text(lines, 90, 103, null, 0);
      pdf.setFont("UniviaPro-Bold");
      var apellidopaterno= primer_apellido + ' '+ segundo_apellido;
      /*
      if(saltosnombre==1){
        tamaniosaltosnombre = 0;
        pdf.setFontSize(45);
      }else{
        tamaniosaltosnombre = (saltosnombre*3);
        pdf.setFontSize(32);
      }
      if(saltosnombre>2){
        pdf.setFontSize(14);
        tamaniosaltosnombre = (saltosnombre*0.4);
        pdf.setFontSize(18);
      }
      var lines = pdf.splitTextToSize(nombre, 120);
      pdf.text(lines, 90, 110 - tamaniosaltosnombre, null, 0);
      pdf.setFont("UniviaPro-Bold");
      var apellidopaterno= primer_apellido + ' '+ segundo_apellido;
      let tmoaux = 0;
      let saltosappat = this.cuantasvecessalta(apellidopaterno, 16);
      let tamaniosaltosappat;
      if(saltosappat==1){
        tamaniosaltosappat = 0;
        pdf.setFontSize(26);
      }else{
        tmoaux = 15;
        tamaniosaltosappat = (saltosappat*11.4);
        pdf.setFontSize(18);
      }
      if(saltosappat>2){
        tamaniosaltosappat = (saltosappat*7.4);
        pdf.setFontSize(15);
      }
      var lines = pdf.splitTextToSize(apellidopaterno, 118);
      pdf.text(lines, 90, (130 + tmoaux) - tamaniosaltosappat, null, 0);
      */
      pdf.setFontSize(30);
      pdf.setFont("UniviaPro-Black");
      pdf.text(vigencia, 90, 156);
      pdf.addImage(urlimage, 'JPG', 11, 84, 70, 82, undefined, undefined, 0 );
      pdf.addImage(barcodeData, "JPG", 150, 164, 32, 32, undefined, undefined, 0  );
      pdf.setFont("UniviaPro-Bold");
      let saltosencargadoreto = this.cuantasvecessalta(encargadoreto, 27);
      let tamaniosaltossaltosencargadoreto;
      if(saltosencargadoreto==1){
        tamaniosaltossaltosencargadoreto = 0;
        pdf.setFontSize(6);
      }else{
        tamaniosaltossaltosencargadoreto = (saltosencargadoreto*1.4);
        pdf.setFontSize(6);
      }
      if(saltosencargadoreto>2){
        tamaniosaltossaltosencargadoreto = (saltosencargadoreto*0.6);
        pdf.setFontSize(6);
      }
      var lines = pdf.splitTextToSize(encargadoreto, 34);
      pdf.text(lines, 106, 219 - tamaniosaltossaltosencargadoreto, null, 0);
      var nombrecompleto= ''+nombrea + primerApellidoa + segundoApellidoa;
      let saltosnombrecompleto = this.cuantasvecessalta(nombrecompleto, 31);
      let tamaniosaltosnombrecompleto;
      if(saltosnombrecompleto==1){
        tamaniosaltosnombrecompleto = 0;
        pdf.setFontSize(6);
      }else{
        tamaniosaltosnombrecompleto = (saltosnombrecompleto*1.2);
        pdf.setFontSize(6);
      }
      if(saltosnombrecompleto>2){
        tamaniosaltosnombrecompleto = (saltosnombrecompleto*0.6);
        pdf.setFontSize(6);
      }
      var lines = pdf.splitTextToSize(nombrecompleto , 40);
      pdf.text(lines, 158, 219 - tamaniosaltosnombrecompleto, null, 0);



      let saltosnombreConcesionario = this.cuantasvecessalta(nombreConcesionario, 40);
     let tamaniosaltosnombreConcesionario;
     if(saltosnombreConcesionario==1){
       tamaniosaltosnombreConcesionario = 0;
       pdf.setFontSize(10);
     }else{
       tamaniosaltosnombreConcesionario = (saltosnombreConcesionario*1.2);
       pdf.setFontSize(10);
     }
     if(saltosnombreConcesionario>2){
       tamaniosaltosnombreConcesionario = (saltosnombreConcesionario*1.2);
       pdf.setFontSize(10);
     }
     var lines = pdf.splitTextToSize(nombreConcesionario, 70);

     pdf.text(lines, 12, 178 - tamaniosaltosnombreConcesionario, null, 0);


      pdf.save('Constancia'+folio+".pdf");
    }




  darVueltaReeimpresion(data,urlimage,nombre,primer_apellido,segundo_apellido,sitio,nombreConcesionario,nuc,placa,nombrea,primerApellidoa,segundoApellidoa,barcodeData,folio,encargadoreto,diavencimiento){
         let calibri_url = CALIBRI.CALIBRI;
         let calibrib_url = CALIBRIB.CALIBRIB;
         var pdf = new jsPDF('l', 'mm', [396,   792]);
         pdf.addFileToVFS("Calibri.ttf",calibri_url);
         pdf.addFileToVFS("Calibrib.ttf",calibrib_url);
         pdf.setFontSize(18);
         let tmo= 10;
         pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
         pdf.setFont("Calibrib");
         let saltosnombre = this.cuantasvecessalta(nombre, 20);
         pdf.setLineWidth(0.1);
         pdf.line(44 + tmo, 14, 44 + tmo, 78);
         var lines = pdf.splitTextToSize(nombre, 70);
         let tamaniosaltosnombre;
         if(saltosnombre==1){
           tamaniosaltosnombre = 0;
         }else{
           tamaniosaltosnombre = (saltosnombre*-3.4);
         }
         if(saltosnombre>2){
           pdf.setFontSize(14);
           tamaniosaltosnombre = (saltosnombre*-3.4);
         }
         pdf.text(lines, (43+tmo)  + tamaniosaltosnombre, 78, null, 90);
         pdf.setFontSize(9);
         pdf.addFont("Calibri.ttf", "Calibri", "normal");
         pdf.setFont("Calibri");
         var titlenombre= 'NOMBRE (S)';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
         pdf.text(linetitlenombre, 47+tmo, 78, null, 90);
         pdf.setFontSize(18);
         pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
         pdf.setFont("Calibrib");
         let saltosapellido= this.cuantasvecessalta(primer_apellido, 20);
         let tamaniosaltosprimerapellido;
         if(saltosapellido==1){
           tamaniosaltosprimerapellido = 0;
         }else{
           tamaniosaltosprimerapellido = (saltosapellido*-3.4);
         }
         if(saltosnombre>2){
           pdf.setFontSize(14);
           tamaniosaltosprimerapellido = (saltosapellido*-3.4);
         }
         var lines2 = pdf.splitTextToSize(primer_apellido, 70);
         pdf.text(lines2, (58+tmo) + tamaniosaltosprimerapellido, 78, null, 90);
         pdf.setLineWidth(0.1);
         pdf.line(59+tmo, 14, 59+tmo, 78);
         pdf.setFontSize(9);
         pdf.addFont("Calibri.ttf", "Calibri", "normal");
         pdf.setFont("Calibri");
         var titlenombre= 'PRIMER APELLIDO';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
         pdf.text(linetitlenombre, 62+tmo, 78, null, 90);
         pdf.setFontSize(18);
         pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
         pdf.setFont("Calibrib");
         let saltosapellidosegundo= this.cuantasvecessalta(segundo_apellido, 20);
         let tamaniosaltossegundoapellido;
         if(saltosapellidosegundo==1){
           tamaniosaltossegundoapellido = 0;
         }else{
           tamaniosaltossegundoapellido = (saltosapellidosegundo*-3.4);
         }
         if(saltosnombre>2){
           pdf.setFontSize(14);
           tamaniosaltossegundoapellido = (saltosapellidosegundo*-3.4);
         }
         var lines3 = pdf.splitTextToSize(segundo_apellido, 70);
         pdf.text(lines3, (75+tmo) + tamaniosaltossegundoapellido, 78, null, 90);
         pdf.addFont("Calibri.ttf", "Calibri", "normal");
         pdf.setFont("Calibri");
         pdf.setLineWidth(0.1);
         pdf.line(76+tmo, 14, 76+tmo, 78);
         pdf.setFontSize(9);
         var titlenombre= 'SEGUNDO APELLIDO';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
         pdf.text(linetitlenombre, 79+tmo, 78, null, 90);
         pdf.setFontSize(13);
         pdf.setLineWidth(0.1);
         pdf.line(94+tmo, 14, 94+tmo, 78);
         pdf.setFontSize(9);
         var titlenombre= 'SITIO';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
         pdf.text(linetitlenombre, 97+tmo, 78, null, 90);
         pdf.setFontSize(13);
         let saltos = this.cuantasvecessalta(sitio, 35);
         let espacio = 52;
         if(saltos==1){
           pdf.setFontSize(13);
           espacio = 0;
         }
         if(saltos==2){
           pdf.setFontSize(10);
           espacio = saltos*-1.3;
         }
         if(saltos==3){
           pdf.setFontSize(8);
           espacio = saltos*-1.1;
         }
         if(saltos>3){
           pdf.setFontSize(7);
           espacio = saltos*-1.1;
         }
         var lines5 = pdf.splitTextToSize(sitio, 70);
         pdf.text(lines5, (93+tmo)+espacio, 78, null, 90);
         pdf.setFontSize(11);
         pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
         pdf.setFont("Calibrib");
         let saltosnombre2 = this.cuantasvecessalta(nombreConcesionario, 28);
         let tamaniosaltosnombre2 = 0;
         if(saltosnombre2==1){
           tamaniosaltosnombre2 = 0;
         }
         if(saltosnombre2>1){
           tamaniosaltosnombre2 = (saltosnombre2*-1.6);
           pdf.setFontSize(10);
         }
         if(saltosnombre2>3){
           pdf.setFontSize(8);
           tamaniosaltosnombre2 = (saltosnombre2*-1.1);
         }
         var lines6 = pdf.splitTextToSize(nombreConcesionario, 70);
         pdf.text(lines6, (105+tmo)+tamaniosaltosnombre2, 78, null, 90);
         pdf.setLineWidth(0.1);
         pdf.line(106+tmo, 14, 106+tmo, 78);
         pdf.setFontSize(9);
         pdf.addFont("Calibri.ttf", "Calibri", "normal");
         pdf.setFont("Calibri");
         var titlenombre= 'CONCESIONARIO VINCULADO';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
         pdf.text(linetitlenombre, 109+tmo, 78, null, 90);
         pdf.setFontSize(13);
         var lines6 = pdf.splitTextToSize(nuc, 60);
         pdf.text(lines6, 116+tmo, 78, null, 90);
         pdf.setLineWidth(0.1);
         pdf.line(117+tmo, 14, 117+tmo, 78);
         pdf.setFontSize(9);
         var titlenombre= 'NUC';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
         pdf.text(linetitlenombre, 120+tmo, 78, null, 90);
         pdf.setFontSize(13);
         let dia = diavencimiento.getDate().toString();
         let anio = diavencimiento.getFullYear().toString();
         let mes = "";
         if((diavencimiento.getMonth()+1)<10){
           mes = "0"+(diavencimiento.getMonth() + 1).toString();
         }else{
           mes = (diavencimiento.getMonth() + 1).toString();
         }
         if((diavencimiento.getDate()+1)<=10){
           dia = "0"+(diavencimiento.getDate()).toString();
         }else{
           dia = (diavencimiento.getDate()).toString();
         }
         let meses = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];
         let vigencia = meses[diavencimiento.getMonth()]  + "-" + anio;
         var lines7 = pdf.splitTextToSize(vigencia, 60);
         pdf.text(lines7, 125+tmo, 78, null, 90);
         pdf.setLineWidth(0.1);
         pdf.line(126+tmo, 14, 126+tmo, 78);
         pdf.setFontSize(9);
         var titlenombre= 'FECHA DE VENCIMIENTO';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
         pdf.text(linetitlenombre, 129+tmo, 78, null, 90);
         pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
         pdf.setFont("Calibrib");
         pdf.setFontSize(20);
         var lines4 = pdf.splitTextToSize(placa, 40);
         pdf.text(lines4, 141+tmo, 78, null, 90);
         pdf.setLineWidth(0.1);
         pdf.line(142+tmo, 14, 142+tmo, 78);
         pdf.setFontSize(9);
         pdf.addFont("Calibri.ttf", "Calibri", "normal");
         pdf.setFont("Calibri");
         var titlenombre= 'PLACAS';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
         pdf.text(linetitlenombre, 145+tmo, 78, null, 90);
         pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
         pdf.setFont("Calibrib");
         pdf.setFontSize(20);
         var lines4 = pdf.splitTextToSize(placa, 40);
         pdf.text(lines4, 141+tmo, 78, null, 90);
         pdf.setLineWidth(0.1);
         pdf.line(142+tmo, 14, 142+tmo, 78);
         pdf.setFontSize(9);
         pdf.addFont("Calibri.ttf", "Calibri", "normal");
         pdf.setFont("Calibri");
         var titlenombre= 'PLACAS';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 60);
         pdf.text(linetitlenombre, 145+tmo, 78, null, 90);
         pdf.setLineWidth(0.1);
         pdf.line(176, 40, 176, 106);
         pdf.setFontSize(12);
         pdf.addFont("Calibrib.ttf", "Calibrib", "normal");
         pdf.setFont("Calibrib");
         var linetitlenombre = pdf.splitTextToSize(encargadoreto, 80);
         pdf.text(linetitlenombre, 180, 100, null, 90);
         pdf.setFontSize(11);
         pdf.addFont("Calibri.ttf", "Calibri", "normal");
         pdf.setFont("Calibri");
         var titlenombre= 'ENCARGADO DEL REGISTRO ESTATAL';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
         pdf.text(linetitlenombre, 184, 103, null, 90);
         var titlenombre= 'DE TRANSPORTE DE OAXACA';
         var linetitlenombre = pdf.splitTextToSize(titlenombre, 70);
         pdf.text(linetitlenombre, 187, 97, null, 90);
         pdf.addImage(urlimage, 'JPG', 130, 64, 50, 70, undefined, undefined, 90 );
         pdf.addImage(barcodeData, "JPG", 211-10, 5, 36, 36, undefined, undefined, 90  );
         pdf.save('Constancia'+folio+".pdf");

  }


  getDatosGenerales(concesion: any,contacto: any){
  let contenedoraux = [];
  let direccion = '';
  let contadorDireccion = 2;
  if(concesion.concessionaire.address!=null){
    direccion = concesion.concessionaire.address;
    contadorDireccion = contadorDireccion + this.cuantasvecessalta(direccion,90);

  }
  let email = '';
  if(concesion.concessionaire.contact.email!=null){
    email = concesion.concessionaire.contact.email;
  }
  let telefono = '';
  if(concesion.concessionaire.contact.cel_number!=null){
    telefono = concesion.concessionaire.contact.cel_number;
  }

   contenedoraux  = [
    { columns: [
        { width: 40, text: '', margin: [0, 0, 0, 3]},
       ]
    },
    { columns: [
        { width: 42, text: ''},
        { width: 100, text: 'Teléfono:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
      ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: -14,
            w: 160,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 135, text: ''},
          { width: 100, text: telefono, fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      },
      { columns: [
          { width: 300, text: ''},
          { width: 100, text: 'Email:', fontSize: 10,bold: true, margin: [0, -15, 0, 0]}
        ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 360,
              y: -20,
              w: 170,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
        { columns: [
          { width: 360, text: ''},
          { width: 170, text: email, fontSize: 8, margin: [0, -17, 0, 0]}
        ]
      },
      { columns: [
          { width: 40, text: '', margin: [0, 0, 0, 2]},
         ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: 0,
              w: 440,
              h: (contadorDireccion * 10),
              color: '#f5f5f5',
            },
          ]
        },
      { columns: [
        { width: 20, text: ''},
        { width: 65, text: 'Dirección:', fontSize: 10,bold: true, margin: [0, (contadorDireccion * -17)/2, 0, 0],alignment: 'right'},
        { width: 10, text: ''},

        { width: 410, text: direccion, fontSize: 9, margin: [0, (contadorDireccion * -18)/2, 0, 0] },
        { width: 5, text: ''},
        ]
      },

  ]
  /*
  if(contacto!=null){
    if(contacto.telefono_celular==''){
      contenedoraux  = [
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 18, text: ''},
            { width: 100, text: 'Telefono Local:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: -14,
                w: 160,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 135, text: ''},
              { width: 120, text: contacto.telefono_local, fontSize: 9, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 295, text: ''},
              { width: 100, text: 'Email:', fontSize: 10,bold: true, margin: [0, -15, 0, 0]}
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 360,
                  y: -20,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            { columns: [
              { width: 410, text: ''},
              { width: 100, text: contacto.correo_electronico, fontSize: 10, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 0, 0, 3]},
             ]
          }

      ]
    }else{
      contenedoraux  = [
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 18, text: ''},
            { width: 100, text: 'Telefono Local:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: -14,
                w: 160,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 135, text: ''},
              { width: 120, text: contacto.telefono_local, fontSize: 9, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 285, text: ''},
              { width: 100, text: 'Telefono Celular:', fontSize: 10,bold: true, margin: [0, -15, 0, 0]}
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 360,
                  y: -20,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            { columns: [
              { width: 410, text: ''},
              { width: 100, text: contacto.telefono_celular, fontSize: 10, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 0, 0, 3]},
             ]
          },
          { columns: [
              { width:60, text: ''},
              { width: 100, text: 'Email:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: -14,
                  w: 440,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            { columns: [
                { width: 115, text: ''},
                { width: 320, text: contacto.correo_electronico, fontSize: 9, margin: [0, -15, 0, 0]}
              ]
            }

      ];
    }
  }

  */











  let contenedor  = [];

  if(concesion.concessionaire.person_type == 'M'){
    let nombreauxiliar = concesion.concessionaire.business_name;
    let contador = 1;
     contador = contador + this.cuantasvecessaltarazonSocial(nombreauxiliar);
    let tamanio = (contador * -20)/2;;
    if(contador > 2){
      tamanio = (contador * -18)/2;
    }
    contenedor.push(
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: -14,
              w: 440,
              h: (contador * 10),
              color: '#f5f5f5',
            },
          ]
        },
        { columns: [
            { width: 100, text: ''},
            { width: 415, text: concesion.concessionaire.business_name, fontSize: 11 , margin: [0, tamanio, 0, 0] }
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 140, text: ''},
            { width: 120, text: '', fontSize: 9,bold: true},
            { width: 120, text: 'RAZON SOCIAL', fontSize: 9,bold: true},
            { width: 160, text: '', fontSize: 9,bold: true}
          ]
        },
        { columns: [
            { width: 65, text: ''},
            { width: 100, text: 'RFC:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: -14,
                w: 160,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 115, text: ''},
              { width: 120, text: concesion.concessionaire.rfc, fontSize: 9, margin: [0, -15, 0, 0]}
            ]
          }
    );
  }

  if(concesion.concessionaire.person_type == 'F'){
    let nombreauxiliar = concesion.concessionaire.name;
    let contador = 1;
     contador = contador + this.cuantasvecessaltanombre(nombreauxiliar);
    let tamanio = (contador * -17)/2;;
    if(contador > 2){
      tamanio = (contador * -14)/2;
    }


    contenedor.push(
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: -14,
              w: 440,
              h: (contador * 10),
              color: '#f5f5f5',
            },
          ]
        },
        { columns: [
            { width: 110, text: ''},
            { width: 130, text: concesion.concessionaire.name, fontSize: 11 , margin: [(contador * -17)/2, (contador * -17)/2, 0, 0],alignment: 'center'}
          ]
        },
        { columns: [
            { width: 235, text: ''},
            { width: 120, text: concesion.concessionaire.first_surname, fontSize: 11 , margin: [0, tamanio, 0, 0],alignment: 'center'}
          ]
        },
        { columns: [
            { width: 365, text: ''},
            { width: 120, text: concesion.concessionaire.second_surname, fontSize: 11 , margin: [0, tamanio, 0, 0],alignment: 'center'}
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 140, text: ''},
            { width: 120, text: 'NOMBRE', fontSize: 9,bold: true},
            { width: 120, text: 'PRIMER APELLIDO', fontSize: 9,bold: true},
            { width: 160, text: 'SEGUNDO APELLIDO', fontSize: 9,bold: true}
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 58, text: ''},
            { width: 100, text: 'CURP:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: -14,
                w: 160,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 115, text: ''},
              { width: 120, text: concesion.concessionaire.curp, fontSize: 9, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 305, text: ''},
              { width: 100, text: 'RFC:', fontSize: 10,bold: true, margin: [0, -15, 0, 0]}
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 360,
                  y: -20,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },

                  { columns: [
                      { width: 410, text: ''},
                      { width: 100, text: concesion.concessionaire.rfc, fontSize: 10, margin: [0, -15, 0, 0]}
                    ]
                  },
                  //contenedorContacto
                  contenedoraux
    );
  }



  let contenedorNombre;
  let contenedorRazonSocial;
  let nombreauxiliar = concesion.concessionaire.name;
  let contador = 1;
   contador = contador + this.cuantasvecessaltanombre(nombreauxiliar);
  let tamanio = (contador * -17)/2;;
  if(contador > 2){
    tamanio = (contador * -14)/2;
  }
  let datosGenerales = [
    {
        canvas: [
          {
            type: 'rect',
            x: 20,
            y: 10,
            w: 510,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'DATOS GENERALES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    }
    ,
  { columns: [
      { width: 40, text: '', margin: [0, 2, 0, 0]},
     ]
  },
  { columns: [
      { width: 6, text: ''},
      { width: 100, text: 'CONCESIONARIO:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
    ]
  },
  contenedor
  ];
   return datosGenerales;
}


getDatosGeneralesReto(concesion: any,contacto: any){
  let contenedoraux = [];
  let direccion = '';
  let contadorDireccion = 1;
  if(concesion.concessionaire.address!=null){
    direccion = concesion.concessionaire.address;
    contadorDireccion = contadorDireccion + this.cuantasvecessalta(direccion,90);

  }
  let email = '';
  if(concesion.concessionaire.contact.email!=null){
    email = concesion.concessionaire.contact.email;
  }
  let telefono = '';
  if(concesion.concessionaire.contact.cel_number!=null){
    telefono = concesion.concessionaire.contact.cel_number;
  }

   contenedoraux  = [
    { columns: [
        { width: 40, text: '', margin: [0, 0, 0, 3]},
       ]
    },
    { columns: [
        { width: 42, text: ''},
        { width: 100, text: 'Teléfono:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
      ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: -14,
            w: 160,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 135, text: ''},
          { width: 100, text: telefono, fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      },
      { columns: [
          { width: 300, text: ''},
          { width: 100, text: 'Email:', fontSize: 10,bold: true, margin: [0, -15, 0, 0]}
        ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 360,
              y: -20,
              w: 170,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
        { columns: [
          { width: 360, text: ''},
          { width: 170, text: email, fontSize: 8, margin: [0, -17, 0, 0]}
        ]
      },
      { columns: [
          { width: 40, text: '', margin: [0, 0, 0, 2]},
         ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: 0,
              w: 440,
              h: (contadorDireccion * 10),
              color: '#f5f5f5',
            },
          ]
        },
      { columns: [
        { width: 20, text: ''},
        { width: 65, text: 'Dirección:', fontSize: 10,bold: true, margin: [0, (contadorDireccion * -17)/2, 0, 0],alignment: 'right'},
        { width: 10, text: ''},

        { width: 410, text: direccion, fontSize: 9, margin: [0, (contadorDireccion * -18)/2, 0, 0] },
        { width: 5, text: ''},
        ]
      },

  ]
  /*
  if(contacto!=null){
    if(contacto.telefono_celular==''){
      contenedoraux  = [
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 18, text: ''},
            { width: 100, text: 'Telefono Local:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: -14,
                w: 160,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 135, text: ''},
              { width: 120, text: contacto.telefono_local, fontSize: 9, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 295, text: ''},
              { width: 100, text: 'Email:', fontSize: 10,bold: true, margin: [0, -15, 0, 0]}
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 360,
                  y: -20,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            { columns: [
              { width: 410, text: ''},
              { width: 100, text: contacto.correo_electronico, fontSize: 10, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 0, 0, 3]},
             ]
          }

      ]
    }else{
      contenedoraux  = [
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 18, text: ''},
            { width: 100, text: 'Telefono Local:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: -14,
                w: 160,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 135, text: ''},
              { width: 120, text: contacto.telefono_local, fontSize: 9, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 285, text: ''},
              { width: 100, text: 'Telefono Celular:', fontSize: 10,bold: true, margin: [0, -15, 0, 0]}
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 360,
                  y: -20,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            { columns: [
              { width: 410, text: ''},
              { width: 100, text: contacto.telefono_celular, fontSize: 10, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 0, 0, 3]},
             ]
          },
          { columns: [
              { width:60, text: ''},
              { width: 100, text: 'Email:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: -14,
                  w: 440,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            { columns: [
                { width: 115, text: ''},
                { width: 320, text: contacto.correo_electronico, fontSize: 9, margin: [0, -15, 0, 0]}
              ]
            }

      ];
    }
  }

  */











  let contenedor  = [];

  if(concesion.concessionaire.person_type == 'M'){
    let nombreauxiliar = concesion.concessionaire.business_name;
    let contador = 1;
     contador = contador + this.cuantasvecessaltarazonSocial(nombreauxiliar);
    let tamanio = (contador * -20)/2;;
    if(contador > 2){
      tamanio = (contador * -18)/2;
    }
    contenedor.push(
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: -14,
              w: 440,
              h: (contador * 10),
              color: '#f5f5f5',
            },
          ]
        },
        { columns: [
            { width: 100, text: ''},
            { width: 415, text: concesion.concessionaire.business_name, fontSize: 11 , margin: [0, tamanio, 0, 0] }
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
    );
  }

  if(concesion.concessionaire.person_type == 'F'){
    let nombreauxiliar = concesion.concessionaire.name;
    let contador = 1;
     contador = contador + this.cuantasvecessaltanombre(nombreauxiliar);
    let tamanio = (contador * -17)/2;;
    if(contador > 2){
      tamanio = (contador * -14)/2;
    }


    contenedor.push(
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: -14,
              w: 440,
              h: (contador * 10),
              color: '#f5f5f5',
            },
          ]
        },
        { columns: [
            { width: 110, text: ''},
            { width: 130, text: concesion.concessionaire.name, fontSize: 11 , margin: [(contador * -17)/2, (contador * -17)/2, 0, 0],alignment: 'center'}
          ]
        },
        { columns: [
            { width: 235, text: ''},
            { width: 120, text: concesion.concessionaire.first_surname, fontSize: 11 , margin: [0, tamanio, 0, 0],alignment: 'center'}
          ]
        },
        { columns: [
            { width: 365, text: ''},
            { width: 120, text: concesion.concessionaire.second_surname, fontSize: 11 , margin: [0, tamanio, 0, 0],alignment: 'center'}
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
        { columns: [
            { width: 140, text: ''},
            { width: 120, text: 'NOMBRE', fontSize: 9,bold: true},
            { width: 120, text: 'PRIMER APELLIDO', fontSize: 9,bold: true},
            { width: 160, text: 'SEGUNDO APELLIDO', fontSize: 9,bold: true}
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 0, 0, 3]},
           ]
        },
    );
  }



  let contenedorNombre;
  let contenedorRazonSocial;
  let nombreauxiliar = concesion.concessionaire.name;
  let contador = 1;
   contador = contador + this.cuantasvecessaltanombre(nombreauxiliar);
  let tamanio = (contador * -17)/2;;
  if(contador > 2){
    tamanio = (contador * -14)/2;
  }
  let datosGenerales = [
    {
        canvas: [
          {
            type: 'rect',
            x: 20,
            y: 10,
            w: 510,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'DATOS GENERALES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    }
    ,
  { columns: [
      { width: 40, text: '', margin: [0, 2, 0, 0]},
     ]
  },
  { columns: [
      { width: 6, text: ''},
      { width: 100, text: 'CONCESIONARIO:', fontSize: 10,bold: true, margin: [0, 0, 0, 0]}
    ]
  },
  contenedor
  ];
   return datosGenerales;
}

getDatosBeneficiarios(beneficiariosSicac,beneficiariosReto){
  let beneficiariosRetoArray = [];
  let beneficiariosSicacArray = [];

  if(beneficiariosReto.length>0){
    for(var i = 0; i < beneficiariosReto.length;i++){
      let stringestado = '';
      if(beneficiariosReto[i].swActivo == 0){
        stringestado = 'INACTIVO';
      }
      if(beneficiariosReto[i].swActivo == 1){
        stringestado = 'ACTIVO';
      }

      let sizefont = 10;
      if(this.cuantasvecessaltanombre(beneficiariosReto[i].beneficiario)>1){
         sizefont = 8;
      }
      beneficiariosRetoArray.push(
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: 0,
                w: 260,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 450,
                  y: -20,
                  w: 80,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
          { columns: [
              { width: 20, text: ''},
              { width: 65, text: 'Nombre:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
              { width: 10, text: ''},
              { width: 240, text: beneficiariosReto[i].beneficiario, fontSize: sizefont, margin: [0, -17, 0, 0],alignment: 'center'},
              { width: 5, text: ''},
              { width: 90, text: 'Estado:', fontSize: 10,bold: true, margin: [0, -20, 0, 0],alignment: 'right'},
              { width: 20, text: ''},
              { width: 80, text: stringestado, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}

            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 2]},
             ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            {
                canvas: [
                  {
                    type: 'rect',
                    x: 360,
                    y: -20,
                    w: 170,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
            { columns: [
                { width: 20, text: ''},
                { width: 65, text: 'Fecha Alta:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
                { width: 10, text: ''},
                { width: 160, text: this.funcioncrearfecha(beneficiariosReto[i].FechaAlta), fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                { width: 5, text: ''},
                { width: 90, text: 'Parentesco:', fontSize: 10,bold: true, margin: [0, -20, 0, 0],alignment: 'right'},
                { width: 20, text: ''},
                { width: 150, text:  beneficiariosReto[i].Parentesco, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}

              ]
            },
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 2]},
               ]
            },
      );
    }
  }else{
     beneficiariosRetoArray.push(
       { columns: [
           { width: 40, text: '', margin: [0, 2, 0, 0]},
          ]
       },
       {
           canvas: [
             {
               type: 'rect',
               x: 90,
               y: 0,
               w: 440,
               h: 20,
               color: '#f5f5f5',
             },
           ]
         },
         { columns: [
             { width: 95, text: ''},
             { width: 220, text: "SIN BENEFICIARIOS REGISTRADOS", fontSize: 9, margin: [0, -15, 0, 0]}
           ]
         }
     );
  }


  if(beneficiariosSicac.length>0){
    for(var i = 0; i < beneficiariosSicac.length;i++){
      let nombrecompleto = '';
      if(beneficiariosSicac[i].name!=undefined){
        nombrecompleto = nombrecompleto + beneficiariosSicac[i].name;
      }
      if(beneficiariosSicac[i].first_surname!=undefined){
        nombrecompleto = nombrecompleto + ' ' + beneficiariosSicac[i].first_surname;
      }
      if(beneficiariosSicac[i].second_surname!=undefined){
        nombrecompleto = nombrecompleto + ' ' + beneficiariosSicac[i].second_surname;
      }


      let sizefont = 10;
      if(this.cuantasvecessaltanombre(nombrecompleto)>1){
         sizefont = 8;
      }
      beneficiariosSicacArray.push(
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: 0,
                w: 260,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 400,
                  y: -20,
                  w: 130,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
          { columns: [
              { width: 20, text: ''},
              { width: 65, text: 'Nombre:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
              { width: 10, text: ''},
              { width: 240, text: nombrecompleto, fontSize: sizefont, margin: [0, -17, 0, 0],alignment: 'center'},
              { width: 2, text: ''},
              { width: 50, text: 'CURP:', fontSize: 10,bold: true, margin: [0, -20, 0, 0],alignment: 'right'},
              { width: 20, text: ''},
              { width: 120, text: beneficiariosSicac[i].curp, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}

            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 2]},
             ]
          },
      );
    }
  }else{
    beneficiariosSicacArray.push(
      { columns: [
          { width: 40, text: '', margin: [0, 2, 0, 0]},
         ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: 0,
              w: 440,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
        { columns: [
            { width: 95, text: ''},
            { width: 220, text: "SIN BENEFICIARIOS REGISTRADOS", fontSize: 9, margin: [0, -15, 0, 0]}
          ]
        }
    );
  }








  let datosGenerales = [
    {
        canvas: [
          {
            type: 'rect',
            x: 20,
            y: 10,
            w: 510,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'BENEFICIARIOS', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    }
    ,
  { columns: [
      { width: 40, text: '', margin: [0, 2, 0, 16]},
     ]
  },
  { columns: [
      { width: 20, text: ''},
      { width: 300, text: 'BENEFICIARIOS RETO', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
    ]
  },
  beneficiariosRetoArray,
  { columns: [
      { width: 40, text: '', margin: [0, 2, 0, 16]},
     ]
  },
  { columns: [
      { width: 20, text: ''},
      { width: 300, text: 'BENEFICIARIOS SICAC', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
    ]
  },
  beneficiariosSicacArray,

  ];
   return datosGenerales;
}


cuantasvecessaltanombre(palabra: any){
  let veces = 1;
  let tamaniopalabra = palabra.length;
  for(var i = 1; (i*30) < tamaniopalabra; i++){
    veces = veces + 1;
  }
   return veces;
}

cuantasvecessaltarazonSocial(palabra: any){
  let veces = 1;
  let tamaniopalabra = palabra.length;
  for(var i = 1; (i*60) < tamaniopalabra; i++){
    veces = veces + 1;
  }
   return veces;
}


getDatosDeLaConcesionReto(concesion: any,mensajeVehiculos: any){
  let mensaje = "";
   if(concesion.status == true){
    mensaje = mensaje + "Activo ";
  }else{
    mensaje = mensaje + "Inactivo ";
  }

  let bloqueado = "";


  if(concesion.attached.locked.status == true){
     bloqueado = "SI";
  }else{
    bloqueado = "NO";
  }


  let reto = "";

  if(concesion.attached.reto != null){
    if(concesion.attached.reto.status == true){
       reto = "SI";
    }else{
      reto = "NO";
    }
  }else{
    reto = "NO";
  }


  let foliorobado = '';
  if(concesion.attached.locked.stolen_folio ==null){
    foliorobado = "NO";

  }else{
    foliorobado = "SI";
  }


  let observacion = ""+concesion.main_notes;
  //let observacion = '';
  let splitobservacion = observacion.split("\n");
  let contador = 1;
  for(var i = 0; i < splitobservacion.length; i++){
    if(splitobservacion[i].length > 70){
      contador = contador + this.cuantasvecessalta(splitobservacion[i],90);
    }else{
      contador = contador + 1;
    }
  }

       let contadorActa = 1 + this.cuantasvecessalta(""+concesion.delivey_certificate_notes,90);
       //let contadorActa = 1;

  let nombreSitio = concesion.site.name;

  let contadorSitio = 1;
      contadorSitio = contadorSitio + this.cuantasvecessalta(nombreSitio,90);
  let datosDeLaConcesion = [
    { //aqui empieza datos de la concesion
        canvas: [
          {
            type: 'rect',
            x: 20,
            y: 10,
            w: 510,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'DATOS DE LA CONCESIÓN', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: 0,
              w: 170,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 360,
                y: -20,
                w: 170,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 20, text: ''},
              { width: 65, text: 'MODALIDAD:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
              { width: 10, text: ''},
              { width: 160, text: concesion.modality.name, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
              { width: 5, text: ''},
              { width: 90, text: 'NUC:', fontSize: 10,bold: true, margin: [0, -17, 0, 0],alignment: 'right'},
              { width: 20, text: ''},
              { width: 150, text:  concesion.consecutive_number+'/'+ concesion.unique_number_key, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}
            ]
          },
              { columns: [
                  { width: 40, text: '', margin: [0, 2, 0, 0]},
                 ]
              },
              {
                  canvas: [
                    {
                      type: 'rect',
                      x: 90,
                      y: 0,
                      w: 440,
                      h: (contadorSitio * 10),
                      color: '#f5f5f5',
                    },
                  ]
                },
              { columns: [
                { width: 20, text: ''},
                { width: 65, text: 'SITIO:', fontSize: 10,bold: true, margin: [0, (contadorSitio * -17)/2, 0, 0],alignment: 'right'},
                { width: 10, text: ''},

                { width: 410, text: concesion.site.name, fontSize: 9, margin: [0, (contadorSitio * -18)/2, 0, 0] },
                { width: 5, text: ''},
                ]
              },
  ];
  return datosDeLaConcesion;
}


getDatosDeLaConcesion(concesion: any,totalvehiculos: any){
  let mensaje = "";
   if(concesion.status == true){
    mensaje = mensaje + "Activo ";
  }else{
    mensaje = mensaje + "Inactivo ";
  }

  let bloqueado = "";


  if(concesion.attached.locked.status == true){
     bloqueado = "SI";
  }else{
    bloqueado = "NO";
  }


  let reto = "";

  if(concesion.attached.reto != null){
    if(concesion.attached.reto.status == true){
       reto = "SI";
    }else{
      reto = "NO";
    }
  }else{
    reto = "NO";
  }


  let foliorobado = '';
  if(concesion.attached.locked.stolen_folio ==null){
    foliorobado = "NO";

  }else{
    foliorobado = "SI";
  }


  let observacion = ""+concesion.main_notes;
  //let observacion = '';
  let splitobservacion = observacion.split("\n");
  let contador = 1;
  for(var i = 0; i < splitobservacion.length; i++){
    if(splitobservacion[i].length > 70){
      contador = contador + this.cuantasvecessalta(splitobservacion[i],90);
    }else{
      contador = contador + 1;
    }
  }

       let contadorActa = 1 + this.cuantasvecessalta(""+concesion.delivey_certificate_notes,90);
       //let contadorActa = 1;

  let nombreSitio = concesion.site.name;

  let contadorSitio = 1;
      contadorSitio = contadorSitio + this.cuantasvecessalta(nombreSitio,90);
  let datosDeLaConcesion = [
    { //aqui empieza datos de la concesion
        canvas: [
          {
            type: 'rect',
            x: 20,
            y: 10,
            w: 510,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'DATOS DE LA CONCESIÓN', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 170,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 360,
              y: -20,
              w: 170,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
      { columns: [
          { width: 20, text: ''},
          { width: 65, text: 'ACUERDO:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
          { width: 10, text: ''},
          { width: 160, text: concesion.agreement, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
          { width: 5, text: ''},
          { width: 90, text: 'FECHA DEL ACUERDO:', fontSize: 10,bold: true, margin: [0, -20, 0, 0],alignment: 'right'},
          { width: 20, text: ''},
          { width: 150, text: this.funcioncrearfecha(concesion.agreement_date), fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}

        ]
      },
      { columns: [
          { width: 40, text: '', margin: [0, 2, 0, 0]},
         ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: 0,
              w: 170,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 360,
                y: -20,
                w: 170,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 20, text: ''},
              { width: 65, text: 'MODALIDAD:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
              { width: 10, text: ''},
              { width: 160, text: concesion.modality.name, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
              { width: 5, text: ''},
              { width: 90, text: 'NUC:', fontSize: 10,bold: true, margin: [0, -17, 0, 0],alignment: 'right'},
              { width: 20, text: ''},
              { width: 150, text:  concesion.consecutive_number+'/'+ concesion.unique_number_key, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}
            ]
          },
        { columns: [
            { width: 40, text: '', margin: [0, 2, 0, 0]},
           ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: 0,
                w: 170,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
            { columns: [
                { width: 20, text: ''},
                { width: 65, text: 'VIGENCIA:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
                { width: 10, text: ''},
                { width: 160, text: concesion.term , fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                ]
            },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },


          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            {
                canvas: [
                  {
                    type: 'rect',
                    x: 360,
                    y: -20,
                    w: 170,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
              { columns: [
                  { width: 5, text: ''},
                  { width: 80, text: 'No. UNIDADES ACTIVAS:', fontSize: 10,bold: true, margin: [0, -22, 0, 0],alignment: 'right'},
                  { width: 10, text: ''},
                  { width: 160, text: totalvehiculos , fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                  { width: 5, text: ''},
                  { width: 90, text: 'No. UNIDADES AMPARADAS:', fontSize: 10,bold: true, margin: [0, -22, 0, 0],alignment: 'right'},
                  { width: 20, text: ''},
                  { width: 150, text: concesion.authorized_vehicles, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}
                ]
              },
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 0]},
               ]
            },


          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            {
                canvas: [
                  {
                    type: 'rect',
                    x: 360,
                    y: -20,
                    w: 170,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
              { columns: [
                  { width: 20, text: ''},
                  { width: 65, text: 'FECHA DE INICIO:', fontSize: 10,bold: true, margin: [0, -21, 0, 0],alignment: 'right'},
                  { width: 10, text: ''},
                  { width: 160, text: this.funcioncrearfecha(concesion.start_date), fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                  { width: 5, text: ''},
                  { width: 90, text: 'VENCIMIENTO:', fontSize: 10,bold: true, margin: [0, -17, 0, 0],alignment: 'right'},
                  { width: 20, text: ''},
                  { width: 150, text: this.funcioncrearfecha(concesion.expiration_date), fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}
                ]
              },
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 0]},
               ]
            },


            {
                canvas: [
                  {
                    type: 'rect',
                    x: 90,
                    y: 0,
                    w: 170,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
                { columns: [
                    { width: 20, text: ''},
                    { width: 65, text: 'ESTATUS:', fontSize: 10,bold: true, margin: [0, -17, 0, 0],alignment: 'right'},
                    { width: 10, text: ''},
                    { width: 160, text: mensaje, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},

                  ]
                },
              { columns: [
                  { width: 40, text: '', margin: [0, 2, 0, 0]},
                 ]
              },
            /*
            {
                canvas: [
                  {
                    type: 'rect',
                    x: 90,
                    y: 5,
                    w: 70,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
              {
                  canvas: [
                    {
                      type: 'rect',
                      x: 230,
                      y: -20,
                      w: 70,
                      h: 20,
                      color: '#f5f5f5',
                    },
                  ]
                },
                {
                    canvas: [
                      {
                        type: 'rect',
                        x: 360,
                        y: -20,
                        w: 50,
                        h: 20,
                        color: '#f5f5f5',
                      },
                    ]
                  },
                  {
                      canvas: [
                        {
                          type: 'rect',
                          x: 460,
                          y: -20,
                          w: 70,
                          h: 20,
                          color: '#f5f5f5',
                        },
                      ]
                    },
                { columns: [
                    { width: 20, text: ''},
                    { width: 65, text: 'ESTATUS:', fontSize: 10,bold: true, margin: [0, -18, 0, 0],alignment: 'right'},
                    { width: 20, text: ''},
                    { width: 30, text: mensaje ,fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                    { width: 25, text: ''},
                    { width: 65, text: 'CONCESIÓN BLOQUEADO:', fontSize: 10,bold: true, margin: [0, -21, 0, 0],alignment: 'right'},
                    { width: 35, text: ''},
                    { width: 15, text: bloqueado, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                    { width: 10, text: ''},
                    { width: 60, text: 'FOLIO ROBADO:', fontSize: 10,bold: true, margin: [0, -21, 0, 0],alignment: 'right'},
                    { width: 25, text: ''},
                    { width: 30, text: foliorobado, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                    { width: 20, text: ''},
                    { width: 30, text: 'RETO:', fontSize: 10,bold: true, margin: [0, -21, 0, 0],alignment: 'right'},
                    { width: 30, text: ''},
                    { width: 30, text: reto, fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                    { width: 20, text: ''},
                   ]
                },

                */

              { columns: [
                  { width: 40, text: '', margin: [0, 2, 0, 0]},
                 ]
              },
              {
                  canvas: [
                    {
                      type: 'rect',
                      x: 90,
                      y: 0,
                      w: 440,
                      h: (contadorSitio * 10),
                      color: '#f5f5f5',
                    },
                  ]
                },
              { columns: [
                { width: 20, text: ''},
                { width: 65, text: 'SITIO:', fontSize: 10,bold: true, margin: [0, (contadorSitio * -17)/2, 0, 0],alignment: 'right'},
                { width: 10, text: ''},

                { width: 410, text: concesion.site.name, fontSize: 9, margin: [0, (contadorSitio * -18)/2, 0, 0] },
                { width: 5, text: ''},
                ]
              },

              { columns: [
                  { width: 40, text: '', margin: [0, 2, 0, 0]},
                 ]
              },

  ];
  return datosDeLaConcesion;
}


getDatosDeUbicacion(concesion: any,contacto: any){
  let auxdirrecion = [];
 /*
  if(contacto!=null){

  for(var i = 0; i < contacto.direcciones.length;i++){
    if(contacto.direcciones[i].actual == true){
      let contador = 1;
      let direccionaux = '' + contacto.direcciones[i].calle.toUpperCase() +' - '+ contacto.direcciones[i].numero_interno +' ,'+  contacto.direcciones[i].colonia.toUpperCase() +' ,'+ contacto.direcciones[i].colonia.toUpperCase() +' ,'+  contacto.direcciones[i].codigo_postal+' '+contacto.direcciones[i].calle.toUpperCase();
      let splitobservacion = direccionaux.split("\n");
      for(var i = 0; i < splitobservacion.length; i++){
        if(splitobservacion[i].length > 70){
          contador = contador + this.cuantasvecessalta(direccionaux);
        }else{
          contador = contador + 1;
        }
      }
      auxdirrecion.push(

      { columns: [
          { width: 40, text: '', margin: [0, 2, 0, 0]},
         ]
      },
      {
          canvas: [
            {
              type: 'rect',
              x: 90,
              y: 0,
              w: 440,
              h: (contador * 10),
              color: '#f5f5f5',
            },
          ]
        },
      { columns: [
        { width: 3, text: ''},
        { width: 82, text: 'DIRECCIÓN:', fontSize: 10,bold: true, margin: [0, (contador * -17)/2, 0, 0],alignment: 'right'},
        { width: 10, text: ''},
        { width: 430, text: direccionaux, fontSize: 9, margin: [0, (contador * -18)/2, 0, 0],alignment: 'justify'},
        { width: 5, text: ''},
        ]
      },
    );
    }

  }
}

*/







  let datosDeUbicacion = [
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },{ //aqui empieza DATOS DE UBICACION
                canvas: [
                  {
                    type: 'rect',
                    x: 20,
                    y: 10,
                    w: 510,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
          { columns: [
              { width: 20, text: ''},
              { width: 300, text: 'DATOS DE UBICACIÓN', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
            ]
          },
        { columns: [
            { width: 40, text: '', margin: [0, 2, 0, 0]},
           ]
        },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 440,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
          { columns: [
            { width: 20, text: ''},
            { width: 65, text: 'LOCALIDAD:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
            { width: 10, text: ''},
            { width: 440, text: concesion.concessionaire.locality.name, fontSize: 10, margin: [0, -17, 0, 0]},
            { width: 5, text: ''},
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 440,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
        { columns: [
          { width: 20, text: ''},
          { width: 65, text: 'MUNICIPIO:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
          { width: 10, text: ''},
          { width: 440, text: concesion.concessionaire.locality.municipality.name, fontSize: 10, margin: [0, -17, 0, 0]},
          { width: 5, text: ''},
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 2, 0, 0]},
           ]
        },
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: 0,
                w: 440,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
      { columns: [
        { width: 20, text: ''},
        { width: 65, text: 'DISTRITO:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
        { width: 10, text: ''},

        { width: 340, text: concesion.concessionaire.locality.municipality.district.name, fontSize: 10, margin: [0, -17, 0, 0]},
        { width: 5, text: ''},
        ]
      },
      auxdirrecion
  ];
   return datosDeUbicacion;
}


getDatosDeUbicacionReto(concesion: any,contacto: any){
  let auxdirrecion = [];
  let datosDeUbicacion = [
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },{ //aqui empieza DATOS DE UBICACION
                canvas: [
                  {
                    type: 'rect',
                    x: 20,
                    y: 10,
                    w: 510,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
          { columns: [
              { width: 20, text: ''},
              { width: 300, text: 'DATOS DE UBICACIÓN', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
            ]
          },
        { columns: [
            { width: 40, text: '', margin: [0, 2, 0, 0]},
           ]
        },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 440,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
          { columns: [
            { width: 20, text: ''},
            { width: 65, text: 'LOCALIDAD:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
            { width: 10, text: ''},
            { width: 440, text: concesion.concessionaire.locality.name, fontSize: 10, margin: [0, -17, 0, 0]},
            { width: 5, text: ''},
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 440,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
        { columns: [
          { width: 20, text: ''},
          { width: 65, text: 'MUNICIPIO:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
          { width: 10, text: ''},
          { width: 440, text: concesion.concessionaire.locality.municipality.name, fontSize: 10, margin: [0, -17, 0, 0]},
          { width: 5, text: ''},
          ]
        },
        { columns: [
            { width: 40, text: '', margin: [0, 2, 0, 0]},
           ]
        }
  ];
   return datosDeUbicacion;
}


getDatosReto(concesion: any){
  let auxdirrecion = [];
  let datosDeUbicacion = [
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },{ //aqui empieza DATOS DE UBICACION
                canvas: [
                  {
                    type: 'rect',
                    x: 20,
                    y: 10,
                    w: 510,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
          { columns: [
              { width: 20, text: ''},
              { width: 300, text: 'DATOS RETO', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
            ]
          },
        { columns: [
            { width: 40, text: '', margin: [0, 2, 0, 0]},
           ]
        },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 440,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
          { columns: [
            { width: 20, text: ''},
            { width: 65, text: 'N° de RETO:', fontSize: 10,bold: true, margin: [0, -16, 0, 0],alignment: 'right'},
            { width: 10, text: ''},
            { width: 440, text: 'R1', fontSize: 10, margin: [0, -17, 0, 0]},
            { width: 5, text: ''},
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
  ];
   return datosDeUbicacion;
}



getDatosTarifas(listTarifas){
   let tarifas;
  if(listTarifas.length > 0 ){
     tarifas = [
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 0]},
               ]
            },{
                  canvas: [
                    {
                      type: 'rect',
                      x: 20,
                      y: 10,
                      w: 510,
                      h: 20,
                      color: '#f5f5f5',
                    },
                  ]
                },
            { columns: [
                { width: 20, text: ''},
                { width: 300, text: 'TARIFAS', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
              ]
            },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          this.getBitacoradeTarifas(listTarifas)

    ];
  }else{
    tarifas =  [
      { //aqui empieza DATOS DE UBICACION
          canvas: [
            {
              type: 'rect',
              x: 20,
              y: 10,
              w: 510,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'Tarifas', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 440,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 95, text: ''},
          { width: 220, text: "SIN TARIFAS REGISTRADAS", fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      }
  ]
  }

  return tarifas;
}


getBitacoradeTarifas(tarifasArray: any){


   let arrayParaTabla = [];
   for(var i = 0; i < tarifasArray.length; i++){
     if(i == 0){
       arrayParaTabla.push(
         [
           {text: 'HOJA VALORADA', fontSize:7, bold: true,alignment: 'center' } ,
           { text: 'FECHA DE EMISIÓN', fontSize:7, bold: true,alignment: 'center' }  ,
           { text: 'SERIE DEL VEHÍCULO', fontSize:7, bold: true,alignment: 'center' }  ,

           {text: 'MUNICIPIO', fontSize:7, bold: true,alignment: 'center' } ,
         {text: 'LOCALIDAD', fontSize:7, bold: true,alignment: 'center' } ,

         ]
       );
     }
      arrayParaTabla.push([
        {text:tarifasArray[i].foliated_sheet, fontSize:7,alignment: 'center'} ,
        { text: this.funcioncrearfecha(tarifasArray[i].expedition_date), fontSize:7,alignment: 'center' },
        { text: tarifasArray[i].serial_number, fontSize:7,alignment: 'center'}  ,

         {text: tarifasArray[i].municipality, fontSize:7,alignment: 'center'} ,
         {text: tarifasArray[i].locality, fontSize:7,alignment: 'center'}
      ]
      );
   }
  let datosTablaReporteLocalidad =
    {
          style: 'tableExample',
           table: {
            headerRows: 1,
            widths: [ '15%','15%','20%','25%', '25%'],
            body:  arrayParaTabla,
          },layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#FFFFFF' : null;
            },
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
            },
            paddingLeft: function(i, node) { return 10; },
          }
   };
  return datosTablaReporteLocalidad;
}


getDatosVehiculos(listVehiculos){
   let vehiculos;
  if(listVehiculos.length > 0 ){
     vehiculos = [
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 0]},
               ]
            },{
                  canvas: [
                    {
                      type: 'rect',
                      x: 20,
                      y: 10,
                      w: 510,
                      h: 20,
                      color: '#f5f5f5',
                    },
                  ]
                },
            { columns: [
                { width: 20, text: ''},
                { width: 300, text: 'VEHÍCULOS ACTIVOS', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
              ]
            },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          this.getBitacoraVehiculos(listVehiculos)

    ];
  }else{
    vehiculos =  [
      { //aqui empieza DATOS DE UBICACION
          canvas: [
            {
              type: 'rect',
              x: 20,
              y: 10,
              w: 510,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'VEHÍCULOS ACTIVOS', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 440,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 95, text: ''},
          { width: 220, text: "SIN VEHÍCULOS ACTIVOS", fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      }
  ]
  }

  return vehiculos;
}

getBitacoraVehiculos(permisosArray: any){


   let arrayParaTabla = [];
   for(var i = 0; i < permisosArray.length; i++){
     if(i == 0){
       arrayParaTabla.push(
         [ {text: 'N°', fontSize:7, bold: true,alignment: 'center' } ,
         {text: 'N° SERIE', fontSize:7, bold: true,alignment: 'center' } ,
         {text: 'N° ECONOMICO', fontSize:7, bold: true,alignment: 'center' } ,

         {text: 'MOTOR', fontSize:7, bold: true,alignment: 'center' } ,
         { text: 'MARCA', fontSize:7, bold: true,alignment: 'center' }  ,
         { text: 'MODELO', fontSize:7, bold: true,alignment: 'center' }  ,
         ]
       );
     }
      arrayParaTabla.push([
         {text: (i+1), fontSize:7,alignment: 'center'} ,
         {text: permisosArray[i].serie, fontSize:7,alignment: 'center'} ,
         {text: permisosArray[i].numeroEconomico, fontSize:7,alignment: 'center'} ,


        { text: permisosArray[i].motor, fontSize:7,alignment: 'center'}  ,
        { text: permisosArray[i].marca, fontSize:7,alignment: 'center' },
        { text: permisosArray[i].modelo, fontSize:7,alignment: 'center'}  ,

      ]
      );
   }
  let datosTablaReporteLocalidad =
    {
          style: 'tableExample',
           table: {
            headerRows: 1,
            widths: [ '3%','25%','15%','25%', '15%', '10%'],
            body:  arrayParaTabla,
          },layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#FFFFFF' : null;
            },
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
            },
            paddingLeft: function(i, node) { return 10; },
          }
   };
  return datosTablaReporteLocalidad;
}

getDatosPermisosDC(listpermisosDC){
   let permisos;
  if(listpermisosDC.length > 0 ){
     permisos = [
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 0]},
               ]
            },{
                  canvas: [
                    {
                      type: 'rect',
                      x: 20,
                      y: 10,
                      w: 510,
                      h: 20,
                      color: '#f5f5f5',
                    },
                  ]
                },
            { columns: [
                { width: 20, text: ''},
                { width: 300, text: 'PERMISOS DIRECCIÓN DE CONCESIONES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
              ]
            },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          this.getBitacoradePermisos(listpermisosDC)

    ];
  }else{
    permisos =  [
      { //aqui empieza DATOS DE UBICACION
          canvas: [
            {
              type: 'rect',
              x: 20,
              y: 10,
              w: 510,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'PERMISOS DC', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 440,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 95, text: ''},
          { width: 220, text: "SIN PERMISOS REGISTRADOS", fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      }
  ]
  }

  return permisos;
}

getDatosExpedientes(){
  let datosExpedientes = [
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },{ //aqui empieza DATOS DE UBICACION
                canvas: [
                  {
                    type: 'rect',
                    x: 20,
                    y: 10,
                    w: 510,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
          { columns: [
              { width: 20, text: ''},
              { width: 300, text: 'DICTAMENES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          {
              canvas: [
                {
                  type: 'rect',
                  x: 90,
                  y: 0,
                  w: 170,
                  h: 20,
                  color: '#f5f5f5',
                },
              ]
            },
            {
                canvas: [
                  {
                    type: 'rect',
                    x: 360,
                    y: -20,
                    w: 170,
                    h: 20,
                    color: '#f5f5f5',
                  },
                ]
              },
            { columns: [
                { width: 20, text: ''},
                { width: 65, text: 'FECHA CREACIÓN:', fontSize: 10,bold: true, margin: [0, -20, 0, 0],alignment: 'right'},
                { width: 10, text: ''},
                { width: 160, text: "30/10/2019", fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'},
                { width: 5, text: ''},
                { width: 90, text: 'ETAPA ACTUAL:', fontSize: 10,bold: true, margin: [0, -20, 0, 0],alignment: 'right'},
                { width: 20, text: ''},
                { width: 150, text: "FINALIZADO", fontSize: 10, margin: [0, -17, 0, 0],alignment: 'center'}

              ]
            }

  ];
  return datosExpedientes;
}

getDatosRevista(revistas){
  let datosrevista;
 if(revistas.length > 0 ){
    datosrevista = [
           { columns: [
               { width: 40, text: '', margin: [0, 2, 0, 0]},
              ]
           },{
                 canvas: [
                   {
                     type: 'rect',
                     x: 20,
                     y: 10,
                     w: 510,
                     h: 20,
                     color: '#f5f5f5',
                   },
                 ]
               },
           { columns: [
               { width: 20, text: ''},
               { width: 300, text: 'REVISTA Y CROMATICA', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
             ]
           },
         { columns: [
             { width: 40, text: '', margin: [0, 2, 0, 0]},
            ]
         },
         this.getBitacoradeRevistas(revistas)

   ];
 }else{
   datosrevista =  [
     { //aqui empieza DATOS DE UBICACION
         canvas: [
           {
             type: 'rect',
             x: 20,
             y: 10,
             w: 510,
             h: 20,
             color: '#f5f5f5',
           },
         ]
       },
   { columns: [
       { width: 20, text: ''},
       { width: 300, text: 'REVISTA Y CROMATICA', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
     ]
   },
   { columns: [
       { width: 40, text: '', margin: [0, 2, 0, 0]},
      ]
   },
   {
       canvas: [
         {
           type: 'rect',
           x: 90,
           y: 0,
           w: 440,
           h: 20,
           color: '#f5f5f5',
         },
       ]
     },
     { columns: [
         { width: 95, text: ''},
         { width: 220, text: "SIN REGISTROS DE REVISTA", fontSize: 9, margin: [0, -15, 0, 0]}
       ]
     }
 ]
 }

 return datosrevista;
}


getDatosEncabezados(){
  let cabecera = [
    { columns: [
        { width: 20, text: ''},
        { width: 240, text: 'Secretaría de Movilidad', fontSize: 14,bold: true}
      ]
    },
    { columns: [
        { width: 20, text: ''},
        { width: 230, text: 'Gobierno del Estado de Oaxaca', fontSize: 14,bold: true }
      ]
    },
    { columns: [
        { width: 20, text: ''},
        { width: 230, text: 'Unidad de Informática', fontSize: 12,bold: true }
      ]
    },
    { columns: [
        { width: 330, text: ''},
        { width: 230, text: 'OAXACA DE JUÁREZ, OAX, A 10 DE OCTUBRE DE 2019', fontSize: 8 }
      ]
    }
    ,
    { columns: [
         { width: '100%', text: 'INFORMACIÓN GENERAL DEL concessionaire', fontSize: 13,bold: true,alignment: 'center' }
      ]
    }
  ];
  return cabecera;
}

getTramites(tramitesArray: any){
  let tramites;
  if(tramitesArray.length > 0 ){
     tramites = [
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 0]},
               ]
            },{ //aqui empieza DATOS DE UBICACION
                  canvas: [
                    {
                      type: 'rect',
                      x: 20,
                      y: 10,
                      w: 510,
                      h: 20,
                      color: '#f5f5f5',
                    },
                  ]
                },
            { columns: [
                { width: 20, text: ''},
                { width: 300, text: 'BITÁCORAS DE TRÁMITES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
              ]
            },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          this.getBitacoradeTramites(tramitesArray)

    ];
  }else{
    tramites =  [
      { //aqui empieza DATOS DE UBICACION
          canvas: [
            {
              type: 'rect',
              x: 20,
              y: 10,
              w: 510,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'TRÁMITES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 440,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 95, text: ''},
          { width: 220, text: "SIN TRÁMITES REGISTRADOS", fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      }
  ]
  }

  return tramites;
}



getConductores(conductoresArray: any){
  let conductores;
  if(conductoresArray.length > 0 ){
     conductores = [
            { columns: [
                { width: 40, text: '', margin: [0, 2, 0, 0]},
               ]
            },{ //aqui empieza DATOS DE UBICACION
                  canvas: [
                    {
                      type: 'rect',
                      x: 20,
                      y: 10,
                      w: 510,
                      h: 20,
                      color: '#f5f5f5',
                    },
                  ]
                },
            { columns: [
                { width: 20, text: ''},
                { width: 300, text: 'CONDUCTORES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
              ]
            },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          this.getBitacoradeConductores(conductoresArray)

    ];
  }else{
    conductores =  [
      { //aqui empieza DATOS DE UBICACION
          canvas: [
            {
              type: 'rect',
              x: 20,
              y: 10,
              w: 510,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'CONDUCTORES', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 440,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 95, text: ''},
          { width: 220, text: "SIN CONDUCTORES REGISTRADOS", fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      }
  ]
  }

  return conductores;
}



getContactos(contactosArray: any){
  let contactos;
  if(contactosArray.length > 0 ){
     contactos = [
            { columns: [
                { width: 40, text: '', margin: [0, 10, 0, 0]},
               ]
            },{ columns: [
                { width: 40, text: '', margin: [0, 10, 0, 0]},
               ]
            },{ //aqui empieza DATOS DE UBICACION
                  canvas: [
                    {
                      type: 'rect',
                      x: 20,
                      y: 10,
                      w: 510,
                      h: 20,
                      color: '#f5f5f5',
                    },
                  ]
                },
            { columns: [
                { width: 20, text: ''},
                { width: 300, text: 'CONTACTOS', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
              ]
            },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 0]},
             ]
          },
          this.getBitacoradeContactos(contactosArray)

    ];
  }else{
    contactos =  [
      { //aqui empieza DATOS DE UBICACION
          canvas: [
            {
              type: 'rect',
              x: 20,
              y: 10,
              w: 510,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'CONTACTOS', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 440,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 95, text: ''},
          { width: 220, text: "SIN CONTACTOS REGISTRADOS", fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      }
  ]
  }

  return contactos;
}


getDatosSIAMOV(quejasArray: any){

  if(quejasArray.length > 0 ){
        let datosDeUbicacion = [
                { columns: [
                    { width: 40, text: '', margin: [0, 2, 0, 0]},
                   ]
                },{ //aqui empieza DATOS DE UBICACION
                      canvas: [
                        {
                          type: 'rect',
                          x: 20,
                          y: 10,
                          w: 510,
                          h: 20,
                          color: '#f5f5f5',
                        },
                      ]
                    },
                { columns: [
                    { width: 20, text: ''},
                    { width: 300, text: 'DATOS DE SIAMOV', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
                  ]
                },
              { columns: [
                  { width: 40, text: '', margin: [0, 2, 0, 0]},
                 ]
              },
              this.getBitacoradeSIAMOV(quejasArray)
        ];
         return datosDeUbicacion;
  }else{
    return [
      { //aqui empieza DATOS DE UBICACION
          canvas: [
            {
              type: 'rect',
              x: 20,
              y: 10,
              w: 510,
              h: 20,
              color: '#f5f5f5',
            },
          ]
        },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'DATOS DE SIAMOV', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    },
    { columns: [
        { width: 40, text: '', margin: [0, 2, 0, 0]},
       ]
    },
    {
        canvas: [
          {
            type: 'rect',
            x: 90,
            y: 0,
            w: 440,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
      { columns: [
          { width: 95, text: ''},
          { width: 220, text: "SIN QUEJAS REGISTRADAS", fontSize: 9, margin: [0, -15, 0, 0]}
        ]
      }
  ]
  }

}


getBitacoradeSIAMOV(quejasArray: any){
  let arrayParaTabla = [];
  for(var i = 0; i < quejasArray.length; i++){
    if(i == 0){
      arrayParaTabla.push(
        [ {text: 'FOLIO', fontSize:7, bold: true,alignment: 'center' } ,
         {text: 'FECHA', fontSize:7, bold: true,alignment: 'center' },
        {text: 'ESTATUS', fontSize:7, bold: true,alignment: 'center' },
        {text: 'MENSAJE', fontSize:7, bold: true }

        ]
      );
    }
     arrayParaTabla.push([
        {text: quejasArray[i].folio , fontSize:7,alignment: 'center'} ,
        { text: this.funcioncrearfecha(quejasArray[i].created_at), fontSize:7,alignment: 'center'},
       { text: quejasArray[i].estado.nombre, fontSize:7,alignment: 'center'},
       { text: quejasArray[i].mensaje, fontSize:7},


     ]
     );
  }
 let datosTablaReporteLocalidad =
   {
         style: 'tableExample',
          table: {
           headerRows: 1,
           widths: [ '10%','10%','10%','70%'],
           body:  arrayParaTabla,
         },layout: {
           fillColor: function (rowIndex, node, columnIndex) {
             return (rowIndex === 0) ? '#FFFFFF' : null;
           },
           hLineWidth: function (i, node) {
             return (i === 0 || i === node.table.body.length) ? 2 : 1;
           },
           vLineWidth: function (i, node) {
             return (i === 0 || i === node.table.widths.length) ? 2 : 1;
           },
           hLineColor: function (i, node) {
             return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
           },
           vLineColor: function (i, node) {
             return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
           },
           paddingLeft: function(i, node) { return 10; },
         }
  };
 return datosTablaReporteLocalidad;
}



getDatosInconsistencias(inconsistencias){
  let inconsistenciasArray = [];


     for(var i = 0; i < inconsistencias.length;i++){

      inconsistenciasArray.push(
        {
            canvas: [
              {
                type: 'rect',
                x: 90,
                y: 0,
                w: 440,
                h: 20,
                color: '#f5f5f5',
              },
            ]
          },
          { columns: [
              { width: 95, text: ''},
              { width: 380, text: inconsistencias[i].informacion, fontSize: 9, margin: [0, -15, 0, 0]}
            ]
          },
          { columns: [
              { width: 40, text: '', margin: [0, 2, 0, 2]},
             ]
          },
      );
    }









  let datosGenerales = [
    {
        canvas: [
          {
            type: 'rect',
            x: 20,
            y: 10,
            w: 510,
            h: 20,
            color: '#f5f5f5',
          },
        ]
      },
    { columns: [
        { width: 20, text: ''},
        { width: 300, text: 'INCONSISTENCIAS', fontSize: 12,bold: true, margin: [0, -17, 0, 0]}
      ]
    }
    ,
  { columns: [
      { width: 40, text: '', margin: [0, 2, 0, 16]},
     ]
  },
  inconsistenciasArray
  ];
   return datosGenerales;
}


getBitacoradeContactos(contactosArray: any){
  let arrayParaTabla = [];
  for(var i = 0; i < contactosArray.length; i++){
    if(i == 0){
      arrayParaTabla.push(
        [ {text: 'NOMBRE', fontSize:7, bold: true,alignment: 'center' } ,
        {text: 'REFERENCIA', fontSize:7, bold: true,alignment: 'center' }
        ]
      );
    }
     arrayParaTabla.push([
        {text:  contactosArray[i].nombre.toUpperCase() +" "+contactosArray[i].primer_apellido.toUpperCase()+" "+contactosArray[i].segundo_apellido.toUpperCase() , fontSize:7} ,
       { text: contactosArray[i].tipoPersona.concepto.toUpperCase(), fontSize:7,alignment: 'center'}
     ]
     );
  }
 let datosTablaReporteLocalidad =
   {
         style: 'tableExample',
          table: {
           headerRows: 1,
           widths: [ '70%','38%'],
           body:  arrayParaTabla,
         },layout: {
           fillColor: function (rowIndex, node, columnIndex) {
             return (rowIndex === 0) ? '#FFFFFF' : null;
           },
           hLineWidth: function (i, node) {
             return (i === 0 || i === node.table.body.length) ? 2 : 1;
           },
           vLineWidth: function (i, node) {
             return (i === 0 || i === node.table.widths.length) ? 2 : 1;
           },
           hLineColor: function (i, node) {
             return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
           },
           vLineColor: function (i, node) {
             return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
           },
           paddingLeft: function(i, node) { return 10; },
         }
  };
 return datosTablaReporteLocalidad;
}


getBitacoradeTramites(tramitesArray: any){


   let arrayParaTabla = [];
   for(var i = 0; i < tramitesArray.length; i++){
     if(i == 0){
       arrayParaTabla.push(
         [ {text: 'REFERENCIA', fontSize:7, bold: true,alignment: 'center' } ,
         {text: 'DESCRIPCIÓN DEL TRÁMITE', fontSize:7, bold: true,alignment: 'center' } ,
         { text: 'ESTATUS', fontSize:7, bold: true,alignment: 'center' }  ,
         { text: 'FECHA', fontSize:7, bold: true,alignment: 'center' } ,
         { text: 'MODELO', fontSize:7, bold: true,alignment: 'center' } ,
         { text: 'ESTATUS DEL VEHICULO', fontSize:7 , bold: true,alignment: 'center'}   ,
         { text: 'FECHA DE IMP.', fontSize:7, bold: true,alignment: 'center' }  ,
         { text: 'ESTATUS DE IMP.', fontSize:7 , bold: true,alignment: 'center'}
         ]
       );
     }
      arrayParaTabla.push([
         {text: tramitesArray[i].referencia, fontSize:7,alignment: 'center'} ,
        { text: tramitesArray[i].descripcion, fontSize:7,alignment: 'center'}  ,
        { text: tramitesArray[i].estatusTramite, fontSize:7,alignment: 'center' } ,
        { text: this.funcioncrearfecha(tramitesArray[i].fecha), fontSize:7,alignment: 'center' } ,
        { text: tramitesArray[i].modelo, fontSize:7,alignment: 'center' }   ,
        { text: tramitesArray[i].estatusVehiculo, fontSize:7,alignment: 'center' }  ,
        { text: this.funcioncrearfecha(tramitesArray[i].fechaImp), fontSize:7,alignment: 'center' }  ,
        { text: tramitesArray[i].estatusIMP, fontSize:7,alignment: 'center' }
      ]
      );
   }
  let datosTablaReporteLocalidad =
    {
          style: 'tableExample',
           table: {
            headerRows: 1,
            widths: [ '10%','28%', '10%', '9%', '7%','8%','10%','9%'],
            body:  arrayParaTabla,
          },layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#FFFFFF' : null;
            },
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
            },
            paddingLeft: function(i, node) { return 10; },
          }
   };
  return datosTablaReporteLocalidad;
}


getBitacoradeRevistas(revistasArray: any){


   let arrayParaTabla = [];
   let mensaje = '';
   for(var i = 0; i < revistasArray.length; i++){
     if(i == 0){
       arrayParaTabla.push(
         [ {text: 'FOLIO', fontSize:7, bold: true,alignment: 'center' } ,
         {text: 'FECHA', fontSize:7, bold: true,alignment: 'center' } ,

         {text: 'ESTATUS', fontSize:7, bold: true,alignment: 'center' } ,
         { text: 'TIPO EVALUACIÓN', fontSize:7, bold: true,alignment: 'center' }  ,
         ]
       );
     }

     if(revistasArray[i].es_correcto){
       mensaje = 'Completo';
     }else{
       mensaje = 'Incompleto';
     }
      arrayParaTabla.push([
         {text: revistasArray[i].folio, fontSize:7,alignment: 'center'} ,
         {text: this.funcioncrearfecha(revistasArray[i].created_at), fontSize:7,alignment: 'center'} ,


        { text: mensaje, fontSize:7,alignment: 'center'}  ,
        { text: this.traerDescripcioncorrecta(revistasArray[i].metadatos.tipo_evaluacion), fontSize:7,alignment: 'center' }
      ]
      );
   }
  let datosTablaReporteLocalidad =
    {
          style: 'tableExample',
           table: {
            headerRows: 1,
            widths: [ '25%','25%','25%', '25%'],
            body:  arrayParaTabla,
          },layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#FFFFFF' : null;
            },
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
            },
            paddingLeft: function(i, node) { return 10; },
          }
   };
  return datosTablaReporteLocalidad;
}


getBitacoradePermisos(permisosArray: any){


   let arrayParaTabla = [];
   for(var i = 0; i < permisosArray.length; i++){
     if(i == 0){
       arrayParaTabla.push(
         [ {text: 'FOLIO', fontSize:7, bold: true,alignment: 'center' } ,
         {text: 'HOJA VALORADA', fontSize:7, bold: true,alignment: 'center' } ,

         {text: 'N° SERIE', fontSize:7, bold: true,alignment: 'center' } ,
         { text: 'VIGENCIA', fontSize:7, bold: true,alignment: 'center' }  ,
         ]
       );
     }
      arrayParaTabla.push([
         {text: permisosArray[i].id, fontSize:7,alignment: 'center'} ,
         {text: permisosArray[i].foliated_sheet, fontSize:7,alignment: 'center'} ,


        { text: permisosArray[i].serial_number, fontSize:7,alignment: 'center'}  ,
        { text: this.funcioncrearfecha(permisosArray[i].expedition_date), fontSize:7,alignment: 'center' }
      ]
      );
   }
  let datosTablaReporteLocalidad =
    {
          style: 'tableExample',
           table: {
            headerRows: 1,
            widths: [ '25%','25%','25%', '25%'],
            body:  arrayParaTabla,
          },layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#FFFFFF' : null;
            },
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
            },
            paddingLeft: function(i, node) { return 10; },
          }
   };
  return datosTablaReporteLocalidad;
}




  getBitacoradeConductores(conductoresArray: any){

     let arrayParaTabla = [];
     for(var i = 0; i < conductoresArray.length; i++){
       let estado = '';
       if(conductoresArray[i].is_expired){
         estado = 'VENCIDO'
       }else{
         estado = 'VIGENTE'

       }
       if(i == 0){
         arrayParaTabla.push(
           [ {text: 'N°', fontSize:7, bold: true,alignment: 'center' } ,
           {text: 'NOMBRE', fontSize:7, bold: true,alignment: 'center' } ,
           {text: 'FOLIO', fontSize:7, bold: true,alignment: 'center' } ,
           { text: 'PLACA', fontSize:7, bold: true,alignment: 'center' }  ,
           { text: 'VIGENCIA', fontSize:7, bold: true,alignment: 'center' } ,
           { text: 'LICENCIA', fontSize:7, bold: true,alignment: 'center' }
           ]
         );
       }
       let nombre = conductoresArray[i].persona.identity.name + ' ' + conductoresArray[i].persona.identity.first_surname;
       if(conductoresArray[i].persona.identity.second_surname!=undefined){
         nombre = nombre + ' ' + conductoresArray[i].persona.identity.second_surname;
       }
        arrayParaTabla.push([
          {text: (i+1), fontSize:7,alignment: 'center'} ,
           {text: nombre, fontSize:7} ,
          { text: conductoresArray[i].hojafoliada, fontSize:7,alignment: 'center'}  ,
          { text: conductoresArray[i].placa, fontSize:7,alignment: 'center' } ,
          { text: this.funcioncrearfecha(conductoresArray[i].expiration_date), fontSize:7,alignment: 'center' } ,
          { text: conductoresArray[i].licencia.license_number, fontSize:7,alignment: 'center' }   ,
        ]
        );
     }
    let datosTablaReporteLocalidad =
      {
            style: 'tableExample',
             table: {
              headerRows: 1,
              widths: [ '3%','35%','15%', '15%', '15%', '20%'],
              body:  arrayParaTabla,
            },layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#FFFFFF' : null;
              },
              hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 2 : 1;
              },
              vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
              },
              hLineColor: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
              },
              vLineColor: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
              },
              paddingLeft: function(i, node) { return 10; },
            }
     };
    return datosTablaReporteLocalidad;
  }

  funcioncrearfecha(stringdate: any){

    if(stringdate == ''){
      return '';
    }
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    //let fecha = new Date(stringdate);
    var arrayDeCadenas = stringdate.split('T');
    var arrayDeCadenas2 = arrayDeCadenas[0].split('-');
  //let fecha = new Date(stringdate);
    let fecha = new Date(parseInt(arrayDeCadenas2[0]),parseInt(arrayDeCadenas2[1])-1,parseInt(arrayDeCadenas2[2]));


    let dia21 = "";
    let anio21 = fecha.getFullYear().toString();
    let mes21 = "";

    if((fecha.getMonth()+1)<10){
     mes21 = "0"+(fecha.getMonth() + 1).toString();
    }else{
     mes21 = (fecha.getMonth() + 1).toString();
    }
     if((fecha.getDate()+1)<=10){
     dia21 = "0"+(fecha.getDate()).toString();
    }else{
     dia21 = (fecha.getDate()).toString();
    }

    return  dia21 +  "-" + mes21 + "-" + anio21 ;
  }


  cuantasvecessalta(palabra: any, tamanio: any){
    let veces = 1;
    let tamaniopalabra = palabra.length;
    for(var i = 1; (i*tamanio) < tamaniopalabra; i++){
      veces = veces + 1;
    }
    return veces;
  }


    traerDescripcioncorrecta(valor) {
      switch (valor) {
        case 'cromática-constancia':
          return 'Constancia de Cromática'
          break;
        case 'cromática-apercibimiento':
          return 'Constancia de Cromática'
          break;
        case 'revista-fs-constancia':
          return 'Revista Físico Mecánica'
          break;
        case 'revista-fs-incompleto':
          return 'Pre Revista'
          break;
        default:
          return ''

          break;
      }
    }

}
