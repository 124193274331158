import {Component, OnInit, Input, HostListener, Output, EventEmitter,ChangeDetectorRef} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import { Observable,Observer } from 'rxjs';
  import gql from 'graphql-tag';
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import {StorageService} from "../../core/services/storage.service";
  import { environment } from '../../../environments/environment';
  import * as B from '../../graphql/queries';
   import * as N from '../../graphql/mutations';

  type AOA = any[][];
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  declare var M: any;
  export interface myinterface {
    remove(index: number,nameComponet: any);
  }
 @Component({
  selector: 'app-conductorestarjeta', templateUrl: './conductorestarjeta.component.html', styleUrls: ['./conductorestarjeta.component.css']
})
export class ConductoresTarjetaComponent implements OnInit {
    public index: number;
    public selfRef: ConductoresTarjetaComponent;
    public compInteraction: myinterface;
    @Input() IModel: any;
    @Input() NameComponet: any;
    @Input() Conductores: any;
    @Input() TotalRecords: number;
    @Output() Ostatus = new EventEmitter<any>();
constructor( ){}
ngOnInit() {
  $(document).ready(function(){
    $('.modal').modal();
  });

}


ngAfterViewInit() {
}

funcioncrearfecha(stringdate: any){
  let fecha = new Date(stringdate);
  let dia21 = "";
  let anio21 = fecha.getFullYear().toString();
  let mes21 = "";
  if((fecha.getMonth()+1)<10){
   mes21 = "0"+(fecha.getMonth() + 1).toString();
  }else{
   mes21 = (fecha.getMonth() + 1).toString();
  }
   if((fecha.getDate()+1)<=10){
   dia21 = "0"+(fecha.getDate()).toString();
  }else{
   dia21 = (fecha.getDate()).toString();
  }
  return anio21  + "-" + mes21 + "-" + dia21 + "";
}
paginate(event){
  this.Ostatus.emit(event.page + 1);

  //this.obtenerTotalConstancias(event.page + 1);
}

}
