import gql from 'graphql-tag';


const GET_TOKEN = gql`
query getToken($codigo_sistema: ID!,$id_tramite: ID!) {
  getToken(codigo_sistema: $codigo_sistema,id_tramite: $id_tramite)
}

`;



export {  GET_TOKEN };
