  import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import { Observable,Observer } from 'rxjs';
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import gql from 'graphql-tag';
  /*
  import * as XLSX from 'xlsx';
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';
  */
  type AOA = any[][];
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  declare var M: any;
  export interface myinterface {
    remove(index: number,nameComponet: any);
  }

 @Component({
  selector: 'app-vehiculos', templateUrl: './vehiculos.component.html', styleUrls: ['./vehiculos.component.css']
})
export class VehiculosComponent implements OnInit {
  public index: number;
  public selfRef: VehiculosComponent;
  public compInteraction: myinterface;
  modalidades: any;
  @Input() IModel: any;
  @Input() NameComponet: any;
  @Input() Vehiculos: any;
  @Input() Operativo: any;


  constructor(){}

  ngOnInit() {
    $(document).ready(function(){
      $('.modal').modal();
    });
   }

  verImodel(){
    console.log(this.IModel.concesionario.primerApellido);
  }

  removeMe(index,nameComponet) {
    this.compInteraction.remove(index,nameComponet)
  }

}
