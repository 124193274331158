import gql from 'graphql-tag';
const GET_PERSON = gql`
query person($id: ID!) {
  person(id: $id) {
    id
    curp
    address_id
    from
    contact{
      id
      email
      phone
    }
    identity{
      id
      name
      first_surname
      second_surname
    }
    license{
      license_number
    }
    personal{
      birthday
      sex

    }
  }
}
`;


const GET_PERSONS = gql`
query persons($params: JSON!,$mode: String!) {
  persons(params: $params,mode: $mode) {
    id
    curp
    address_id
    contact{
      id
      email
      phone
    }
    identity{
      id
      name
      first_surname
      second_surname
    }
    license{
      license_number
    }
    personal{
      birthday
      sex

    }
  }
}
`;


const GET_LICENSE_BY_PERSON = gql`
query licenseByPerson($person_id: ID!) {
  licenseByPerson(person_id: $person_id) {
    id
    license_number
  }
}
`;


const SEARCH_PERSON = gql`
query getPersonByCURP($uprc: String!) {
  personByUprc(uprc: $uprc) {
    id
    name
    first_surname
    second_surname
    uprc
    status
    created_at
  }
}
`;

const GET_CONDUCTOR = gql`
query getDriverByCurp($curp: String!) {
      driverByCurp(curp: $curp) {
        id
        name
        first_surname
        second_surname
        curp
        birthday
        license_number
        contact {
          id
          email
          celphone
          status
          created_at
        }
        address {
          id
          road_name
          road_type {
            id
            name
            description
            status
            created_at
          }
          outdoor_number
          indoor_number
          postal_code
          settlement {
            id
            name
            locality {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              code
              status
              created_at
            }
            settlement_type {
              id
              name
              description
              status
              created_at
            }
            status
            created_at
          }
          sublevel {
            id
            name
            municipality {
              id
              name
              district {
                id
                name
                region {
                  id
                  name
                  status
                  created_at
                }
                code
                status
                created_at
              }
              code
              status
              created_at
            }
            sublevel_type {
              id
              name
              description
              status
              created_at
            }
            status
            created_at
          }
          status
          created_at
        }
        trainings {
          id
          folio
          description
          expiration_date
          status
          created_at
        }
        status
        created_at
      }
    }

`;



const GET_CETIFICADO = gql`
query rePrintCertificate($id: ID!) {
      rePrintCertificate(id: $id) {
        id
    		concession_id
    	person_id
    plate{
      id
      folio
    }
    foliated_sheet{
      id
      folio
    }
    expedition_date
    expiration_date
    is_canceled
    cancel_description
    image_name
    modality_id
    is_expired
    deprecated

      }

    }

`;


const GET_PREREGISTROS = gql`
query getPreCertificatesByConcessionAndPageNumber(
      $concession_id: ID!
      $page_number: Int!
      $limit: Int!
    ) {
      preCertificatesByConcessionAndPageNumber(
        concession_id: $concession_id
        page_number: $page_number
        limit: $limit
      ) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          authorized_vehicles
          status
          modality {
            id
            name
          }
          site {
            id
            name
          }
          consecutive_number
          unique_number_key
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  code
                  status
                  created_at
                }
                code
              }
              settlement_type {
                id
                name
                description
              }
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                code
              }
              sublevel_type {
                id
                name
              }
            }
          }
          trainings {
            id
            folio
            description
            expiration_date
          }
        }
        plate {
          id
          folio
        }
        image {
          id
        }
        is_canceled
        is_completed
        expedition_date
        modality {
          id
          name
        }
      }
    }

`;

const BUSCAR_CONDUCTORES = gql`
query getDriversLike($input: String!, $limit: Int, $option: Int!) {
      driversLike(input: $input, limit: $limit, option: $option) {
        id
        name
        first_surname
        second_surname
        curp
        birthday
        license_number
        contact {
          id
          email
          celphone
          status
          created_at
        }
        address {
          id
          road_name
          road_type {
            id
            name
            description
            status
            created_at
          }
          outdoor_number
          indoor_number
          postal_code
          settlement {
            id
            name
            locality {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              code
              status
              created_at
            }
            settlement_type {
              id
              name
              description
              status
              created_at
            }
            status
            created_at
          }
          sublevel {
            id
            name
            municipality {
              id
              name
              district {
                id
                name
                region {
                  id
                  name
                  status
                  created_at
                }
                code
                status
                created_at
              }
              code
              status
              created_at
            }
            sublevel_type {
              id
              name
              description
              status
              created_at
            }
            status
            created_at
          }
          status
          created_at
        }
        trainings {
          id
          folio
          description
          expiration_date
          status
          created_at
        }
        status
        created_at
      }
    }
,
`;


const GET_CERTIFICADOS_CONDUCTOR = gql`
query getCertificatesByDriver($driver_id: ID!) {
      certificatesByDriver(driver_id: $driver_id) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          term
          main_notes
          delivery_certificate_notes
          route
          authorized_vehicles
          status
          modality {
            id
            name
            description
            status
            abbreviation
          }
          site {
            id
            name
            legal_representative
            address
            authorized_colors
            status
          }
          consecutive_number
          unique_number_key
          attached {
            active
            new_vehicle_auth
            new_concession_auth
            locked {
              status
              stolen_folio
            }
            reto {
              status
            }
          }
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
            status
            created_at
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              settlement_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              sublevel_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            status
            created_at
          }
          trainings {
            id
            folio
            description
            expiration_date
            status
            created_at
          }
          status
          created_at
        }
        plate {
          id
          folio
          status
          created_at
        }
        image {
          id
          name
          original_name
          extension
          type_content
          route
          base64
          status
          created_at
        }
        foliated_sheet {
          id
          folio
          status
          created_at
        }
        expedition_date
        expiration_date
        modality {
          id
          name
          description
          status
          abbreviation
        }
        log {
          id
          description
          id_user
          user_description
          workplace
          region
          ip_address
          user_agent
          http_method
          status
          created_at
        }
        is_canceled
        is_expired
        cancel_description
        status
        created_at
      }
    }

`;

const GET_OBSERVACIONES_BY_CONSTANCIA = gql`
query getObservationsByCertificate($certificate_id: ID!) {
      observationsByCertificate(certificate_id: $certificate_id) {
        id
        description
        certificate {
          id
          concession {
            id
            concessionaire {
              id
              person_type
              name
              first_surname
              second_surname
              curp
              rfc
              business_name
            }
            agreement
            agreement_date
            expiration_date
            capture_date
            start_date
            term
            main_notes
            delivery_certificate_notes
            route
            authorized_vehicles
            status
            modality {
              id
              name
              description
              status
              abbreviation
            }
            site {
              id
              name
              legal_representative
              address
              authorized_colors
              status
            }
            consecutive_number
            unique_number_key
            attached {
              active
              new_vehicle_auth
              new_concession_auth
              locked {
                status
                stolen_folio
              }
              reto {
                status
              }
            }
          }
          driver {
            id
            name
            first_surname
            second_surname
            curp
            birthday
            license_number
            contact {
              id
              email
              celphone
              status
              created_at
            }
            address {
              id
              road_name
              road_type {
                id
                name
                description
                status
                created_at
              }
              outdoor_number
              indoor_number
              postal_code
              settlement {
                id
                name
                locality {
                  id
                  name
                  municipality {
                    id
                    name
                    district {
                      id
                      name
                      region {
                        id
                        name
                        status
                        created_at
                      }
                      code
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                settlement_type {
                  id
                  name
                  description
                  status
                  created_at
                }
                status
                created_at
              }
              sublevel {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                sublevel_type {
                  id
                  name
                  description
                  status
                  created_at
                }
                status
                created_at
              }
              status
              created_at
            }
            trainings {
              id
              folio
              description
              expiration_date
              status
              created_at
            }
            status
            created_at
          }
          plate {
            id
            folio
            status
            created_at
          }
          image {
            id
            name
            original_name
            extension
            type_content
            route
            base64
            status
            created_at
          }
          foliated_sheet {
            id
            folio
            status
            created_at
          }
          expedition_date
          expiration_date
          modality {
            id
            name
            description
            status
            abbreviation
          }
          log {
            id
            description
            id_user
            user_description
            workplace
            region
            ip_address
            user_agent
            http_method
            status
            created_at
          }
          is_canceled
          cancel_description
          status
          created_at
        }
        log {
          id
          description
          id_user
          user_description
          workplace
          region
          ip_address
          user_agent
          http_method
          status
          created_at
        }
        status
        created_at
      }
    }

`;




const GET_PREREGISTROS_DRIVER = gql`
query getPreCertificatesByDriver($driver_id: ID!) {
      preCertificatesByDriver(driver_id: $driver_id) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          term
          main_notes
          delivery_certificate_notes
          route
          authorized_vehicles
          status
          modality {
            id
            name
            description
            status
            abbreviation
          }
          site {
            id
            name
            legal_representative
            address
            authorized_colors
            status
          }
          consecutive_number
          unique_number_key
          attached {
            active
            new_vehicle_auth
            new_concession_auth
            locked {
              status
              stolen_folio
            }
            reto {
              status
            }
          }
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
            status
            created_at
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              settlement_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                district {
                  id
                  name
                  region {
                    id
                    name
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                code
                status
                created_at
              }
              sublevel_type {
                id
                name
                description
                status
                created_at
              }
              status
              created_at
            }
            status
            created_at
          }
          trainings {
            id
            folio
            description
            expiration_date
            status
            created_at
          }
          status
          created_at
        }
        plate {
          id
          folio
          status
          created_at
        }
        image {
          id
          name
          original_name
          extension
          type_content
          route
          base64
          status
          created_at
        }
        is_canceled
        is_completed
        expedition_date
        modality {
          id
          name
          description
          status
          abbreviation
        }
        log {
          id
          description
          id_user
          user_description
          workplace
          region
          ip_address
          user_agent
          http_method
          status
          created_at
        }
        status
        created_at
      }
    }

`;



const GET_EMPRESASPORID= gql`
query empresaByUsuario($id_usuario: ID!) {
  empresaByUsuario(id_usuario: $id_usuario) {
    id
    id_concesion
    id_usuario
    estado {
      id
      nombre
      deprecated
      created_at
      updated_at
    }
    rutas {
      id
      clave_semovi
      clave_empresa
      inicio
      destino
      longitud
      tiempo_recorrido
      minimo_unidades
      maximo_unidades
      detalles
      estado {
        id
        nombre
        deprecated
        created_at
        updated_at
      }
      actividad_previa {
        id
        cantidad_unidades
        frecuencia
        turno {
          id
          inicio
          fin
          estado {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          deprecated
          created_at
          updated_at
        }
        incidencias {
          id
          id_usuario
          mensaje
          revisado
          fecha_revisado
          acciones
          estado {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          incidente {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          deprecated
          created_at
          updated_at
        }
        porcentaje
        deprecated
        created_at
        updated_at
      }
      actividades {
        id
        cantidad_unidades
        frecuencia
        turno {
          id
          inicio
          fin
          estado {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          deprecated
          created_at
          updated_at
        }
        incidencias {
          id
          id_usuario
          mensaje
          revisado
          fecha_revisado
          acciones
          estado {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          incidente {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          deprecated
          created_at
          updated_at
        }
        porcentaje
        deprecated
        created_at
        updated_at
      }
      historial_actividades {
        id
        cantidad_unidades
        frecuencia
        turno {
          id
          inicio
          fin
          estado {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          deprecated
          created_at
          updated_at
        }
        incidencias {
          id
          id_usuario
          mensaje
          revisado
          fecha_revisado
          acciones
          estado {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          incidente {
            id
            nombre
            deprecated
            created_at
            updated_at
          }
          deprecated
          created_at
          updated_at
        }
        porcentaje
        deprecated
        created_at
        updated_at
      }
      deprecated
      created_at
      updated_at
    }
    deprecated
    created_at
    updated_at
  }
}



`;




const GET_IMAGE_LICENSE = gql`
query getImage($id: ID!) {
  image(id: $id) {
    id
    name
    original_name
    extension
    type_content
    route
    base64
    status
    created_at
  }
}
,
`;


const SEARCH_PERSON_BY_CURP = gql`
query getPersonByCURP($curp: String!) {
  personByCurp(curp: $curp) {
    id
    curp
    from
    address_id
    contact{
      id
      email
      phone
    }
    disabilities{
      id
      name
      description
    }
    identity{
      id
      name
      first_surname
      second_surname
    }
    license{
      id
      license_number
    }
    personal{
      id
      birthday
      sex
      education_level{
        id
        name
      }
      marital_status{
        id
        name
      }
    }
    bloqueado
  }
}
`;

export { SEARCH_PERSON,GET_CONDUCTOR,GET_CETIFICADO,GET_PREREGISTROS,BUSCAR_CONDUCTORES,GET_CERTIFICADOS_CONDUCTOR,GET_OBSERVACIONES_BY_CONSTANCIA,GET_PREREGISTROS_DRIVER,GET_EMPRESASPORID,GET_IMAGE_LICENSE,SEARCH_PERSON_BY_CURP,GET_PERSON,GET_LICENSE_BY_PERSON,GET_PERSONS };
