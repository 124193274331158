import gql from 'graphql-tag';

const LISTAR_VEHICULOS_CONCESION = gql`
query getVehiclesByConcession($concession_id: ID!) {
      vehiclesByConcession(concession_id: $concession_id) {
        id
        model
        engine_number
        serial_number
        doors_number
        capacity
        unit_capacity {
          id
          name
        }
        economic_number
        status
        brand {
          id
          name
        }
        vehicle_type {
          id
          name
        }
        plates {
          id
          folio
        }
      }
    }
,
`;

export { LISTAR_VEHICULOS_CONCESION };
