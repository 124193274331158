import gql from 'graphql-tag';

const LISTAR_MODALIDADES = gql`
query modalitiesLike($input: String!, $limit: Int) {
  modalitiesLike(input: $input, limit: $limit) {
    id
    name
    abbreviation
  }
},
`;

export { LISTAR_MODALIDADES };
