import { ComponentRef, ComponentFactoryResolver,ChangeDetectorRef, ViewContainerRef, ViewChild, Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
  import {Router} from '@angular/router';
  import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable,Observer } from 'rxjs';
  import {User} from "../core/models/user.model";
  declare var M: any;
  import * as B from '../graphql/queries';
  import * as N from '../graphql/mutations';

@Component({
  selector: 'app-searchSitio', templateUrl: './searchSitio.component.html', styleUrls: ['./searchSitio.component.css']
})

export class SearchSitioComponent implements OnInit {

  divdebusqueda: boolean = true;
  busquedapornombre: boolean = true;
  busquedapornuc: boolean = false;
  busquedaporsitio: boolean = false;
  registroabuscar: any;
  data: any;
  divplantillas: boolean = false;
  loading: boolean = false;

  registroamostrar: any;
  @Output() Ostatus = new EventEmitter<any>();
  @Output() Result = new EventEmitter<any>();
  @Input() Hidden: any;

  opBusquedaConcesionario: boolean = true;
  opBusquedaVehiculo: boolean = false;
  opBusquedaSitio: boolean = false;
  divdebusquedaSitio: boolean = false;
  divsitio: boolean = false;
  sitios: any;
  tamaniodivsarriba: any;
  busquedaporneconomico: boolean = false;
  busquedaporserie: boolean = false;
  busquedapormotor: boolean = false;
  busquedaporplaca: boolean = false;
  vehiculos : any;
  sitio: any;
  modalidadesSitio: any;
  modalidadesSitioVencidos:any;
  arrayBusquedas: any = [];
  constructor(
      private router?: Router,
      private apollo?: Apollo,

    ){}

  ngOnInit() {
      this.tamaniodivsarriba = "col s12 center-align";
   }

  asignarBusquedaXNombre(){
    this.busquedapornombre=true;
    this.busquedapornuc=false;
    this.busquedaporsitio=false;
  }

  asignarBusquedaXSitio(){
    this.busquedapornombre=false;
    this.busquedaporsitio=true;
    this.busquedapornuc=false;
  }

  asignarBusquedaXNUC(){
    this.busquedapornombre=false;
    this.busquedaporsitio=false;
    this.busquedapornuc=true;
  }

  mychange(event){
    this.loading = true;
     if( this.registroabuscar.length != 0 && this.registroabuscar.length != 1){
         this.divdebusqueda = true;
         this.llamarregistrosXSitio(1);
     }




  }


  crearsitios(sitiosB: any){
     this.sitios = [];
     this.loading = false;
    for(var i = 0;i < sitiosB.sitesLike.length; i++){




      let localidad = "";
      let municipio = "";
      let distrito = "";
      let region = "";

      if(sitiosB.sitesLike[i].locality!=null){
        localidad = sitiosB.sitesLike[i].locality.name;
      }
      if(sitiosB.sitesLike[i].locality!=null && sitiosB.sitesLike[i].locality.municipality!=null){
        municipio = sitiosB.sitesLike[i].locality.municipality.name;
      }
      if(sitiosB.sitesLike[i].locality.municipality.district!=null){
        if(sitiosB.sitesLike[i].locality!=null && sitiosB.sitesLike[i].locality.municipality.district!=null){
          distrito = sitiosB.sitesLike[i].locality.municipality.district.name;
        }
        if(sitiosB.sitesLike[i].locality.municipality.district.region!=null){
          if(sitiosB.sitesLike[i].locality!=null && sitiosB.sitesLike[i].locality.municipality.district.region!=null){
            region = sitiosB.sitesLike[i].locality.municipality.district.region.name;
          }
        }

      }



       this.sitios.push({idSitio:sitiosB.sitesLike[i].id,nombre: sitiosB.sitesLike[i].name,localidad:localidad,colores: sitiosB.sitesLike[i].authorized_colors,domicilio: sitiosB.sitesLike[i].address,represetantelegal: sitiosB.sitesLike[i].legal_representative,municipio: municipio ,distrito: distrito,region: region  });
    }


  }

  llamarregistrosXSitio(decision: any){
    this.apollo.use('backConsulta').watchQuery({
      query: B.LISTAR_SITIOS,
      variables: {
        input: this.registroabuscar,
        limit: 10,
      //  restrict: true
      }, fetchPolicy: 'network-only'
    })
    .valueChanges.subscribe(result => {
      this.crearsitios(result.data)
    });
  }


  crearregistrosSitios(registrosencontrados: any){
    this.data = registrosencontrados.sitios;
  }

  seleccionarregistro(registro: any){
    this.registroamostrar = registro;
    this.Ostatus.emit(this.registroamostrar);

  }




  seleccionarsitio(registro: any){
    this.sitio = registro;
    this.divdebusqueda = false;
    this.divplantillas = true;
    this.Ostatus.emit(this.sitio);

    //this.buscarModalidades(this.sitio);
   }



  buscarOtroConcesionario(){
    this.Result.emit(false);
    this.sitio = undefined;
    this.divdebusqueda = true;

   }


      public limpiarbusqueda(){
        console.log('Aqui entra');
        this.divdebusqueda = true;
        this.divplantillas = false;
        this.sitio = undefined;
        this.data = [];
        this.registroabuscar = undefined;

      }

}
