import gql from 'graphql-tag';

const GET_CERTIFICATE = gql`
query certificate($id: ID!) {
  certificate(id: $id) {
    id
    concession_id
    person_id
    fecha_cancelacion
    plate{
      id
      folio
    }
    foliated_sheet{
      id
      folio
    }
    expedition_date
    expiration_date
    is_canceled
    cancel_description
    image_name
    modality_id
    is_expired
    deprecated

  }
}
`;

const VALID_PLACA = gql`
query validarPlacaParaCrear($folio: String!,$concession_id: ID!,$unidadesAmparadas: Int!) {
		validarPlacaParaCrear(folio: $folio,concession_id: $concession_id,unidadesAmparadas: $unidadesAmparadas)
	  }
`;

const GET_LICENSE_CONDUCTOR = gql`
query licenseByPerson($person_id: ID!) {
		licenseByPerson(person_id: $person_id) {
      license_number
		}
	  }
`;


const GET_CONCESIONARIO = gql`
query concessionaire($id: ID!) {
		concessionaire(id: $id) {
				person_type
				name
				first_surname
				second_surname
				business_name
  }
}
`;

const GET_CONCESION = gql`
query concession($id: ID!) {
		concession(id: $id) {
      consecutive_number
      unique_number_key
		  modality{
				id
				name
			}
			site{
				name
			}
      concessionaire{
				person_type
				name
				first_surname
				second_surname
				business_name
			}
		}
	  }
`;

const GET_CERTIFICADOS_X_CONCESION = gql`
query certificates($params: JSON!,$mode: String!) {
  certificates(params: $params,mode: $mode) {
    id
    concession_id
    person_id
    plate{
      id
      folio
    }
    foliated_sheet{
      id
      folio
    }
    expedition_date
    expiration_date
    is_canceled
		fecha_cancelacion
    cancel_description
    image_name
    modality_id
    is_expired
    deprecated

  }
}
`;


const GET_PREREGISTROS_X_CONCESION = gql`
query preCertificates($params: JSON!,$mode: String!) {
  preCertificates(params: $params,mode: $mode) {
    id
    concession_id
    person_id
    plate{
      id
      folio
    }
    is_canceled
    is_completed
    capture_date
    image_name
    modality_id
    deprecated


  }
}
`;


const GET_COUNT_CERTIFICADOS_X_CONCESION = gql`
query certificatesCountByConcession($concession_id: ID!) {
  certificatesCountByConcession(concession_id: $concession_id)
}
`;

const GET_COUNT_PREREGISTROS_X_CONCESION = gql`
query preCertificatesCountByConcession($concession_id: ID!) {
  preCertificatesCountByConcession(concession_id: $concession_id)
}
`;


const GET_PLATES = gql`
query plates($params: JSON!,$mode: String!) {
  plates(params: $params,mode: $mode) {
    id
    folio
    deprecated
  }
}
`;


const VALID_FOLIO = gql`
query foliatedSheetIsValid($folio: String!) {
  foliatedSheetIsValid(folio: $folio)
}
`;


const GET_OBSERVACIONES = gql`
query observations($params: JSON!,$mode: String!) {
  observations(params: $params,mode: $mode) {
    id
    description
    capture_date
		certificate_id
    deprecated
    log{
      user_description
    }
  }
}
`;

const GET_ID_PLACA_FOLIO = gql`
query persons($params: JSON!,$mode: String!) {
		persons(params: $params,mode: $mode) {
      person_id,
      plate{
        folio
      }
      foliated_sheet{
        folio
      }
		}
	  }
`;

export { GET_CERTIFICATE,GET_ID_PLACA_FOLIO, GET_CONCESIONARIO,VALID_PLACA,GET_LICENSE_CONDUCTOR,GET_CONCESION,GET_CERTIFICADOS_X_CONCESION,GET_COUNT_CERTIFICADOS_X_CONCESION,GET_PLATES,VALID_FOLIO,GET_COUNT_PREREGISTROS_X_CONCESION,GET_PREREGISTROS_X_CONCESION,GET_OBSERVACIONES };
