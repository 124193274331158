import gql from 'graphql-tag';

const BUSQUEDA_PERSONALIZADA = gql`
query busquedaPersonalizada($filtros: Filtro) {
  busquedaPersonalizada(filtros: $filtros) {
    id
    id_catalogo_estatus
    id_concesion
    id_concesionario
    id_linea_captura
    id_persona
    id_modalidad
    id_modulo
    id_usuario
    id_observacion
    fecha_registro
    tipo_persona
    bandera_fase
    observacion{
      id
      observacion
      informacion
    }
  }
}
`;

const GET_REGISTROS_INCREMENTAL = gql`
query totalByRangoFechaIncremental($fecha_inicio: Date!,$fecha_fin: Date!,$pagina: Int!,$numregistro: Int!) {
  totalByRangoFechaIncremental(fecha_inicio: $fecha_inicio,fecha_fin: $fecha_fin,pagina: $pagina,numregistro: $numregistro) {
    id
    id_catalogo_estatus
    id_concesion
    id_concesionario
    id_linea_captura
    id_persona
    id_modalidad
    id_modulo
    id_usuario
    id_observacion
    fecha_registro
    tipo_persona
    bandera_fase
    observacion{
      id
      observacion
      informacion
    }
  }
}
`;

const GET_HISTORIAL = gql`
query historialCambioFases($id: ID!) {
  historialCambioFases(id: $id)
}
`;

const GET_REGISTROS_CONCESIONARIO = gql`
query registro_retoByConcesion($id_concesion: ID!) {
  registro_retoByConcesion(id_concesion: $id_concesion) {
    id
    id_catalogo_estatus
    id_concesion
    id_concesionario
    id_linea_captura
    id_persona
    id_modalidad
    id_modulo
    id_usuario
    id_observacion
    fecha_registro
    tipo_persona
    bandera_fase
    observacion{
      id
      observacion
      informacion
    }
  }
}
`;

const GET_REGISTROS_RETO_X_FASE = gql`
query registro_retoByFase($id_catalogo_estatus: Int!) {
  registro_retoByFase(id_catalogo_estatus: $id_catalogo_estatus) {
    id
    id_catalogo_estatus
    id_concesion
    id_concesionario
    id_linea_captura
    id_persona
    id_modalidad
    id_modulo
    id_usuario
    id_observacion
    fecha_registro
    tipo_persona
    bandera_fase
    observacion{
      id
      observacion
      informacion
    }
  }
}
`;

const GET_FORMATOS_REGISTRO = gql`
query formatosByRegistro($id_registro_reto: ID!) {
  formatosByRegistro(id_registro_reto: $id_registro_reto) {
    id
    id_catalogo_formato
    id_registro_reto
    nombre
    validado
  }
}
`;


const GET_CATALOGO_FORMATO = gql`
query catalogo_estatus($id: ID!) {
  catalogo_estatus(id: $id) {
    id
    nombre

  }
}
`;

const GET_CATALOGO_FORMATOS = gql`
query{
   catalogos_formatos{
     id
     nombre
   }
 }
`;


const GET_CATALOGO_STATUS = gql`
query catalogos_estatus {
      catalogos_estatus{
        id
        nombre
        descripcion
      }
    }
`;


const GET_REGISTRO_RETO = gql`
query registro_reto($id: ID!) {
  registro_reto(id: $id) {
    id
    id_catalogo_estatus
    id_concesion
    id_concesionario
    id_linea_captura
    id_persona
    id_modalidad
    id_modulo
    id_usuario
    id_observacion
    fecha_registro
    tipo_persona
    bandera_fase
    observacion{
      id
      observacion
      informacion
    }
  }
}
`;

const GET_REGISTROS_RETO = gql`
query{
  registros_reto{
    id
    id_catalogo_estatus
    id_concesion
    id_concesionario
    id_linea_captura
    id_persona
    id_modalidad
    id_modulo
    id_usuario
    id_observacion
    fecha_registro
    tipo_persona
    bandera_fase
    observacion{
      id
      observacion
      informacion
    }
  }
}
`;


export { BUSQUEDA_PERSONALIZADA,GET_REGISTROS_INCREMENTAL,GET_HISTORIAL,GET_REGISTROS_CONCESIONARIO,GET_REGISTROS_RETO_X_FASE,GET_CATALOGO_STATUS,GET_FORMATOS_REGISTRO,GET_CATALOGO_FORMATO,GET_REGISTRO_RETO,GET_CATALOGO_FORMATOS,GET_REGISTROS_RETO };
