  import {Component, OnInit} from '@angular/core';
  import {Router} from '@angular/router';
  import {Concesionario} from '../models/concesionario';
  import {Apollo} from 'apollo-angular';
  import gql from 'graphql-tag';
  import { Observable } from 'rxjs';
  import { UpdateConcesionarioGQL } from '../graphqlconsultas/graphqlupdateconcesionario';
  import {User} from "../core/models/user.model";
  import {StorageService} from "../core/services/storage.service";
  import {UserInput} from '../models/useraux';
  import { saveAs  } from 'file-saver';
  declare var M: any;
  import {Ubicacion} from '../models/ubicacion';
  import {Conductor} from '../models/conductor';
  import {Capacitacion} from '../models/capacitacion';
  import { SessionStorageService } from '../core/services/sessionstorage.service';
  import { ErrorApolloService } from '../services/errors/error.service';

  import * as N from '../graphql/mutations';
  import * as A from '../graphql/queries';
@Component({
  selector: 'app-informacion', templateUrl: './informacion.component.html', styleUrls: ['./informacion.component.css']
})
export class InformacionComponent implements OnInit {
  //Variables para decidir que busqueda realizar
  busquedapornuc: boolean = false;
  busquedapornombre: boolean = true;
  // Variable para el input de busqueda
  registroabuscar: string = "";
  // Coleccion de resultados despues de la busqueda por nombre/razon social o nuc
  data: any;
  documentos: any;
  cols: any;
  es: any;
  datetoday: Date = new Date();

  busquedametodo: number = 0;

  // Registro a mostrar cuando se da click
  registroamostrar: any = "";
  //Mensaje a mostrar dependiendo del estado
  mensajeamostrar: any = "";
  //Variables de imagenes a mostrar dependiendo del estado
  booleaninscrito: boolean = false;
  booleanpagado: boolean = false;
  booleansinregistro: boolean = false;
  // Variiable del boton que se va a mostrar al admin (Se va a borrar)
  mostrarsolosueradmin: boolean = true;
  //Variables para esconder los divs que no se utilicen
  divinformacion: boolean = false;
  divdebusqueda: boolean = true;
  esconderaftersearch: boolean = true;
  btnsearch: boolean = false;
  //Para conocer los datos del usuario
  public user: User;
  public ubicacion: Ubicacion;
  public conductor: Conductor;
  public capacitacion: Capacitacion;
  meses: any;

  mostrarpdf: boolean = false;
  mostrarimg: boolean = false;
  //
  src: any;
  pdfSrc: any;

  licencia: any;
  objlicencia: any;
  validLicencia: boolean = false;

  loading: boolean = false;
  mostrarexamenes: boolean = true;
  nombreretoconcesionaro: any;



  constructor(
      private router?: Router,
      private apollo?: Apollo,
      private updateConcesionarioGQL?: UpdateConcesionarioGQL,
      private storageService?: StorageService,
      private sessionStorageService?: SessionStorageService,
      public errorApolloService?: ErrorApolloService,

    ){}

  ngOnInit() {

    this.user = JSON.parse(this.sessionStorageService.get('usuario'));

    this.meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
    this.es = {
            firstDayOfWeek: 1,
            dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
            dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
            dayNamesMin: [ "D","L","M","X","J","V","S" ],
            monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
            monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
            today: 'Hoy',
            clear: 'Borrar'
          }
    this.conductor= new Conductor();
    this.capacitacion = new Capacitacion();
    this.ubicacion = new Ubicacion();
    this.licencia  = '';
    //  this.user = this.storageService.getCurrentUser();
      $(document).ready(function(){
      $('.modal').modal({dismissible: false});
      });
      this.cols = [
           { field: 'tipo_documento', header: 'Nombre' }
         ];
      }

   /* Metodo que se ocupa de traer de forma incremental los registros en la input */

   llamarregistros(){
     var nombrerazonsocial = "";
     var nuc = "";
     if(this.busquedapornuc){
       nuc = this.registroabuscar.toUpperCase() ;
       nombrerazonsocial = null;
       this.busquedametodo = 2;
    }
    if(this.busquedapornombre){
      nombrerazonsocial =  this.registroabuscar.toUpperCase() ;
      nuc = null;
      this.busquedametodo = 1;
    }

    /*
    this.apollo.use('backconsultareto')
      .watchQuery({
        query: A.LISTAR_CONCESIONARIOS,
        variables: {
                entrada: this.registroabuscar.toUpperCase(),
                campo: this.busquedametodo,
      }
      })
      .valueChanges.subscribe(result => {
        this.crearregistros(result.data)
      }, (error) => {
        M.toast({html: error})
      });
      */
      this.apollo.use('backConsulta').watchQuery({
       query: A.LISTAR_CONCESIONARIOS,
       variables: {
         input:  this.registroabuscar.toUpperCase(),
         option: this.busquedametodo,
         limit: 10
       }
      })
      .valueChanges.subscribe(result => {
       this.crearregistros(result.data)
      });

  }

  /* Metodos para seleccionar la forma de busqueda */

    buscarpornombre(){
      this.busquedapornuc = false;
      this.busquedapornombre = true;
    }

    buscarpornuc(){
      this.busquedapornuc = true;
      this.busquedapornombre = false;
    }

  /* Metodo que se ocupa de traer de forma incremental los registros en la input */

     cambioennumeroserie(event){
     var inputanio = (<HTMLInputElement>document.getElementById("nombre")).value;
     this.registroabuscar = inputanio;
     if("/" != this.registroabuscar && this.registroabuscar.length>1){
       this.llamarregistros();
      }
     }

    /* Asignar los concesionarios encontrados en el objeto para mostrarlos en las tablas */

      crearregistros(registrosencontrados: any){
        this.data = null;
        this.data = registrosencontrados.concessionsLike;
      }

    /* Cuando el usuario de click en algun registro este se le asigna a una variable llamada registro a mostrar y se muestran los datos */

      seleccionarregistro(registro: any){
         this.btnsearch = true;
        this.registroamostrar = registro;
          if(this.busquedapornombre){
            if(this.registroamostrar.concessionaire.person_type=="F"){
               this.registroabuscar = this.registroamostrar.concessionaire.name + " " +this.registroamostrar.concessionaire.first_surname + " " + this.registroamostrar.concessionaire.second_surname;
            }else{
            this.registroabuscar = this.registroamostrar.concessionaire.business_name;
            }
          }
          if(this.busquedapornuc){
             this.registroabuscar = this.registroamostrar.consecutive_number+'/'+this.registroamostrar.unique_number_key;
          }
        this.divdebusqueda = false;
      }


    /* Metodo que buscar el estado actual de un registro */

      buscarregistro(){
        let che: boolean = true;
        var razonsocial = this.registroamostrar.concessionaire.business_name.toUpperCase();
        var nuc = this.registroamostrar.consecutive_number+'/'+this.registroamostrar.unique_number_key.toUpperCase();
        var nombre = this.registroamostrar.concessionaire.name.toUpperCase();
        var ape_pat = this.registroamostrar.concessionaire.first_surname.toUpperCase();
        var ape_mat = this.registroamostrar.concessionaire.second_surname.toUpperCase();


        this.esconderaftersearch= false;
        this.divinformacion = true;
     if(this.busquedapornuc){
        nombre = null;
        ape_pat = null;
        ape_mat = null;
        razonsocial = null;
      }
      if(this.busquedapornombre){
         if(this.registroamostrar.concessionaire.business_name == ""){
            razonsocial = null;
            nuc = null;
         }else{
            nuc = null;
            nombre = null;
            ape_pat = null;
            ape_mat = null;
         }
       }

    this.apollo.use('backretoutilidades')
    .watchQuery({
      query: A.BUSCAR_COMPROBADO,
      variables: {
              nombre:   nombre,
              primer_apellido:  ape_pat,
              segundo_apellido:  ape_mat,
              nuc:  nuc,
              razon_social:   razonsocial,
    }
    })
    .valueChanges.subscribe(result => {
     this.pintaradvertencia(result.data);
     }, (error) => {
       M.toast({html: error})
     });


        /*
        if(this.storageService.isExpired()){
           this.storageService.removeCurrentSession();
           M.toast({html: "Tu sesión ha expirado. Volviendo a pantalla de inicio de sesión."})
           che =  true;
        }else{
           che =  false;
        }
        if(che ==  true){
           window.location.href = "/login";
        }else{
           this.esconderaftersearch= false;
           this.divinformacion = true;
        if(this.busquedapornuc){
           nombre = null;
           ape_pat = null;
           ape_mat = null;
           razonsocial = null;
         }
         if(this.busquedapornombre){
            if(this.registroamostrar.concessionaire.business_name == ""){
               razonsocial = null;
               nuc = null;
            }else{
               nuc = null;
               nombre = null;
               ape_pat = null;
               ape_mat = null;
            }
          }
       let useraenviar = new UserInput();
           useraenviar.id = this.user.id;
           useraenviar.correo = this.user.correo;
       this.apollo.use('backconsultareto')
       .watchQuery({
         query: A.BUSCAR_COMPROBADO,
         variables: {
                 nombre:   nombre,
                 primer_apellido:  ape_pat,
                 segundo_apellido:  ape_mat,
                 nuc:  nuc,
                 razon_social:   razonsocial,
                 token: this.storageService.getCurrentToken()
       }
       })
       .valueChanges.subscribe(result => {
        this.pintaradvertencia(result.data);
        }, (error) => {
          M.toast({html: error})
        });
      }
        */

    }

    /* Pintar la advertencia con los diferentes tipos de estado */

    pintaradvertencia(decision: any){

      this.nombreretoconcesionaro = decision.comprobado.concesionario;

      switch (decision.comprobado.codigo) {
              case 2:
                     this.mensajeamostrar = "Inscrito";
                     this.booleaninscrito = true;
                     this.booleanpagado = false;
                     this.booleansinregistro = false;
                     this.documentos = decision.comprobado.documentos;
              break;
              case 1:
                     this.mensajeamostrar = "Pagado";
                     this.booleaninscrito = false;
                     this.booleanpagado = true;
                     this.booleansinregistro = false;
              break;
              case 0:
                     this.mensajeamostrar = "Sin registro";
                     this.booleaninscrito = false;
                     this.booleanpagado = false;
                     this.booleansinregistro = true;
              break;
              default:
              M.toast({html: 'Error en el tipo de estado, favor de comunicarse con el supervisor.'})
              }
    }

    /* Recargar una pagina */

    recargarpagina(){
      window.location.href = "/informacion";
    }

    /* Metodo que cambia el estado de un concesionario*/

    cambiarestado(){

       let nombre = this.registroamostrar.nombre;
       let apellido_paterno = this.registroamostrar.primer_apellido;
       let apellido_materno = this.registroamostrar.segundo_apellido;
       let razon_social = this.registroamostrar.razon_social;
       if(this.registroamostrar.tipo_persona == "F"){
        razon_social = null;
       }else{
        nombre = null;
        apellido_paterno = null;
        apellido_materno = null;
      }


      this.apollo.use('backconsultareto')
       .mutate({
         mutation:  N.CHANGE_ESTATUS,
         variables: {
           nombre: nombre,
           primer_apellido: apellido_paterno,
           segundo_apellido: apellido_materno,
           tipo_persona: this.registroamostrar.tipo_persona,
           razon_social: razon_social,
           nuc: this.registroamostrar.nuc,
           token: this.storageService.getCurrentToken()
         }
       })
       .subscribe(({ data }) => {
         M.toast({html: "Se ha cambiado el estado al usuario."})
         window.location.href = "/informacion";
       }, (error) => {
         this.errorApolloService.checkError(error.message);
       });

     }

     obtenerbloqueadoandrol(){
       return this.registroamostrar.bloqueado && (this.storageService.getCurrentUser().id_rol == 4 || this.storageService.getCurrentUser().id_rol == 3);
     }

     obtenervigenciaandrol(){
       return this.registroamostrar.vigencia && (this.storageService.getCurrentUser().id_rol == 4 || this.storageService.getCurrentUser().id_rol == 3);
     }

     pruebadescargararchivo(){
       saveAs("", "image.png");
     }

     obtenerimagen(url: any,mime: any){

       this.apollo.use('backconsultareto')
       .watchQuery({
         query: A.BUSCAR_DOCUMENTO,
         variables: {
                 url:   url
       }
       })
       .valueChanges.subscribe(result => {
        this.pintarimagen(result.data,mime);
        }, (error) => {
          M.toast({html: error})
        });

     }

      dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
       }

     pintarimagen(base64: any,mime: any){
       var image = new Image();
       var tipoimagen = "";

       if(mime == "image/jpeg" || mime == "image/png" || mime == "image/bmp"){
         this.mostrarpdf = false;
         this.mostrarimg = true;
         tipoimagen = mime;
         this.src = 'data:'+tipoimagen+';base64,'+base64.documento.data;

       }

       if(mime == "application/pdf"){
         this.mostrarpdf = true;
         this.mostrarimg = false;
         var file = this.dataURLtoFile('data:application/pdf;base64,'+base64.documento.data, 'ejemplo.pdf');
         this.mostrarpdf = true;

         this.pintarprevisualizacion(file);



       }

     }

     pintarprevisualizacion(img: any){
       let $img: any = img;
      let reader = new FileReader();
       if (typeof (FileReader) !== 'undefined') {
          let reader = new FileReader();
          reader.onload = (e: any) => {
           this.pdfSrc = e.target.result;
          };
          reader.readAsArrayBuffer($img);
        }
      }

      cambiarestadolicencia(){
        this.loading = true;
       this.apollo.use('backLicencias').watchQuery({
         query: A.FIND_LICENSE,
         variables: {
              numero: this.licencia
            }
          })
       .valueChanges.subscribe(result => {
           this.asignarLicencia(result);
        }, (error) => {
          var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Error al conectarse al verificar la Licencia, contactar al Departamento de Sistematización.    </div></span>';
          M.toast({html: toastHTML});
         });
       }


       asignarLicencia(data: any){
         this.loading = false;
         if(data.data.licenseByNumber!=null){
           this.objlicencia = data.data.licenseByNumber;
             let fechaVencimientoLicencia = new Date(data.data.licenseByNumber.fecha_vencimiento);
             let fechanow = new Date();

            if (fechaVencimientoLicencia.getTime() >= fechanow.getTime()){
             var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia valida</div></span>';
             M.toast({html: toastHTML});
             this.validLicencia = true;
            }else{
             var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia vencida, fecha de vencimiento ' + fechaVencimientoLicencia.getDate() +'/'+ this.meses[fechaVencimientoLicencia.getMonth()]+'/'+ fechaVencimientoLicencia.getFullYear() + ' </div></span>';
             M.toast({html: toastHTML});
             this.validLicencia = false;
           }
         }else{
           var toastHTML = '<span> <div class="valign-wrapper"><i class="material-icons">error_outline</i>  &nbsp;&nbsp;Licencia no valida    </div></span>';
           M.toast({html: toastHTML});
            this.validLicencia = false;
         }
       }



       registrarConductor(){
         this.conductor.nombre = this.conductor.nombre.toUpperCase();
         this.conductor.primer_apellido = this.conductor.primer_apellido.toUpperCase();
         if(this.conductor.segundo_apellido!=undefined){
           this.conductor.segundo_apellido = this.conductor.segundo_apellido.toUpperCase();
         }
         this.ubicacion.calle = this.ubicacion.calle.toUpperCase();
         this.ubicacion.colonia = this.ubicacion.colonia.toUpperCase();
         this.ubicacion.municipio = this.ubicacion.municipio.toUpperCase();

         this.capacitacion.folio = this.capacitacion.folio.toUpperCase();

         this.conductor.ubicacion = this.ubicacion;
         this.conductor.capacitacion = this.capacitacion;

       }



}
