import { Injectable } from '@angular/core';
 import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {Apollo} from 'apollo-angular';
import {IMAGEOAXACAWEB} from "../../core/key/imgoaxacagobmx";
import { SessionStorageService } from '../../core/services/sessionstorage.service';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
type AOA = any[][];
declare var saveAs:any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})

export class CreadorComprobanteService {
  public user: any;

  constructor(private sessionStorageService: SessionStorageService,private apollo?: Apollo) {
      }

      download(callback: Function,dataURL,imageconstancia,conductor,conductoractivo,qrmensagge): void {

        const meses = [
              "Enero", "Febrero", "Marzo",
              "Abril", "Mayo", "Junio", "Julio",
              "Agosto", "Septiembre", "Octubre",
              "Noviembre", "Diciembre"
            ];
        const date = new Date()
        const dia = date.getDate()
        const mes = date.getMonth()
        const ano = date.getFullYear()
         let fechaactual = `${dia} de ${meses[mes]} del ${ano}`;

         let nombreCompleto = conductor.persona.identity.name + ' ' + conductor.persona.identity.first_surname;
         if(conductor.persona.identity.second_surname!=undefined){
           nombreCompleto = nombreCompleto + ' ' + conductor.persona.identity.second_surname;
         }
         let arrestadoconstancia = [];

         let estadoconstancia = '';
         let colorcancelado = ''

         if(!conductor.is_expired && conductoractivo){
           estadoconstancia = 'Activa'
           colorcancelado = 'green';
           arrestadoconstancia.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Estado del Tarjetón:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: 'green' ,absolutePosition: {x: 280, y: 260}  }
                ]
             },
           );



         }

         if(!conductoractivo){
           estadoconstancia = 'Cancelada'
           arrestadoconstancia.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Estado del Tarjetón:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: 'red' ,absolutePosition: {x: 280, y: 260}  }
                ]
             },
           );

         }

         if(conductor.is_expired && conductoractivo){
           estadoconstancia = 'Vencida'
           arrestadoconstancia.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Estado del Tarjetón:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: 'red' ,absolutePosition: {x: 280, y: 260}  }
                ]
             },
           );

         }

         /*
         <label *ngIf="this.conductoractivo == true && conductorseleccionado.is_expired == false" class="conseciontitulopequeñoPrevisualizacion activo"> Activa </label> <br
           *ngIf="this.conductoractivo == true && conductorseleccionado.is_expired == false">
         <label *ngIf="this.conductoractivo == false" class="conseciontitulopequeñoPrevisualizacion cancelado"> Cancelada </label> <br *ngIf="this.conductoractivo == false">
         <label *ngIf="this.conductoractivo == true && conductorseleccionado.is_expired == true" class="conseciontitulopequeñoPrevisualizacion cancelado"> Vencida </label> <br
           *ngIf="this.conductoractivo == true && this.conductoractivo.is_expired == true">
         */

         let arrcancelacion = [];
         if(conductor.motivocancelacion!=null){
           arrcancelacion.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Motivo de la cancelación:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 450} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: conductor.motivocancelacion, fontSize: 15 ,absolutePosition: {x: 280, y: 470},color: 'red' }
                ]
             },
           );
         }
         if(conductor.fecha_cancelacion!=null){
           arrcancelacion.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Fecha de la cancelación:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 490} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: this.funcioncrearfecha(conductor.fecha_cancelacion), fontSize: 15 ,absolutePosition: {x: 280, y: 510},color: 'red' }
                ]
             },
           );
         }


        pdfMake.fonts = {
        Roboto: {
                       normal: 'Roboto-Regular.ttf',
                       bold: 'Roboto-Medium.ttf',
                       italics: 'Roboto-Italic.ttf',
                       bolditalics: 'Roboto-MediumItalic.ttf'
               }
          }
            var dd = {
              pageSize: 'LETTER',
              content: [


                { columns: [
                    { image: dataURL,width: 240,height: 62,absolutePosition: {x: 320, y: 35}}
                   ]
                },

                { columns: [
                    { width: 10, text: '' },
                    { alignment: 'justify', width: 250, text:'SECRETARÍA DE MOVILIDAD' , fontSize: 10, bold: true,absolutePosition: {x: 40, y: 50} },
                   ]
                },


                { columns: [
                    { width: 240, text: 'Oaxaca de Juárez, Oaxaca a ' + fechaactual +'.', fontSize: 8 ,absolutePosition: {x: 40, y: 65}}
                  ]
                },

                { columns: [
                    { width: 10, text: '' },
                    { alignment: 'justify', width: 250, text:'COMPROBANTE DEL TARJETÓN' , fontSize: 12, bold: true,absolutePosition: {x: 40, y: 75} },
                   ]
                },


                { columns: [

                    { image: imageconstancia,width: 190,height: 250,absolutePosition: {x: 60, y: 115}}
                   ]
                },


                { columns: [
                     { alignment: 'justify', width: 250, text: 'DATOS DEL CONDUCTOR', fontSize: 16, bold: true, color: 'red',absolutePosition: {x: 280, y: 110} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Nombre:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 130} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: nombreCompleto, fontSize: 15 ,absolutePosition: {x: 280, y: 150} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Número de Licencia:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 170} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: conductor.persona.license.license_number, fontSize: 15 ,absolutePosition: {x: 280, y: 190} }
                   ]
                },


                { columns: [
                  { alignment: 'justify', width: 250, text: 'DATOS DEL TARJETÓN', fontSize: 16, bold: true, color: 'red',absolutePosition: {x: 280, y: 220} }
                   ]
                },

                arrestadoconstancia,

/*
                { columns: [
                     { alignment: 'justify', width: 250, text: 'Estado de la constancia:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: colorcancelado ,absolutePosition: {x: 280, y: 260}  }
                   ]
                },

                */

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Número de Folio:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 280} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: conductor.hojafoliada, fontSize: 15 ,absolutePosition: {x: 280, y: 300} }
                   ]
                },


                { columns: [
                     { alignment: 'justify', width: 250, text: 'Placa:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 320} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: conductor.placa, fontSize: 15 ,absolutePosition: {x: 280, y: 340} }
                   ]
                },


                { columns: [
                     { alignment: 'justify', width: 250, text: 'Expedición:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 360} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: this.funcioncrearfecha(conductor.expedition_date), fontSize: 15 ,absolutePosition: {x: 280, y: 380} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Vigencia:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 400} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: this.funcioncrearfecha(conductor.expiration_date), fontSize: 15 ,absolutePosition: {x: 280, y: 420} }
                   ]
                },
                arrcancelacion,
                { qr:  qrmensagge  , fit: '85',absolutePosition: {x: 120, y: 410} },










               ]
            };
             pdfMake.createPdf(dd).download(nombreCompleto+'.pdf', callback());

            // pdfMake.createPdf(dd).download('Ejemplo.pdf', callback());
        //this.crearConstanciaComprobante(conductor,conductoractivo,qrmensagge);
        /*
        if(this.PDF) {
          createPdf(this.PDF, undefined, this.fonts(), pdfMake.vfs).download("Revista - Físico Mecánica", callback());
        }
        */
      }


      crearConstanciaComprobante(conductor,conductoractivo,qrmensagge){
        let imageurl;
        setTimeout(() => {
          imageurl = this.getBase64ImageFromURLImage(environment.URIImage + 'image/' + conductor.image,conductor,conductoractivo,qrmensagge);
        }, 100);
      }


      getBase64ImageFromURLImage(url,conductor,conductoractivo,qrmensagge) {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
           this.traerImagenBanner(dataURL,conductor,conductoractivo,qrmensagge);
           };
          img.onerror = error => {
            reject(error);
          };
          img.src = url;
        });
      }

      traerImagenBanner(dataURL,conductor,conductoractivo,qrmensagge){
        let imageurl;
        setTimeout(() => {
          imageurl = this.getBase64ImageFromURLBanner('https://resource.semovioaxaca.gob.mx/assets/images/banner.png',dataURL,conductor,conductoractivo,qrmensagge);
        }, 100);
      }


      getBase64ImageFromURLBanner(url,imageconstancia,conductor,conductoractivo,qrmensagge) {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
           this.construirConstanciaComprobante(dataURL,imageconstancia,conductor,conductoractivo,qrmensagge);
           };
          img.onerror = error => {
            reject(error);
          };
          img.src = url;
        });
      }





      construirConstanciaComprobante(dataURL,imageconstancia,conductor,conductoractivo,qrmensagge){
        const meses = [
              "Enero", "Febrero", "Marzo",
              "Abril", "Mayo", "Junio", "Julio",
              "Agosto", "Septiembre", "Octubre",
              "Noviembre", "Diciembre"
            ];
        const date = new Date()
        const dia = date.getDate()
        const mes = date.getMonth()
        const ano = date.getFullYear()
         let fechaactual = `${dia} de ${meses[mes]} del ${ano}`;

         let nombreCompleto = conductor.persona.identity.name + ' ' + conductor.persona.identity.first_surname;
         if(conductor.persona.identity.second_surname!=undefined){
           nombreCompleto = nombreCompleto + ' ' + conductor.persona.identity.second_surname;
         }
         let arrestadoconstancia = [];

         let estadoconstancia = '';
         let colorcancelado = ''

         if(!conductor.is_expired && conductoractivo){
           estadoconstancia = 'Activa'
           colorcancelado = 'green';
           arrestadoconstancia.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Estado del Tarjetón:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: 'green' ,absolutePosition: {x: 280, y: 260}  }
                ]
             },
           );



         }

         if(!conductoractivo){
           estadoconstancia = 'Cancelada'
           arrestadoconstancia.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Estado del Tarjetón:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: 'red' ,absolutePosition: {x: 280, y: 260}  }
                ]
             },
           );

         }

         if(conductor.is_expired && conductoractivo){
           estadoconstancia = 'Vencida'
           arrestadoconstancia.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Estado del Tarjetón:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: 'red' ,absolutePosition: {x: 280, y: 260}  }
                ]
             },
           );

         }

         /*
         <label *ngIf="this.conductoractivo == true && conductorseleccionado.is_expired == false" class="conseciontitulopequeñoPrevisualizacion activo"> Activa </label> <br
           *ngIf="this.conductoractivo == true && conductorseleccionado.is_expired == false">
         <label *ngIf="this.conductoractivo == false" class="conseciontitulopequeñoPrevisualizacion cancelado"> Cancelada </label> <br *ngIf="this.conductoractivo == false">
         <label *ngIf="this.conductoractivo == true && conductorseleccionado.is_expired == true" class="conseciontitulopequeñoPrevisualizacion cancelado"> Vencida </label> <br
           *ngIf="this.conductoractivo == true && this.conductoractivo.is_expired == true">
         */

         let arrcancelacion = [];
         if(conductor.motivocancelacion!=null){
           arrcancelacion.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Motivo de la cancelación:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 450} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: conductor.motivocancelacion, fontSize: 15 ,absolutePosition: {x: 280, y: 470},color: 'red' }
                ]
             },
           );
         }
         if(conductor.fecha_cancelacion!=null){
           arrcancelacion.push(
             { columns: [
                  { alignment: 'justify', width: 250, text: 'Fecha de la cancelación:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 490} }
                ]
             },

             { columns: [
                  { alignment: 'justify', width: 250, text: this.funcioncrearfecha(conductor.fecha_cancelacion), fontSize: 15 ,absolutePosition: {x: 280, y: 510},color: 'red' }
                ]
             },
           );
         }


        pdfMake.fonts = {
        Roboto: {
                       normal: 'Roboto-Regular.ttf',
                       bold: 'Roboto-Medium.ttf',
                       italics: 'Roboto-Italic.ttf',
                       bolditalics: 'Roboto-MediumItalic.ttf'
               }
          }
            var dd = {
              pageSize: 'LETTER',
              content: [


                { columns: [
                    { image: dataURL,width: 240,height: 62,absolutePosition: {x: 320, y: 35}}
                   ]
                },

                { columns: [
                    { width: 10, text: '' },
                    { alignment: 'justify', width: 250, text:'SECRETARÍA DE MOVILIDAD' , fontSize: 10, bold: true,absolutePosition: {x: 40, y: 50} },
                   ]
                },


                { columns: [
                    { width: 240, text: 'Oaxaca de Juárez, Oaxaca a ' + fechaactual +'.', fontSize: 8 ,absolutePosition: {x: 40, y: 65}}
                  ]
                },

                { columns: [
                    { width: 10, text: '' },
                    { alignment: 'justify', width: 250, text:'COMPROBANTE DEL TARJETÓN' , fontSize: 12, bold: true,absolutePosition: {x: 40, y: 75} },
                   ]
                },


                { columns: [

                    { image: imageconstancia,width: 190,height: 250,absolutePosition: {x: 60, y: 115}}
                   ]
                },


                { columns: [
                     { alignment: 'justify', width: 250, text: 'DATOS DEL CONDUCTOR', fontSize: 16, bold: true, color: 'red',absolutePosition: {x: 280, y: 110} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Nombre:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 130} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: nombreCompleto, fontSize: 15 ,absolutePosition: {x: 280, y: 150} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Número de Licencia:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 170} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: conductor.persona.license.license_number, fontSize: 15 ,absolutePosition: {x: 280, y: 190} }
                   ]
                },


                { columns: [
                  { alignment: 'justify', width: 250, text: 'DATOS DEL TARJETÓN', fontSize: 16, bold: true, color: 'red',absolutePosition: {x: 280, y: 220} }
                   ]
                },

                arrestadoconstancia,

/*
                { columns: [
                     { alignment: 'justify', width: 250, text: 'Estado de la constancia:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 240} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: estadoconstancia, fontSize: 15, color: colorcancelado ,absolutePosition: {x: 280, y: 260}  }
                   ]
                },

                */

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Número de Folio:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 280} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: conductor.hojafoliada, fontSize: 15 ,absolutePosition: {x: 280, y: 300} }
                   ]
                },


                { columns: [
                     { alignment: 'justify', width: 250, text: 'Placa:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 320} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: conductor.placa, fontSize: 15 ,absolutePosition: {x: 280, y: 340} }
                   ]
                },


                { columns: [
                     { alignment: 'justify', width: 250, text: 'Expedición:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 360} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: this.funcioncrearfecha(conductor.expedition_date), fontSize: 15 ,absolutePosition: {x: 280, y: 380} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: 'Vigencia:', fontSize: 15, bold: true ,absolutePosition: {x: 280, y: 400} }
                   ]
                },

                { columns: [
                     { alignment: 'justify', width: 250, text: this.funcioncrearfecha(conductor.expiration_date), fontSize: 15 ,absolutePosition: {x: 280, y: 420} }
                   ]
                },
                arrcancelacion,
                { qr:  qrmensagge  , fit: '85',absolutePosition: {x: 120, y: 410} },










               ]
            };
             pdfMake.createPdf(dd).download(nombreCompleto+'.pdf');



      }


      funcioncrearfecha(stringdate: any) {
        let fecha = new Date(stringdate);
        let dia21 = "";
        let anio21 = fecha.getFullYear().toString();
        let mes21 = "";
        if ((fecha.getMonth() + 1) < 10) {
          mes21 = "0" + (fecha.getMonth() + 1).toString();
        } else {
          mes21 = (fecha.getMonth() + 1).toString();
        }
        if ((fecha.getDate() + 1) <= 10) {
          dia21 = "0" + (fecha.getDate()).toString();
        } else {
          dia21 = (fecha.getDate()).toString();
        }
        return anio21 + "-" + mes21 + "-" + dia21 + "";
      }

      crearComprobante(concesion){
        let imageurl;
        setTimeout(() => {
          imageurl = this.getBase64ImageFromURL('https://resource.semovioaxaca.gob.mx/assets/images/banner.png',concesion);
        }, 100);
      }


      getBase64ImageFromURL(url,concesion) {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
           this.generaformatoconmakepdf(dataURL,concesion);
           };
          img.onerror = error => {
            reject(error);
          };
          img.src = url;
        });
      }

  generaformatoconmakepdf(dataURL,concesion){
    this.user = JSON.parse(this.sessionStorageService.get('usuario'));

    let content_array = [];

    const meses = [
          "Enero", "Febrero", "Marzo",
          "Abril", "Mayo", "Junio", "Julio",
          "Agosto", "Septiembre", "Octubre",
          "Noviembre", "Diciembre"
        ];
    const date = new Date()
    const dia = date.getDate()
    const mes = date.getMonth()
    const ano = date.getFullYear()
     let fechaactual = `${dia} de ${meses[mes]} del ${ano}`;


    let nombreCompleto = '';
    if(concesion.concessionaire.person_type == 'M'){
      nombreCompleto = concesion.concessionaire.business_name;

    }else{
     nombreCompleto = concesion.concessionaire.name + ' ' + concesion.concessionaire.first_surname ;
     if(concesion.concessionaire.second_surname!=undefined){
       nombreCompleto = nombreCompleto + ' ' + concesion.concessionaire.second_surname;
     }

    }
    pdfMake.fonts = {
    Roboto: {
                   normal: 'Roboto-Regular.ttf',
                   bold: 'Roboto-Medium.ttf',
                   italics: 'Roboto-Italic.ttf',
                   bolditalics: 'Roboto-MediumItalic.ttf'
           }
      }
        var dd = {
          pageSize: 'LETTER',
          content: [

            { columns: [
                { width: 10,text: ''},
              //  { qr: 'WHK18|'+ numeroOficio + '|NUC: ' + mensaje + "|" + this.infoqr ,fit: 75},
                { width: 220, text: ''
                },
                { image: dataURL,width: 240,height: 62}
               ]
            },


            { columns: [
                { width: 105,text: ''},
                { width: '*', text: '“2021, AÑO DEL RECONOCIMIENTO AL PERSONAL DE SALUD, POR LA LUCHA CONTRA EL VIRUS SARS-COV2, COVID19”', fontSize: 9, bold: true, margin: [0, 20]  },
                { width: 50, text: ''}
              ]
            },
  /*
            { columns: [
                { width: 282, text: ''},
                { width: 38, text: 'ORIGEN:', fontSize: 9,bold: true },
                { width: 200, text: 'DIRECCIÓN DE PLANEACIÓN Y ESTUDIOS', fontSize: 9}
              ]
            },
            { columns: [
                { width: 286, text: ''},
                { width: 34, text: 'OFICIO:', fontSize: 9,bold: true },
                { width: 230, text: 'SEMOVI/SPN/DPE/TOT/' + numeroOficio +'/' +  this.datenow.getFullYear().toString(), fontSize: 9 }
              ]
            },

            { columns: [
                { width: 279, text: '' },
                { width: 41, text: 'ASUNTO:', fontSize: 9,bold: true },
                { width: 200, text: 'ENTREGA DE TARJETÓN OFICIAL DE TARIFA', fontSize: 9}
              ]
            },
            */
            { columns: [
                { width: 300, text: '' },
                { width: 240, text: 'Oaxaca de Juárez, Oaxaca a ' +fechaactual + '.', fontSize: 8, margin: [10, 20] }
              ], columnGap: 10
            },
            {
              columns: [
                { width: 10, text: '', margin: [0, 20] }
              ]
            },
            { columns: [
                { width: 10, text: '' },
                 { alignment: 'justify', width: 250, text: 'ING. DAVID LÓPEZ HERNÁNDEZ', fontSize: 10, bold: true }
               ]
            },
            { columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 250, text: 'COORDINADOR TÉCNICO Y ENCARGADO DEL REGISTRO ESTATAL DE TRANSPORTE.' , fontSize: 10, bold: true },

                ]
            },
            { columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 250, text:'SECRETARÍA DE MOVILIDAD' , fontSize: 10, bold: true },
               ]
            },


            { columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 80, text: 'P R E S E N T E.', fontSize: 10, bold: true }
              ]
            },
            {
              columns: [
                { width: 10, text: '', margin: [0, 10] }
              ]
            },
            { columns: [
              { width: 10, text: '' },
                           { alignment: 'justify',text: ['El que suscribe C.'  +nombreCompleto+ ', concesionario del servicio público, número único de concesionario ' + concesion.consecutive_number+'/'+ concesion.unique_number_key + ' con domicilio en ' + concesion.concessionaire.address + ', número de teléfono ' + concesion.concessionaire.contact.cel_number + ' y correo electrónico ' + concesion.concessionaire.contact.email+ ', mismo que autorizo para notificarme y comunicarse conmigo, y con fundamento en el artículo 77 Fracción I de la Ley de Movilidad para el estado de Oaxaca y articulo 142 Fracción I del Reglamento del mismo, solicito mi inscripción al Registro Estatal de Transporte de OAXACA; manifestando que cuento con toda la documentación que acredita la veracidad y autenticidad de la información proporcionada necesaria para la inscripción.'], width: 460, fontSize: 8, margin: [0, 10]}
                         ]
                       },


            { columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 460, text: '', fontSize: 8, margin: [0, 20] }
              ]
            },
            {
              columns: [
                { width: 10, text: '', margin: [0, 20] }
              ]
            },
            {
              columns: [
                 {  width: '100%', text: 'C. ' + nombreCompleto , fontSize: 8,bold: true,alignment: 'center' },
              ]
            },
            {
              columns: [
                { width: 10, text: '', margin: [0, 20] }
              ]
            },
            /*
            {
              columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 300, text: 'C.c.p.', fontSize: 5  }
              ]
            },

            {
              columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 300, text: '            C. MARIANA ERANDI NASSAR PIÑEYRO.- Secretaria de Movilidad del Estado de Oaxaca.- Para su conocimiento.', fontSize: 5  }
              ]
            }
            ,
            {
              columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 300, text: '            LIC. MARIO ALBERTO GUZMÁN JAIME.- Subsecretario de Regulación y Control del Transporte. Mismo fin.', fontSize: 5  }
              ]
            },
            {
              columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 300, text: '            L.A.E. ALEJANDRO VILLANUEVA LÓPEZ.- Subsecretario de Planeación y Normatividad. Mismo fin.', fontSize: 5  }
              ]
            },
            {
              columns: [
                { width: 10, text: '' },
                { alignment: 'justify', width: 300, text: '            Archivo y minutario.', fontSize: 5  }
              ]
            },
            {
              columns: [
                { width: 10, text: '', margin: [0, 20] }
              ]
            },
            {
              columns: [
                { width: 170, text: '' },
                { alignment: 'right', width: 300, text: 'Heroico Colegio Militar 317, Colonia Reforma,', fontSize: 6  }
              ]
            }
            ,
            {
              columns: [
                { width: 170, text: '' },
                { alignment: 'right', width: 300, text: 'Oaxaca de Juárez, Oaxaca – C.P. 68050', fontSize: 6  }
              ]
            },

            */
            {
              image:  'data:image/jpeg;base64,'+IMAGEOAXACAWEB.IMAGE_W,width: 44,height: 460,
              absolutePosition: {x: 540, y: 200}
            }

           ]
        };
        pdfMake.createPdf(dd).download('Comprobante_Registro.pdf');

  }












}
