import gql from 'graphql-tag';




const CREATE_OBSERVACION_CONDUCTOR = gql`
mutation observation($description: String!,$persons_id: ID!) {
		observation(description: $description,persons_id: $persons_id){
			id
		}
	  }
`;


const CANCELAR_PREREGISTRO = gql`
mutation cancelPreCertificate($id: ID!) {
      cancelPreCertificate(id: $id)
    }
`;

const CREATE_PLATE = gql`
mutation plate($folio: String!, $concession_id: ID!,$unidadesAmparadas: Int!) {
      plate(folio: $folio, concession_id: $concession_id, unidadesAmparadas: $unidadesAmparadas ) {
        id
        folio
      }
    }
`;


const CREATE_CERTIFICATE = gql`
mutation certificate($concession_id: ID!,$person_id: ID!,$plate_id: ID!,$foliated_sheet: FoliatedSheetInput!,$modality_id: ID!,$photo: Upload!,$is_locked: Boolean!) {
      certificate(concession_id: $concession_id, person_id: $person_id, plate_id: $plate_id, foliated_sheet: $foliated_sheet, modality_id: $modality_id, photo: $photo, is_locked: $is_locked ) {
        id
        concession_id
        person_id
        plate{
          id
          folio
        }
        foliated_sheet{
          id
          folio
        }
        expedition_date
        expiration_date
        is_canceled
        cancel_description
        image_name
        modality_id
        is_expired
        deprecated
      }
    }
`;


const CREATE_PRECERTIFICATE = gql`
mutation preCertificate($concession_id: ID!,$person_id: ID!,$plate_id: ID!,$modality_id: ID!,$photo: Upload!) {
      preCertificate(concession_id: $concession_id, person_id: $person_id, plate_id: $plate_id, modality_id: $modality_id, photo: $photo ) {
        id
        concession_id
        person_id
        plate{
          id
          folio
        }
        is_canceled
        is_completed
        capture_date
        image_name
        modality_id
        deprecated
      }
    }
`;

const CONVERT_PRE_TO_CERTIFICATE = gql`
mutation preCertificateToCertificate($pre_certificate_id: ID!, $foliated_sheet: FoliatedSheetInput!) {
      preCertificateToCertificate(pre_certificate_id: $pre_certificate_id, foliated_sheet: $foliated_sheet ) {
        id
        concession_id
        person_id
        plate{
          id
          folio
        }
        foliated_sheet{
          id
          folio
        }
        expedition_date
        expiration_date
        is_canceled
        cancel_description
        image_name
        modality_id
        is_expired
        deprecated
      }
    }
`;


const CREATE_COMENTARIO = gql`
mutation observation($description: String!,$certificate_id: ID!) {
      observation(description: $description, certificate_id: $certificate_id ) {
        id
      }
    }
`;

export { CREATE_OBSERVACION_CONDUCTOR,CREATE_PLATE,CREATE_CERTIFICATE,CREATE_PRECERTIFICATE,CONVERT_PRE_TO_CERTIFICATE,CANCELAR_PREREGISTRO,CREATE_COMENTARIO };
