import gql from 'graphql-tag';

const CREATE_LOG = gql`
mutation newLog($description: String!) {
      log(description: $description) {
        id
        description
        user_id
        user_description
        workplace
        region
        ip_address
        user_agent
        http_method
        status
        created_at
      }
    }

`;


export { CREATE_LOG };
