import gql from 'graphql-tag';

const GET_OBSERVACIONES_BY_DRIVER = gql`
query getObservationsByDriver($driver_id: ID!) {
      observationsByDriver(driver_id: $driver_id) {
        id
        description
        certificate {
          id
          concession {
            id
            concessionaire {
              id
              person_type
              name
              first_surname
              second_surname
              curp
              rfc
              business_name
            }
            agreement
            agreement_date
            expiration_date
            capture_date
            start_date
            term
            main_notes
            delivery_certificate_notes
            route
            authorized_vehicles
            status
            modality {
              id
              name
              description
              status
              abbreviation
            }
            site {
              id
              name
              legal_representative
              address
              authorized_colors
              status
            }
            consecutive_number
            unique_number_key
            attached {
              active
              new_vehicle_auth
              new_concession_auth
              locked {
                status
                stolen_folio
              }
              reto {
                status
              }
            }
          }
          driver {
            id
            name
            first_surname
            second_surname
            curp
            birthday
            license_number
            contact {
              id
              email
              celphone
              status
              created_at
            }
            address {
              id
              road_name
              road_type {
                id
                name
                description
                status
                created_at
              }
              outdoor_number
              indoor_number
              postal_code
              settlement {
                id
                name
                locality {
                  id
                  name
                  municipality {
                    id
                    name
                    district {
                      id
                      name
                      region {
                        id
                        name
                        status
                        created_at
                      }
                      code
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                settlement_type {
                  id
                  name
                  description
                  status
                  created_at
                }
                status
                created_at
              }
              sublevel {
                id
                name
                municipality {
                  id
                  name
                  district {
                    id
                    name
                    region {
                      id
                      name
                      status
                      created_at
                    }
                    code
                    status
                    created_at
                  }
                  code
                  status
                  created_at
                }
                sublevel_type {
                  id
                  name
                  description
                  status
                  created_at
                }
                status
                created_at
              }
              status
              created_at
            }
            trainings {
              id
              folio
              description
              expiration_date
              status
              created_at
            }
            status
            created_at
          }
          plate {
            id
            folio
            status
            created_at
          }
          image {
            id
            name
            original_name
            extension
            type_content
            route
            base64
            status
            created_at
          }
          foliated_sheet {
            id
            folio
            status
            created_at
          }
          expedition_date
          expiration_date
          modality {
            id
            name
            description
            status
            abbreviation
          }
          log {
            id
            description
            id_user
            user_description
            workplace
            region
            ip_address
            user_agent
            http_method
            status
            created_at
          }
          is_canceled
          cancel_description
          status
          created_at
        }
        log {
          id
          description
          id_user
          user_description
          workplace
          region
          ip_address
          user_agent
          http_method
          status
          created_at
        }
        status
        created_at
      }
    }

`;




export { GET_OBSERVACIONES_BY_DRIVER };
