
export const environment = {
  production: true,
  GITHUB_GRAPHQL_API_ENDPOINT: 'https://api.github.com/graphql',

  HOST: 'http://constancias.reto.gql.semovioaxaca.gob.mx/api',
  PORT: 10018,
  WS_PATH: '/subscriptions',  
  GRAPHQL_PATH: '/api',
  __encrypt: 'FXqJI1pAxNEUXbP',
  tokenID: ' eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IlUyRnNkR1ZrWDE5Umhwb1YwZmRkemQ4Zlo5ZURDSmJxOXY5aUtOdHFxMU09IiwiaWF0IjoxNTYyNzc4NDYwfQ.roFnWfxt-DMLpCO3dZrAeV6s1YYexXJtwjh-8h4hhSw',
  URIConsultaReto: 'https://api.reto.semovioaxaca.gob.mx/graphql',
  URIBackLicencias: 'https://ws.constanciasrl.dle.semovioaxaca.gob.mx/gql',
  URIBackLicenciasLatin: 'https://ws.licencias.ui.semovioaxaca.gob.mx/api',
  URIRetoUtilidades: 'http://172.80.8.188:11012/api',
  URIRegistroRETO: 'http://172.80.8.188:11013/api',
  URLRegistroRETO: 'http://172.80.8.188:11013/',
  URIImage: 'http://172.80.13.12:15010/',
  URISicac: '',//'https://wssicac.semovioaxaca.gob.mx/graphql',
  URIConsultas: 'http://172.80.8.188:11000/api',
  URIConductores: 'http://172.80.8.188:11010/api',
  URIDirecciones: 'http://172.80.8.188:11004/api',
  Version: 'v2.0.7',
  client_id: ' eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJub21icmUiOiJDT05TVEFOQ0lBUyBSRVRPIiwiZGVzY3JpcGNpb24iOiJDT05TVEFOQ0lBUyBSRVRPIiwidXJsIjoiaHR0cHM6Ly9jb25zdWx0YS5yZXRvLnNlbW92aW9heGFjYS5nb2IubXgvIiwicmVkaXJlY3RfdXJsIjoiaHR0cHM6Ly9sYWIuc2Vtb3Zpb2F4YWNhLmdvYi5teC9zYWd1ei9jb25zdWx0YS5yZXRvLmFuZ3VsYXIuZ2l0IiwiaWF0IjoxNjI1MTU1MDU2fQ.dXDlcT1kZmM9TpdwOdh-b1MVZ5C3i3ThbfHy-QzK3YYZm9MUqPfxyW-VHTqXlrr2q8KGqSoCQv9zz2AkVlf4UJ-NTgkZKXU79YH-dB_towsz_aK0k9j5dwRhnza_8Rp_FWugkHTAmfI_epPpRmT3K5iymqK8B53WRaOlY6uSDk9SEyX21ZxgLahukUzb8y46e3UFLzczghLHKjALPihtV59hYMjw4z_wUagJx5DBB8CRqmhIl76FBQ-MNx-5HD8NM5zck1XpFx7V7TH-ZUXT_NmveIXIyW0muPZitTo2XOUaEZOMDWK-CzHa_ajFWk5zV8Oi7aLGs7N6Zb8-b_qgIw',
  URIServicios: 'http://172.80.8.188:1336',
  URIToken: 'http://172.80.8.188:1336',
  URIRecursos: 'https://resource.semovioaxaca.gob.mx',
  Cabecera: 'constancias_reto',
  URLDocumentos: 'http://172.80.8.188:11002/api',
  ID_Aplicacion: 7,
  project_name: "Constancias Reto",
  project_version: 'Desarrollo'
};