import gql from 'graphql-tag';


const PUEDE_ACTUALIZAR_CONDUCTOR = gql`
query getCanUpdateDriver($id: ID!) {
      canUpdateDriver(id: $id)
    }
`;

const PUEDE_REGISTRAR = gql`
query getCanMakeTransactionToDriver($id: ID!) {
	  canMakeTransactionToDriver(id: $id)
	}

`;


export { //CONDUCTOR_TIENE_CONSTANCIA,PUEDE_ACTUALIZAR_CONDUCTOR,PREREGISTRO_INCOMPLETO
  PUEDE_ACTUALIZAR_CONDUCTOR,PUEDE_REGISTRAR
};
