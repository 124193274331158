import gql from 'graphql-tag';

const CREATE_LINEA_CAPTURA = gql`
mutation linea_captura($linea_captura: String!,$amparados: Int!) {
		linea_captura(linea_captura: $linea_captura,amparados: $amparados) {
		  id
		}
	  }

`;


const ASIGNACION_LINEA_CAPTURA = gql`
mutation update_lineaCaptura($id_linea_captura: ID!, $id: ID!)
{
  update_lineaCaptura(id_linea_captura: $id_linea_captura, id: $id){
    id
  }
}
`;

export { CREATE_LINEA_CAPTURA,ASIGNACION_LINEA_CAPTURA };
