export class Capacitacion {
    folio: String;
    vigencia: String;
    descripcion: String;
    constructor(
      folio?: string,
      vigencia?: string,
      descripcion?: string

    ) {}
}
