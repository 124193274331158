import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare var M: any;
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
 import { SessionStorageService } from '../../core/services/sessionstorage.service';
import { AccountService } from '../autentication.service';
import { UtilsService } from '../utils/utils.service';
import { environment } from 'src/environments/environment';
import { RelacionesInputValidacionesService } from '../../services/validaciones/relacioninputvalidaciones.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public password: string;
  public passwordconf: string;
  valid: boolean = false;
  public user: any;
  form: FormGroup;

  constructor(
    private sessionStorageService: SessionStorageService,
    private service: AccountService,
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    public relacionesInputValidacionesService?: RelacionesInputValidacionesService,

  ) { }
  ngOnInit() {
    this.user = JSON.parse(this.sessionStorageService.get('usuario'));

    this.form = this.formBuilder.group({
      clave_privada: this.formBuilder.control('', {
        validators: [Validators.required]
      }),
      nuevaclave: this.formBuilder.control('', this.relacionesInputValidacionesService.getValidacion('password'))
    });
  }

  submit(event) {


    this.service.changepassword({
      nuevaclave: this.utils.hashing(this.getNuevaClave.value),
      clave_privada: this.utils.hashing(this.getClavePrivada.value),
      correo_electronico:  this.user.usuario.correo_electronico
    }).subscribe({
      next: () => { },
      error: (error) => {
        M.toast({html: '<i class="material-icons left">info</i> Error en el cambio de contraseña!'})

      },
      complete: () => {
      let ruta = "/";
      this.service.logout();
      this.sessionStorageService.clean();
      window.location.href = ruta;
      }
    });

  }

  get getNuevaClave(): AbstractControl {
    return this.form.get('nuevaclave');
  }

  get getClavePrivada(): AbstractControl {
    return this.form.get('clave_privada');
  }

}
