import gql from 'graphql-tag';

const GET_COMPROBACION = gql`
query ($id: ID,$id_concesion: String,$id_aplicacion: ID,$id_concesionario: ID) {
  concesion_bloqueadaPersonalizada(id:$id,id_concesion:$id_concesion,id_aplicacion:$id_aplicacion,id_concesionario:$id_concesionario) {
    id
    id_concesion
    id_concesionario
    id_aplicacion
    deprecated
    created_at
  }
}
`;


const GET_MODALIDAD = gql`
query modality($id: ID!){
  modality(id:$id){
    id,
    name
  }
}
`;

const LISTAR_CONCESIONARIOS = gql`
query getConcessionsLike($input: String!, $limit: Int, $option: Int!) {
      concessionsLike(input: $input, limit: $limit, option: $option) {
        id
        concessionaire {
          id
          person_type
          name
          first_surname
          second_surname
          curp
          rfc
          business_name
          address
  	      contact {
  	        email
  	        cel_number
  	      }
          locality {
            id
            name
            municipality {
              id
              name
              district {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        agreement
        agreement_date
        expiration_date
        capture_date
        start_date
        term
        main_notes
        delivery_certificate_notes
        route
        authorized_vehicles
        status
        modality {
          id
          name
          category
          description
          status
          abbreviation
        }
        site {
          id
          name
          legal_representative
          address
          authorized_colors
          locality {
            id
            name
            municipality {
              id
              name
              district {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
          status
        }
        consecutive_number
        unique_number_key
        attached {
          active
          locked {
            status
            stolen_folio
          }
          reto {
            status
          }

        }
      }
    }
`;


const BUSCAR_COMPROBADO = gql`
query findComprobado($nombre:String,$primer_apellido:String,$segundo_apellido:String,$razon_social:String,$nuc:String){
  comprobado(nombre:$nombre,primer_apellido:$primer_apellido,segundo_apellido:$segundo_apellido,razon_social:$razon_social,nuc:$nuc){
    concesionario,
    mensaje,
    codigo,
    documentos{
      tipo,
      url,
      mime
    }
  }
}
`;

const GET_VERIFICACIONRETO = gql`
query getStatusReto(
  $name: String
  $first_surname: String
  $second_surname: String
  $business_name: String
  $nuc: String!
) {
  checkReto(
    name: $name
    first_surname: $first_surname
    second_surname: $second_surname
    business_name: $business_name
    nuc: $nuc
  ) {
    message
    code
  }
}
`;

const GET_CONDUCTORES = gql`
query getCertificatesByConcessionAndPageNumber(
      $concession_id: ID!
      $page_number: Int!
      $limit: Int!
    ) {
      certificatesByConcessionAndPageNumber(
        concession_id: $concession_id
        page_number: $page_number
        limit: $limit
      ) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          status
          modality {
            id
            name
          }
          site {
            id
            name
          }
          consecutive_number
          unique_number_key
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                  code
                }
                code
              }
              settlement_type {
                id
                name
                description
              }
            }
            sublevel {
              id
              name
              municipality {
                id
                name
                code
              }
              sublevel_type {
                id
                name
                description
              }
            }
          }
          trainings {
            id
            folio
            description
            expiration_date
          }
        }
        plate {
          id
          folio
        }
        image {
          id
          base64
        }
        foliated_sheet {
          id
          folio
        }
        expedition_date
        expiration_date
        modality {
          id
          name
        }
        is_canceled
        cancel_description
        is_expired
        status
        created_at
      }
    }

`;



const GET_CERTIFICATE_ACTIVATE = gql`
query getCurrentCertificate($driver_id: ID!) {
  currentCertificate(driver_id: $driver_id) {
    id
    concession {
      id
      sitio {
        nombre
      }
      nuc
    }
    driver {
      name
      first_surname
      second_surname
    }
    plate {
      folio
    }
    image {
      base64
    }
    foliated_sheet {
      folio
    }
    expedition_date
    expiration_date
  }
}
`;


const GET_TOTAL_ELEMENTOS = gql`
query getPaginationByConcession(
	  $concession_id: ID!
	  $limit: Int!
	  $option: Int!
	) {
	  paginationByConcession(
	    concession_id: $concession_id
	    limit: $limit
	    option: $option
	  )
	}
`;


const GET_INCREMENTAL_CONDUCTORES = gql`
query getCertificatesLike($input: String!, $limit: Int) {
      certificatesLike(input: $input, limit: $limit) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          term
          main_notes
          delivery_certificate_notes
          route
          authorized_vehicles
          status
          modality {
            id
            name
          }
          site {
            id
            name
          }
          consecutive_number
          unique_number_key
          attached {
            active
            new_vehicle_auth
            new_concession_auth
          }
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                }
              }
              settlement_type {
                id
                name
              }
            }
            sublevel {
              id
              name
              municipality {
                id
                name
              }
              sublevel_type {
                id
                name
              }
            }
          }
          trainings {
            id
            folio
            description
            expiration_date
          }
        }
        plate {
          id
          folio
        }
        image {
          id
        }
        foliated_sheet {
          id
          folio
        }
        expedition_date
        expiration_date
        modality {
          id
          name
        }
        is_canceled
        cancel_description
      }
    }
`;

const GET_INCREMENTAL_PREREGISTROS = gql`
query getPreCertificatesLike($input: String!, $limit: Int) {
      preCertificatesLike(input: $input, limit: $limit) {
        id
        concession {
          id
          concessionaire {
            id
            person_type
            name
            first_surname
            second_surname
            curp
            rfc
            business_name
          }
          agreement
          agreement_date
          expiration_date
          capture_date
          start_date
          term
          main_notes
          delivery_certificate_notes
          route
          authorized_vehicles
          status
          modality {
            id
            name
            description
            status
            abbreviation
          }
          site {
            id
            name
            legal_representative
            address
            authorized_colors
            status
          }
          consecutive_number
          unique_number_key
          attached {
            active
            new_vehicle_auth
            new_concession_auth
            locked {
              status
              stolen_folio
            }
            reto {
              status
            }
          }
        }
        driver {
          id
          name
          first_surname
          second_surname
          curp
          birthday
          license_number
          contact {
            id
            email
            celphone
            status
            created_at
          }
          address {
            id
            road_name
            road_type {
              id
              name
              description
              status
              created_at
            }
            outdoor_number
            indoor_number
            postal_code
            settlement {
              id
              name
              locality {
                id
                name
                municipality {
                  id
                  name
                }
              }
              settlement_type {
                id
                name
                description
              }
            }
            sublevel {
              id
              name
              municipality {
                id
                name
              }
              sublevel_type {
                id
                name
                description
              }
            }
          }
          trainings {
            id
            folio
            description
            expiration_date
          }
        }
        plate {
          id
          folio
        }
        image {
          id
        }
        is_canceled
        is_completed
        expedition_date
        modality {
          id
          name
        }
      }
    }
`;

export {
  GET_COMPROBACION,
  GET_MODALIDAD,
  LISTAR_CONCESIONARIOS,
  BUSCAR_COMPROBADO,
  GET_VERIFICACIONRETO,
  GET_CONDUCTORES,
  GET_CERTIFICATE_ACTIVATE,
  GET_TOTAL_ELEMENTOS,
  GET_INCREMENTAL_CONDUCTORES,
  GET_INCREMENTAL_PREREGISTROS};
