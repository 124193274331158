
export class Asentamiento {
    nombre: String;
    tipo: String;

    constructor(
      nombre?: String,
      tipo?: String 

    ) {}
}
